import React, { Fragment } from 'react';

export const WeightChartLabels = ({ type, maxValue, minValue, explanation }) => {
    const labels = [];
    let defaultExplanation = 'Years';

    if (type === 'Money') {
        if (maxValue - minValue === 0) {
            minValue = maxValue / 2;
            maxValue = maxValue + maxValue / 2;
        }

        const interval = (maxValue - minValue) / 10;

        defaultExplanation = 'Cost';

        for (let i = minValue, j = 0; j <= 1; i = i + interval, j = j + 0.1) {
            labels.push({
                bottom: (j * 100) + '%',
                text: (Math.round(i) + '$')
            });
        }
    } else {
        if (type === 'Quality') {
            defaultExplanation = 'Quality Impact';
        }
        const showNumber = (num) => Math.round(num * (maxValue > 10 ? 10 : 100)) / (maxValue > 10 ? 10 : 100);
        for (let i = 0; i <= 1; i += .2) {
            labels.push({
                bottom: (i * 100) + '%',
                text: showNumber(maxValue * i) + (type === 'Years' ? '' : '%')
            });
        }
    }

    return (
        <>
            <div className="misery_weight_chart_legend">{explanation || defaultExplanation}</div>
            {labels.map(l => (
                <Fragment key={l.bottom}>
                    <div className="misery_weight_chart_label" style={{ bottom: l.bottom }}>{l.text}</div>
                    <div className="misery_weight_chart_h_line" style={{ bottom: l.bottom }}></div>
                </Fragment>
            ))}
        </>
    );
}