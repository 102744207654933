import React, {useState, useEffect} from 'react';
import { Popup } from 'semantic-ui-react'
import { connect } from 'react-redux';

const FormTitle = React.memo(({ trait, categories }) => {

    const activeCategory = categories.find(c => c.id === trait.category_id);
    let [icon, setIcon] = useState(null);
    useEffect(() => {
        try {
            if (icon) return;
            let issetIcon = trait.icon || activeCategory.icon;
            
            if (issetIcon instanceof Blob) {
                setIcon(URL.createObjectURL(issetIcon.slice(0, issetIcon.size, "image/svg+xml")));
                return;
            }

            setIcon(issetIcon);
        } catch(e) {
            console.log(e);
        }
    }, [trait.icon, icon]);

    return (
        <div className="misery_weight_title">
            <div className='question-icon-container'>
                <Popup
                    className='category-icon-popup'
                    position='top right'
                    hoverable
                    trigger={
                        <div className='disease-group-icon misery-icon'>
                            {icon && icon !== null ? <img src={icon} alt={activeCategory.name} style={{ width: 50 }} /> : null}
                        </div>
                    }
                    content={
                        <div>
                            <p>
                                {activeCategory.name}
                            </p>
                        </div>
                    }
                    inverted
                />
                <h2>
                    {trait.trait_name}
                </h2>
            </div>
        </div>
    )
})

const mapStateToProps = (store) => ({
    categories: store.categories,
    trait: store.activeTrait
});

export default connect(mapStateToProps)(FormTitle);