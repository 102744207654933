import { put, takeLatest, call, select } from 'redux-saga/effects';
import { ESS_ACTIONS } from '../actions/constants';
import actions from '../actions';
import * as api from '../../api/ess';

const { loading, ess } = actions;

function* load(action) {
    yield put(loading.startLoading('ess'));
    try {
        let data;
        if (action.fillNA && typeof action.demographicId === 'number') {
            data = yield call(api.loadDemographicWithFillByGeneral, action.payload, action.demographicId)
        }
        else {
            data = typeof action.demographicId === 'number'
                ? yield call(api.loadDemographic, action.payload, action.demographicId)
                : yield  call(api.load, action.payload);
        }
        let auc_rrr = yield select(store => store.AUCRRRs);
        if (auc_rrr.length === 0) {
            let auc_rrr_data = yield call(api.getRRRs);
            yield put(actions.auc_rrr.aucrrrChange(auc_rrr_data));
            auc_rrr = auc_rrr_data;
        }
        yield put(ess.receiveEss(data));
    } catch (e) {
        console.error(e);
    }
    yield put(loading.endLoading('ess'));
}

function* saveAdditionalInfo(action) {
    yield put(loading.startLoading('ess'));
    try {
        let data = yield call(api.saveAdditionalInfo, action.payload);
        yield put(ess.updateAdditionalInfo(data));
    } catch (e) {
        console.error(e);
    }
    yield put(loading.endLoading('ess'));
}

export function* essSaga() {
    yield takeLatest(ESS_ACTIONS.LOAD, load);
    yield takeLatest(ESS_ACTIONS.SAVE_ADDITIONAL_INFO, saveAdditionalInfo);
}