import { VERSIONS_ACTIONS } from './constants';


export const loadVersions = (page = 'stats', version) => ({
    type: VERSIONS_ACTIONS.LOAD,
    payload: page,
    version
});

export const receiveVersions = (data) => ({
    type: VERSIONS_ACTIONS.RECEIVE,
    payload: data
});

export const chooseVersion = (id) => ({
    type: VERSIONS_ACTIONS.CHOOSE,
    payload: id
});

export const choosedVersion = (id) => ({
    type: VERSIONS_ACTIONS.CHOOSED,
    payload: id
});

export const increaseVersion = () => ({
    type: VERSIONS_ACTIONS.INCREASE
});

export const saveVersion = (payload) => ({
    type: VERSIONS_ACTIONS.SAVE,
    payload
});

export const savedVersion = (payload) => ({
    type: VERSIONS_ACTIONS.SAVED,
    payload
});

