import React, { PureComponent } from 'react'
import s from './NumberInput.module.scss';

class NumberInput extends PureComponent {
    state = { validation: '' }
    ref = React.createRef
    render() {
        let { onChange, value, validationFieldName, ...props } = this.props;
        let { validation } = this.state;
        let {float, ...params} = props;
        return <>
            <input type="number" {...params} onBlur={this.onChange} onKeyDown={() => this.setState({validation: ''})} />
    {validation && <div className={s.inputValidation}>{validation}</div>}
        </>
    }

    onChange = (e) => {
        if (e.target.value === '') {
            this.props.onChange(e);
            return;
        }

        let number = +e.target.value;
        if (String(number) !== e.target.value.trim()) {
            e.target.value = this.props.value;
            this.setState({ validation: `Invalid number` });
            return;
        }
        if (typeof this.props.min === 'number' && number < this.props.min) {
            this.setState({ validation: `${this.props.validationFieldName || 'The value'} should be not less than ${this.props.min}` });
            e.target.value = this.props.value;
            return;
        }
        if (typeof this.props.max === 'number' && number > this.props.max) {
            this.setState({ validation: `${this.props.validationFieldName || 'The value'} should be less than ${this.props.max}` });
            e.target.value = this.props.value;
            return;
        }
        if (this.props.float === false && number !== parseInt(number, 10)) {
            this.setState({ validation: `${this.props.validationFieldName || 'The value'} must be integer` });
            e.target.value = Math.round(e.target.value, 10);
            this.props.onChange(e);
            return;
        }
        this.props.onChange(e);
        this.setState({validation: ''})
    }
}

export default NumberInput