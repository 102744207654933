import { DEMOGRAPHICS_ACTIONS } from '../actions/constants';

export const demographics = (state = [], action) => {
    switch (action.type) {
        case DEMOGRAPHICS_ACTIONS.RECEIVE: return action.payload;
        case DEMOGRAPHICS_ACTIONS.ADDED: return [...state, action.payload];
        case DEMOGRAPHICS_ACTIONS.UPDATED: return state.map(i => i.id === action.payload.id ? action.payload : i); 
        case DEMOGRAPHICS_ACTIONS.DELETED: return state.filter(i => i.id !== action.payload); 
        case DEMOGRAPHICS_ACTIONS.CHOOSE: return state.map(d => ({...d, selected: d.short_name === action.payload.value}))
        case DEMOGRAPHICS_ACTIONS.CLEAR: return state.map(d => ({...d, selected: false}));
        default: return state;
    }
}