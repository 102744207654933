import React, { PureComponent } from 'react';
import './CustomCheckbox.scss';

export default class Checkbox extends PureComponent {

    changeValue = (e) => {
        const { value, disabled, onChange } = this.props;
        if (!disabled) onChange(e, !value);
    }
    render() {
        const { value, disabled, className } = this.props;
        const classList = ['custom-checkbox', value ? 'checked' : '', disabled ? 'disabled' : '', className].filter(i => i).join(' ');
        return <div className={classList} onClick={this.changeValue}><div className="custom-checkbox_check"></div></div>
    }
}