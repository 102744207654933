import { put, takeLatest, call, select } from 'redux-saga/effects';
import actions from '../actions';
import * as api from '../../api/research';

const { loading, research, authors } = actions;

function* loadResearch(action) {
    yield put(loading.startLoading());
    try {
        let data = yield call(api.getResearch);
        yield put(research.receiveResearch(data, action.payload))
    } catch (e) {
        console.error(e);
    }
    yield put(loading.endLoading());
}

function* loadResearchItem(action) {
    yield put(loading.startLoading());
    try {
        let data = yield call(api.getResearchItemById, action.payload);
        yield put(research.receiveResearchItem(data))
    } catch (e) {
        console.error(e);
    }
    yield put(loading.endLoading());
}

function* deleteResearchItem(action) {
    yield put(loading.startLoading());
    try {
        yield call(api.deleteResearchItem, action.payload.id);
    } catch (e) {
        yield put(research.receiveResearchItem(action.payload));
        console.error(e);
    }
    yield put(loading.endLoading());
}

function* updateResearchItem(action) {
    yield put(loading.startLoading());
    try {
        let item = yield call(api.updateResearchItem, action.payload);
        let items = yield select(s => s.research);
        if (!items.some(i => i.id !== item.id)) {
            yield put(research.loadResearch(true));
        } else {
            yield put(research.receiveResearchItem(item));
        }
    } catch (e) {
        console.error(e);
    }
    yield put(loading.endLoading());
}

function* createResearchItem(action) {
    yield put(loading.startLoading());
    try {
        let item = yield call(api.createResearchItem, action.payload);
        yield put(research.receiveResearchItem(item));
        window.location.href= '/administration/research';
    } catch (e) {
        console.error(e);
    }
    yield put(loading.endLoading());
}

function* changeResearchOrder(action) {
    yield put(loading.startLoading());
    try {
        yield call(api.changeResearchOrder, action.payload);
    } catch (e) {
        console.error(e);
    }
    yield put(loading.endLoading());
}

function* loadAllAuthors(action) {
    yield put(loading.startLoading());
    try {
        let data = yield call(api.loadAllAuthors);
        yield put(authors.receiveAllAuthors(data))
    } catch (e) {
        console.error(e);
    }
    yield put(loading.endLoading());
}


export function* researchSaga() {
    yield takeLatest(research.RESEARCH_ACTIONS.LOAD, loadResearch);
    yield takeLatest(research.RESEARCH_ACTIONS.LOAD_ITEM, loadResearchItem);
    yield takeLatest(research.RESEARCH_ACTIONS.DELETE, deleteResearchItem);
    yield takeLatest(research.RESEARCH_ACTIONS.UPDATE, updateResearchItem);
    yield takeLatest(research.RESEARCH_ACTIONS.CREATE, createResearchItem);
    yield takeLatest(authors.AUTHORS_ACTIONS.LOAD_ALL_AUTHORS, loadAllAuthors);
    yield takeLatest(research.RESEARCH_ACTIONS.CHANGE_ORDER, changeResearchOrder);
}