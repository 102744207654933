import React from 'react';
import { withRouter } from 'react-router-dom';

import Header from './Header';
import HeaderMobile from './HeaderMobile';

import { Grid, Responsive } from 'semantic-ui-react';

import './styles.css';

const Navigation = ({ history }) => (
    <header>
        <Responsive maxWidth={959}>
            <Grid className='nav mobile-nav' centered>
                <HeaderMobile />
            </Grid>
        </Responsive>
        <Responsive minWidth={960}>
            <Grid stackable className={`nav ${history.location.pathname !== '/' && 'shadowed-nav'}`}>
                <Header />
            </Grid>
        </Responsive>
    </header>
);

export default withRouter(Navigation);
