export const PMID = 'PMID';
export const SO = 'SO';
export const TI = 'TI';
export const FAU = 'FAU';
export const DP = 'DP';

export const  SEASONS_TO_MONTHS = {
    winter: 'Jan',
    spring: 'Mar',
    summer: 'Jun',
    autumn: 'Sep'
}
