import axios from 'axios';
import { sendToCloud } from './files';

const prefix = process.env.REACT_APP_API;
const publicURL = prefix + 'public/research';
const adminURL = prefix + 'admin/research';
const publicApi = axios.create({ baseURL: publicURL });
const adminApi = axios.create({ baseURL: adminURL });

const splitArrayIntoParams = (arrayOfParams, paramName) => {
    if (arrayOfParams.length > 0) {
        return `&${arrayOfParams.map((param) => `${paramName}[]=${encodeURIComponent(param)}`).join('&')}`;
    }
    return '';
};

export const getResearch = () => adminApi.get('/').then(r => r.data);

export const makePDF = async (data) => {
    const { itemsToExport = [], authors = [], text = '', selectAll = false } = data;
    let query = `/pdf?text=${encodeURIComponent(text)}&selectAll=${selectAll}`;
    query += splitArrayIntoParams(authors, 'authors');
    query += splitArrayIntoParams(itemsToExport, 'items');
    const result = await publicApi.get(query, { responseType: 'blob' });
    return result.data;
};

export const getResearchItemById = id => adminApi.get(`/${id}`).then(r => r.data);

export const getResearchNumber = () => publicApi.get('/count');

export const createResearchItem = async (item) => {
    let data = await adminApi.post('/', item).then(r => r.data);

    if (data && data.presignedDocument) {
        await sendToCloud(data.presignedDocument, item.document.file);
    }
    return data;
};

export const updateResearchItem = async ({id, ...item}) => {
    let result = await adminApi.put('/' + id, item);

    if (result.status === 200 && result.data.presignedDocument) {
        await sendToCloud(result.data.presignedDocument, item.document.file)
    }
    return result.data;
};

export const setPublished = (id, isPublished) => adminApi.put(`/${id}/publish`, { published: isPublished });

export const deleteResearchItem = id => adminApi.delete(`/${id}`);

export const changeResearchOrder = data => adminApi.put(`/order`, data).then(r => r.data);

export const loadPage = async (data) => {
    const { page = 0, limit, authors = [], text = '', desc = false } = data;
    let query = `/page?page=${page}&limit=${limit}&text=${encodeURIComponent(text)}&desc=${desc}`;
    query += splitArrayIntoParams(authors, 'authors');
    const result = await publicApi.get(query);
    return result.data;
}

export const loadAllAuthors = (data) => publicApi.get(`/authors`).then(r => r.data)
