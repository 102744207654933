import React, { PureComponent, Fragment } from 'react';
import FrequencySection from './FrequencySection';
import { connect } from 'react-redux';

class Frequency extends PureComponent {

    render() {
        let { votes } = this.props;
        votes = Array.from(new Set(votes.map(v => v.sexId)))
            .map(id => ({ sex: id, votes: votes.filter(v => v.sexId === id) }))
            .filter(i => i.votes.length);
        return <Fragment>
            {votes.map(v => <FrequencySection key={v.sex} votes={v.votes} title={`Lifetime Risk ${v.votes[0].sex.name}`} />)}
        </Fragment>
    }
}
const mapStateToProps = (store) => ({
    frequencies: store.frequencies,
    sex: store.sex
})
export default connect(mapStateToProps)(Frequency);