import React, { useState, useEffect, useRef } from 'react';

const XLabel = ({ styleObj, text }) => {
    const [width, setWidth] = useState(null);
    const ref = useRef(null);

    useEffect(() => {
        if (ref.current && !width && width !== ref.current.offsetWidth) {
            setWidth(ref.current.offsetWidth);
        }
    }, [ref, width]);

    return (
        <div
            className="misery_weight_chart_label x_label"
            style={{ 
                ...styleObj,
                marginLeft: width ? -(width / 2) : 0
            }}
            ref={ref}
        >
            {text}
        </div>
    );
};

export default XLabel;