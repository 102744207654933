import React from 'react';
import { Navigation, Footer } from './common';
import { changeIsPrivate } from '../store/actions/private';
import { connect } from 'react-redux';


const App = React.memo((props) => {
    
    // let showPrivacyInfo = privacy !== 'false' && pathname !== '/privacy' && localStorage['private'] !== 'false';

    return (
        <>
            {/* {showPrivacyInfo && <UserDataNotification />} */}
            <Navigation />
            <div>
                {props.children}
            </div>
            <Footer />
        </>
    );
})

export default connect(({ privacy }) => ({ privacy }), { changeIsPrivate })(App);
