import React, { memo, useEffect, useState } from 'react';
import { WeightChart } from './charts';
import { connect } from 'react-redux';
import SelectTrait from './charts/controls/SelectTrait';

const WeightSubmitted = ({ fields }) => {
    const charts = fields.filter(f => f.type !== 'List');
    const [selectedTraits, setSelectedTraits] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [fields])

    return <div className="misery_weight_submitted">
        <p className="misery_weight_submitted_title"><b>Thank you very much for your contribution!<br />
                We really appreciate your help! <br />
        </b>
        </p>
        <p>
            You can contribute your knowledge for another disease <br />
                or continue <a href="/">exploring our website</a>.
                <a href="/weight" className="button misery_weight_submitted_contibute">Contribute</a>
        </p>
        <div className="misery_weight_submitted_divider"></div>
        <p>
            Review diseases weight statistics based on other users’ contribution.
        </p>
        <SelectTrait placeholder="Select Common Diseases" selectedTraits={selectedTraits} setSelectedTraits={setSelectedTraits} />
        {charts.map((data, ind) => {
            return <WeightChart key={ind} field={data} ind={ind} commonTraits={selectedTraits} />
        })}
    </div>
}

export default connect((store) => ({
    fields: store.voteFields,
}), {})(WeightSubmitted);