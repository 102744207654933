import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import { Menu, Grid } from 'semantic-ui-react';

import ic_logo_header from '../../resources/images/ic_logo_header.svg';
import { connect } from 'react-redux';
import { clearTrait } from '../../store/actions/traits';
import { updateSex } from '../../store/actions/sex';
import { updateRegions } from '../../store/actions/regions';
import {mwEditChange as changeEditMode} from '../../store/actions/mw.edit';



class Header extends PureComponent {
    render() {
        let user = this.props.user || {role: 'guest'};

        return <Grid.Row centered>
            <Grid.Column width={2}>
                <div className="logo_wrapper">
                    <img src={ic_logo_header} alt={ic_logo_header} />
                    <Link className="item" to='/'>
                        <h3 className='title'>Embryo Selection</h3>
                    </Link>
                </div>        
            </Grid.Column>
            <Grid.Column width={12} verticalAlign='middle'>
                <Menu className='nav-menu' attached borderless>
                    <Link className="item" to='/' >
                        <h3>HOME</h3>
                    </Link>
                    {/* <Link className="item" to='/choose'>
                        <h3>CHOOSE</h3>
                    </Link>
                    <Link className="item" to='/design'>
                        <h3>DESIGN</h3>
                    </Link>
                    <Link className="item" to='/browse'>
                        <h3>BROWSE</h3>
                    </Link> */}
                    <Link className="item" to='/weight' onClick={this.clearTrait}>
                        <h3>WEIGHT</h3>
                    </Link>
                    {user?.role === 'admin' && <Link className="item" to='/administration'>
                        <h3>ADMINISTRATION</h3>
                    </Link>}
                    {user?.role === 'senior' && <Link className="item" to='/administration-senior'>
                        <h3>ADMINISTRATION</h3>
                    </Link>}
                    {localStorage['token'] && <Link className="item" to='/logout'>
                        <h3>SIGN OUT</h3>
                    </Link>}
                </Menu>
            </Grid.Column>
        </Grid.Row>
    }

    clearTrait = () => {
        this.props.clearTrait();
        this.props.updateSex({ value: null });
        this.props.updateRegions({ value: null });
        this.props.changeEditMode('INITIAL');
    }
}

const mapDispatchToProps = {
    updateRegions,
    updateSex,
    clearTrait,
    changeEditMode
}

export default connect(({user}) => ({user}), mapDispatchToProps)(Header)