import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Checkbox, Popup } from 'semantic-ui-react';
class SelectTrait extends PureComponent {
    state = {
        expanded: false,
    };

    render() {
        const { expanded } = this.state;
        const {
            placeholder = 'Select Disease',
            selectedTraits = [],
            traits,
            categories,
            activeTrait,
            setSelectedTraits: onChange,
            user,
        } = this.props;
        const traitsWithActive = [
            ...traits,
            !traits.some(t => t.id === activeTrait.id) ? activeTrait || null : null,
        ].filter(t => t);

        const dataTree = categories
            .filter(c => (user.role === 'user' ? c.name !== 'Inactive' : c))
            .map(category => ({
                category_name: category.name,
                category_id: category.id,
                traits: traitsWithActive
                    .filter(t => t.category_id === category.id)
                    .map(t => ({
                        name: t.trait_name,
                        trait_text: t.trait_text,
                        id: t.id,
                        selected:
                            selectedTraits.indexOf(t.id) > -1 || t.id === activeTrait.id,
                    })),
            }));
        const selectedTitles = traitsWithActive
            .filter(t => selectedTraits.some(s => s === t.id) || t.id === activeTrait.id)
            .map(t => t.trait_name)
            .join(', ');
        return (
            <div
                className={[
                    'misery_weight_chart_dropdown wide',
                    expanded ? 'expanded' : '',
                ].join(' ')}
            >
                <div
                    className="misery_weight_chart_dropdown_title"
                    onClick={this.toggleView}
                >
                    {selectedTitles || placeholder}
                </div>
                <div
                    className="dropdown_fake_background"
                    onClick={e => this.toggleView(e, false)}
                ></div>
                <div className="misery_weight_chart_dropdown_list">
                    {dataTree
                        .filter(c => c.traits.length)
                        .map(c => (
                            <div
                                className="misery_weight_chart_dropdown_list_category"
                                key={c.category_id}
                                onClick={e => {
                                    const isChecked =
                                        c.traits.filter(t => t.selected).length ===
                                        c.traits.length;
                                    onChange(
                                        isChecked
                                            ? selectedTraits.filter(
                                                  s => !c.traits.some(t => t.id === s)
                                              )
                                            : [
                                                  ...selectedTraits,
                                                  ...c.traits.map(t => t.id),
                                              ]
                                    );
                                }}
                            >
                                <Checkbox
                                    checked={
                                        c.traits.filter(t => t.selected).length ===
                                        c.traits.length
                                    }
                                />
                                <div className="misery_weight_chart_dropdown_list_category_title">
                                    {c.category_name}
                                </div>
                                <div className="misery_weight_chart_dropdown_list_traits">
                                    {c.traits.map(t => (
                                        <div
                                            className="misery_weight_chart_dropdown_list_trait"
                                            key={t.id}
                                            onClick={e => {
                                                e.stopPropagation();
                                                return t.id === activeTrait.id
                                                    ? null
                                                    : onChange(
                                                          t.selected
                                                              ? selectedTraits.filter(
                                                                    s => s !== t.id
                                                                )
                                                              : [...selectedTraits, t.id]
                                                      );
                                            }}
                                        >
                                            <Checkbox
                                                checked={
                                                    t.selected || t.id === activeTrait.id
                                                }
                                                disabled={t.id === activeTrait.id}
                                            />
                                            <div className="misery_weight_chart_dropdown_list_trait_title">
                                                {t.name}
                                                <Popup
                                                    position="top right"
                                                    hoverable
                                                    trigger={
                                                        <div className="help_icon"></div>
                                                    }
                                                    content={
                                                        <div>
                                                            <p>{t.trait_text || ''}</p>
                                                        </div>
                                                    }
                                                    inverted
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        );
    }

    toggleView = (e, to = null) =>
        this.setState({ expanded: to === null ? !this.state.expanded : to });
}

const mapStateToProps = store => ({
    traits: store.traits.filter(t => t.approved),
    categories: store.categories,
    activeTrait: store.activeTrait,
    user: store.user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SelectTrait);
