import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Table } from './PHEATable';
import s from './PHEA.module.scss';
import actions from '../../store/actions';
import { classList } from '../../utils';
import { Dropdown } from '../common';
import { PHEAMap } from './PHEAMap';

const PHEA = React.memo((props) => {
    let activeVersion = props.versions.find(v => v.selected);
    let [fractionOfBirth, setFractionOfBirth] = useState(1);
    let birthInput = useRef();
    useEffect(() => {
        props.loadVersions('phea', props?.match?.params?.version);
    }, []);

    useEffect(() => {
        birthInput.current.value = Math.round(fractionOfBirth * 10000) / 100;
    }, [fractionOfBirth])

    if (!props?.match?.params?.version && activeVersion) {
        props.history.push(`/administration/phea/${activeVersion.major}-${activeVersion.changed}-${activeVersion.updated}`);
    }

    let versions = props.versions.map(v => {
        const version = { ...v }
        if (version.updatedAt) {
            let date = new Date(version.updatedAt);
            version.updatedAt = `(${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()})`;
        } else {
            version.updatedAt = '';
        }
        return version;
    });

    const onVersionChoose = (value) => {
        let version = versions.find(v => v.id === value);
        if (version) {
            props.history.push(`/administration/phea/${version.major}-${version.changed}-${version.updated}`);
        }

        props.chooseVersion(value);
        props.loadPhea(value);
    }

    const phea = props.phea.find(p => p.version === activeVersion?.id)?.data || {};
    const { general, regions } = phea;

    let [expandedTables, setExpandedTables] = useState(false);

    if (regions?.length && !expandedTables) {
        let expandedInitialData = {};
        for (let i = 0; i < regions.length; i++) {
            expandedInitialData[regions[i].short_name] = i > 0;
        }
        setExpandedTables(expandedInitialData);
    }
    let selectedVersion = versions.find(v => v.selected);

    const checkBirth = () => {
        let newValue = +birthInput.current.value;
        if (isNaN(newValue) || newValue <= 0 || newValue > 100) {
            if (fractionOfBirth === 1) birthInput.current.value = 100;
            setFractionOfBirth(1);
            return;
        }
        setFractionOfBirth(newValue / 100);
    }

    const checkKey = (e) => {
        if (e.key === 'Enter') checkBirth();
    }

    return (<div className={classList(s.Page, props.loading && s.Page_loading)}>
        <h1>Public Health</h1>
        <div className={s.Content}>
            <div className={s.Controls}>
                <div>
                    <label>Public Health based on ESS version: </label><Dropdown autoComplete={true} items={versions.map(v => ({
                        text: `${v.major}.${v.changed}.${v.updated} ${v.updatedAt}`,
                        value: v.id,
                        selected: v.selected
                    }))}
                        onChange={onVersionChoose}
                        className={s.Dropdown}
                    />
                </div>
                <div className={s.FractionOfBirth}>
                    <label>Fraction of Births:</label>
                    <input type="text" className={s.FractionOfBirth_Input} ref={birthInput} onBlur={checkBirth} onKeyPress={checkKey} />
                </div>
                {selectedVersion && <a target="_blank" rel="noopener norefferrer" href={`/api/public/phea/${selectedVersion.id}`} className={s.ApiLink}>API</a>}
            </div>

            {general && <>
                <h3 className={s.Title}>Overall Impact for ALL Genomic Ancestry</h3>
                <div className={s.TableWrapper}><Table fractionOfBirth={fractionOfBirth} data={general.map(i => ({
                    ...i,
                    economic_impact_rrr: {
                        common: i.economic_impact_rrr.common * fractionOfBirth,
                        male: i.economic_impact_rrr.male * fractionOfBirth,
                        female: i.economic_impact_rrr.female * fractionOfBirth
                    },
                    lifespan_impact_rrr: {
                        common: i.lifespan_impact_rrr.common * fractionOfBirth,
                        male: i.lifespan_impact_rrr.male * fractionOfBirth,
                        female: i.lifespan_impact_rrr.female * fractionOfBirth
                    },
                    life_quality_impact_rrr: {
                        common: i.life_quality_impact_rrr.common * fractionOfBirth,
                        male: i.life_quality_impact_rrr.male * fractionOfBirth,
                        female: i.life_quality_impact_rrr.female * fractionOfBirth
                    }
                }))} regional={false} /></div>
            </>}
            {regions?.length > 0 && <>
                <h3 className={s.Title}>Impact By Region</h3>
                <div className={s.RegionsContainer}>
                    <PHEAMap phea={phea} fractionOfBirth={fractionOfBirth} />
                    {regions.map(r => <div className={classList(s.RegionsTableContainer, expandedTables[r.short_name] && s.RegionsTableContainer_Expanded)} key={r.short_name}>
                        <h4 className={s.Title} onClick={() => setExpandedTables({ ...expandedTables, [r.short_name]: !expandedTables[r.short_name] })}>{r.name}</h4>
                        <div className={s.RegionInfo}>
                            {(r.population || r.population === 0) && <a href={r.population_source} rel="noopener noreferrer" target="_blank">Population {r.population}, </a>}
                            {r.demographics?.length && <a href={r.demos_source} target="_blank" rel="noopener noreferrer">{r.demographics.map(d => `${d.short_name} ${d.regions_demo.percent}%`).join(', ')}</a>}
                            {(r.births || r.births === 0) && <a href={r.births_source} rel="noopener noreferrer" target="_blank">, Births/year {r.births}</a>}
                        </div>
                        <div className={classList(s.TableWrapper, expandedTables[r.short_name] && s.TableWrapper_Hidden)}>
                            <Table data={r.traits.map(i => ({
                                ...i,
                                economic_impact_rrr: {
                                    common: i.economic_impact_rrr.common * fractionOfBirth,
                                    male: i.economic_impact_rrr.male * fractionOfBirth,
                                    female: i.economic_impact_rrr.female * fractionOfBirth
                                },
                                lifespan_impact_rrr: {
                                    common: i.lifespan_impact_rrr.common * fractionOfBirth,
                                    male: i.lifespan_impact_rrr.male * fractionOfBirth,
                                    female: i.lifespan_impact_rrr.female * fractionOfBirth
                                },
                                life_quality_impact_rrr: {
                                    common: i.life_quality_impact_rrr.common * fractionOfBirth,
                                    male: i.life_quality_impact_rrr.male * fractionOfBirth,
                                    female: i.life_quality_impact_rrr.female * fractionOfBirth
                                }
                            }))} regional={true} />
                        </div>
                    </div>)}
                </div>
            </>}
        </div>
    </div>
    )
});
const mapStateToProps = (store) => ({
    versions: store.versions,
    phea: store.phea,
    regions: store.adminRegions,
    loading: store.loading.length > 0
})

const mapDispatchToProps = {
    loadVersions: actions.versions.loadVersions,
    chooseVersion: actions.versions.chooseVersion,
    loadPhea: actions.phea.loadPhea
}
export default connect(mapStateToProps, mapDispatchToProps)(PHEA);
