import { takeLatest, call, put } from 'redux-saga/effects';
import { DEMOGRAPHICS_ACTIONS } from '../actions/constants';
import * as actions from '../actions/demographics';
import * as loadActions from '../actions/loading';
import api from '../../api/demographics';

function* loadDemographics(action) {
    yield put(loadActions.startLoading('demographics'));
    try {
        let res = yield call(action.payload.admin ? api.loadAdmin : api.load);
        yield put(actions.receiveDemographics(res.data));
    } catch (err) {
        console.log(err);
    }
    yield put(loadActions.endLoading('demographics'));
}

function* deleteDemo(action) {
    try {
        let res = yield call(api.delete, action.payload);
        if (res.status !== 200) throw new Error(res.data);
        yield put(actions.removedDemographics(action.payload));
    } catch (err) {
        console.log(err);
    }
}

function* addDemo(action) {
    try {
        let res = yield call(api.add, action.payload);
        if (res.status !== 200) throw new Error(res.data);
        yield put(actions.addedDemographics(res.data));
    } catch (err) {
        console.log(err);
    }
}

function* updateDemo(action) {
    yield put(loadActions.startLoading('demographics'));
    try {
        let res = yield call(api.update, action.payload);
        if (res.status !== 200) throw new Error(res.data);
        yield put(actions.updatedDemographics(res.data));
    } catch (err) {
        console.log(err);
    }
    yield put(loadActions.endLoading('demographics'));
}

export function* demographicsSaga() {
    yield takeLatest(DEMOGRAPHICS_ACTIONS.LOAD, loadDemographics);
    yield takeLatest(DEMOGRAPHICS_ACTIONS.DELETE, deleteDemo);
    yield takeLatest(DEMOGRAPHICS_ACTIONS.ADD, addDemo);
    yield takeLatest(DEMOGRAPHICS_ACTIONS.UPDATE, updateDemo);
}
