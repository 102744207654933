import React, { Component } from 'react';
import { connect } from 'react-redux';
import { GenomicIndexTableRowHead, GenomicIndexTableRow } from './Table';
import './GenomicIndex.scss';
import { loadDemographics } from '../../store/actions/demographics';
import { loadGenomicIndex, removeGenomicIndex } from '../../store/actions/genomic.index';
import { classList } from '../../utils';

class GenomicIndex extends Component {
    state = { loading: false, status: '' }
    
    render() {
        let { data = [], loading } = this.props;
        data.sort((a, b) => b.id - a.id);
        return <div className={classList('genomic-index', loading && 'loading')} data-status={this.state.status}>
            <h1>Genomic Index</h1>
            <div className="edit-traits_container">
                <div className="genomic-index_table">
                    <GenomicIndexTableRowHead />

                    {data.map(i => <GenomicIndexTableRow  key={i.id} {...i} />)}

                    <GenomicIndexTableRow id="new" />
                </div>
            </div>
        </div>
    }
    componentDidMount() {
        this.props.loadGenomicIndex();
        if (!this.props.demographics.length) this.props.loadDemographics();
    }

}
const mapStateToProps = (store) => ({
    data: store.genomicIndex,
    demographics: store.demographics,
    loading: store.loading.some(l => l === 'load_index' || l === 'save_index')
})

const mapDispatchToProps = {
    loadGenomicIndex,
    removeGenomicIndex,
    loadDemographics
}
export default connect(mapStateToProps, mapDispatchToProps)(GenomicIndex);