import React from 'react';
import { Container, Card, Responsive } from 'semantic-ui-react'

import './pageWrapper.scss';

const PageWrapper = ({ title, children, className, icon }) => (
    <Container>
        <div className='page-container'>
            <h1 className='page-title'>
                {icon && (
                    <img className="page-title-icon" src={icon} alt="icon" />
                )}
                {title}
            </h1>
            <Card raised className={`card-container ${className}`}>
                {children}
            </Card>
        </div>
    </Container>
);

export default PageWrapper;
