import { ADMIN_REGIONS_ACTIONS } from './constants';

export const removeRegion = (id) => ({
    type: ADMIN_REGIONS_ACTIONS.REMOVE,
    payload: id
});

export const removedRegion = (id) => ({
    type: ADMIN_REGIONS_ACTIONS.REMOVED,
    payload: id
});

export const updateRegion = (data) => ({
    type: ADMIN_REGIONS_ACTIONS.UPDATE,
    payload: data
});

export const addRegion = (data) => ({
    type: ADMIN_REGIONS_ACTIONS.ADD,
    payload: data
});

export const saveRegion = (data) => ({
    type: ADMIN_REGIONS_ACTIONS.SAVE,
    payload: data
});

export const loadRegions = () => ({
    type: ADMIN_REGIONS_ACTIONS.LOAD
})

export const loadedRegions = (data) => ({
    type: ADMIN_REGIONS_ACTIONS.LOADED,
    payload: data
})



