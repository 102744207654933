import React from 'react';

import Carousel from './Carousel';

import { Container, Responsive } from 'semantic-ui-react'

import './styles.css';

const Media = () => (
    <Container>
        <div className='page-container'>
            <h1>Media</h1>
            <Responsive maxWidth={959}>
                <Carousel />
            </Responsive>
            <Responsive minWidth={960}>
                <Carousel desktop={true} />
            </Responsive>
        </div>
    </Container>
);

export default Media;
