import React, {useEffect, useState} from "react";
import styles from "../IndexStatus.module.scss";
import {classList} from "../../../../../utils";
import {IndexStatusActivityBlock} from "./IndexStatusActivityBlock";
import {Popup, Item} from "semantic-ui-react";
import {getVotesByIds} from "../../../../../api/votes";
import VotePopup from "./VotePopup";

const regions = {
    'European': 'EU',
    'East Asian': 'EA',
    'South Asian': 'SA',
    'African': 'AF',
    'American Native': 'AN',
    'Do Not Know/All': 'DK'
}

export const TraitMetricDetailsPopup = ({metricVotes}) => {
    const [votes, setVotes] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const ids = Object.keys(metricVotes)
            .map(demo => metricVotes[demo].map(vote => vote.id))
            .reduce((acc, cur) => [...acc, ...cur], []);
        getVotesByIds(ids).then(votes => {
            setVotes(votes);
            setIsLoading(false)
        });
    }, []);
    console.log(votes);
    return (
        <div className={styles.metric_popup}>
            <div className={styles.color_descriptions}>
                <div>
                    <div className={styles.desc}>
                        <div className={classList(styles.rectangle, styles.green)}/>
                        Vote Approved
                    </div>
                    <div className={styles.desc}>
                        <div className={classList(styles.rectangle, styles.gray)}/>
                        Vote Awaiting Approval
                    </div>
                </div>
            </div>
            <div className={styles.region_chin}>
                {Object.keys(regions).map(region => (
                    <div key={region}>
                        <div className={styles.sexes}>
                            <div className={styles.sex}>
                                {metricVotes[regions[region]]
                                    .filter(vote => vote.sex === 'male')
                                    .sort((a, b) => b.approved - a.approved || b.confirmations - a.confirmations)
                                    .map(vote =>
                                        <Popup
                                            position="top left"
                                            hoverable
                                            trigger={
                                                <Item>
                                                    <IndexStatusActivityBlock
                                                        activityCount={vote.confirmations}
                                                        isConfirms={true}
                                                        isApproved={vote.approved}
                                                    />
                                                </Item>
                                            }
                                            content={
                                                <>
                                                    {isLoading
                                                        ? (<p>Loading...</p>)
                                                        : (
                                                            <VotePopup
                                                                vote={votes.find(fetchedVote => fetchedVote.id === vote.id)}
                                                            />
                                                        )
                                                    }
                                                </>
                                            }
                                            inverted
                                            key={`${vote.sex} ${vote.id} ${region}`}
                                        />
                                    )}
                                <div>M</div>
                            </div>
                            <div className={styles.sex}>
                                {metricVotes[regions[region]]
                                    .filter(vote => vote.sex === 'female')
                                    .sort((a, b) => b.approved - a.approved || b.confirmations - a.confirmations)
                                    .map(vote =>
                                        <Popup
                                            position="top left"
                                            hoverable
                                            trigger={
                                                <Item>
                                                    <IndexStatusActivityBlock
                                                        activityCount={vote.confirmations}
                                                        isConfirms={true}
                                                        isApproved={vote.approved}
                                                    />
                                                </Item>
                                            }
                                            content={
                                                <>
                                                    {isLoading
                                                        ? (<p>Loading...</p>)
                                                        : (
                                                            <VotePopup
                                                                vote={votes.find(fetchedVote => fetchedVote.id === vote.id)}
                                                            />
                                                        )
                                                    }
                                                </>
                                            }
                                            inverted
                                            key={`${vote.sex} ${vote.id} ${region}`}
                                        />
                                    )}
                                <div>F</div>
                            </div>
                        </div>
                        <div>{region}</div>
                    </div>
                ))}
            </div>
        </div>
    )
}
