import React, { useState } from 'react';
import { Field, Form, FieldArray, ErrorMessage } from 'formik';

import DocumentDropZone from './DocumentDropZone';
import { Checkbox } from '../common';
import { classList } from '../../utils';
import s from './Research.module.scss';

const ResearchForm = ({ values, errors, touched, handleDrop, setFieldValue, setFieldTouched, setFieldError, allAuthors = [], ...props }) => {
    let [autoComplete, setAutoComplete] = useState({ authors: [], visible: false, index: 0 })
    return (
        <Form className={s.Form}>
            <div className="is-published-box">
                <Field
                    as="input"
                    type="checkbox"
                    name="published"
                >
                    {() => (
                        <Checkbox value={values && values.published} onChange={e => setFieldValue('published', values && !values.published)} />
                    )}
                </Field>
                <label>Publish</label>
            </div>
            <div className="mdl-grid">
                <div className={classList(s.Form__Row)}>
                    <label className="required" htmlFor="title">Title</label>
                    <ErrorMessage className={s.Validation__Error} component="span" name="title" />
                    <Field
                        className={classList(s.Input, errors.title && touched.title && s.Input_Error)}
                        as="input"
                        type="text"
                        name="title"
                        id="title"
                        placeholder="Add Research Title"
                    />
                </div>
                <div className={classList(s.Form__Row)}>
                    <label htmlFor="url">Document URL</label>
                    <ErrorMessage className={s.Validation__Error} component="span" name="url" />
                    <Field
                        className={classList(s.Input, errors.url && touched.url && s.Input_Error)}
                        as="input"
                        type="text"
                        name="url"
                        id="url"
                        placeholder="Add Research Document URL"
                    />
                </div>
                <div className={classList(s.Form__Row)}>
                    <div className="datepickers">
                        <div className="datepicker-item">
                            <label htmlFor="publicationDate">Publication Date</label>
                            <Field
                                placeholder="YYYY mmm, dd"
                                as="input"
                                type="text"
                                className={classList(s.Input, errors.dp && touched.dp && s.Input_Error)}
                                name="dp"
                                id="dp"
                            />
                        </div>
                    </div>
                </div>
                <div className={classList(s.Form__Row)}>
                    <label htmlFor="source">Journal</label>
                    <ErrorMessage className={s.Validation__Error} component="span" name="source" />
                    <Field
                        className={classList(s.Input, errors.source && touched.source && s.Input_Error)}
                        as="input"
                        type="text"
                        name="source"
                        id="source"
                        placeholder="Add Research Journal"
                    />
                </div>
                <div className={classList(s.Form__Row)}>
                    <label htmlFor="pmid">PubMed Id</label>
                    <ErrorMessage className={s.Validation__Error} component="span" name="pmid" />
                    <Field
                        className={classList(s.Input, errors.pmid && touched.pmid && s.Input_Error)}
                        as="input"
                        type="number"
                        name="pmid"
                        id="pmid"
                        placeholder="Add PubMed Id"
                    />
                </div>
                <div className={classList(s.Form__Row, s.Form__Row_Dropzone)}>
                    <label htmlFor="document">Document</label>
                    <ErrorMessage className={s.Validation__Error} component="span" name="document" />
                    <Field
                        name="document"
                    >
                        {props => (
                            <DocumentDropZone
                                isError={errors.document && touched.document}
                                setFieldValue={setFieldValue}
                                setFieldTouched={setFieldTouched}
                                setFieldError={setFieldError}
                                placeholder="Click To Add Research Document"
                                {...props}
                            />
                        )}
                    </Field>
                </div>
                <div className={classList(s.Form__Row)}>
                    <div className="author-fields">
                        <FieldArray
                            name="authors"
                            render={arrayHelpers => {
                                if (!values.authors?.length) {
                                    arrayHelpers.push('');
                                }

                                return (<>
                                    <label htmlFor="authors">Authors</label>
                                    {errors.authors && touched.authors && <span className={s.Validation__Error}>Make sure you've added at least one author</span>}
                                    {values.authors && values.authors.map((item, ind) => (
                                        <div className="author" key={'author-' + ind}>
                                            <Field
                                                className={classList(s.Input, 
                                                errors.authors && errors.authors[ind] && errors.authors[ind] &&
                                                    touched.authors && touched.authors[ind] && touched.authors[ind] && s.Input_Error
                                                )}
                                                placeholder="Enter Full Name"
                                                type="text"
                                                style={{marginBottom: 10}}
                                                name={`authors[${ind}]`}
                                                id={`authors[${ind}]`}
                                                autoComplete={'off'}
                                                onChange={(e) => {
                                                    setFieldValue(`authors[${ind}]`, e.target.value);
                                                    setAutoComplete({
                                                        authors: [e.target.value, ...allAuthors.map(a => a.name).filter(a => ~a.toLowerCase().indexOf(e.target.value.toLowerCase().trim()))],
                                                        index: ind
                                                    })
                                                }}

                                            />
                                            {autoComplete.authors.length > 0 && autoComplete.index === ind && (<>
                                                <div className={s.Autocomplete__BlurHandler} onClick={() => setAutoComplete({ authors: [], index: null })}></div>

                                                <div className={classList(s.Autocomplete)}>
                                                    {autoComplete.authors.map(a => <div className={s.Autocomplete__Item} key={a} onClick={() => {
                                                        setFieldValue(`authors[${autoComplete.index}]`, a);

                                                        setAutoComplete({ authors: [], index: null });
                                                    }}>{a}</div>)}
                                                </div></>)}
                                            {ind > 0 && (
                                                <button className={s.Btn_Delete} style={{marginLeft: 10}}
                                                    onClick={() => arrayHelpers.remove(ind)}>delete</button>
                                            )}
                                        </div>
                                    ))}
                                    <p className={s.Btn_Add_Author} onClick={() => arrayHelpers.push('')}>
                                        Add New Author
                                    </p>
                                </>
                                )
                            }
                            }
                        />
                    </div>
                </div>
            </div>
        </Form>
    );
}

export default ResearchForm;