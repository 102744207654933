import React, { PureComponent } from 'react';
import s from './Medline.module.scss';
import { Checkbox } from '../../common';
import { classList, parseMedlineDate } from '../../../utils';

class ResearchItemPreview extends PureComponent {
    render() {
        const { PMID, TI, SO, DP, FAU, onChoose, checked, disabled } = this.props;
        
        let publicationDate = parseMedlineDate(DP);

        return (
            <div className={classList(s.ResearchItemPreview, checked && s.ResearchItemPreview_Checked, disabled && s.ResearchItemPreview_Disabled)} data-text={disabled ? 'Item with identical PubMedId already exists' : '' }>
                <div className={s.ResearchItemPreview__Title}>
                    <Checkbox onChange={() => onChoose(PMID)} value={checked} className={s.Checkbox} />
                    {TI}
                </div>
                <div className={s.ResearchItemPreview__Source}>{SO}</div>
        <div className={s.ResearchItemPreview__Date}>{DP} {publicationDate && <>({publicationDate.toDateString()})</>}</div>
                <div className={s.ResearchItemPreview__Authors}>
                    {FAU.map(a => <div key={a} className={s.ResearchItemPreview__Author}>{a}</div>)}
                </div>
            </div>
        )
    }
}

export default ResearchItemPreview;