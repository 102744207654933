import * as votes from './votes';
import * as traits from './traits';
import * as categories from './categories';
import * as chromosomes from './chromosomes';
import * as newvotes from './newvotes.actions';
import * as voteFields from './vote.fields.actions';
import * as loading from './loading';
import * as validation from './weight.validation';
import * as popups from './popups';
import * as demographics from './demographics';
import * as sexes from './sex';
import * as log from './log';
import * as users from './users';
import * as adminRegions from './admin.regions';
import * as ess from './ess';
import * as versions from './versions';
import * as phea from './phea';
import * as mwEdit from './mw.edit';
import * as contribution from './contribution';
import * as auc_rrr from './auc.rrr';
import * as settingsActions from './settings';
import * as research from './research';
import * as authors from './authors';
import * as countries from './countries';
import * as statistic from './statistic.actions';

export default {
    votes,
    traits,
    categories,
    chromosomes,
    newvotes,
    voteFields,
    loading,
    validation,
    popups,
    demographics,
    sexes,
    log,
    users,
    adminRegions,
    ess,
    phea,
    versions,
    mwEdit,
    contribution,
    auc_rrr,
    settingsActions,
    research,
    authors,
    countries,
    statistic
}
