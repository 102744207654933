import React from 'react';
import styles from './Statistic.module.scss';
import { StatisticTabs } from "./StatisticTabs";


const StatisticContainer = (props) => {
    return (
        <div className={styles.statistic}>
            <StatisticTabs activeTab={props.activeTab} />
            {props.children}
        </div>
    )
}

export default StatisticContainer;
