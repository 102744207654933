import { takeLatest, call, put } from 'redux-saga/effects';
import { GENOMIC_INDEX_ACTIONS } from '../actions/constants';
import * as actions from '../actions/genomic.index';
import * as loading from '../actions/loading';
import * as GenomicIndexApi from '../../api/genomicIndex';

function* loadIndex() {
    yield put(loading.startLoading('load_index'));
    let data = [];
    try {
        data = yield call(GenomicIndexApi.load);
    } catch (err) {
        console.log(err);
    }

    for (let item of data) {
        if (item.csvFileName) {
            try {
                let data = yield call(GenomicIndexApi.loadFile, 'GSBS', item.csvFileName);
                item.gsbs = { name: item.csvFileName, data };
            } catch(e) {
                console.log(e);
            }
        }
        if (item.gain) {
            try {
                let data = yield call(GenomicIndexApi.loadFile, 'GAIN', item.gain);
                item.gain = { name: item.gain, data };
            } catch(e) {
                console.log(e);
            }
        }
    }
    yield put(actions.receiveGenomicIndex(data));

    yield put(loading.endLoading('load_index'));

}

function* deleteIndex(action) {
    try {
        let res = yield call(GenomicIndexApi.deleteIndex, action.payload);
        if (res.status !== 200) throw new Error(res.data);
        yield put(actions.removedGenomicIndex(action.payload));
    } catch (err) {
        console.log(err);
    }
}

function* saveIndex(action) {
    yield put(loading.startLoading('save_index'))
    try {
        yield call(GenomicIndexApi.save, {...action.payload});
        yield put(actions.loadGenomicIndex());
    } catch (err) {
        console.log(err);
    }
    yield put(loading.endLoading('save_index'))

}

export function* genomicIndexSaga() {
    yield takeLatest(GENOMIC_INDEX_ACTIONS.LOAD, loadIndex);
    yield takeLatest(GENOMIC_INDEX_ACTIONS.DELETE, deleteIndex);
    yield takeLatest(GENOMIC_INDEX_ACTIONS.SAVE, saveIndex);
}