import React from 'react';

import { Container, Grid } from 'semantic-ui-react'

import { wrapPage } from '../HOC';

const FAQ = () => (
    <Container>
        <Grid>
            <Grid.Row centered>
                <Grid.Column mobile={16} computer={14}>
                    <h2>What is the goal of Embryo Selection?</h2>
                    <p>We are now able to make fine distinctions when screening the genomes of human embryos.
                        This rapidly evolving technological capability is outpacing our ethics research and resources.
                        Meeting this need, Embryo Selection is crowdsourcing the ethics data needed to inform IVF couples and
                        professionals using this new technology. More generally, Embryo Selection engages in other research,
                        education and outreach on ethics related to the genomic science of reproduction.</p>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row centered>
                <Grid.Column mobile={16} computer={14}>
                    <h2>Is your data open?</h2>
                    <p>
                        Yes, our (
                        <a href="https://en.wikipedia.org/wiki/Data_anonymization">anonymized</a>
                        ... aggregated <a href="https://en.wikipedia.org/wiki/Summary_statistics">
                        summary statistics</a>) data is
                        <a href="https://en.wikipedia.org/wiki/Open_science"> open science</a>.
                    </p>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row centered>
                <Grid.Column mobile={16} computer={14}>
                    <h2>What diseases are clinicians able to test for?</h2>
                    <p>Increasingly, almost anything with a genetic component can be tested for, in the embryo.
                        For decades, clinicians have performed genetic testing on embryos only for “simple” diseases.
                        But testing for complex, subtle, polygenic traits is now increasingly becoming routine.</p>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row centered>
                <Grid.Column mobile={16} computer={14}>
                    <h2>What gave you the idea?</h2>
                    <p>
                        Our project was inspired by
                        <a href="https://www.popsci.com/g00/mit-game-asks-who-driverless-cars-should-kill?i10c.encReferrer=aHR0cHM6Ly93d3cuZ29vZ2xlLmNvbS8%3d&i10c.ua=1&i10c.dv=14">
                        &nbsp;MIT Moral Machine</a> (MMM). We think that MMM is admirable.
                    </p>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </Container>
);

export default wrapPage(FAQ, 'FAQ');
