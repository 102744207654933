import React, { useEffect } from 'react';
import styles from '../Votes.module.scss';
import { TraitRow, Votes } from '.';
import actions from '../../../store/actions';
import { connect } from 'react-redux';
import { classList } from '../../../utils';

const Trait = (props) => {
    const { votes, opened, id = 0 } = props;
    
    useEffect(() => {
        if (props.opened) {
            props.clearSubmitted();
            props.loadVotes(id, true);
        }
    }, [props.loadVotes]);
    
    let neededVotes = votes.filter(v => v.traitId === props.id);

    return <div className={classList(styles.Trait, opened ? styles.Trait_expanded : '')}>
        <TraitRow {...props} />
        {(opened) && neededVotes && (neededVotes.length > 0 ? <Votes {...props} votes={neededVotes} /> : <div className={styles.NothingFound}>No votes</div>)}
    </div>
}

const mapStateToProps = (store) => ({
    votes: store.votes
});

const mapDispatchToProps = {
    loadVotes: actions.votes.loadVotes,
    clearSubmitted: actions.newvotes.clearNewVotes
}

export default connect(mapStateToProps, mapDispatchToProps)(Trait);