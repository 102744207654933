import React, {useEffect} from 'react';
import styles from './ProgressGraph.module.scss';
import Dropdown from "../../../common/Dropdown";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import actions from "../../../../store/actions";
import {classList} from "../../../../utils";
import DemographicSelector from "./selectors/DemographicSelector";

const ProgressGraphSelectors = ({
    demographics,
    selectedState,
    updateSelectedProperty,
    lookingForOptions,
    targetMetrics,
    voteFields,
    loadFields,
    traits,
    loadTraits
}) => {
    useEffect(() => {
        !voteFields.length && loadFields();
        !traits.length && loadTraits()
    }, []);

    return (
        <div className={styles.selectors}>
            <Dropdown
                className={styles.selector}
                items={voteFields
                    .filter(field => targetMetrics.includes(field.name))
                    .map(
                        field => ({
                            text: field.displayName,
                            value: field.id,
                            selected: selectedState.metricId === field.id
                        })
                    )
                }
                onChange={(value) => updateSelectedProperty({metricId: value})}
            />

            <Dropdown
                className={styles.selector}
                items={lookingForOptions.map(option => ({...option, selected: option.value === selectedState.lookingFor}))}
                onChange={(value) => updateSelectedProperty({lookingFor: value})}
            />

            <DemographicSelector
                className={classList(styles.selector, styles.demographic_selector)}
                items={demographics.map(
                    option => (
                        {
                            text: option.name,
                            value: option.id,
                            color: option.color,
                            selected: selectedState.selectedDemographics.includes(option.id)
                        }
                    )
                )}
                onChange={(value) =>
                    updateSelectedProperty(
                        {selectedDemographics: selectedState.selectedDemographics.includes(value)
                                ? [...selectedState.selectedDemographics.filter(demoId => demoId !== value)]
                                : [...selectedState.selectedDemographics, value]
                        }
                    )
                }
            />
        </div>
    )
}

const mapStateToProps = (store) => ({
    voteFields: store.voteFields,
    traits: store.traits
})

const mapDispatchToProps = {
    loadFields: actions.voteFields.loadVoteFields,
    loadTraits: actions.traits.loadTraits
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProgressGraphSelectors));
