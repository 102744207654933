import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';

import { PrivateRoute, ScrollToTop, Route } from './components/common';

import {
    Home,
    Media,
    About,
    FAQ,
    Contact,
    Privacy,
    Weight,
    Login,
    Charts,
    Regions,
    Ess,
    Phea,
    Settings,
    ResearchList,
    NewResearchItem,
    EditResearchItem,
    UserContributions,
    IndexStatus,
    ProgressGraph
} from './components';
import { setAuthTokenToHeader } from './utils';
import {
    EditTraits,
    GenomicIndex,
    EditDemographics,
    EditVotes,
    About2,
    Users,
} from './components/pages';
import { connect, Provider } from 'react-redux';
import { configureStore } from './store/configure';
import Popup from './components/common/Popup/Popup';
import actions from './store/actions';
import TraitsCategoriesContainer from './components/TraitCategories/TraitsCategoriesContainer';

const Logout = connect(() => ({}), { setUser: actions.users.setCurrentUser })(
    ({ setUser }) => {
        setUser(null);
        localStorage.clear();
        return <Redirect to="/" />;
    }
);

setAuthTokenToHeader(localStorage['token']);
ReactDOM.render(
    <Provider store={configureStore()}>
        <Router>
            <ScrollToTop>
                <Switch>
                    <PrivateRoute exact path="/" component={Home} role="user" />

                    {/* Login */}
                    <Route path="/login" component={Login} />

                    {/* Weight - Browse and submit ratings for existing diseases */}
                    <PrivateRoute path="/weight/:id?" component={Weight} role="user" />

                    {/* Admin Homepage */}
                    <PrivateRoute exact path="/administration" component={IndexStatus} />
                    <PrivateRoute
                        exact
                        path="/administration/categories"
                        component={TraitsCategoriesContainer}
                    />

                    {/* Admin Homepage for senior user */}
                    <PrivateRoute
                        exact
                        path="/administration-senior"
                        component={IndexStatus}
                        role="senior"
                    />

                    {/* Edit Traits - Edit existing diseases */}
                    <PrivateRoute
                        exact
                        path="/administration/edit-traits"
                        component={EditTraits}
                    />

                    <PrivateRoute
                        path="/administration/edit-index"
                        component={GenomicIndex}
                    />

                    <PrivateRoute
                        path="/administration/demographics"
                        component={EditDemographics}
                    />
                    <PrivateRoute
                        path="/administration/regions"
                        component={Regions}
                        role="senior"
                    />
                    <PrivateRoute
                        path="/administration/status/contributions"
                        component={UserContributions}
                        role="senior"
                    />
                    <PrivateRoute
                        path="/administration/status/"
                        component={IndexStatus}
                        role="senior"
                    />
                    <PrivateRoute
                        path="/administration/graph/"
                        component={ProgressGraph}
                        role="senior"
                    />
                    <PrivateRoute
                        path="/administration/ess/:version?"
                        component={Ess}
                        role="senior"
                    />
                    <PrivateRoute
                        path="/administration/phea/:version?"
                        component={Phea}
                        role="senior"
                    />
                    <PrivateRoute
                        exact
                        path="/administration/votes/"
                        component={EditVotes}
                        role="senior"
                    />
                    <PrivateRoute
                        path="/administration/votes/trait/:id/:voteId"
                        component={EditVotes}
                        role="senior"
                    />
                    <PrivateRoute
                        path="/administration/votes/trait/:id"
                        component={EditVotes}
                        role="senior"
                    />
                    <PrivateRoute path="/administration/Users" component={Users} />
                    <PrivateRoute
                        path="/administration/settings/:id?"
                        component={Settings}
                        role="admin"
                    />
                    <PrivateRoute
                        exact
                        path="/administration/research"
                        component={ResearchList}
                    />
                    <PrivateRoute
                        path="/administration/research/new"
                        component={NewResearchItem}
                        role="admin"
                    />
                    <PrivateRoute
                        path="/administration/research/:id"
                        component={EditResearchItem}
                        role="admin"
                    />
                    <PrivateRoute
                        path="/administration/charts"
                        component={Charts}
                        role="senior"
                    />

                    {/* Media - Watch Embryo Selection videos */}
                    <PrivateRoute path="/media" component={Media} role="user" />

                    {/* About - About the Project */}
                    <PrivateRoute path="/about" component={About} role="user" />
                    <PrivateRoute path="/about2" component={About2} role="user" />

                    {/* FAQ - Frequently asked questions */}
                    {/*<PrivateRoute path="/faq" component={FAQ} role="user" />*/}

                    {/* Contact - Contact Info */}
                    {/*<PrivateRoute path="/contact" component={Contact} role="user" />*/}

                    {/* Privacy - Privacy Info */}
                    <PrivateRoute path="/privacy" component={Privacy} role="user" />

                    <PrivateRoute path="/logout" component={Logout} role="user" />
                </Switch>
            </ScrollToTop>
        </Router>
        <Popup />
    </Provider>,
    document.querySelector('.wrapper')
);
