import { VOTES_ACTIONS } from './constants';

export const loadVotes = (traitId, all = false) => ({
    type: VOTES_ACTIONS.LOAD,
    payload: { traitId, all }
});

export const receiveVotes = (data, append = false) => ({
    type: VOTES_ACTIONS.RECEIVE,
    payload: data,
    append
});

export const updateVote = (vote) => ({
    type: VOTES_ACTIONS.UPDATE,
    payload: vote
})

export const updateVoteAndSave = (vote) => ({
    type: VOTES_ACTIONS.UPDATE_AND_SAVE,
    payload: vote
})

export const addReasonForVoteUpdate = (reason, voteId) => ({
    type: VOTES_ACTIONS.ADD_EDIT_REASON,
    payload: { reason, voteId }
})

export const clearVotes = () => ({
    type: VOTES_ACTIONS.CLEAR
})


export const loadVoteHistory = (id) => ({
    type: VOTES_ACTIONS.LOAD_HISTORY,
    payload: { id }
})

export const saveVote = (id) => ({
    type: VOTES_ACTIONS.SAVE_VOTE,
    payload: id
})

export const deleteVote = (id) => ({
    type: VOTES_ACTIONS.DELETE,
    payload: id
})

export const deletedVote = (id) => ({
    type: VOTES_ACTIONS.DELETED,
    payload: id
})

export const loadVoteScreenshot = (id, file) => ({
    type: VOTES_ACTIONS.LOAD_VOTE_SCREENSHOT,
    payload: { id, file }
})

export const confirmRejectVote = (id, confirmed) => ({
    type: VOTES_ACTIONS.CONFIRM_REJECT,
    payload: { id, confirmed }
})

export const changeApprovedVote = (id, approved) => ({
    type: VOTES_ACTIONS.CHANGE_APPROVE,
    payload: { id, approved }
})