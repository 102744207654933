import axios from 'axios';
require('dotenv').config();

const prefix = process.env.REACT_APP_API;
const publicURL = prefix + 'public/settings';
const adminURL = prefix + 'admin/settings';
const publicApi = axios.create({ baseURL: publicURL });
const adminApi = axios.create({ baseURL: adminURL });


export const getSettings = (type = '') => publicApi.get(`/${type}`).then(res => res.data);

export const saveSettings = ({ id, data }) => adminApi.post(`/${id}`, data).then(r => r.data);