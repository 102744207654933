import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styles from './Users.module.scss';
import { loadUsers } from '../../store/actions/users';
import { classList } from '../../utils';
import { User } from '.';
import { UserHead } from './UserHead';
import Checkbox from '../common/CustomCheckbox';
import EditUser from './EditUser';

class Users extends Component {
    state = { adding: false, status: '', real: true, sort: '', desc: 1 }
    render() {
        let { users = [], loading } = this.props;
        const { real, adding, sort, desc } = this.state;

        if (real) users = users.filter(u => u.role);
        if (sort) {
            users.sort((a, b) => {
                if (a[sort] > b[sort]) return desc;
                if (a[sort] < b[sort]) return -desc;
                return 0;
            });
        }
        return <div className={classList(styles.Users, loading.length ? styles.loading : '')}>
            <h1>Users</h1>
            <div className={styles.ManagePanel}>
                <Checkbox value={real} onChange={() => this.setState({ real: !real })} /> <label onClick={() => this.setState({ real: !real })} >Only users with role</label>
                <button className={styles.Btn_Add} onClick={() => this.setState({ adding: true })}>Add user</button>
            </div>
            {adding && <EditUser onClose={() => this.setState({ adding: false })} />}
            <UserHead sort={this.sort} sortField={users?.length > 0 ? sort : ''} />
            {users?.length > 0 ? users.map(u => <User sort={sort} key={u.id} {...u} />) : <p>No data</p>}
        </div>
    }

    componentDidMount() {
        this.props.loadUsers();
    }

    sort = (sort) => {
        if (sort !== this.state.sort) {
            this.setState({sort, desc: 1});
        } else {
            this.setState({desc: -this.state.desc});

        }
    }
}

const mapStateToProps = (store) => ({
    users: store.users,
    loading: store.loading
});
const mapDispatchToProps = {
    loadUsers
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Users));