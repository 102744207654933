import React from 'react';

import { ContactForm } from '../forms';

import { Container, Grid, Icon } from 'semantic-ui-react'

const Contact = ({ handleChange, handleSubmit }) => (
    <Container>
        <Grid>
            <Grid.Row>
                <Grid.Column mobile={16} computer={5}>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column mobile={16} computer={12} textAlign='center'>
                                <h2>Embryo Selection</h2>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column mobile={16} computer={12} textAlign='center'>
                                <p>
                                    <Icon name='map marker alternate' /><br/>
                                    Jan Gronemann <br />
                                    Blytækkerporten 153<br/>
                                    2650 Hvidovre<br/>
                                    DENMARK
                                </p>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column mobile={16} computer={12} textAlign='center'>
                                <p>
                                    <Icon name='mail outline' /><br/>
                                    <a href='mailto:contact@embryo-selection.com'>
                                        contact@embryo-selection.com
                                    </a>
                                </p>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column mobile={16} computer={12} textAlign='center'>
                                <p>
                                    <Icon name='phone' /><br/>
                                    (+45) 61 47 30 00
                                </p>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Grid.Column>
                <Grid.Column mobile={16} computer={11}>
                    <ContactForm handleChange={handleChange} handleSubmit={handleSubmit} />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </Container>
);

export default Contact;
