import React from 'react';
import { connect } from 'react-redux';
import actions from '../../../store/actions';
import { classList, toFixed } from '../../../utils';
import s from '../PHEA.module.scss';
import { Popup } from 'semantic-ui-react';
import table from '../../common/Table.module.scss';
import { Confirm } from '../../common/Popup/Confirm';
import { ShowNumber } from '../../Ess/ESSTable';
const Row = React.memo((props) => {
    const { model } = props;
    return (
        <div className={table.Table__Row}>
            <div className={classList(table.Table__Column, s.Column_disease)}>
                {model.disease}
                <Popup
                    position='top right'
                    hoverable
                    trigger={
                        <div className={s.HelpIcon}></div>
                    }
                    content={
                        <div>
                            <p>{model.trait_text || ''}</p>
                        </div>
                    }
                    inverted
                />
            </div>
            <div className={classList(table.Table__Column, s.Column_number)}><ShowNumber value={model.avg_morbid_risk} emptyText='-' afterText='%' /></div>
            <div className={classList(table.Table__Column, s.Column_number)}><ShowNumber value={model.avg_rrr} emptyText='-' afterText='%' /></div>
            <div className={classList(table.Table__Column, s.Column_number)}><ShowNumber value={model.morbid_risk_impact_rrr} emptyText='-' afterText='%' /></div>
            <div className={classList(table.Table__Column, s.Column_number)}><ShowNumber value={model.economic_impact_rrr} emptyText='-' afterText='/person' preText='$' /></div>
            <div className={classList(table.Table__Column, s.Column_number)}><ShowNumber value={model.lifespan_impact_rrr} emptyText='-' afterText=' years/person' /></div>
            <div className={classList(table.Table__Column, s.Column_number)}><ShowNumber value={model.life_quality_impact_rrr} emptyText='-' afterText=' DALY/person' /></div>
        </div>
    )
})


export default Row;