import React, { useState, useEffect, memo } from 'react';
import { connect } from 'react-redux';

import WeightChart from './WeightChart';
import * as votesApi from '../../../api/votes';
import { loadVoteScreenshot, clearTrait } from '../../../store/actions/traits';
import actions from '../../../store/actions';
import { usePrevious } from '../../hooks';
import { splitLabel } from '../../../utils';

const WeightChartContainer = memo(({ ind, traits, activeTrait, field, sex, demographics, votes, ...props }) => {
    const [expanded, setExpanded] = useState(ind === 0);
    const [selectedTraits, setSelectedTraits] = useState([]);
    let activeSex = sex.find(s => s.selected) || sex.find(s => s.is_default)
    let activeDemo = demographics.find(s => s.selected) || demographics.find(s => s.is_default)

    const [chartData, setChartData] = useState({
        dots: {},
        traitsAvgValues: []
    });

    const setActiveSex = (value) => props.setActiveSex({ value });
    const setActiveDemo = (value) => props.setActiveDemo({ value });

    const [modal, setModal] = useState(null);

    const prevCommonTraits = usePrevious(props.commonTraits);

    const { refreshTrait, loadVotes } = props;

    useEffect(() => {
        if (!expanded) return;
        [...selectedTraits, activeTrait.id].filter(id => id).forEach(id => {
            let history = votes.filter(v => v.fieldId === field.id && v.traitId === id && !v.new);
            let trait = [...traits, activeTrait].find(t => t.id === id);
            if (trait && history.length < 1 && !trait?.loadedVotes) {
                refreshTrait({ id, loadedVotes: true });
                loadVotes(id);
            }
        })
    }, [selectedTraits, expanded, activeTrait, refreshTrait, loadVotes, field.id, traits, votes]);

    useEffect(() => {
        setChartData(getChartData());
    }, [selectedTraits, sex, demographics, votes, field, traits, activeSex, activeDemo]);

    useEffect(() => {
        (async () => {
            if (modal && !modal.source) {
                const { voteId } = modal;
                const source = votes.find(item => item.id === voteId).source;

                if (source && typeof source === 'object') {
                    setModal({ ...modal, source: URL.createObjectURL(source), loading: false });
                }
            }
        })();
    }, [modal, traits, votes]);

    useEffect(() => {
        const removedCommonTraits = (prevCommonTraits || []).filter(trait => !props.commonTraits.includes(trait));
        setSelectedTraits(
            selectedTraits.concat(props.commonTraits
                .filter(trait => !selectedTraits.includes(trait)))
                .filter(trait => !removedCommonTraits.includes(trait))
        );
    }, [props.commonTraits, prevCommonTraits]);

    const getAvgValueForTrait = (t) => {
        let votesForAvg = t.votes.filter(v => v.approved && !(v.confirmations || []).some(c => !c.confirmed));
        let avg = votesForAvg.reduce((a, b) => a + b.value, 0) / (votesForAvg.length || 1);
        return avg || 0;
    }

    const makeFilteredTrait = (t) => {
        t.votes = votes.filter(v => v.traitId === t.id && v.fieldId === field.id);
        if (!activeDemo?.is_default && field.demos) t.votes = t.votes.filter(v => v.demoId === activeDemo?.id);

        if (t.sex && field.sexes) {
            t.votes = t.votes.filter(v => v.sexId === t.sex.id);
            if (!activeSex.is_default) t.votes = t.votes.filter(v => v.sexId === activeSex.id);
        }

        let avg = getAvgValueForTrait(t);
        return { ...t, original_name: t.trait_name, trait_name: `${t.trait_name} ${t?.sex?.name || ''}`, votes: t.votes.map(v => ({ ...v, avg })) };
    }

    const getFilteredTraits = () => {
        let filteredTraits = traits.filter(t => ~selectedTraits.indexOf(t.id) || t.id === activeTrait?.id);
        let result = [];

        let maleSex = sex.find(s => s.name.toLowerCase() === 'male');
        let femaleSex = sex.find(s => s.name.toLowerCase() === 'female');

        for (let t of filteredTraits) {
            if (!field.sexes) {
                result.push(makeFilteredTrait(t))
            } else {
                result.push(makeFilteredTrait({ ...t, sex: maleSex }));
                result.push(makeFilteredTrait({ ...t, sex: femaleSex }));
            }
        }
        return result.filter(t => t.votes?.length > 0);
    }

    const prepareDots = filteredTraits => {
        const allVotes = filteredTraits.map(t => t.votes.map(v => ({ ...v, trait_name: t.trait_name, original_name: t.original_name }))).flat();
        allVotes.sort((a, b) => a.value - b.value);
        let min = Math.min(...allVotes.map(h => +h.value));
        let max = Math.max(...allVotes.map(h => +h.value));

        if (max - min === 0) {
            min = max / 2;
            max = max + max / 2;
        }

        const interval = (max - min) / 100;
        const obj = {};

        //cases when min and max === 0
        if (min === max) {
            let key = Math.round(min);
            if (!obj[key]) {
                obj[key] = allVotes;
            } else {
                obj[key] = obj[key].concat(allVotes);
            }
            return obj;
        }

        for (let i = min; i <= max; i += interval) {
            const votes = allVotes.filter(h => +h.value >= i && +h.value < i + interval);

            if (votes.length > 0) {
                let key = i;

                if (i + interval > max) {
                    key = max;
                }

                if (!obj[key]) {
                    obj[key] = votes;
                } else {
                    obj[key] = obj[key].concat(votes);
                }
            }
        }

        return obj;
    };

    const getChartData = () => {
        const filteredTraits = getFilteredTraits();
        const dots = prepareDots(filteredTraits);
        const traitsAvgValues = filteredTraits.map(t => getAvgValueForTrait(t));
        const approvedTraitsAvgValues = filteredTraits.filter(t => t.approved).map(t => getAvgValueForTrait(t));
        let maxValue = Object.keys(dots).length > 0 ? Math.max(...Object.keys(dots).map(i => +i)) : 100;
        let minValue = Object.keys(dots).length > 0 && field.type === 'Money' ? Math.min(...Object.keys(dots).map(i => +i)) : 0;
        const maxAvgValue = Math.max(...traitsAvgValues) || 0;
        const xLabels = {};

        traitsAvgValues.forEach((item, ind) => {
            if (!xLabels[item]) {
                xLabels[item] = { title: '', traits: [] };
            }
            xLabels[item].traits.push(filteredTraits[ind]);
            xLabels[item].title = xLabels[item].title ? xLabels[item].title + '\n\n' + splitLabel(filteredTraits[ind].trait_name) : splitLabel(filteredTraits[ind].trait_name);
        });

        if (maxValue - minValue === 0 && field.type === 'Money') {
            minValue = maxValue / 2;
            maxValue = maxValue + maxValue / 2;
        }
        return {
            dots,
            traitsAvgValues: Array.from(new Set(traitsAvgValues)),
            approvedTraitsAvgValues: Array.from(new Set(approvedTraitsAvgValues)),
            maxValue,
            minValue,
            maxAvgValue,
            title: field.displayName,
            field,
            xLabels,
        };
    };

    const loadScreenshot = async (id, source) => {
        setModal({ voteId: id, source, loading: true });

        if (typeof source === 'string') {
            let screenshot = await votesApi.getFile(source);

            setModal({ voteId: id, source: URL.createObjectURL(screenshot), loading: false });
        }
    };

    const loadSourceFile = async (source, fileName) => {
        if (typeof source === 'string') {
            const file = await votesApi.getFile(source);
            const url = URL.createObjectURL(file);
            const fileLink = document.createElement('a');

            fileLink.href = url;
            fileLink.download = fileName;
            fileLink.click();
        }
    };

    return (
        <WeightChart
            activeSex={activeSex}
            activeDemo={activeDemo}
            expanded={expanded}
            chartData={chartData}
            modal={modal}
            field={field}
            toggleView={() => setExpanded(!expanded)}
            onSexChange={setActiveSex}
            onDemoChange={setActiveDemo}
            loadVoteScreenshot={loadScreenshot}
            loadVoteSourceFile={loadSourceFile}
            onModalClose={() => setModal(null)}
            selectedTraits={selectedTraits}
            setSelectedTraits={setSelectedTraits}
            commonTraits={props.commonTraits}
        />
    );
})

const mapStateToProps = store => ({
    activeTrait: store.activeTrait,
    sex: store.sex,
    votes: store.votes.filter(v => v.approved || v.new),
    traits: store.traits,
    demographics: store.demographics,
})

const mapDispatchToProps = {
    loadVotes: actions.votes.loadVotes,
    refreshTrait: actions.traits.refreshTrait,
    setActiveSex: actions.sexes.updateSex,
    setActiveDemo: actions.demographics.chooseDemographics,
    loadVoteScreenshot,
    clearTrait,
}

export default connect(mapStateToProps, mapDispatchToProps)(WeightChartContainer);