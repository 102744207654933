import { SEX_ACTIONS } from './constants';


export const loadSex = () => ({
    type: SEX_ACTIONS.LOAD,
});

export const receiveSex = (data) => ({
    type: SEX_ACTIONS.RECEIVE,
    payload: data
});

export const updateSex = (data) => ({
    type: SEX_ACTIONS.UPDATE,
    payload: data
});

export const clearSex = () => ({
    type: SEX_ACTIONS.UPDATE,
    payload: { value: null }
});