import React, { PureComponent } from 'react';

import AdministrationTableHeader from './AdministrationTableHeader';
import AdministrationTableBody from './AdministrationTableBody';

export default class AdministrationTable extends PureComponent {
    render() {
        const props = this.props;
        return <table>
            <AdministrationTableHeader data={props.headerValues.labels} category={props.category} />
            <AdministrationTableBody
                headerValues={props.headerValues}
                data={props.data}
                image={props.image}
                category={props.category}
                handleChange={props.handleChange}
            />
        </table>
    }

}