import React from 'react';
import { Container } from 'semantic-ui-react';

import { wrapPage } from '../HOC';

import avatar_jonathan from '../../resources/images/avatar_jonathan.png';
import avatar_jan from '../../resources/images/avatar_jan.png';

import './about.scss';

const About = () => (
    <Container className="about_us">
        <div className="card_content">
            <p><b>Embryo Selection</b> is a non-commercial, non-profit, independent research entity, exploring the ethics of reproductive technology and genetics.</p>
            <p>Based in Denmark, Embryo Selection aims to be international in scope, gathering data and performing research globally. Research output from Embryo Selection is available to all entities working in the space of reproductive technology and genetics.</p>
            <p>None of Embryo Selection’s researchers or ethicists are compensated, or have other conflict of interest. <b>A broad goal of Embryo Selection</b> is to develop data-driven research into the ethics of reproduction, in a format usable to IVF couples and IVF clinicians doing Preimplantation Genetic Testing (PGT). This includes, but is not limited to, education and outreach generally, and research on ethics related to genomic science more broadly.</p>
            <p>Embryo Selection gathers data in the form of responses to ethical questions and judgements from a pool of surveyed individuals; many of these from outside of the IVF field. This data is used to build a crowd-sourced, empirical ethics framework for assisting difficult decisions in human reproduction.</p>
            <p>The rapidly developing science of embryo screening and modification needs this research data. The scenarios faced in advanced IVF have grave ethical consequence. Crowd-sourcing ethical intuitions is both necessary and overdue.</p>
            <p>Embryo Selection was inspired by the Google Cars affiliated ethics project <a href="http://moralmachine.mit.edu/" target="_blank" rel="noopener noreferrer">MIT Moral Machine</a>. The <a href="https://www.forbes.com/sites/oliversmith/2018/03/21/the-results-of-the-biggest-global-study-on-driverless-car-ethics-are-in/#5317c19f4a9f" target="_blank" rel="noopener noreferrer">output data of MMM</a> is being used to inform life-or-death decisions by cars being driven by AI. These decisions, about preservation and loss of life in the context of automobile transport, are comparable in moral weight to the decisions made in embryo selection.</p>
            <div className="team">
                <div className="member">
                    <h3>Ethicists</h3>
                    <div className="member_card">
                        <img src={avatar_jonathan} alt="Jonathan Anomaly" />
                        <p><b>Jonathan Anomaly</b></p>
                        <p>University of San Diego</p>
                        <p className="description">
                            These include what we should do about antibiotic resistance, and how we should respond to the emerging market for genetic engineering. 
                        </p>
                        <a className="get_in_touch" href="mailto:janomaly@sandiego.edu">Get in touch</a>
                    </div>
                </div>
                <div className="member">
                    <h3>Technical Staff</h3>
                    <div className="member_card">
                        <img src={avatar_jan} alt="Jonathan Anomaly" />
                        <p><b>Jan Gronemann</b></p>
                        <p>Administrator, Treasurer, and Legal</p>
                        <p className="description">
                            Trying hard to be nice. Sometimes succeeding.
                        </p>
                        <a className="get_in_touch" href="mailto:jan@spacepope.dk">Get in touch</a>
                    </div>
                </div>
            </div>
        </div>
    </Container>
); 

export default wrapPage(About, 'About Us');
