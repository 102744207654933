import React, { useEffect } from 'react';
import { connect } from "react-redux";
import actions from "../../../../store/actions";
import { withRouter } from "react-router-dom";
import Loader from "../../../choose/Loader";
import StatisticContainer from "../../StatisticContainer";
import { UserContributionsTable } from "./UserContributionsTable";

const UserContributions = ({loadUsersContributions, usersContributionStatistic, loading}) => {
    useEffect(() => {
        loadUsersContributions();
    }, []);

    return (
        <StatisticContainer activeTab="contributions">
            <UserContributionsTable data={usersContributionStatistic} />
            {loading.length > 0 && <Loader />}
        </StatisticContainer>
    )
}

const mapStateToProps = (store) => ({
    loading: store.loading,
    usersContributionStatistic: store.statistic.userStatistic,
})

const mapDispatchToProps = {
    loadUsersContributions: actions.statistic.loadUsersContributions,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserContributions));
