import React from 'react';
import { connect } from 'react-redux';
import { closePopup } from '../../../store/actions/popups';

const GSBSUploadWarning = ({
    invalidTraits = [],
    primaryAction,
    closePopup,
}) => {
    const onPrimaryClickHandler = () => {
        if (typeof primaryAction === 'function') {
            primaryAction();
            closePopup();
        }
    };

    const onSecondaryClickHandler = () => {
        closePopup();
    };

    return (
        <div className="GSBSUploadComponent">
            <p className="GSBSUploadComponent-paragraph">
                The following diseases are not detected in the uploading file:
            </p>
            <ul className="GSBSUploadComponent-list">
                {invalidTraits.map((trait) => <li className="GSBSUploadComponent-list--item" key={trait}>{trait}</li>)}
            </ul>
            <p className="GSBSUploadComponent-paragraph">
                Do you want to upload another file?
            </p>
            <div className="GSBSUploadComponent-btnGroup">
                <button className="GSBSUploadComponent-btnGroup--primary" onClick={onPrimaryClickHandler}>Upload new file</button>
                <button className="GSBSUploadComponent-btnGroup--secondary" onClick={onSecondaryClickHandler}>Cancel</button>
            </div>
        </div>
    );
};

export default connect(() => ({}), { closePopup })(GSBSUploadWarning);
