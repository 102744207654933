import './styles.css';

import React from 'react';
import { Card, Grid, Container } from 'semantic-ui-react'

import logo_university from '../../resources/images/logo_university.svg';
// import gPrediction_logo from '../resources/images/gPrediction_logo.svg';
// import MichiganStateUniversity_Logo from '../resources/images/MichiganStateUniversity_Logo.png';

const CollaborationCards = () => (
    <Container>
        <Grid className='collaboration-cards'>
            <Grid.Row centered>
                <Grid.Column width={16} textAlign='center'>
                    <span>in collaboration with</span>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row centered>
                <Grid.Column width={16}>
                    <Grid>
                        <Grid.Row centered>
                            <Grid.Column className='column-with-margin' mobile={14} tablet={8} computer={7}>
                                <Card className='collaboration-card'>
                                    <Grid>
                                        <Grid.Row className='collaboration-university' centered>
                                            <Grid.Column width={5}>
                                                <img src={logo_university} alt={logo_university} />
                                            </Grid.Column>
                                            <Grid.Column width={10} textAlign='left' verticalAlign='middle'>
                                                <h3>UNIVERSITY OF COPENHAGEN</h3>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Card>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </Container>
);

export default CollaborationCards;
