import React, { PureComponent } from 'react'
import DropZone from './DropZone';

export default class JsonDrop extends PureComponent {

    handleDelete = () => {
        const { handleChange } = this.props;
        handleChange(null);
    };

    handleDrop = file => {
        var reader = new FileReader();
        reader.onload = () => {
            let text = reader.result;
            try {
                // text = dJSON.parse(text);
                text = JSON.parse(text);

                if (Array.isArray(text) && text.length === 1) {
                    text = text[0];
                }

                if (!text.stats_array || !Array.isArray(text.stats_array)) throw new Error('Invalid content');

                // text = JSON.stringify(text);
                this.props.handleChange(JSON.stringify(text));
            } catch(e) {
                console.log(e);
                alert('This file is not valid!');
            }
        };

        reader.readAsText(file);
    };

    render() {
        const { field, defaultValue } = this.props;

        return (
            <>
                {defaultValue ? <button onClick={this.handleDelete} className="clear-btn">Clear</button> : <button disabled className="clear-btn disabled">Empty</button>}
                <DropZone
                    className='administration-table-cell--drop'
                    field={field}
                    defaultValue={null}
                    onDrop={this.handleDrop} />

            </>
        );
    }
};
