import dotenv  from 'dotenv';

dotenv.config();

let API = process.env.REACT_APP_API;
let GSBS_API = process.env.REACT_APP_GSBS_API;
let AUC_API = process.env.REACT_APP_AUC_API;
let ENV = process.env.REACT_APP_ENVIRONMENT;

export default {
    API,
    GSBS_API,
    ENV,
    AUC_API
}
