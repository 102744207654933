import { put, takeLatest, call, select } from 'redux-saga/effects';
import { PHEA_ACTIONS } from '../actions/constants';
import actions from '../actions';
import * as api from '../../api/phea';

const { loading, phea } = actions;

function* load(action) {
    yield put(loading.startLoading('phea'));
    try {
        let version = action.payload;
        let needPhea = yield select(s => s.phea.find(p => p.version === version));
        if (!needPhea) {
            let data = yield call(api.load, version);
            yield put(phea.receivePhea({version, data}));
        } 
    } catch (e) {
        console.error(e);
    }
    yield put(loading.endLoading('phea'));
}

export function* pheaSaga() {
    yield takeLatest(PHEA_ACTIONS.LOAD, load);
}