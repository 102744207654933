import { USERS_ACTIONS } from '../actions/constants';

export const users = (state = [], action) => {
    switch (action.type) {
        case USERS_ACTIONS.LOADED: return action.payload;
        case USERS_ACTIONS.ADDED: return [...state, action.payload];
        case USERS_ACTIONS.UPDATED: return state.map(u => u.id === action.payload.id ? action.payload : u);
        case USERS_ACTIONS.DELETED: return state.filter(u => u.id !== action.payload);
        default: return state;
    }
}

export const user = (state = null, action) => {
    switch (action.type) {
        case USERS_ACTIONS.SET_CURRENT:
            localStorage['user'] = JSON.stringify(action.payload);
            return action.payload;
        default:
            let user = state;
            if (user) return user;

            try {
                if (localStorage['user']) user = JSON.parse(localStorage['user']);
                return user;
            } catch (e) {
                return user;
            }
    }
}