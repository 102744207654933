import React, { PureComponent } from 'react';

import AdministrationTableCell from './AdministrationTableCell';

import { Table } from 'semantic-ui-react'

export default class AdministrationTableRow extends PureComponent {
    render() {
        const { headerValues: { raw, fields }, item, handleChange } = this.props;
        return <Table.Row>
            {raw.map(field => (
                <AdministrationTableCell
                    key={field + item.id}
                    field={field}
                    fieldMeta={fields[field]}
                    item={item}
                    handleChange={handleChange}
                />
            ))}
        </Table.Row>
    }
}