import React, { useEffect, useState, memo } from 'react';
import './Ess.scss';
import { classList } from '../../utils';
import { connect } from 'react-redux';
import actions from '../../store/actions';
import { CEOApprovalLinkPopup } from '.';
const CEOApproval = memo(({ data, onSave, showPopup, user, className = '' }) => {
    let [opened, setOpened] = useState(false);
    let { approved = null, link = null } = data || {};

    let approvalItems = [
        { title: 'Approved', approved: 1, className: 'CEO_approved_Approved' },
        { title: 'Rejected', approved: 0, className: 'CEO_approved_Rejected' },
        { title: 'N/A', approved: -1, className: 'CEO_approved_NA' }
    ]

    approvalItems.sort((a, b) => {
        if (approved) return b?.approved - a?.approved;
        if (link) return Math.abs(a?.approved) - Math.abs(b?.approved);
        return a?.approved - b?.approved;
    });

    if (user?.role !== 'admin') approvalItems = approvalItems.slice(0, 1);

    const changeApproved = (item) => {
        if (user?.role !== 'admin') return;
        let newData = { ...data, approved: item.approved > 0 };

        if (item.approved >= 0) {
            let url = new URL(item.link);
            newData.link = url.href;
        } else {
            newData.link = null;
        }
        onSave(newData);
    }

    const toggleOpened = (e) => {
        e.stopPropagation();
        setOpened(!opened);
    }

    const chooseNewApprovedValue = (item) => {
        if (item.approved < 0) {
            changeApproved(item);
        } else {
            showPopup(<CEOApprovalLinkPopup model={item} onSubmit={changeApproved} />, 'Please enter the link')
        }
    }

    return (
        <div className={classList('CEO_Approval', className)}>
            <div className={classList('CEO_Approval_background', opened && 'CEO_Approval_background_visible')} onClick={toggleOpened}></div>
            <div className={'CEO_Approval_dd_container'} onClick={toggleOpened}>
                <div className={classList('CEO_Approval_dd', opened && 'CEO_Approval_dd__opened', user?.role)} onClick={toggleOpened}>
                    {approvalItems.map(i => <div key={i.approved} className={classList('CEO_Approval_dd_item', i.className)} onClick={() => opened && chooseNewApprovedValue(i)}>{i.title}</div>)}
                </div>
            </div>
            {data?.link && <a href={data.link} className={classList('CEO_ApprovalLink', className)} rel="noopener noreferrer" target="_blank">{data.link}</a>}
        </div>
    )
})

const mapStateToProps = (store) => ({
    user: store.user
})

const mapDispatchToProps = {
    showPopup: actions.popups.showPopup
}

export default connect(mapStateToProps, mapDispatchToProps)(CEOApproval);