import React, { memo } from 'react';
import s from './Vote.module.scss';
import { Popup } from 'semantic-ui-react';

export const VoteCalculationMethodPreview = memo(({ methodOfCalculation }) => <Popup
    trigger={<div className={s.CalculationMethodPreview}><div className={s.CalculationMethodIcon}></div></div>}
    hoverable
    inverted
    position="bottom center"
>
        <div>Method of calculation: </div>
        <p>{methodOfCalculation}</p>
</Popup>);


