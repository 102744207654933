import { STATISTIC_ACTIONS } from '../actions/constants';

export const statistic = (state = [], action) => {
    switch (action.type) {
        case STATISTIC_ACTIONS.RECEIVE_CONTRIBUTIONS: return ({...state, userStatistic: action.payload});
        case STATISTIC_ACTIONS.RECEIVE_USER_CONTRIBUTIONS:
            return ({...state, userStatistic: state.userStatistic.map(
                userStat => userStat.id === action.payload.userId ? ({...userStat, history: action.payload.data}) : userStat
            )});
        case STATISTIC_ACTIONS.RECEIVE_INDEX_ACTIVITY_TRAITS_DATA: return ({...state, indexStatusTraits: action.payload});
        case STATISTIC_ACTIONS.RECEIVE_PROGRESS_GRAPH_DATA: return ({...state, progressGraph: action.payload});
        default: return state;
    }
}
