import React from 'react';
import { connect } from 'react-redux';
import { classList, metricsTooltips } from '../../../utils';
import s from '../PHEA.module.scss';
import table from '../../common/Table.module.scss';
import { Row, TotalRow } from '.';
import { TooltipIcon } from '../../common';

const Table = React.memo((props) => {
    const { data, loading, regional } = props;
    const caption = (text) => `${regional ? 'Regional ' : ''}${text}`;

    let totalRow = {
        disease: 'Total/Births', ...data.reduce((acc, row) => ({
            economic_impact_rrr: {
                common: acc.economic_impact_rrr.common + row.economic_impact_rrr.common,
                male: acc.economic_impact_rrr.male + row.economic_impact_rrr.male,
                female: acc.economic_impact_rrr.female + row.economic_impact_rrr.female,
            },
            lifespan_impact_rrr: {
                common: acc.lifespan_impact_rrr.common + row.lifespan_impact_rrr.common,
                male: acc.lifespan_impact_rrr.male + row.lifespan_impact_rrr.male,
                female: acc.lifespan_impact_rrr.female + row.lifespan_impact_rrr.female,
            },
            life_quality_impact_rrr: {
                common: acc.life_quality_impact_rrr.common + row.life_quality_impact_rrr.common,
                male: acc.life_quality_impact_rrr.male + row.life_quality_impact_rrr.male,
                female: acc.life_quality_impact_rrr.female + row.life_quality_impact_rrr.female,
            }
        }), {
            economic_impact_rrr: {common: 0, male: 0, female: 0},
            lifespan_impact_rrr: {common: 0, male: 0, female: 0},
            life_quality_impact_rrr: {common: 0, male: 0, female: 0}
        })
    }
    return (
        <div className={classList(table.Table, loading && table.Table_loading)}>
            <div className={classList(table.Table__Row, table.Table__Row_Head, s.Head)}>
                <div className={classList(table.Table__Column, s.Column_disease)}></div>
                <div className={classList(table.Table__Column, s.Column_number)}>{caption('Avg Lifetime Risk')}<TooltipIcon className="ess_tooltip" content={metricsTooltips['morbid_risk_general']} /></div>
                <div className={classList(table.Table__Column, s.Column_number)}>{caption('Avg RRR')}<TooltipIcon className="ess_tooltip" content="Relative Risk Reduction" /></div>
                <div className={classList(table.Table__Column, s.Column_number)}>{caption('Lifetime Risk/Lifetime Risk Impact of RRR')}<TooltipIcon className="ess_tooltip" content="Lifetime Risk/Lifetime Risk Impact * RRR" /></div>
                <div className={classList(table.Table__Column, s.Column_number)}>{caption('Economic Impact of RRR')}<TooltipIcon className="ess_tooltip" content="Lifetime Cost * Lifetime Risk/Lifetime Risk Impact of RRR" /></div>
                <div className={classList(table.Table__Column, s.Column_number)}>{caption('Lifespan Impact of RRR')}<TooltipIcon className="ess_tooltip" content="Lifespan Impact * Lifetime Risk/Lifetime Risk Impact of RRR" /></div>
                <div className={classList(table.Table__Column, s.Column_number)}>{caption('DALY Impact of RRR')}<TooltipIcon className="ess_tooltip" content="Life Quality Impact * Lifetime Risk/Lifetime Risk Impact of RRR" /></div>
            </div>
            {data.map(r => <Row key={r.disease} model={r} />)}
            <TotalRow model={totalRow} />
        </div>
    )
})

const mapStateToProps = (store) => ({
    loading: store.loading
})

export default connect(mapStateToProps)(Table);