import React from 'react';
import { SEASONS_TO_MONTHS } from './medline.constants';
import ic_blood from '../resources/images/ic_blood.svg';
import ic_lung from '../resources/images/ic_lung.svg';
import ic_musculoskeletal from '../resources/images/ic_musculoskeletal.svg';
import ic_metabolic from '../resources/images/ic_metabolic.svg';
import ic_neurological from '../resources/images/ic_neurological.svg';
import ic_cancer from '../resources/images/ic_cancer.svg';
import axios from 'axios';
import config from '../config';
import countryCenters from './countryCenters';

export const categoryImages = {
    Blood: ic_blood,
    Lung: ic_lung,
    Metabolic: ic_metabolic,
    Neurological: ic_neurological,
    'Hereditary Cancer': ic_cancer,
    'Muscular Skeletal': ic_musculoskeletal,
};

export const shuffle = ({ ...data }) => {
    let currentIndex = Object.keys(data).length;
    let temporaryValue;
    let randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = data[currentIndex];
        data[currentIndex] = data[randomIndex];
        data[randomIndex] = temporaryValue;
    }

    return data;
};

export const getHearts = score => {
    if (score > 0 && score <= 20) return 5;
    if (score > 20 && score <= 40) return 4;
    if (score > 40 && score <= 60) return 3;
    if (score > 60 && score <= 80) return 2;
    if (score > 80 && score <= 100) return 1;
};

export function classList(...classes) {
    return classes
        .filter(a => typeof a === 'string')
        .map(a => String(a).trim())
        .filter(a => a)
        .join(' ');
}

export const toFixed = (num, pow = 2) => {
    if (num === null || typeof num === 'undefined') return '';
    if (typeof num !== 'number') num = +num;
    return isNaN(num) ? '' : num.toFixed(pow);
};

export const decodeBLOB = data =>
    new Promise(resolve => {
        const reader = new FileReader();

        reader.addEventListener('loadend', e => {
            const text = e.srcElement.result;
            if (text.indexOf('<svg') > -1) {
                resolve(new Blob([text], { type: 'image/svg+xml' }));
            } else {
                resolve(data);
            }
        });

        reader.readAsText(data);
    });

export const splitLabel = str => {
    const strArr = str.split(' ');
    const maxLength = Math.max(Math.max(...strArr.map(item => item.length)), 15);
    let resultArr = [strArr[0]];

    strArr.slice(1).forEach(str => {
        const lastRow = resultArr[resultArr.length - 1];

        if (lastRow.length + str.length < maxLength) {
            resultArr[resultArr.length - 1] = `${lastRow} ${str}`;
        } else {
            resultArr.push(str);
        }
    });

    return resultArr.join('\n');
};

export const setAuthTokenToHeader = token => {
    delete axios.defaults.headers.common.authorization;
    if (token) {
        axios.defaults.headers.common.authorization = `Bearer ${token}`;
    }
};

export const FIELD_NAMES = {
    average_age_of_onset: `average_age_of_onset`,
    life_quality_impact_score: `life_quality_impact_score`,
    life_span_impact_score: `life_span_impact_score`,
    life_treatment_cost_score: `life_treatment_cost_score`,
};

export const cloudField = field =>
    config.ENV === 'production' ? field : field + '_' + config.ENV;

export const getCountryData = geo => {
    return {
        coordinates: countryCenters[geo.properties?.['Alpha-2']],
        name: geo.properties?.name,
        short_name: geo.properties?.['Alpha-2'],
    };
};

export const internalMetricsTooltips = {
    countryOfStudy: (
        <p>
            Please enter the country where the data in the study was collected. If less
            than 70% of the data was collected from any single country, please select
            "Multiple countries". If you're not sure how much of the data was collected
            from each country, select "multiple countries
        </p>
    ),
    yearOfStudy: (
        <p>
            The average year in which data for the study was collected. If, for example,
            data was collected betwen 2005 and 2010, the average year would be 2008. If
            this information is not available, enter the year the study was published.
        </p>
    ),
    doiIsbnIssn: (
        <p>
            Please enter the DOI of the study, or if it doesn't have one, the ISBN or ISSN.
        </p>
    ),
};

export const metricsChartExplanation = {
    average_age_of_onset: "Age",
    life_quality_impact_score: "% impact out of 100%",
    morbid_risk_general: "% risk of diagnosis",
    morbid_risk: "% risk of diagnosis"
}

export const metricsTooltips = {
    life_span_impact_score: (
        <span>
            How much a disease reduces lifespan, or the{' '}
            <a
                href="https://en.wikipedia.org/wiki/Years_of_potential_life_lost"
                target="_blank"
                rel="noopener noreferrer"
            >
                years of life lost
            </a>{' '}
            due to the disease. Could also be classified as a reduced life expectancy due
            to a disease. The average lifespan for healthy individuals is ~76 for males
            and ~78 for females. The reduction of life is in reference to these numbers. A
            disease which reduces average lifespan to 60 for males would have a lifespan
            impact of 16.
        </span>
    ),
    average_age_of_onset: (
        <span>
            The{' '}
            <a
                href="https://en.wikipedia.org/wiki/Age_of_onset#:~:text=The%20age%20of%20onset%20is,of%20a%20disease%20or%20disorder."
                target="_blank"
                rel="noopener noreferrer"
            >
                mean age
            </a>{' '}
            at which a disease begins or symptoms of the disease are present in the
            patient. The average age of onset may coincide with the average age of
            diagnosis.
        </span>
    ),
    life_treatment_cost_score: (
        <span>
            In American dollars, an estimate of how much money a{' '}
            <a
                href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC1127320/"
                target="_blank"
                rel="noopener noreferrer"
            >
                disorder costs
            </a>{' '}
            in treatment, over an entire lifespan. Average age of onset and lifespan
            impact data points may need to be taken into account if using incremental
            costs.
        </span>
    ),
    life_quality_impact_score: (
        <span>
            On a scale from 1 to 100, a rate of how strongly a{' '}
            <a
                href="https://www.britannica.com/topic/quality-of-life"
                target="_blank"
                rel="noopener noreferrer"
            >
                disorder negatively affects
            </a>{' '}
            life quality. 1 represents minor inconvenience, and 100 represents major
            debilitation.
        </span>
    ),
    morbid_risk_general: (
        <span>
            {' '}
            The probability or risk of developing a disease during one's lifetime.
            Lifetime risk is also called{' '}
            <a
                href="https://www.cancer.gov/publications/dictionaries/cancer-terms/def/cumulative-risk"
                target="_blank"
                rel="noopener noreferrer"
            >
                cumulative risk
            </a>
            . Lifetime risk is not the prevalence or incidence of a disease in the
            population.
        </span>
    ),
    morbid_risk: (
        <span>
            The risk or probability of developing a disease over one’s lifetime when there
            is a{' '}
            <a
                href="https://en.wikipedia.org/wiki/First-degree_relative"
                target="_blank"
                rel="noopener noreferrer"
            >
                first degree relative
            </a>{' '}
            (FDR) with the same disease. The lifetime risk with a FDR is typically higher
            than the lifetime risk of the general population or someone without that
            family history. Lifetime risk with a FDR can be calculated using an{' '}
            <a
                href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2938757/#:~:text=Odds%20ratios%20are%20used%20to,%2C%20aspect%20of%20medical%20history)."
                target="_blank"
                rel="noopener noreferrer"
            >
                Odds Ratio
            </a>{' '}
            and{' '}
            <a
                href="https://www.ncbi.nlm.nih.gov/books/NBK430824/#:~:text=Clinical%20Significance,event%20based%20on%20some%20exposure."
                target="_blank"
                rel="noopener noreferrer"
            >
                Relative Risk Ratio
            </a>
            .
        </span>
    ),
    life_expectancy_with_disease: (
        <span>
            Please enter the average life expectancy of a person with the condition.
        </span>
    ),
    years_of_life_lost: (
        <span>
            How many years of life does the average person dying of this condition lose?<br/>
            In other words, how many more years would a person who died of this disease have otherwise been expected to live?
        </span>
    ),
    survival_rate_in_five_years: (
        <span>
            What percentage of people that develop this condition are alive five years after their initial diagnosis?
        </span>
    )
};

export const parseMedlineDate = DP => {
    let publicationDate = null;
    if (DP) {
        let dpForParsing = DP.replace(
            /winter|spring|summer|autumn/gi,
            match => SEASONS_TO_MONTHS[match.toLowerCase()]
        ) // Replaces seasons to first months
            .replace(/-[a-z]{3}/gi, ''); //removes from month ranges last one, Examle: Nov-Dec becomes Nov

        publicationDate = new Date(dpForParsing + ' GMT');
    }

    return publicationDate;
};

export const bindCatchInterceptor = api => {
    api.interceptors.response.use(
        response => response,
        error => (error?.response ? Promise.resolve(error.response) : error)
    );
};

export const maxNumOfMB = num => num * 1024 * 1024;
