import React, { Component } from 'react';
import {Form} from "semantic-ui-react";
import Fuse from 'fuse.js';

import Traits from "../../api/traits";

class TraitSearch extends Component {

    constructor(props){
        super(props);

        this.state = {
            // Error Handling
            loading: false,
            error: false,

            // Scenario Handling
            search: '',
            traits: [],
            results: []
        };
    }

    componentDidMount() {
        Traits
            .getTraits({
                num_traits: -1
            }).then((traits) => {
            this.setState({traits: traits.data})
        })
    }

    searchTraits (e) {
        if(e.target.name === 'search') {
            this.setState({loading: true});
        }

        this.handleInput(e);

        const { traits } = this.state;

        let options = {
            keys: ['trait_name'],
        };

        let fuse = new Fuse(traits, options);
        let search = e.target.value;
        let results = fuse.search(search);

        if(results.length > 0) {
            this.setState({ results: results });

            if(results[0].trait_name === search) {
                this.setState({results: []});

                if (e.target.name === 'search') {
                    this.setState({
                        trait: results[0],
                        error: false,
                        loading: false
                    });

                    this.props.onSelectTrait(results[0]);
                }
            }
        }
    }

    handleInput = e => {
        this.setState({[e.target.name]: e.target.value});
    };

    render() {
        if(this.props.A)
            return(
               <div>
                   <Form.Input
                       className='traitSearch'
                       fluid
                       list='traits'
                       name='search'
                       value={this.state.search}
                       onChange={this.searchTraits.bind(this)}
                       loading={this.state.loading}
                       error={this.state.error}
                       placeholder='Search for traits...'
                   />

                   <datalist id='traits'>
                       {this.state.results.map(function(result, index){
                           return <option key={index} value={result.trait_name} />;
                       })}
                   </datalist>
               </div>
            );

        if(this.props.B)
            return(
                <div>
                    <Form.Input
                        className='traitSearch'
                        fluid
                        list='traitsB'
                        name='search'
                        value={this.state.search}
                        onChange={this.searchTraits.bind(this)}
                        loading={this.state.loading}
                        error={this.state.error}
                        placeholder='Search for traits...'
                    />

                    <datalist id='traitsB'>
                        {this.state.results.map(function(result, index){
                            return <option key={index} value={result.trait_name} />;
                        })}
                    </datalist>
                </div>
            );
    }
}

export default TraitSearch;