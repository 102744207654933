import axios from 'axios';
import config from '../config';

const URL = config.API + 'admin/ess';
const api = axios.create({ baseURL: URL });

export const load = (id) => api.get(`/${id}`).then(r => r.data);

export const loadDemographic = (id, demographicId) => api.get(`/${id}/${demographicId}`).then(r => r.data);

export const loadDemographicWithFillByGeneral = (id, demographicId) =>
    api.get(`/${id}/${demographicId}/fillByGeneral`).then(r => r.data);

export const saveAdditionalInfo = (data) => api.post(`/${data.versionId}/additional`, data).then(r => r.data);

export const getRRRs = () => api.get(`/RRRs`).then(r => r.data);