import React, { PureComponent } from "react";
import { Button } from "semantic-ui-react";
import { TraitCategoriesDropdown } from '../../common';
import './AddTraitPopup.scss';
import { connect } from "react-redux";
import { classList } from "../../../utils";
import actions from '../../../store/actions';
import { Confirm } from "../../common/Popup/Confirm";

const EMPTY_FIELD_ERROR = 'All fields fields should be filled!';

class AddTraitPopup extends PureComponent {
    state = { name: '', category_id: null, trait_text:  '', error: '', nameTouched: false, categoryTouched: false, traitTextTouched: false }
    inputRef = React.createRef()
    render() {
        const { name, error, trait_text, nameTouched, traitTextTouched, categoryTouched } = this.state;

        return <div className="add_trait_popup">
            <p>Add New Trait</p>
            <input
                type="text"
                value={name}
                ref={this.inputRef}
                onChange={this.onChangeName}
                placeholder="Name" />
             <textarea
                value={trait_text}
                onChange={this.onChangeText}
                placeholder="Trait Text (Short)" />
            <TraitCategoriesDropdown
                fluid 
                placeholder="Category"
                categories={this.props.categories}
                onChange={value => this.setState({ category_id: value, categoryTouched: true })}
            />
            <p className="add_trait_popup__validation">{error}</p>
            <Button onClick={this.onSubmit} className={classList('gradient', (!nameTouched || !categoryTouched || !traitTextTouched || !!error) && 'disabled_btn')}>ADD</Button>
        </div>
    }

    onChangeName = (e) => {
        let name = e.target.value;
        let error = name.trim().length ? '' : 'Trait name is empty!';

        this.setState({ name, error, nameTouched: true })
    }

    onChangeText = (e) => {
        let trait_text = e.target.value;
        let error = trait_text.trim().length ? '' : 'Trait text is empty!';

        this.setState({ trait_text, error, traitTextTouched: true })
    }

    onSubmit = () => {
        const { name, trait_text, category_id, error } = this.state;
        let { traitNames = [], showPopup, requestRestoreTrait, requestApproveTrait, onClose } = this.props;
        let lowerName = name.toLowerCase().trim();
        const issetTrait = (traitNames || []).find(t => t.trait_name.toLowerCase().trim() === lowerName);

        if (issetTrait) {
            if (issetTrait.is_deleted) {
                showPopup(<Confirm text={`You're trying to add a trait which was deleted some time ago. Do you want to make a request to restore this trait with all related data?`} onConfirm={() => { requestRestoreTrait(issetTrait.id); onClose(); }} />, `${issetTrait.trait_name} already exists`);
            } else if (!issetTrait.approved) {
                showPopup(<Confirm text={`You're trying to add a trait which was unapproved some time ago. Do you want to make a request to approve this trait?`} onConfirm={() => { requestApproveTrait(issetTrait.id); onClose(); }} />, `${issetTrait.trait_name} already exists`);
            }

            this.setState({ error: 'The trait with this name already exists!' });
            return;
        }

        if (!error.length) {
            this.props.onCreateTrait(name, category_id, trait_text);
            this.setState({ name: '', category_id: null, trait_text:  '', error: '', nameTouched: false, categoryTouched: false, traitTextTouched: false })
        }
    }

    componentDidMount() {
        const { traitNames } = this.props;
        if (!traitNames) this.props.loadTraitNames();
        this.inputRef.current.focus();
    }

    componentDidUpdate(prevProps) {
        const { error, name, category_id, nameTouched, categoryTouched } = this.state;

        if (prevProps.traits?.length !== this.props.traits?.length) {
            this.props.loadTraitNames();
        }

        if (nameTouched && categoryTouched) {
            if (error === EMPTY_FIELD_ERROR && (!!name && !!category_id)) {
                this.setState({ error: '' });
            } else if (!name || !category_id) {
                this.setState({ error: EMPTY_FIELD_ERROR });
            }
        }
    }
}

const mapStateToProps = (store) => ({
    traits: store.traits,
    traitNames: store.traitNames,
    popups: store.popups
});

const mapDispatchToProps = {
    loadTraitNames: actions.traits.loadTraitNames,
    requestRestoreTrait: actions.traits.requestRestoreTrait,
    requestApproveTrait: actions.traits.requestApproveTrait,
    showPopup: actions.popups.showPopup
}



export default connect(mapStateToProps, mapDispatchToProps)(AddTraitPopup);