import { DEMOGRAPHICS_ACTIONS } from './constants';

export const loadDemographics = (admin = false) => ({
    type: DEMOGRAPHICS_ACTIONS.LOAD,
    payload: {admin}
});

export const receiveDemographics = (data) => ({
    type: DEMOGRAPHICS_ACTIONS.RECEIVE,
    payload: data
});

export const addDemographics = (data) => ({
    type: DEMOGRAPHICS_ACTIONS.ADD,
    payload: data
});

export const addedDemographics = (data) => ({
    type: DEMOGRAPHICS_ACTIONS.ADDED,
    payload: data
});

export const updateDemographics = (data) => ({
    type: DEMOGRAPHICS_ACTIONS.UPDATE,
    payload: data
});

export const updatedDemographics = (data) => ({
    type: DEMOGRAPHICS_ACTIONS.UPDATED,
    payload: data
});

export const removeDemographics = (data) => ({
    type: DEMOGRAPHICS_ACTIONS.DELETE,
    payload: data
});

export const removedDemographics = (data) => ({
    type: DEMOGRAPHICS_ACTIONS.DELETED,
    payload: data
});

export const chooseDemographics = (data) => ({
    type: DEMOGRAPHICS_ACTIONS.CHOOSE,
    payload: data
});


export const clearDemographics = () => ({
    type: DEMOGRAPHICS_ACTIONS.CLEAR
});
