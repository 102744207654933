import React from 'react';

import { Grid, Form } from 'semantic-ui-react'

const FieldsSection = props => (
    <>
        <Grid.Row centered>
            <Grid.Column mobile={16} tablet={10} computer={8}>
                <p>Username</p>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={10} computer={7}>
                <Form.Input name='username' placeholder='Enter username' onChange={props.handleChange} value={props.username} fluid />
            </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
            <Grid.Column mobile={16} tablet={10} computer={8}>
                <p>Password</p>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={10} computer={7}>
                <Form.Input name='password' placeholder='Enter password' type='password' onChange={props.handleChange} value={props.password} fluid />
            </Grid.Column>
        </Grid.Row>
    </>
);

export default FieldsSection;