import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { addDemographics } from '../../../store/actions/demographics';

class DemographicsNewRow extends PureComponent {
    state = {
        name: '',
        short_name: '',
    }
    render() {
        const { name, short_name } = this.state;
        return (
            <div className={`demographic_table_row new`}>
                <div className="demographic_table_cell id">New </div>
                <div className="demographic_table_cell name">
                    <input type="text" value={name} onChange={e => this.setState({name: e.target.value})} />
                </div>
                <div className="demographic_table_cell short_name">
                    <input type="text" value={short_name} onChange={e => this.setState({short_name: e.target.value})} />
                </div>

                <div className="demographic_table_cell actions">
                    <button className="cancel-btn" disabled={!name || !short_name} onClick={this.saveDemo}>Save</button>
                </div>
            </div>
        )
    }

    saveDemo = () => {
        const { name, short_name } = this.state;
        this.props.addDemographics({ name, short_name });
        this.setState({ name: '', short_name: '' });
    }
}

const mapDispatchToProps = {
    addDemographics
}

export default connect((store) => ({}), mapDispatchToProps)(DemographicsNewRow); 