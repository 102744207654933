import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DemographicsRow, DemographicsNewRow } from './Table';
import './Demographics.scss';
import Loader from '../choose/Loader';
import { loadDemographics } from '../../store/actions/demographics';
class Demographics extends Component {
    state = { status: '' };
    headData = {
        id: 'id',
        name: 'Name',
        short_name: 'Short name',

        actions: 'Make Public',
    };
    render() {
        let { data = [] } = this.props;
        data.sort((a, b) => b.id - a.id);
        return (
            <div
                className={`demographic_page${
                    ~this.props.loading.indexOf('demographics') ? ' loading' : ''
                }`}
                data-status={this.state.status}
            >
                <h1>Genetic Ancestry</h1>
                <div className="edit-traits_container">
                    <div className="demographics_table">
                        <DemographicsRow {...this.headData} className="head" isHeader />

                        {data.map(i => (
                            <DemographicsRow
                                key={i.id}
                                {...i}
                                actions={{
                                    edit: true,
                                    delete: true,
                                }}
                            />
                        ))}

                        <DemographicsNewRow
                            changeLoadingStatus={this.changeLoadingStatus}
                        />
                    </div>
                </div>
                {this.props.loading.length > 0 && <Loader />}
            </div>
        );
    }
    componentDidMount() {
        this.props.loadDemographics(true);
    }

    changeLoadingStatus = (loading, status = '') => this.setState({ loading, status });
}
const mapStateToProps = store => ({
    data: store.demographics,
    loading: store.loading,
});

const mapDispatchToProps = {
    loadDemographics,
};
export default connect(mapStateToProps, mapDispatchToProps)(Demographics);
