import React, { memo } from 'react';
import s from './Vote.module.scss';
import { Popup } from 'semantic-ui-react';
export const VoteLinkPreview = memo(({ link }) => <Popup trigger={<div className={s.LinkPreview}><div className={s.LinkIcon}></div></div>}
    hoverable
    inverted
    position="bottom center"
>
    <a className={s.LinkPreview} href={link} title={link} target="_blank" rel="noopener noreferrer">{link}</a>
</Popup>);


