import React, { PureComponent } from 'react';
import { Dropdown } from "semantic-ui-react";
import { connect } from 'react-redux';
import { loadTraits } from '../../store/actions/traits';

class TraitSearchDropdown extends PureComponent {

    prepareOptions = () => {
        let { traits = [], customTraits } = this.props;
        traits = customTraits || traits;

        return traits.length ? traits.map(trait => ({
            key: trait.trait_name,
            value: trait.id,
            text: trait.trait_name
        })) : null;
    };

    getSelectedTrait = value => {
        let { traits, customTraits } = this.props;
        traits = customTraits || traits;
        return traits.find(trait => trait.id === value);
    };

    onDropDownChange = (e, { value }) => {
        if (value === null || (this.props.trait && value === this.props.trait.id)) return;
        this.props.handleChange(this.getSelectedTrait(value));
    }

    render() {
        const { trait, disabled = false, className } = this.props;
        return (
            <Dropdown
                className={`diseases-dropdown ${className}`}
                search
                selection
                disabled={disabled}
                placeholder='Choose trait...'
                value={trait && trait.id ? trait.id : null}
                options={this.prepareOptions()}
                onChange={this.onDropDownChange}
            />
        );
    };

    componentDidMount() {
        if (this.props.traits.length < 1) this.props.loadTraits();
    }
};

export default connect((store) => ({ traits: store.traits }), { loadTraits })(TraitSearchDropdown);