import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styles from './Votes.module.scss';
import { Trait, TraitRowHead } from './Table';
import { classList } from '../../utils';
import s from './Votes.module.scss';
import VoteFilters from './VoteFilters';

const Votes = (props) => {
    let [filter, setFilter] = useState('');
    let [sexId, setSex] = useState(0);
    let [fieldId, setField] = useState(0);
    let [demoId, setDemo] = useState(0);
    let { traits = [], loading } = props;
    let trait = null;
    if (props?.match?.params?.id) {
        trait = traits.find(t => t.id === +props.match.params.id);
    } else if (filter) traits = traits.filter(t => ~t.trait_name.toLowerCase().indexOf(filter));

    let message;
    if (!traits?.length > 0 && filter) message = 'Nothing found';

    traits.sort((a, b) => {
        if (a.trait_name > b.trait_name) return 1;
        if (a.trait_name < b.trait_name) return -1;
        return 0;
    });


    return <div className={classList(styles.votes, loading.length ? styles.votes_loading : '')}>
        <h1>Votes{trait && `: ${trait.trait_name}`}</h1>
        {props?.match?.params?.id ? <VoteFilters setDemo={setDemo} setField={setField} setSex={setSex} sexId={sexId} demoId={demoId} fieldId={fieldId} /> :
            <input type="text" placeholder="Filter traits" className={s.SearchBox} onChange={(e) => setFilter(e.target.value.toLowerCase())} />}
        <TraitRowHead />
        {traits.length > 0 && !trait && traits.map(t => <Trait key={t.id} {...t} />)}
        {traits.length === 0 && !trait && <div className={s.NothingFound}>{message}</div>}
        {trait && <Trait {...trait} opened={true} sexId={sexId} fieldId={fieldId} demoId={demoId} />}
    </div>
}

const mapStateToProps = (store) => ({
    traits: store.traits,
    loading: store.loading

});

export default withRouter(connect(mapStateToProps)(Votes));