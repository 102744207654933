import React, { memo, useState } from 'react';
import '../../../../common/Dropdown.scss';
import {classList} from "../../../../../utils";

const DemographicSelector = memo(({ items = [], onChange, className = '', autoComplete = true, multiple = true, placeholder = '' }) => {
    const toggleView = (e, to = null) => {
        setExpanded(to === null ? !expanded : to);
        if (to) setFilter('');
    }

    let [expanded, setExpanded] = useState(false);
    let [filter, setFilter] = useState('');

    if (filter) items = items.filter(i => ~i.text.toLowerCase().indexOf(filter.trim().toLowerCase()));
    let activeItem = items.find(i => i.selected);
    let ddText = activeItem ? activeItem.text : '';
    if (multiple) {
        activeItem = items.filter(i => i.selected);
        ddText = activeItem.map(i => i.text).join(', ');
    }
    if (filter) ddText = filter;

    return <div className={classList('dropdown', expanded && 'expanded', className)} onClick={e => e.stopPropagation()}>
        <div className={classList('dropdown_title', 'dropdown_title__autoComplete')} onClick={toggleView}>
            <div className="dropdown_input">{ddText}</div>
        </div>
        <div className="dropdown_list">
            {items.map(demographic => (
                <div
                    className="demographic"
                    key={demographic.id}
                    onClick={e => {
                        e.stopPropagation();
                        onChange(demographic.value);
                        if (!multiple) setExpanded(false);
                        setFilter('');
                    }}
                >
                    <input
                        type="checkbox"
                        style={{borderColor: demographic.color, ...(demographic.selected && {backgroundColor: demographic.color})}}
                        checked={demographic.selected}
                    />
                    <label>{demographic.text}</label>
                </div>))}
        </div>
        <div className="dropdown_fake_background" onClick={e => toggleView(e, false)}></div>
    </div>
});

export default DemographicSelector;
