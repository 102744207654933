import React from 'react';
import styles from './Users.module.scss';
import { classList } from '../../utils';

export const UserHead = ({ sort, sortField = '' }) => (
    <div className={styles.User_Head}>
        <div title="Click to sort by this column" className={classList(styles.User__Col, styles.User__Col_Sortable, sortField === 'login' && styles.User__Col_Sorted, styles.User__Col_User)} onClick={() => sort('login')}>Login</div>
        <div title="Click to sort by this column" className={classList(styles.User__Col, styles.User__Col_Sortable, sortField === 'email' && styles.User__Col_Sorted)} onClick={() => sort('email')}>E-mail</div>
        <div title="Click to sort by this column" className={classList(styles.User__Col, styles.User__Col_Sortable, sortField === 'role' && styles.User__Col_Sorted)} onClick={() => sort('role')}>Role</div>
        <div title="Click to sort by this column" className={classList(styles.User__Col, styles.User__Col_Sortable, sortField === 'totalVotes' && styles.User__Col_Sorted)} onClick={() => sort('totalVotes')}>Total votes</div>
        <div title="Click to sort by this column" className={classList(styles.User__Col, styles.User__Col_Sortable, sortField === 'confirmedVotes' && styles.User__Col_Sorted)} onClick={() => sort('confirmedVotes')}>Confirmed votes</div>
        <div title="Click to sort by this column" className={classList(styles.User__Col, styles.User__Col_Sortable, sortField === 'rejectedVotes' && styles.User__Col_Sorted)} onClick={() => sort('rejectedVotes')}>Rejected votes</div>
        <div title="Click to sort by this column" className={classList(styles.User__Col, styles.User__Col_Sortable, sortField === 'notReviewedVotes' && styles.User__Col_Sorted)} onClick={() => sort('notReviewedVotes')}>Not reviewed votes</div>
        <div title="Click to sort by this column" className={classList(styles.User__Col, styles.User__Col_Sortable, sortField === 'inactive' && styles.User__Col_Sorted, styles.User__Col_Status)} onClick={() => sort('inactive')}>Status</div>
        <div className={classList(styles.User__Col, styles.User__Col_Actions)}><label>Actions</label></div>
    </div>
)