import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { TraitSearchDropdown } from '../traitSearch';
import { Dropdown } from 'semantic-ui-react';
import actions from '../../store/actions';
import { DropZone } from '../common';
import { FIELD_NAMES } from '../../utils';
import { getEditableVote, updateVote } from '../../api/votes';

const WeightEditBlock = props => {
    const {
        traits,
        selectTrait,
        voteId,
        demographics,
        sex,
        loadVoteHistory,
        showPopup,
        closePopup,
    } = props;
    const [vote, setVote] = useState(null);
    const [error, setError] = useState('');
    const [isFoundWithoutError, setIsFoundWithoutError] = useState(true);
    const [model, setModel] = useState({
        demo: null,
        sex: null,
        value: '',
        link: '',
        citation: '',
        source: null,
        source_file: null,
        source_file_name: null,
    });

    useEffect(() => {
        (async () => {
            try {
                const vote = await getEditableVote(voteId);
                if (!vote) {
                    setIsFoundWithoutError(false);
                }

                setVote(vote);
            } catch (e) {
                showPopup('Something went wrong', e.message);
            }
        })();
    }, []);

    useEffect(() => {
        setModel({
            id: vote?.id,
            demo: demographics.find(d => d.id === vote?.demoId),
            sex: sex.find(s => s.id === vote?.sexId),
            value: vote?.value || model.value,
            link: vote?.link || model.link,
            citation: vote?.citation || model.citation,
            source: vote?.source || model.source,
            source_file: vote?.source_file || model.source_file,
            source_file_name: vote?.source_file_name || model.source_file_name,
        });
    }, [vote]);

    useEffect(() => {
        vote && validateFields();
    }, [model]);

    const validateFields = () => {
        if (vote.field.type !== 'List') {
            if (
                (!model.demo,
                !model.sex,
                !model.source || !model.source_file || !model.link || !model.citation)
            ) {
                return setError('Please fill in all empty fields.');
            }

            if (
                model.value > 80 &&
                vote.field.name === FIELD_NAMES.life_span_impact_score
            ) {
                return setError(`Value should be less than 80.`);
            }
            if (
                model.value > 100 &&
                vote.field.name !== FIELD_NAMES.life_span_impact_score &&
                vote.field.name !== FIELD_NAMES.life_treatment_cost_score
            ) {
                return setError(`Value should be less than 100.`);
            }

            if (model.value < 0 || isNaN(model.value) || model.value === '') {
                return setError(`Value should be a non-negative number.`);
            }

            model.value = +model.value;

            try {
                let link = new URL(model.link);
                model.link = link.href;
            } catch (e) {
                return setError(`Link is not valid`);
            }
        }

        setError('');
    };

    const handleSubmit = async () => {
        if (!error) {
            try {
                const updatedVote = await updateVote(model);
                loadVoteHistory(model.id);
                showPopup(
                    'You will be redirected to the main page in 3 seconds',
                    'Vote has been successfully submitted.'
                );
                setTimeout(() => {
                    setVote(updatedVote.vote);
                    closePopup();
                    setIsFoundWithoutError(false);
                }, 3000);
            } catch (e) {
                showPopup('Something went wrong', e.message);
            }
        }
    };

    return (
        <>
            {(!voteId ||
                !isFoundWithoutError) && <Redirect to="/" />}

            <div className="mw_section_title_edit">Chosen options:</div>
            <div className="form_row">
                <div className="form_col label">
                    <div className="text">Trait</div>
                </div>
                <div className="form_col field">
                    <TraitSearchDropdown
                        className="gray"
                        disabled={!!voteId}
                        trait={traits.find(t => t.id === vote?.traitId)}
                        handleChange={trait => selectTrait(trait)}
                        placeholder="Search by Trait"
                    />
                </div>
            </div>
            <div className="form_row">
                <div className="form_col label">
                    <div className="text">Demographic</div>
                </div>
                <div className="form_col field">
                    <Dropdown
                        className="demographic_dropdown gray"
                        placeholder="Demographic"
                        options={demographics.map(d => ({
                            text: d.name,
                            value: d.short_name,
                        }))}
                        value={model.demo?.short_name}
                        onChange={demo => setModel({ ...model, demo })}
                    />
                </div>
            </div>
            <div className="form_row">
                <div className="form_col label">
                    <div className="text">Sex</div>
                </div>
                <div className="form_col field">
                    <Dropdown
                        className="demographic_dropdown gray"
                        placeholder="Sex"
                        options={sex.map(s => ({ text: s.name, value: s.id }))}
                        value={model.sex?.id}
                        onChange={gender => setModel({ ...model, sex: gender })}
                    />
                </div>
            </div>

            <hr className="mw_section_divider" />

            <div className="mw_section_title_edit">Vote #{vote?.id}</div>
            <div className="form_row">
                <div className="form_col label">
                    <div className="text">Value</div>
                </div>
                <div className="form_col field">
                    <input
                        type="number"
                        step="0.01"
                        min="0"
                        value={model.value}
                        onChange={e => setModel({ ...model, value: e.target.value })}
                        placeholder="Enter amount"
                    />
                </div>
            </div>
            <div className="form_row">
                <div className="form_col label">
                    <div className="text">Link</div>
                </div>
                <div className="form_col field">
                    <input
                        type="text"
                        value={model.link}
                        onChange={e => setModel({ ...model, link: e.target.value })}
                        placeholder="https://"
                    />
                </div>
            </div>
            <div className="form_row">
                <div className="form_col label">
                    <div className="text">Citation</div>
                </div>
                <div className="form_col field">
                    <textarea
                        className="non_resizable"
                        value={model.citation}
                        onChange={e => setModel({ ...model, citation: e.target.value })}
                        placeholder="Paste citation here"
                    />
                </div>
            </div>
            <div className="form_row">
                <div className="form_col label">
                    <div className="text">Source Screenshot</div>
                </div>
                <div className="form_col dropzone_col field">
                    <DropZone
                        field="vote_screenshot"
                        defaultValue={model.source}
                        onDrop={source => setModel({ ...model, source })}
                    />
                </div>
            </div>
            <div className="form_row">
                <div className="form_col  label">
                    <div className="text">Source File (PDF)</div>
                </div>
                <div className="form_col dropzone_col field">
                    <DropZone
                        field="source_file"
                        defaultValue={model.source_file_name}
                        onDrop={source_file =>
                            setModel({
                                ...model,
                                source_file,
                                source_file_name: source_file.name,
                            })
                        }
                    />
                </div>
            </div>

            <div className="form_row block">
                <div className="form_col full error">{error}</div>
            </div>

            <div className="form_row">
                <div className="form_col label"></div>
                <div className="form_col field"></div>
                <div className="form_col submit_col">
                    <button
                        className="ui button gradient invert misery_weight-save"
                        disabled={error}
                        onClick={handleSubmit}
                    >
                        Save
                    </button>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = store => ({
    demographics: store.demographics,
    sex: store.sex,
    traits: store.traits,
});

const mapDispatchToProps = {
    selectTrait: actions.traits.selectTrait,
    updateVoteAndSave: actions.votes.updateVoteAndSave,
    loadVoteHistory: actions.votes.loadVoteHistory,
    showPopup: actions.popups.showPopup,
    closePopup: actions.popups.closePopup,
};

export default connect(mapStateToProps, mapDispatchToProps)(WeightEditBlock);
