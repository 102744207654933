import React from 'react';
import ChangeVoteStatusPopup from '../../../Votes/Vote/ChangeVoteStatusPopup';
import { ChangeVotePopupType } from '../../../Votes/Vote/ChangeVotePopupTypes';

export const ConfirmRejectButtons = (props) => {
    const {vote, user, confirmRejectVote, showPopup, changeConfirmed } = props;
    let showConfirm = !(vote?.confirmations || []).some(c => c.userId === user.id && c.confirmed);
    let showReject = !(vote?.confirmations || []).some(c => c.userId === user.id && !c.confirmed);

    const showRejectVotePopup = () => {
        showPopup(
            <ChangeVoteStatusPopup
                changesType={ChangeVotePopupType.REJECT}
                handleSubmit={
                    reason => changeConfirmed(vote.id, { confirm: false, reason: reason })
                }
            />,
            'Reject Vote'
        );
    }

    return (
        <div className="confirm-reject-btns">
            {showConfirm && <button type="button" className="confirm-reject-btns_confirm" onClick={() => confirmRejectVote(vote.id, { confirm: true })}>Confirm</button>}
            {showReject && <button type="button" className="confirm-reject-btns_reject" onClick={showRejectVotePopup}>Reject</button>}
        </div>
    )
}