import React, { Fragment } from 'react';
import { toFixed } from '../../utils';

export const RegionsTooltipContent = ({ fields, activeDisease, region, zoom, fractionOfBirth }) => {
    let trait = region.traits.find(t => t.disease === activeDisease) || {};
    const empty = '-';
    const showNumber = (number, afterText = '', preText = '') => +number ? preText + toFixed(number * fractionOfBirth, 2) + afterText : empty;
    const linesCount = fields.filter(f => f.selected).reduce((acc, f) => acc + (f.common ? 1 : 3), 0);
    let values = fields.map(f => f.common ? ({ ...f, score: trait[f.value].common }) : [
        { ...f, score: trait[f.value].male, text: f.text + ' Male' },
        { ...f, score: trait[f.value].female, text: f.text + ' Female' },
        { ...f, score: trait[f.value].common, text: f.text + ' Both' }
    ]).flat()
    return <>
        <rect width={140 / zoom} className='rsm-marker-tooltip' x={10 / zoom} y={-15 / zoom} height={(linesCount * 8 + (fields.some(f => f.selected) ? 17 : 12)) / zoom} strokeWidth={.5 / zoom} />
        <polyline className='rsm-marker-tooltip_stick' points={`${10.5 / zoom} ${-11 / zoom}, ${7 / zoom} ${-8 / zoom}, ${10.5 / zoom} ${-5 / zoom}`} strokeWidth={.5 / zoom} />
        <text x={13 / zoom} y={-7 / zoom} fontSize={6 / zoom} className="rsm-marker-tooltip__region">{region.name}</text>
        {values.filter(v => v.selected).map((v, i) => <Fragment key={v.text}>
            <text x={13 / zoom} y={(8 * i + 5) / zoom} fontSize={6 / zoom}>{v.text}</text>
            <text textAnchor="end" className="rsm-marker-tooltip__value" x={147 / zoom} y={(8 * i + 5) / zoom} fontSize={6 / zoom}>{showNumber(v.score, v.type)}</text>
        </Fragment>)}
    </>
}