import { put, takeLatest, takeEvery, call, select } from 'redux-saga/effects';
import { CATEGORIES_ACTIONS } from '../actions/constants';
import * as api from "../../api/trait_categories";
import * as categoriesActions from '../actions/categories';
import actions from '../actions';
import * as loading from '../actions/loading';

function* loadCategories(action) {
    yield put(loading.startLoading('load_categories'));
    let categories = yield call(api.getCategories);

    yield put(categoriesActions.receiveCategories(categories))

    yield put(loading.endLoading('load_categories'));
}

function* addCategory(action) {
    yield put(loading.startLoading('categories'));
    try {
        let data = yield call(api.createCategory, action.payload);
        yield put(categoriesActions.addedTraitCategory(data));
    } catch (e) {
        console.log(e);
    }

    yield put(loading.endLoading('categories'));
}

function* updateCategory(action) {
    yield put(loading.startLoading('categories'));
    try {
        let data = yield call(api.updateCategory, action.payload);
        yield put(categoriesActions.updatedTraitCategory(data));
    } catch (e) {
        console.log(e);
    }

    yield put(loading.endLoading('categories'));
}

function* deleteCategory(action) {
    yield put(loading.startLoading('categories'));
    try {
        yield call(api.deleteCategory, action.payload);
        yield put(categoriesActions.deletedTraitCategory(action.payload));
    } catch (e) {
        console.log(e);
    }

    yield put(loading.endLoading('categories'));
}

export function* categoriesSaga() {
    yield takeLatest(CATEGORIES_ACTIONS.LOAD, loadCategories);
    yield takeLatest(CATEGORIES_ACTIONS.UPDATE, updateCategory);
    yield takeLatest(CATEGORIES_ACTIONS.ADD, addCategory);
    yield takeLatest(CATEGORIES_ACTIONS.DELETE, deleteCategory);
}