import { GENOMIC_INDEX_ACTIONS } from './constants';

export const loadGenomicIndex = () => ({
    type: GENOMIC_INDEX_ACTIONS.LOAD
});

export const receiveGenomicIndex = (data) => ({
    type: GENOMIC_INDEX_ACTIONS.RECEIVE,
    payload: data
});

export const removeGenomicIndex = (data) => ({
    type: GENOMIC_INDEX_ACTIONS.DELETE,
    payload: data
});

export const saveGenomicIndex = (data) => ({
    type: GENOMIC_INDEX_ACTIONS.SAVE,
    payload: data
});

export const removedGenomicIndex = (data) => ({
    type: GENOMIC_INDEX_ACTIONS.DELETED,
    payload: data
});

export const addedGenomicIndex = (data) => ({
    type: GENOMIC_INDEX_ACTIONS.ADDED,
    payload: data
});