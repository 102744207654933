import axios from 'axios';
import config from '../config';

const adminVotesHistoryURL = config.API + 'admin/vote_history/';
const adminVotesHistoryApi = axios.create({ baseURL: adminVotesHistoryURL });

export const getAllUsersContributions = () =>
    adminVotesHistoryApi.get('get_contributions').then(r => r.data);

export const getUserContributions = (userId) =>
    adminVotesHistoryApi.get(`get_contributions/${userId}`).then(r => r.data);

export const getTraitsActivity = () =>
    adminVotesHistoryApi.get('get_traits_activity').then(r => r.data);

export const getProgressGraph = ({metricFieldId, typeOfVotes, traitId}) =>
    adminVotesHistoryApi.post('/get_chart_info', {metricFieldId, typeOfVotes, traitId}).then(r => r.data);
