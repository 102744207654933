import { RESEARCH_ACTIONS as ACTIONS } from '../actions/research';
export const research = (state = [], action) => {
    switch (action.type) {
        case ACTIONS.RECEIVE: return action.payload.rewrite ? action.payload.items : [...state, ...action.payload.items];
        case ACTIONS.RECEIVE_ITEM: 
            if (state.some(i => i.id === action.payload.id)) return  state.map(i => i.id === action.payload.id ? action.payload : i);
            return state.concat(action.payload);
        case ACTIONS.DELETE: return state.filter(i => i.id !== action.payload.id);
        default: return state;
    }
}
