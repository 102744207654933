import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { updateTraitCategory, deleteTraitCategory } from '../../../store/actions/categories';
import actions from '../../../store/actions';
import { Confirm } from '../../common/Popup/Confirm';
import { DropZone } from '../../common';

const defaultState = {
    name: '',
    description: '',
    editing: false,
    icon: null,
    icon_nocircle: null
}

class RowComponent extends PureComponent {
    state = defaultState
    render() {
        const { actions = { delete: false, edit: false }, id, name, description } = this.props;
        const { name: stateName, description: stateDescription, editing } = this.state;
        return (
            <div className="TraitsCategories_table_row">
                <div className="TraitsCategories_table_cell id">{id}</div>
                <div className="TraitsCategories_table_cell name">{editing ? <input type="text" value={stateName} onChange={e => this.setState({ name: e.target.value })} /> : name}</div>
                <div className="TraitsCategories_table_cell description">{editing ? <input type="text" value={stateDescription} onChange={e => this.setState({ description: e.target.value })} /> : description}</div>
                <div className="TraitsCategories_table_cell icon">{actions.edit ?
                    <DropZone field="source_screenshot"
                        otherStyles={{ none: true }}
                        disabled={!editing}
                        defaultValue={this.state.icon?.toDelete ? null : this.props.icon}
                        onDrop={icon => this.setState({ icon })} />
                    : this.props.icon}
                    {editing && (this.props.icon || this.state.icon?.path) && <div className="TraitsCategories__btn_delete_cross" onClick={() => this.setState({icon: {toDelete: true}})}>x</div>}
                </div>
                <div className="TraitsCategories_table_cell icon">{actions.edit ?
                    <DropZone field="source_screenshot"
                        otherStyles={{ none: true }}
                        defaultValue={this.state.icon_nocircle?.toDelete ? null : this.props.icon_nocircle}
                        disabled={!editing}
                        onDrop={icon_nocircle => this.setState({ icon_nocircle })} />
                    : this.props.icon_nocircle}
                    {editing && (this.props.icon_nocircle || this.state.icon_nocircle?.path) && <div className="TraitsCategories__btn_delete_cross" onClick={() => this.setState({icon_nocircle: {toDelete: true}})}>x</div>}

                </div>
                <div className="TraitsCategories_table_cell actions">
                    {actions.edit && editing && stateName && <button className="TraitsCategories_btn_save" onClick={this.saveData}>Save</button>}
                    {actions.edit && editing && <button className="TraitsCategories_btn_cancel" onClick={this.clearState}>Cancel</button>}
                    {actions.edit && !editing && <button className="TraitsCategories_btn_edit" onClick={this.editState}>Edit</button>}
                    {actions.delete && !editing && <button className="TraitsCategories__btn_delete" onClick={this.removeCategory}>Delete</button>}
                </div>
            </div>
        )
    }

    removeCategory = () => {
        const { id, name, linkedTraits = 0, deleteTraitCategory, showPopup } = this.props;

        if (linkedTraits > 0) {
            showPopup(`The Category cannot be deleted because there ${linkedTraits === 1 ? 'is' : 'are'} ${linkedTraits} ${linkedTraits === 1 ? 'trait' : 'traits'} related to it.`, 'Delete Category');
            return;
        }

        showPopup(<Confirm text={`Are you sure you want to delete ${name} category?`} onConfirm={() => deleteTraitCategory(id)} />, 'Delete Category')
    }

    clearState = () => this.setState(defaultState)

    editState = () => {
        this.setState({
            name: this.props.name,
            description: this.props.description,
            editing: true
        });
    }

    saveData = () => {
        const { editing, ...newData } = this.state;

        this.props.updateTraitCategory({
            id: this.props.id,
            ...newData
        });

        this.setState(defaultState);
    }
}

const mapDispatchToProps = {
    deleteTraitCategory,
    updateTraitCategory,
    showPopup: actions.popups.showPopup
}

export const Row = connect(() => ({}), mapDispatchToProps)(RowComponent)