import React, { PureComponent } from 'react';
import Traits from '../../api/traits';
export default class DownloadObjectLink extends PureComponent {
    state = { data: null }
    ref = React.createRef();
    render() {
        return (
            <div className="download-object-link">
                <div className="download-object-link">
                    <div className="download-object-link__text" onClick={this.downloadFile}>Download</div>
                    <a className="download-object-link__invisible" ref={this.ref} href={this.state.data} download={this.props.file} ></a>
                </div>
            </div>
        )
    }

    downloadFile = (e) => {
        e.preventDefault();
        Traits.getDataObject(this.props.field, this.props.file)
            .then(res => this.setState({ data: URL.createObjectURL(new Blob([res.data], { type: 'text/json' })) }, () => this.ref.current.click()))
            .catch(() => alert('Something went wrong'));
    }
}

