import React, { Component } from 'react';

import VotesContainer from '../Votes/VotesContainer';


class EditVotes extends Component {
    render() {
        return (
            <div className='page-container'>
                <VotesContainer />
            </div>
        );
    }
};

export default EditVotes;