import axios from 'axios';
import config from '../config';
import { setAuthTokenToHeader, cloudField } from '../utils';

const URL = config.API + 'admin/genomic_index';
const api = axios.create({ baseURL: URL });

const fileApi = axios.create({
    baseURL: `${config.API}public/get_file/`,
});


export const load = () => api.get().then(r => r.data);
export const loadFile = (folder, file) => fileApi.get(`${cloudField(folder)}/${file}`).then(r => r.data);
export const save = async (data) => {
    let {gsbs, gain, ...model} = data;

    let response = await api.post(`/${data.id}`, data);
    if (response.status !== 200) throw new Error(response.data);
    let { data:responseData } = response;

    delete axios.defaults.headers.common.authorization;

    if (gsbs?.data && responseData.gsbsPresignedUrl) {
        await axios.put(responseData.gsbsPresignedUrl, new Blob([gsbs.data]));
    }

    if (gsbs?.json && responseData.gsbsJsonPresignedUrl) {
        await axios.put(responseData.gsbsJsonPresignedUrl, new Blob([gsbs.json]));
    }

    if (gain?.data && responseData.gainPresignedUrl) {
        await axios.put(responseData.gainPresignedUrl, new Blob([gain.data]));
    }

    setAuthTokenToHeader(localStorage['token']);
    return responseData;
}

export const deleteIndex = (id) => api.delete(`/${id}`);
