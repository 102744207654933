import React, { PureComponent } from 'react';
import { Link } from "react-router-dom";

import { Grid, Button, Container } from 'semantic-ui-react'

export default class AdminHomeContainer extends PureComponent {

    render() {
        return (
            <Container fluid>
                <Grid>
                    <Grid.Row centered>
                        <Grid.Column className='column-with-margin' mobile={16} tablet={16} computer={5}>
                            <Button className='gradient' as={Link} to='/administration/edit-traits' fluid>
                                TRAITS
                            </Button>
                        </Grid.Column>

                        <Grid.Column className='column-with-margin' mobile={16} tablet={16} computer={5}>
                            <Button className='gradient' as={Link} to='/administration/edit-index' fluid>
                                GENOMIC INDEX
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column className='column-with-margin' mobile={16} tablet={16} computer={5}>
                            <Button className='gradient' as={Link} to='/administration/demographics' fluid>
                                DEMOGRAPHICS
                            </Button>
                        </Grid.Column>

                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column className='column-with-margin' mobile={16} tablet={16} computer={5}>
                            <Button className='gradient' as={Link} to='/administration/regions' fluid>
                                REGIONS
                            </Button>
                        </Grid.Column>

                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column className='column-with-margin' mobile={16} tablet={16} computer={5}>
                            <Button className='gradient' as={Link} to='/administration/ess' fluid>
                                ESS
                            </Button>
                        </Grid.Column>

                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column className='column-with-margin' mobile={16} tablet={16} computer={5}>
                            <Button className='gradient' as={Link} to='/administration/phea' fluid>
                                Public Health
                            </Button>
                        </Grid.Column>

                    </Grid.Row>
                </Grid>
            </Container>
        );
    };
};