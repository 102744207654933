import { TRAIT_ACTIONS } from '../actions/constants';

export const traits = (state = [], action) => {
    switch (action.type) {
        case TRAIT_ACTIONS.RECEIVE: return [...action.payload.map(t => {
            const history = state.find(s => s.id === t.id)?.history;
            if (history) t.history = history;
            return t;
        })];
        case TRAIT_ACTIONS.ADD_HISTORY: return state.map(t => trait(t, action));
        case TRAIT_ACTIONS.SAVED_HISTORY_ITEM: return state.map(t => trait(t, action));
        case TRAIT_ACTIONS.DELETED_HISTORY_ITEM: return state.map(t => trait(t, action));
        case TRAIT_ACTIONS.RECEIVED_HISTORY_ITEM: return state.map(t => trait(t, action));
        case TRAIT_ACTIONS.RECEIVE_VOTE_HISTORY: return state.map(t => trait(t, action));
        case TRAIT_ACTIONS.UPDATE: return state.map(t => trait(t, action));
        case TRAIT_ACTIONS.UPDATE_NEW: return [...state, action.payload];
        case TRAIT_ACTIONS.ADD_VOTE_SCREENSHOT: state.map(t => trait(t, action));
        case TRAIT_ACTIONS.CLEAR_ALL: return [];
        default: return state;
    }
}

const trait = (state = {}, action) => {
    switch (action.type) {
        case TRAIT_ACTIONS.ADD_HISTORY: {
            if (state.id === action.payload.id) {
                const token = localStorage.getItem('token');
                const payload = token.split('.')[1];
                const decoded = atob(payload);
                const userId = decoded ? JSON.parse(decoded).subject : undefined;

                return {
                    ...state,
                    history: action.payload.history.map(h => ({
                        ...h,
                        confirmed: h.confirmations && h.confirmations.some(c => c.userId === userId) ? !!h.confirmations.find(c => c.userId === userId).confirmed : null
                    }))
                };
            }

            return state;
        };
        case TRAIT_ACTIONS.SAVED_HISTORY_ITEM: return state.id === action.payload.traitId ? { ...state, history: history(state.history, action) } : state;
        case TRAIT_ACTIONS.DELETED_HISTORY_ITEM: return state.id === action.payload.traitId ? { ...state, history: history(state.history, action) } : state;
        case TRAIT_ACTIONS.RECEIVED_HISTORY_ITEM: return state.id === action.payload.traitId ? { ...state, history: history(state.history, action) } : state;
        case TRAIT_ACTIONS.RECEIVE_VOTE_HISTORY: return state.id === action.payload.traitId ? { ...state, history: history(state.history, action) } : state;
        case TRAIT_ACTIONS.UPDATE: return state.id === action.payload.id ? { ...state, ...action.payload } : state;
        case TRAIT_ACTIONS.ADD_VOTE_SCREENSHOT: return state.id === action.payload.traitId ? { ...state, history: history(state.history, action) } : state;
        default: return state;
    }
}

const history = (state = [], action) => {
    switch (action.type) {
        case TRAIT_ACTIONS.SAVED_HISTORY_ITEM: return state.map(h => vote(h, action));
        case TRAIT_ACTIONS.RECEIVED_HISTORY_ITEM: return state.map(h => vote(h, action));
        case TRAIT_ACTIONS.RECEIVE_VOTE_HISTORY: return state.map(h => vote(h, action));
        case TRAIT_ACTIONS.DELETED_HISTORY_ITEM: return state.filter(h => h.id !== action.payload.id);
        case TRAIT_ACTIONS.ADD_VOTE_SCREENSHOT: return state.map(h => vote(h, action));
        default: return state;
    }
}

export const historyItem = (state = {}, action) => {
    switch (action.type) {
        case TRAIT_ACTIONS.RECEIVED_HISTORY_ITEM:
            const token = localStorage.getItem('token');
            const payload = token.split('.')[1];
            const decoded = atob(payload);
            const userId = decoded ? JSON.parse(decoded).subject : undefined;

            return {
                ...state,
                ...action.payload,
                confirmed: action.payload.confirmations && action.payload.confirmations.some(c => c.userId === userId) ? !!action.payload.confirmations.find(c => c.userId === userId).confirmed : null
            };
        case TRAIT_ACTIONS.RECEIVE_VOTE_HISTORY: return state.id === action.payload.id ? { ...state, history: action.payload.history } : state;
        case TRAIT_ACTIONS.SAVED_HISTORY_ITEM: return { ...state, ...action.payload };
        case TRAIT_ACTIONS.DELETED_HISTORY_ITEM: return state.id === action.payload.id ? { deleted: true } : state;
        case TRAIT_ACTIONS.ADD_VOTE_SCREENSHOT: return state.id === action.payload.id ? { ...state, [action.payload.fieldName]: action.payload.file } : state;

        default: return state;
    }
}

const vote = (state = {}, action) => {
    switch (action.type) {
        case TRAIT_ACTIONS.RECEIVED_HISTORY_ITEM: {
            if (state?.id === action.payload.id) {
                const token = localStorage.getItem('token');
                const payload = token.split('.')[1];
                const decoded = atob(payload);
                const userId = decoded ? JSON.parse(decoded).subject : undefined;

                return {
                    ...action.payload,
                    confirmed: action.payload.confirmations && action.payload.confirmations.some(c => c.userId === userId) ? !!action.payload.confirmations.find(c => c.userId === userId).confirmed : null
                };
            }

            return state;
        };
        case TRAIT_ACTIONS.ADD_VOTE_SCREENSHOT: return state.id === action.payload.id ? { ...state, [action.payload.fieldName]: action.payload.file } : state;

        case TRAIT_ACTIONS.RECEIVE_VOTE_HISTORY: return state.id === action.payload.id ? { ...state, history: action.payload.history } : state;

        case TRAIT_ACTIONS.SAVED_HISTORY_ITEM: {
            if (state.id === action.payload.id) {
                return {
                    ...state,
                    ...action.payload
                };
            }

            return state;
        };
        default: return state;
    }
}

export const submittedHistory = (state = [], action) => {
    switch (action.type) {
        case TRAIT_ACTIONS.CLEAR:
        case TRAIT_ACTIONS.CLEAR_ALL: return [];

        case TRAIT_ACTIONS.ADD_SUBMITTED_HISTORY_ITEM: return state.concat(action.payload0);
        default: return state;
    }
}

export const activeTrait = (state = {}, action) => {
    switch (action.type) {
        case TRAIT_ACTIONS.CLEAR:
        case TRAIT_ACTIONS.CLEAR_ALL: return {};

        case TRAIT_ACTIONS.CHANGE_ONE: return { ...action.payload };
        case TRAIT_ACTIONS.UPDATE_ONE: return { ...state, ...action.payload }
        case TRAIT_ACTIONS.UPDATE_NEW: return { ...state, ...action.payload }
        default: return state;
    }
}