import { put, takeLatest, call, select } from 'redux-saga/effects';
import { VERSIONS_ACTIONS } from '../actions/constants';
import actions from '../actions';
import * as api from '../../api/versions';

const { versions: versionsActions, loading, ess, phea } = actions;

function* loadVersions(action) {
    yield put(loading.startLoading());
    try {
        let versions = yield call(api.load);


        if (versions.length > 0) {
            if (action.version) {
                versions = versions.map(v => ({ ...v, selected: `${v.major}-${v.changed}-${v.updated}` === action.version }))
            } else {
                versions[0].selected = true;
            }
        }

        yield put(versionsActions.receiveVersions(versions));

        if (versions.length > 0) {
            let v = versions.find(v => v.selected);
            if (action.payload === 'stats') yield put(ess.loadEss(v.id));
            if (action.payload === 'phea') yield put(phea.loadPhea(v.id));
        }
    } catch (e) {
        console.error(e);
    }
    yield put(loading.endLoading());
}

function* increaseVersion() {
    yield put(loading.startLoading());
    try {
        let version = yield select(s => s.versions.reduce((last, current) => current.id > last.id ? current : last,{id: 0}));
        if (!version?.approved) {
            yield put(actions.popups.showPopup('Version increase is denied. Required CEO legal use approval of version increase is not verified.', 'Version increase is denied.'));
            yield put(loading.endLoading());
            return;
        }
        yield put(actions.popups.showPopup('Required CEO legal use approval of version increase is verified. Version increase is processing.', 'Version increase'));

        let versions = yield call(api.increase);
        if (versions.length > 0) versions[0].selected = true;
        yield put(versionsActions.receiveVersions(versions));
        if (versions.length > 0) yield put(ess.loadEss(versions[0].id));
    } catch (e) {
        yield put(actions.popups.showPopup('Version increase is erorred. <br/>Maybe the latest version is not approved. <br/>Please refresh the page and check the latest version', 'Version increase'));

    }
    yield put(loading.endLoading());
}

function* chooseVersion(action) {
    yield put(loading.startLoading());
    try {
        yield put(versionsActions.choosedVersion(action.payload));
    } catch (e) {
        console.error(e);
    }
    yield put(loading.endLoading());
}

function* saveVersion(action) {
    yield put(loading.startLoading());
    try {
        let version = yield call(api.save, action.payload);
        yield put(versionsActions.savedVersion(version));
    } catch (e) {
        console.error(e);
    }
    yield put(loading.endLoading());
}


export function* versionsSaga() {
    yield takeLatest(VERSIONS_ACTIONS.LOAD, loadVersions);
    yield takeLatest(VERSIONS_ACTIONS.CHOOSE, chooseVersion);
    yield takeLatest(VERSIONS_ACTIONS.INCREASE, increaseVersion);
    yield takeLatest(VERSIONS_ACTIONS.SAVE, saveVersion);
}