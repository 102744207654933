import React, { memo } from 'react';
import { Popup } from 'semantic-ui-react';

import * as votesApi from '../../../api/votes';

import s from './Vote.module.scss';

export const VoteSourceFilePreview = memo(({ sourceFile, fileName }) => {
    const loadSourceFile = async () => {
        if (typeof sourceFile === 'string') {
            const file = await votesApi.getFile(sourceFile);
            const url = URL.createObjectURL(file);
            const fileLink = document.createElement('a');

            fileLink.href = url;
            fileLink.download = fileName;
            fileLink.click();
        } else {
            const url = URL.createObjectURL(sourceFile);
            const fileLink = document.createElement('a');

            fileLink.href = url;
            fileLink.download = fileName;
            fileLink.click();
        }
    };

    return (
        <Popup trigger={<div className={s.SourceFile} onClick={loadSourceFile}></div>}
            hoverable
            inverted
            position="bottom center"
        >
            <p>{fileName}</p>
        </Popup>
    );
});


