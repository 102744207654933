export const SSR = {
    "title": "SSR Object",
    "type": "object",
    "properties": {
        "stats_array": {
            "type": "array",
            "items": {
                "type": "object"
            }
        },
        "demographic": {
            "type": "string"
        }
    },
    "required": ["stats_array", "demographic"]
}