import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { closePopup } from '../../../store/actions/popups';
import s from './Popup.module.scss';
import { classList } from "../../../utils";

class Popup extends PureComponent {
    render() {
        const { popup, closePopup } = this.props;
        return <div className={classList(s.Popup, popup && s.Popup_Visible)}>
            <div className={s.Background} onClick={() => closePopup(popup?.id)}></div>
            <div className={s.Content}>
                <div className={s.Header}>
                    <div className={s.Header__Text}>{popup?.title}</div>
                    <div onClick={() => closePopup(popup?.id)} className={s.CloseBtn}>Close</div>
                </div>
                {typeof popup?.content === 'string' ? <div className={s.Body} dangerouslySetInnerHTML={{ __html: popup.content}}></div> :
                    <div className={s.Body}>
                        {popup?.content && React.cloneElement(popup?.content, { closePopup: () => closePopup(popup?.id) })}
                    </div>}
            </div>
        </div>
    }
}

const mapStateToProps = (store) => ({
    popup: store.popups[store.popups.length - 1]
})

const mapDispatchToProps = {
    closePopup
}

export default connect(mapStateToProps, mapDispatchToProps)(Popup)