import React, { PureComponent } from 'react'

import DropZone from './DropZone';

import { Dropdown, Icon } from 'semantic-ui-react'
 
export default class DistCurveCell extends PureComponent {

    state = {
        curves: null,
        selectedCurve: 'NEW',
        dropdownValues: null,
        newCurve: null,
        trait: null,
        action: 'EDIT'
    };

    componentDidMount = () => {
        const { traitName, defaultValue } = this.props;
        const currentValues = JSON.parse(defaultValue || null);

        if (currentValues) {
            this.setState({ curves: currentValues });
        }

        this.setDropdownValues(currentValues);

        this.setState({ trait: traitName });
    };

    setDropdownValues = arr => this.setState({ dropdownValues: arr ? [
        ...arr.map(({ filename }, ind) => 
            ({  
                value: ind,
                text: filename,
                content: (
                    <div
                        className='dropdown-item-container'
                        key={filename + ind}
                    >
                        <span>
                            {filename}
                        </span> 
                        <Icon 
                            className='delete-curve-button' 
                            name='close'
                            onClick={(e) => this.handleDelete(ind, e)}
                        />
                    </div>
                )
            })
        ),
        { text: 'New', value: 'NEW' }
    ] : [{ text: 'New', value: 'NEW' }] });

    handleDelete = (ind, e) => {
        const { curves } = this.state;
        const { handleChange } = this.props;
        const newCurves = [ ...curves ];

        newCurves.splice(ind, 1);

        e.stopPropagation();
        this.setState({ curves: newCurves });
        this.setDropdownValues(newCurves);
        this.handleCurveChange('NEW');
        handleChange(JSON.stringify(newCurves));
    };

    toggleAction = () => this.setState(({ action }) => ({ action: !action }));

    handleChange = (value, field) => {
        const { selectedCurve, curves, newCurve } = this.state;

        if(selectedCurve !== 'NEW') {
            const { handleChange } = this.props;
            const updatedCurves = [ ...curves ];
            updatedCurves[selectedCurve] = { ...curves[selectedCurve], [field]: value };

            this.setState({ curves: updatedCurves });
            handleChange(JSON.stringify(updatedCurves));
        } 

        if(selectedCurve === 'NEW') {
            const { handleNewCurve } = this.props;
            const updatedCurve = { ...newCurve };
            updatedCurve[field] = value;

            this.setState({ newCurve: updatedCurve });
            handleNewCurve(updatedCurve);
        } 
    };

    handleCurveChange = value => {
        value === 'NEW' && this.setState({ selectedCurve: 'NEW' });
        value !== 'NEW' && this.setState({ selectedCurve: value });
    };

    handleDrop = file => {                  //User can't update file of the existing curve JSON, so he must delete the old one and submit a new one
        const { selectedCurve } = this.state;

        if(selectedCurve === 'NEW') {
            const { newCurve } = this.state;
            const { handleNewCurve } = this.props;

            this.setState({ newCurve: { ...newCurve, file: file } });
            handleNewCurve({ newCurve: { ...newCurve, file: file } });
        } 

        // if(selectedCurve !== 'NEW') {
        //     const { handleChange } = this.props;

        // } 
        // this.setState({ file });
        // handleChange({ ...this.state, file });
    };

    render() {
        const { curves, selectedCurve, dropdownValues, newCurve } = this.state;
        const { field } = this.props;

        return (
            <>
                <Dropdown 
                    className='administration-table-cell--dropdown administration-table-cell--dropdown-large'
                    selection
                    options={dropdownValues}
                    value={selectedCurve || 'NEW'}
                    onChange={(e, { value }) => this.handleCurveChange(value)} 
                />
                <DropZone
                    className='administration-table-cell--drop'
                    disabled={selectedCurve !== 'NEW'}
                    field={field}
                    defaultValue={
                        selectedCurve === 'NEW' ? (newCurve && newCurve.file && newCurve.file.name) : 
                        (curves && selectedCurve !== null && curves[selectedCurve].filename)
                    }
                    onDrop={this.handleDrop} 
                />
                <Dropdown 
                    className='administration-table-cell--dropdown administration-table-cell--dropdown-large'
                    selection
                    options={[
                        { text: 'Caucasian', value: 'Caucasian' }, 
                        { text: 'Asian', value: 'Asian' },
                    ]}
                    value={
                        selectedCurve === 'NEW' ? (newCurve && newCurve.demo) : 
                        (curves && selectedCurve !== null && curves[selectedCurve].demo)
                    }
                    onChange={(e, { value }) => this.handleChange(value, 'demo')} 
                />
                <Dropdown 
                    className='administration-table-cell--dropdown administration-table-cell--dropdown-large'
                    selection
                    options={[
                        { text: 'Male', value: 'Male' }, 
                        { text: 'Female', value: 'Female' },
                    ]}
                    value={
                        selectedCurve === 'NEW' ? (newCurve && newCurve.sex) : 
                        (curves && selectedCurve !== null && curves[selectedCurve].sex)
                    }
                    onChange={(e, { value }) => this.handleChange(value, 'sex')} 
                />
            </>
        );
    }
};
