import { put, takeLatest, takeEvery, call, select } from 'redux-saga/effects';
import { VOTE_FIELDS_ACTIONS, NEWVOTES_ACTIONS, VOTES_ACTIONS } from '../actions/constants';
import * as Votes from "../../api/votes";
import * as Comments from "../../api/comments";
import actions from '../actions';
import Traits from '../../api/traits';
import { sendToCloud } from '../../api/files';

function* loadFields(action) {
    yield put(actions.loading.startLoading());
    let response = yield call(Votes.getVoteFields);
    let fields = response.data;

    yield put(actions.voteFields.receiveVoteFields(fields));

    yield put(actions.loading.endLoading());
}

function* fillNewVotes() {
    let fields = yield select(s => s.voteFields);
    let sex = yield select(s => s.sex);
    let demographics = yield select(s => s.demographics);
    let categories = yield select(s => s.categories);
    let chromosomes = yield select(s => s.chromosomes);
    let trait = yield select(s => s.activeTrait);
    let votes = [];

    const generateVote = (f, sex, demoId) => {
        let value = null;
        const country = null;
        const yearOfStudy = null;
        const publicationIdentifier = '';

        if (f.name === 'category') {
            value = categories.find(c => c.id === trait.category_id);
            value = value ? value.id : categories.find(c => c.name.toLowerCase() === 'do not know').id;
        }

        if (f.name === 'chromosomes_affected') {
            value = chromosomes.find(c => c.name.toLowerCase() === 'do not know').id;
        }

        return {
            id: Symbol(),
            value,
            demoId,
            sexId: sex?.id,
            sex,
            traitId: trait.id,
            fieldId: f.id,
            field: f,
            new: true,
            title: f.displayName,
            country,
            yearOfStudy,
            publicationIdentifier,
        }
    }

    fields.filter(f => !f.disabled).forEach(f => {
        let demo = f.demos ? demographics.find(d => d.selected).id : null;
        let selectedSex = f.sexes ? sex.find(s => s.selected) : null;

        if (!selectedSex) {
            votes.push(generateVote(f, selectedSex, demo));
            return;
        }

        votes.push(generateVote(f, selectedSex, demo));
    });
    yield put(actions.newvotes.filledNewVotes(votes));
}

function* contributeVotes(action) {
    yield put(actions.loading.startLoading('votes_contribution'));
    try {
        let trait = yield select(s => s.activeTrait);
        let votes = yield select(s => s.newVotes);
        let sexes = yield select(s => s.sex);
        let forgottenVotes = votes.filter(v => !v.value && v.value !== 0 && (v.link || v.source || v.citation));
        votes = votes.filter(v => !!v.value);
        let { captchaResponse } = action.payload;
        let validationMessages = [];

        const handleBothSexesVotes = (votes) => {
            let newVoteList = [];
            const maleSex = sexes.find(item => item.name.toLowerCase() === 'male')
            const femaleSex = sexes.find(item => item.name.toLowerCase() === 'female')
            votes.forEach(vote => vote?.sex?.is_default
                ? newVoteList.push(...[
                    {
                        ...vote,
                        sex: maleSex,
                        sexId: maleSex.id
                    },
                    {
                        ...vote,
                        sex: femaleSex,
                        sexId: femaleSex.id
                    },
                ])
                : newVoteList.push(vote)
            );
            return newVoteList;
        }

        votes = handleBothSexesVotes(votes);

        if (votes.some(v => ~v.field.name.indexOf('life_treatment_cost_score') && +v.value < 0)) {
            validationMessages.push('Lifetime Cost should be a positive number.');
        }
        let numericVotes = votes.filter(v => v.field.type !== 'List');

        if (trait.id !== 'new' && !numericVotes.length && !forgottenVotes.length) {
            validationMessages.push('Please fill in at least one metric.');
        }

        for (let v of forgottenVotes) {
            let fieldName = v.field.displayName;
            if (v.field.sexes) fieldName += ` ${sexes.find(s => s.id === v.sexId).name}`;
            validationMessages.push(`${fieldName} does not have a value.`);
        }

        for (let v of numericVotes) {
            let fieldName = v.field.displayName;
            let { 
                source = null, 
                source_file = null, 
                citation = null, 
                link = null, 
                traitId = null, 
                demoId = null, 
                sexId = null, 
                fieldId = null,
                country = null, 
                yearOfStudy = null,
                publicationIdentifier = '',
            } = v;

            if (!source || !source_file || !citation || !link || !country || !publicationIdentifier.trim() || !yearOfStudy) {
                if (v.field.sexes) fieldName += ` ${sexes.find(s => s.id === v.sexId).name}`;
                validationMessages.push(`${fieldName} should have filled link, source screenshot, source file, citation, DOI/ISBN/ISSN, country and year of study fields.`);
            }
            if (link) {
                try {
                    let url = new URL(v.link);
                    v.link = url.href;
                } catch (e) {
                    validationMessages.push(`${fieldName} has invalid link`);
                }
            }
            if (publicationIdentifier) {
                const response = yield call(Votes.checkPublicationIdentifier, { traitId, demoId, sexId, fieldId, publicationIdentifier  }); 
                response && validationMessages.push(`DOI/ISBN/ISSN in ${fieldName} is already in use.`); 
            }
        }

        yield put(actions.validation.setValidation(validationMessages));

        if (validationMessages.length > 0) {
            yield put(actions.loading.endLoading('votes_contribution'));
            return;
        }

        if (trait.id === 'new') {
            let { category_id, trait_name, trait_text } = trait
            const response = yield call(Traits.submitTrait, { category_id, trait_name, trait_text, captchaResponse, comment: '' });
            trait = response.data;
            votes = votes.map(v => ({ ...v, traitId: trait.id }));
            yield put(actions.traits.updateNewTrait(trait));
        }

        yield put(actions.contribution.contributionChange({ message: 'Saving votes...' }));

        let savedVotes = yield call(Votes.submitVotes, votes, captchaResponse);
        let resourcesCount = savedVotes.reduce((sum, v) => sum +
            (v.presignedSource ? 1 : 0) + (v.presignedSourcePDF ? 1 : 0) + (v.presignedScreenOfCalc ? 1 : 0),
            0);
        yield put(actions.contribution.contributionChange({ message: 'Votes are saved, saving resources...', resources: resourcesCount, total: resourcesCount }))

        for (let vote of savedVotes) {
            let oldVote = votes.find(v => (vote.sexId ? v.sexId === vote.sexId : true) && v.fieldId  === vote.fieldId);
            
            try {
                let { source = null, source_file = null, comment = null } = oldVote;

                if (source) {
                    yield call(sendToCloud, vote.presignedSource, source);
                    resourcesCount--;
                    yield put(actions.contribution.contributionChange({ resources: resourcesCount }));
    
                }
                if (source_file) {
                    yield call(sendToCloud, vote.presignedSourcePDF, source_file);
                    resourcesCount--;
                    yield put(actions.contribution.contributionChange({ resources: resourcesCount }));
                }

                if (comment?.screenshotOfCalculation) {
                    yield call(sendToCloud, vote.presignedScreenOfCalc, comment?.screenshotOfCalculation);
                    resourcesCount--;
                    yield put(actions.contribution.contributionChange({ resources: resourcesCount }));
                }

            } catch(e) {
                yield put(actions.popups.showPopup(`Failed Resource Uploading for ${vote.field.name} `));
            }
            
        }

        yield put(actions.votes.receiveVotes(savedVotes.map(v => ({ ...v, new: true })), true));
        yield put(actions.votes.loadVotes(trait.id));
        yield put(actions.mwEdit.mwEditChange('SUBMITTED'));
        yield put(actions.contribution.contributionChange({ message: '', resources: 0, total: 0 }));
    } catch (e) {
        console.log(e);
        yield put(actions.log.sendLog(e));
        yield put(actions.popups.showPopup(`${e.message ? e.message + '<br/>' : ''}Please try to contribute votes again.`, 'Oooops! Something went wrong :('));
    }
    yield put(actions.loading.endLoading('votes_contribution'));
}

function* loadVotes(action) {
    yield put(actions.loading.startLoading());

    try {
        let votes = yield call(Votes.getVotesForTrait, action.payload);

        yield put(actions.votes.receiveVotes(votes));
    } catch (e) {
        console.log(e);
        yield put(actions.log.sendLog(e));
    }
    yield put(actions.loading.endLoading());
}

function* loadVoteHistory(action) {
    yield put(actions.loading.startLoading());

    try {
        let history = yield call(Votes.getVoteHistory, action.payload.id);
        yield put(actions.votes.updateVote({ oldId: action.payload.id, history }));
    } catch (e) {
        console.log(e);
        yield put(actions.log.sendLog(e));
    }
    yield put(actions.loading.endLoading());
}

function* updateAndSaveVote(action) {
    yield put(actions.votes.updateVote(action.payload));
    yield put(actions.votes.saveVote(action.payload.id));
}

function* saveVote(action) {
    yield put(actions.loading.startLoading());

    try {
        let vote = yield select(s => s.votes.find(v => v.id === action.payload));
        let { confirmations, demographic, field, history, sex, author, ...rawVote } = vote;
        let data = yield call(Votes.updateVote, rawVote);
        if (data?.vote?.is_deleted) {
            yield put(actions.votes.deletedVote(data.vote.id));
            yield put(actions.popups.showPopup('This vote has been already deleted by another user', 'Save vote'));
        } else {
            yield put(actions.votes.updateVote({ oldId: action.payload, ...data.vote }));
            yield put(actions.traits.refreshTrait(data.trait));
            yield put(actions.votes.loadVoteHistory(data.vote.id));
        }

    } catch (e) {
        console.log(e);
        yield put(actions.log.sendLog(e.message));
    }
    yield put(actions.loading.endLoading());
}

function* deleteVote(action) {
    yield put(actions.loading.startLoading());

    try {
        let vote = yield select(s => s.votes.find(v => v.id === action.payload));
        yield call(Votes.deleteVote, action.payload);
        yield put(actions.votes.deletedVote(action.payload));
        let trait = yield call(Traits.getTraitByIdAdmin, vote?.traitId);
        yield put(actions.traits.refreshTrait(trait));
    } catch (e) {
        console.log(e);
        yield put(actions.log.sendLog(e));
    }
    yield put(actions.loading.endLoading());
}


function* loadScreenshot(action) {
    yield put(actions.loading.startLoading());

    try {
        let { id, file } = action.payload;
        let source = yield call(Votes.loadScreenshot, id, file);
        yield put(actions.votes.updateVote({ oldId: id, source }));
    } catch (e) {
        console.log(e);
        yield put(actions.log.sendLog(e));
    }
    yield put(actions.loading.endLoading());
}

function* changeConfirmed(action) {
    yield put(actions.loading.startLoading());

    try {
        let { id, confirmed } = action.payload;
        let vote = yield call(Votes.changeConfirmed, id, confirmed);
        if (vote.is_deleted) {
            yield put(actions.votes.deletedVote(id));
            yield put(actions.popups.showPopup('This vote has been already deleted by another user', 'Change vote confirmation'));
        } else {
            let trait = yield call(Traits.getTraitByIdAdmin, vote?.traitId);
            yield put(actions.traits.refreshTrait(trait));

            yield put(actions.votes.updateVote({ oldId: id, ...vote }));
            yield put(actions.votes.loadVoteHistory(vote.id));
        }

    } catch (e) {
        console.log(e);
        yield put(actions.log.sendLog(e));
    }
    yield put(actions.loading.endLoading());
}

function* changeApproved(action) {
    yield put(actions.loading.startLoading());

    try {
        let { id, approved } = action.payload;
        let vote = yield call(Votes.changeApproved, id, approved);
        if (vote.is_deleted) {
            yield put(actions.votes.deletedVote(id));
            yield put(actions.popups.showPopup('This vote has been already deleted by another user', 'Change vote approval'));

        } else {
            yield put(actions.votes.updateVote({ oldId: id, ...vote }));
            if (approved) {
                let filterFunc;
                switch (true) {
                    case vote.field.sexes && vote.field.demos:
                        filterFunc = v => v.id !== vote.id && v.fieldId === vote.fieldId && v.authorId === vote.authorId && v.traitId === vote.traitId && v.approved && v.sexId === vote.sexId && v.demoId === vote.demoId;
                        break;
                    case vote.field.sexes:
                        filterFunc = v => v.id !== vote.id && v.fieldId === vote.fieldId && v.authorId === vote.authorId && v.traitId === vote.traitId && v.approved && v.sexId === vote.sexId;
                        break;
                    case vote.field.sexes && vote.field.demos:
                        filterFunc = v => v.id !== vote.id && v.fieldId === vote.fieldId && v.authorId === vote.authorId && v.traitId === vote.traitId && v.approved && v.demoId === vote.demoId;
                        break;
                    default:
                        filterFunc = v => v.id !== vote.id && v.fieldId === vote.fieldId && v.authorId === vote.authorId && v.traitId === vote.traitId && v.approved;
                }

                let votesToDelete = yield select(s => s.votes.filter(filterFunc));

                for (let v of votesToDelete) {
                    yield put(actions.votes.deletedVote(v.id));
                }

            }
            let trait = yield call(Traits.getTraitByIdAdmin, vote?.traitId);
            yield put(actions.traits.refreshTrait(trait));
            yield put(actions.votes.loadVoteHistory(vote.id));
        }
    } catch (e) {
        console.log(e);
        yield put(actions.log.sendLog(e));
    }
    yield put(actions.loading.endLoading());
}

function* addReasonForVoteEdit(action) {
    try {
        let { reason, voteId } = action.payload;
        let response = yield call(Votes.notifyEditReason, voteId, reason);
        const { preSignedFileUrl } = response;
        if (preSignedFileUrl) {
            yield call(sendToCloud, preSignedFileUrl, reason.file);
        }
    } catch (e) {
        console.log(e);
        yield put(actions.log.sendLog(e));
    }
}

export function* votesSaga() {
    yield takeLatest(VOTE_FIELDS_ACTIONS.LOAD, loadFields);
    yield takeLatest(NEWVOTES_ACTIONS.FILL, fillNewVotes);
    yield takeLatest(NEWVOTES_ACTIONS.CONTRIBUTE, contributeVotes);
    yield takeEvery(VOTES_ACTIONS.LOAD, loadVotes);
    yield takeEvery(VOTES_ACTIONS.LOAD_HISTORY, loadVoteHistory);
    yield takeLatest(VOTES_ACTIONS.SAVE_VOTE, saveVote);
    yield takeLatest(VOTES_ACTIONS.UPDATE_AND_SAVE, updateAndSaveVote);
    yield takeLatest(VOTES_ACTIONS.ADD_EDIT_REASON, addReasonForVoteEdit)
    yield takeLatest(VOTES_ACTIONS.DELETE, deleteVote);
    yield takeLatest(VOTES_ACTIONS.LOAD_VOTE_SCREENSHOT, loadScreenshot);
    yield takeLatest(VOTES_ACTIONS.CONFIRM_REJECT, changeConfirmed);
    yield takeLatest(VOTES_ACTIONS.CHANGE_APPROVE, changeApproved);
}