import React, { Fragment, memo } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { WeightSubmitted } from '.';
import './Weight.scss';
import WeightForm from './Form/WeightForm';
import WeightInitialBlock from './WeightInitialBlock';
import WeightEditBlock from './WeightEditBlock';
import Loader from './Loader';
import ContributionStatus from './ContributionStatus/ContributionStatus';

const Weight = memo(props => {
    const { trait, saving, loading, mwEdit, validation } = props;
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const voteId = queryParams.get('voteId');

    return (
        <div
            className={`misery_weight${mwEdit === 'SUBMITTED' ? ' submitted' : ''}${
                trait && trait.trait_name ? ' editing' : ' blank'
            }${saving ? ' saving' : ''}`}
        >
            {loading.length > 0 && <Loader />}
            {voteId && (
                <Fragment>
                    <WeightEditBlock voteId={voteId} />
                </Fragment>
            )}
            {(mwEdit !== 'SUBMITTED' || validation.length > 0) && !voteId && (
                <Fragment>
                    <WeightInitialBlock />
                    {mwEdit === 'EDIT' && <WeightForm {...props} />}
                </Fragment>
            )}
            {mwEdit === 'SUBMITTED' && validation.length === 0 && <WeightSubmitted />}
            <ContributionStatus />
        </div>
    );
});

const mapStateToProps = store => ({
    traits: store.traits,
    loading: store.loading,
    mwEdit: store.mwEdit,
    trait: store.activeTrait,
    validation: store.weightValidation,
});

export default connect(mapStateToProps)(Weight);
