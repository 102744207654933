import React, { Component } from 'react';

import { EditTraitsContainer } from '../editTraits';


class EditTraits extends Component {
    render() {
        return (
            <div className='page-container'>
                <EditTraitsContainer />
            </div>
        );
    }
};

export default EditTraits;
