import React, { PureComponent } from 'react';
import s from './Medline.module.scss';
import { classList } from '../../../utils';
import * as constants from '../../../utils/medline.constants';

class UploadButton extends PureComponent {
    state = { dragging: false }

    ref = React.createRef()

    render() {
        const { dragging } = this.state;
        return (
            <>
                <button type="button" className={classList(s.UploadButton, dragging && s.UploadButton_Dragging)}
                    onClick={this.openDialog}
                    onDrop={this.onDrop}
                    onDragEnter={this.onDragEnter}
                    onDragOver={this.preventDefault}
                    onDragLeave={this.onDragLeave}>
                </button>
                <input type="file" accept=".txt" className={s.FileInput} ref={this.ref} onChange={(e) => this.handleFiles(e.target.files)} />
            </>
        )
    }

    preventDefault = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    onDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();

        this.setState({ dragging: true });
    }

    onDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();

        this.setState({ dragging: false });
    }

    openDialog = (e) => {
        this.ref.current.click();
    }

    onDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (this.props.disabled) return;


        const dt = e.dataTransfer;
        const files = dt?.files;

        if (files?.length) this.handleFiles(files);
        this.setState({ dragging: false });

    }

    handleFiles = (files) => {
        if (this.props.disabled) return;
        if (!files?.length) return;
        const reader = new FileReader();
        const file = files[0];
        reader.onload = this.parseItems;
        reader.onerror = this.onError;
        reader.readAsText(file);

        this.ref.current.value = '';
    }

    onError = () => {
        this.setState({ error: 'Invalid file' }, () => setTimeout(() => this.setState({ error: '' }), 5000));
    }

    parseItems = (e) => {
        let data = (e.target.result || '').split('\n');
        let items = [];
        let lastField = '';

        for (let row of data) {
            let key = row.slice(0, 4).trim();
            let value = row.slice(6).trim();

            if (key === constants.PMID) {
                items.push({
                    PMID: +value,
                    FAU: []
                });

                lastField = constants.PMID;
                continue;
            }

            if (!value) continue;

            let lastItem = items[items.length - 1];

            if (!lastItem) continue;
            if (key) lastField = key;

            switch (key) {
                case constants.TI:
                case constants.SO:
                case constants.DP:
                    lastItem[key] = value;
                    continue;
                case constants.FAU:
                    lastItem.FAU.push(value);
                    continue;
                case '':
                    if (!lastField) continue;

                    if (lastField === constants.FAU) {
                        lastItem.FAU[lastItem.FAU.length - 1] = lastItem.FAU[lastItem.FAU.length - 1] + ' ' + value;
                    } else {
                        lastItem[lastField] = lastItem[lastField] + ' ' + value;
                    }

                    continue;
                default:
                    lastField = '';
                    continue;
            }
        }
        this.props.onParse(items);
    }
}

export default UploadButton;