import React, { useRef } from 'react';

export const File = React.forwardRef(({ onChange, file = null, uploaded, label = 'Upload', format = '*' }, buttonRef) => {
    let ref = useRef();
    const uploadFile = (event) => {
        const { files } = event.target;
        if (!files.length) return;

        let file = files[0];
        if (format !== '*') {
            let fileExt = file.name.split('.').slice(-1)[0].toLowerCase();
            if (fileExt !== format.replace('.', '').toLowerCase()) {
                alert(`Only files with ${format} extension can be uploaded`);
                return;
            }
        }
        
        let reader = new FileReader();

        reader.onload = () => {
            let data = reader.result;
            ref.current.value = "";
            let { name } = file;
            onChange({ data, name });
        }

        reader.readAsText(file);
    }

    const deleteFile = (e) => {
        e.preventDefault();
        onChange({ toDelete: true })
    }
    return (
        <>
            <button ref={buttonRef} type="button" className="genomic-index_upload-btn" onClick={() => ref.current.click()} title={file?.name || label}>{file?.name || label}</button>
            {uploaded && <div className="genomic-index_upload-date">{`${uploaded.toDateString()}, ${uploaded.getHours()}:${uploaded.getMinutes() > 9 ? uploaded.getMinutes() : '0' + uploaded.getMinutes()}`}</div>}
            {file && !file.toDelete && <div className="genomic-index_delete-file" onClick={deleteFile}>Delete file</div>}

            <input accept={format} type="file" style={{ display: 'none' }} onChange={uploadFile} ref={ref} />
        </>
    )
})
