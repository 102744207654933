import { CATEGORIES_ACTIONS } from './constants';


export const loadCategories = () => ({
    type: CATEGORIES_ACTIONS.LOAD
});


export const receiveCategories = (data) => ({
    type: CATEGORIES_ACTIONS.RECEIVE,
    payload: data
});

export const addTraitCategory = (data) => ({
    type: CATEGORIES_ACTIONS.ADD,
    payload: data
});

export const addedTraitCategory = (data) => ({
    type: CATEGORIES_ACTIONS.ADDED,
    payload: data
});

export const updateTraitCategory = (data) => ({
    type: CATEGORIES_ACTIONS.UPDATE,
    payload: data
});

export const updatedTraitCategory = (data) => ({
    type: CATEGORIES_ACTIONS.UPDATED,
    payload: data
});

export const deleteTraitCategory = (id) => ({
    type: CATEGORIES_ACTIONS.DELETE,
    payload: id
});

export const deletedTraitCategory = (id) => ({
    type: CATEGORIES_ACTIONS.DELETED,
    payload: id
});