import React from 'react';

import { PageWrapper } from '../common';

const wrapPage = (Component, title, className, icon) => (props) => (
    <PageWrapper title={title} className={className} icon={icon}>
        <Component {...props} />
    </PageWrapper>
);

export default wrapPage;
