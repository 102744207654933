import React, { memo } from 'react';
import s from './ContributionStatus.module.scss';
import { connect } from 'react-redux';
import { classList } from '../../../utils';

const ContributionStatus = ({ contribution, loading = true }) => {
    const { message = '', resources = 0, total = 0 } = contribution;
    return (
        <div className={classList(s.Status, loading && contribution.message && s.Status_Visible)}>
            <div className={s.Popup}>
                <div className={s.Message}>{message}</div>
                {total > 0 && <div className={classList(s.Bar, contribution.total && s.Bar_Visible)} data-text={`${total - resources} of ${total} uploaded`}>
                    <div className={s.Bar__Value} style={{ width: `${(total - resources) / total * 100}%` }}></div>
                </div>}
            </div>
        </div>
    )
}

const mapStateToProps = (store) => ({
    contribution: store.contribution,
    loading: store.loading.some(l => l === 'votes_contribution')
})

export default connect(mapStateToProps)(memo(ContributionStatus));