import React from 'react';

import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import s from './Settings.module.scss';
import { classList } from '../../utils';
import { changeSettings } from '../../store/actions/settings';
import { Checkbox } from '../common';

class GenomicIndexSettings extends React.PureComponent {
    render() {
        const { settings = {}, traits } = this.props;
        let { tabs = this.props.tabs } = (settings?.data || {});
        return <div className={classList(s.Tabs)}>
            <h2 className={s.Title_Small}>Tabs</h2>
            <table className="admin-table news-table">
                <tbody>
                    <tr>
                        <th>Tab</th>
                        <th>Preselected</th>
                        <th>Enabled</th>
                    </tr>
                    {tabs.map(t => <tr key={t.id}>
                        <td>{t.name}</td>
                        <td>
                            <Checkbox
                                className={s.Checkbox}
                                onChange={() => this.changeTabPreselect(t.id)} value={t.active} />
                        </td>
                        <td>
                            <Checkbox
                                className={s.Checkbox}
                                onChange={() => this.changeTabAvailability(t.id)} value={t.enabled} />
                        </td>
                    </tr>
                    )}
                </tbody>
            </table>
        </div>
    }

    changeTabAvailability = (id) => {
        let { settings } = this.props;
        let { tabs = this.props.tabs } = settings?.data;
        tabs = tabs.map(t => t.id === id ? { ...t, enabled: !t.enabled } : t);
        if (tabs.filter(t => t.enabled).length < 2) tabs = tabs.map(t => ({ ...t, active: t.enabled }));
        settings.data.tabs = tabs;
        this.props.changeSettings(settings);
    }

    changeTabPreselect = (id) => {
        let { settings } = this.props;
        let { tabs = this.props.tabs } = settings?.data;
        tabs = tabs.map(t => ({ ...t, active: !t.active }));
        if (tabs.filter(t => t.enabled).length < 2) tabs = tabs.map(t => ({ ...t, active: t.enabled }));
        settings.data.tabs = tabs;
        this.props.changeSettings(settings);
    }

}

const mapDispatchToProps = {
    changeSettings
}

const mapStateToProps = (store) => ({
    tabs: store.performanceTabs,
    settings: store.settings.find(s => s.id === 'relative-risk-reduction')
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GenomicIndexSettings))