import React, {useEffect} from "react";
import styles from "../IndexStatus.module.scss";
import {IndexStatusActivityBlock} from "./IndexStatusActivityBlock";
import {TraitMetricDetailsPopup} from "./TraitMetricDetailsPopup";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import actions from "../../../../../store/actions";

const TraitRow = ({data, showPopup, loadFields, voteFields, confirmationsToCount}) => {
    useEffect(() => {
        !voteFields.length && loadFields();
    }, []);

    const getActivityCount = (metric, region) => {
        if (!data.sex) {
            const maleActivity = data.metrics[metric][region].filter(
                    vote => vote.approved && vote.confirmations >= confirmationsToCount && vote.sex === "male"
                ).length

            const femaleActivity = data.metrics[metric][region].filter(
                    vote => vote.approved && vote.confirmations >= confirmationsToCount && vote.sex === "female"
                ).length

            return Math.min(maleActivity, femaleActivity)
        }
        return data.metrics[metric][region].filter(
                vote => vote.approved && vote.confirmations >= confirmationsToCount && vote.sex === data.sex
            ).length
    }

    return (
        <div className={styles.trait_row}>
            <div className={styles.title}>{data.name}</div>
            {Object.keys(data.metrics).map(metric => (
                <div
                    key={`${metric} ${data.name}`}
                    className={styles.metric_block}
                    onClick={() =>
                        showPopup(
                            <TraitMetricDetailsPopup metricVotes={data.metrics[metric]}/>,
                            `${data.name} ${voteFields.find(field => field.name === metric)?.displayName}`
                        )}
                >
                    <div className={styles.regions}>
                        {Object.keys(data.metrics[metric]).map(region => {
                            return (
                                    <IndexStatusActivityBlock
                                        key={`${metric} ${region}`}
                                        activityCount={getActivityCount(metric, region)}
                                    />
                                )
                            }
                        )}
                    </div>
                </div>
            ))}

        </div>
    )
}

const mapStateToProps = (store) => ({
    voteFields: store.voteFields
})

const mapDispatchToProps = {
    showPopup: actions.popups.showPopup,
    closePopup: actions.popups.closePopup,
    loadFields: actions.voteFields.loadVoteFields
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TraitRow));
