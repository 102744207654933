import { put, takeLatest, call } from 'redux-saga/effects';
import * as api from "../../api/settings";
import actions from '../actions';
const { traits, settingsActions, loading, demographics }= actions
function* fetchSettings(action) {
    yield put(loading.startLoading('settings'));
    try {
        let settings = yield call(api.getSettings);
        yield put(settingsActions.receiveSettings(settings));
        yield put(traits.loadTraits());
        yield put(demographics.loadDemographics());
    } catch (e) {
        console.error(e);
    }
    yield put(loading.endLoading('settings'));
}

function* saveSettings(action) {
    yield put(loading.startLoading('settings'))
    try {
        let setting = yield call(api.saveSettings, action.payload);
        yield put(settingsActions.changedSettings(setting));
    } catch (e) {
        console.log(e);
    }
    yield put(loading.endLoading('settings'));

}

export function* settingsSaga() {
    yield takeLatest(settingsActions.SETTINGS_ACTIONS.LOAD, fetchSettings);
    yield takeLatest(settingsActions.SETTINGS_ACTIONS.CHANGE, saveSettings);

}