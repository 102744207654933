import React from 'react';
import {Header} from "./table/Header";
import UserRow from "./table/UserRow";

export const UserContributionsTable = ({data}) => {
    const isUserActive = (userContributionsData) => {
        return 0 !== (userContributionsData.ADDED | 0
            + userContributionsData.USER_VOTES_CONFIRMED | 0
            + userContributionsData.USER_VOTES_REJECTED | 0
            + userContributionsData.CONFIRMED | 0
            + userContributionsData.REJECTED | 0
            + userContributionsData.UPDATED | 0)
    }

    return (
        <>
            <Header />
            {data.map(userData => userData.contributions && isUserActive(userData.contributions) && (
                <UserRow
                    key={userData.id}
                    userId={userData.id}
                    username={userData.login || userData.email}
                    submittedVotes={userData.contributions?.ADDED}
                    userVotesConfirmed={userData.contributions?.USER_VOTES_CONFIRMED}
                    userVotesRejected={userData.contributions?.USER_VOTES_REJECTED}
                    confirmedVotes={userData.contributions?.CONFIRMED}
                    rejectedVotes={userData.contributions?.REJECTED}
                    editedVotes={userData.contributions?.UPDATED}
                    historyActions={userData?.history}
                />
            ))}
        </>
    )
}
