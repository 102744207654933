import actions from "../actions";
import { put, takeLatest, call } from 'redux-saga/effects';
import * as Statistic from "../../api/statistic";
import { STATISTIC_ACTIONS } from "../actions/constants";


function* loadAllUsersContributions(action) {
    yield put(actions.loading.startLoading());

    try {
        const usersContributions = yield call(Statistic.getAllUsersContributions, action.payload);
        yield put(actions.statistic.receiveUsersContributions(usersContributions));
    } catch (e) {
        console.log(e);
        yield put(actions.log.sendLog(e));
    }
    yield put(actions.loading.endLoading());
}

function* loadUserContributions(action) {
    yield put(actions.loading.startLoading());
    try {
        const userId = action.payload;
        const userActionsHistory = yield call(Statistic.getUserContributions, userId);
        yield put(actions.statistic.receiveUserContributions(userActionsHistory, userId));
    } catch (e) {
        console.log(e);
        yield put(actions.log.sendLog(e));
    }
    yield put(actions.loading.endLoading());
}

function* loadIndexStatusActivityTraitsData(action) {
    yield put(actions.loading.startLoading());
    try {
        const traitActivity = yield call(Statistic.getTraitsActivity);
        yield put(actions.statistic.receiveIndexStatusActivityTraitsData(traitActivity));
    } catch (e) {
        console.log(e);
        yield put(actions.log.sendLog(e));
    }
    yield put(actions.loading.endLoading());
}

function* loadProgressGraphData(action) {
    yield put(actions.loading.startLoading());
    try {
        const data = yield call(Statistic.getProgressGraph, action.payload);
        yield put(actions.statistic.receiveProgressGraphData(data));
    } catch (e) {
        console.log(e);
        yield put(actions.log.sendLog(e));
    }
    yield put(actions.loading.endLoading());
}


export function* statisticSaga() {
    yield takeLatest(STATISTIC_ACTIONS.LOAD_CONTRIBUTIONS, loadAllUsersContributions);
    yield takeLatest(STATISTIC_ACTIONS.LOAD_USER_CONTRIBUTIONS, loadUserContributions);
    yield takeLatest(STATISTIC_ACTIONS.LOAD_INDEX_ACTIVITY_TRAITS_DATA, loadIndexStatusActivityTraitsData);
    yield takeLatest(STATISTIC_ACTIONS.LOAD_PROGRESS_GRAPH_DATA, loadProgressGraphData);
}
