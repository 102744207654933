import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import  actions from '../../../store/actions';
import { loadUsers } from '../../../store/actions/users';
import s from './VoteHistory.module.scss';
import SlackIcon from '../../common/SlackIcon';
class VoteHistory extends PureComponent {
    render() {
        const { votes, id, users } = this.props;
        const vote = votes.find(v => v.id === id);
        let history = vote?.history;

        const isLoading = !Array.isArray(history);
        const isNoData = !isLoading && history?.length === 0;

        return <div className={s.VoteHistory}>
            {isNoData && <div className={s.NoData}>No Data</div>}
            {isLoading && <div className={s.NoData}>Loading...</div>}

            {history?.length > 0 && <>
                <div className={s.Item}>
                    <div>Date</div>
                    <div>User</div>
                    <div>Action</div>
                </div>
                {history.map(h => <div key={h.id} className={s.Item}>
                    <div>{new Date(h.createdAt).toLocaleString()}</div>
                    <div>
                        {h.slackUser && <>{h.slackUser} <SlackIcon /></>}
                        {(h.userId && !h.slackUser?.length) && (users.find(u => u.id === h.userId)?.login || 'Deleted user')}
                    </div>
                    <div className={s['Action_' + h.action]}>{h.action}</div>
                </div>)}
            </>}
        </div>
    }
    componentDidMount() {
        const { id, votes, loadVoteHistory, loadUsers, users } = this.props;

        const vote = votes.find(v => v.id === id);
        if (!users?.length) loadUsers();
        if (!vote?.history) loadVoteHistory(id)
    }
}

const mapStateToProps = (store) => ({
    votes: store.votes,
    users: store.users
})

const mapDispatchToProps = {
    loadVoteHistory: actions.votes.loadVoteHistory,
    loadUsers
}


export default connect(mapStateToProps, mapDispatchToProps)(VoteHistory)