import React from 'react';
import styles from './Statistic.module.scss'
import {classList} from "../../utils";
import {Link} from "react-router-dom";


export const StatisticTabs = (props) => {
    return (
        <div className={styles.statistic_tabs}>
            <Link
                to="/administration/status"
                className={classList(styles.tab, props.activeTab === "index_status" && styles.active)}
            >
                Index Status
            </Link>
            <Link
                to="/administration/graph"
                className={classList(styles.tab, props.activeTab === "progress_graph" &&  styles.active)}
            >
                Progress Graph
            </Link>
            <Link
                to="/administration/status/contributions"
                className={classList(styles.tab, props.activeTab === "contributions" && styles.active)}
            >
                User Contributions
            </Link>
        </div>
    )
}
