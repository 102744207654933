import React, {useState} from "react";
import {connect} from "react-redux";
import styles from "../IndexStatus.module.scss";
import * as votesApi from "../../../../../api/votes";
import {ScreenshotModal} from "../../../../weight/charts/controls/ScrenshotModal";

export const VotePopup = ({vote, user}) => {
    const [modal, setModal] = useState(null);

    const loadScreenshot = async (id, source) => {
        setModal({ voteId: id, source, loading: true });
        if (typeof source === 'string') {
            let screenshot = await votesApi.getFile(source);
            setModal({ voteId: id, source: URL.createObjectURL(screenshot), loading: false });
        }
    };

    const loadSourceFile = async (source, fileName) => {
        if (typeof source === 'string') {
            const file = await votesApi.getFile(source);
            const url = URL.createObjectURL(file);
            const fileLink = document.createElement('a');

            fileLink.href = url;
            fileLink.download = fileName;
            fileLink.click();
        }
    };

    return (
        <div className={styles.vote_popup}>
            <div>
                <b>Trait Name:</b> {vote.trait.trait_name} <br />
                {vote.demographic && <div><b>Genetic Ancestry:</b> {vote.demographic.name}</div>}
                {vote.sex && <div><b>Sex:</b> {vote.sex.name}</div>}
                <b>Value:</b> {vote.value} <br />
                {vote.country && <div><b>Country:</b> {vote.country.name}</div>}
                {vote.yearOfStudy && <div><b>Year:</b> {vote.yearOfStudy}</div>}
                {vote.publicationIdentifier && <div><b>DOI/ISBN/ISSN:</b> {vote.publicationIdentifier}</div>}
                {vote.link ? <a target="_blank" rel="noopener noreferrer" href={vote.link}><b>Source</b></a> : <span>No URL</span>}<br />
                {vote.author ? (
                    <span><b>Created by:</b> {vote.author?.login === 'admin' ? 'Laurent' : vote.author?.login}</span>
                ) : (
                        <span>No Author Data</span>
                    )}<br />
                {vote.whoApproved ? (
                    <span><b>Added by:</b> {vote.whoApproved === 'admin' ? 'Laurent' : vote.whoApproved}</span>
                ) : (
                        <span>No Approver Data</span>
                )}<br />

                {vote.comment?.methodOfCalculation && <div style={{ marginTop: '5px'}}>Method of Calculation: {vote.comment?.methodOfCalculation}</div>}
                {vote.comment?.text && <div style={{ marginTop: '5px'}}>Comment: {vote.comment?.text}</div>}

                {(vote.confirmations && vote.confirmations.filter(c => c.confirmed).length > 0) ? (
                    <div>
                        <span>Confirmed by </span>
                        <span>
                            {vote.confirmations.filter(c => c.confirmed).map(c => (
                                c.user?.login === 'admin' ? 'Laurent' : (c.user?.login || 'deleted user')
                            )).join(', ')}
                        </span>
                    </div>
                ) : null}
                {vote.confirmations && vote.confirmations.filter(c => !c.confirmed).length > 0 ? (
                    <>
                        <span>Rejected by </span>
                        <span>
                            {vote.confirmations.filter(c => !c.confirmed).map(c => (
                                c.user?.login === 'admin' ? 'Laurent' : (c.user?.login || 'deleted user')
                            )).join(', ')}
                        </span>
                        <br />
                    </>
                ) : null}
                {vote.source
                    ? (
                        <div
                            className="screenshot_link"
                            onClick={() => loadScreenshot(vote.id, vote.source)}
                        >
                            Screenshot
                        </div>
                    )
                    : <div>No Screenshot</div>
                }
                {vote.comment?.calculationLink &&
                    <div
                        className="screenshot_link"
                        onClick={() => loadScreenshot(vote.id, vote.comment.calculationLink)}
                    >
                        Screenshot of Calculation
                    </div>
                }
                {vote.source_file
                    ? (
                        <div
                            className="screenshot_link"
                            onClick={() => loadSourceFile(vote.source_file, vote.source_file_name)}
                        >
                            Source File
                        </div>
                    )
                    : <div>No Source File</div>
                }
                {['admin', 'senior'].includes(user?.role) &&
                    <a
                        className={styles.edit_vote_link}
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`/administration/votes/trait/${vote.traitId}/${vote.id}`}
                    >
                        <button title="Edit" className={styles.edit_btn}><i></i></button>
                    </a>
                }
                {modal && <ScreenshotModal modal={modal} onModalClose={() => setModal(null)}/>}

            </div>
        </div>
    )
}

const mapStateToProps = (store) => ({
    user: store.user,
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(VotePopup);
