import React, { memo } from 'react';
import s from './Vote.module.scss';
import { Popup } from 'semantic-ui-react';

export const VoteCommentPreview = memo(({ comment }) => <Popup
    trigger={<div className={s.CommentPreview}><div className={s.CommentIcon}></div></div>}
    hoverable
    inverted
    position="bottom center"
>
        <div>Comment: </div>
        <p>{comment}</p>
</Popup>);


