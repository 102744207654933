import React from 'react';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { useDispatch } from 'react-redux';
import { Checkbox } from '../common/';
import { deleteResearchItem, updateResearchItem } from '../../store/actions/research';
import t from '../common/Table.module.scss';
import c from '../common/Controls.module.scss';
import s from './Research.module.scss';
import { classList } from '../../utils';
import { Draggable } from 'react-beautiful-dnd';

const Row = ({ data, index }) => {
    const dispatch = useDispatch();
    const onPublishedChange = () => dispatch(updateResearchItem({ id: data.id, published: !data.published }));
    const onDelete = () => dispatch(deleteResearchItem(data));
    return (
        data && <Draggable draggableId={data.id.toString()} index={index}>
            {(provided, snapshot) => (<div className={classList(t.Table__Row, snapshot.isDragging && t.Table__Row_Dragging)}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef} >
                <div className={classList(t.Table__Column, s.Column)}>{data.title}</div>
                <div className={classList(t.Table__Column, s.Column)}>
                    <Checkbox value={!!data.published} onChange={e => {
                        onPublishedChange(data.id, !data.published, e)
                    }} />
                </div>
                <div className={classList(t.Table__Column, s.Column)}>
                    <ReactTooltip
                        clickable={true}
                        delayHide={200}
                        place="top"
                        type="light"
                        effect="solid"
                        backgroundColor="transparent"
                        id={data.id.toString()}
                        scrollHide={true}
                    >
                        <div className={s.DeleteModal}>
                            <p>Are you sure you want to delete research item?</p>
                            <button className={c.Btn_Delete} style={{ display: 'block', margin: 'auto', transform: 'scale(.9)' }} onClick={e => onDelete(data.id, e)}>
                                Delete
                            </button>
                        </div>
                    </ReactTooltip>
                    <Link className={c.Btn} to={`/administration/research/${data.id}`}>
                        <i className="material-icons-outlined edit-post">edit</i>
                    </Link>
                    <i className={c.Btn_Delete} style={{ marginLeft: 10 }} data-tip data-for={data.id} data-iscapture="true">delete</i>
                </div>
            </div>)}
        </Draggable>
    )
}

export default Row;