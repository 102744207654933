import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import s from './Medline.module.scss';
import UploadButton from './UploadButton';
import ResearchItemPreview from './ResearchItemPreview';
import { parseMedlineDate } from '../../../utils';
import actions from '../../../store/actions';
class MedlinePopup extends PureComponent {
    state = { items: null }

    render() {
        const { items } = this.state;
        const checkedItems = (items || []).filter(i => i.checked);

        return (
                <div className={s.Popup}>
                    <UploadButton onParse={this.setItems} />
                    {checkedItems.length > 0 && <button className={s.SaveButton} onClick={this.saveData}>Save {checkedItems.length} Research {checkedItems.length === 1 ? 'Item' : 'Items'}</button>}
                    {items?.length > 0 && <div className={s.ResearchItems}>
                        {items.map(i => <ResearchItemPreview onChoose={this.checkUncheckItem} {...i} key={i.PMID} />)}
                    </div>}
                </div>
        )
    }

    setItems = (items) => {
        const { existingItems = [] } = this.props;

        this.setState({
            items: items.map(i => ({
                checked: !existingItems.some(e => e.pmid === i.PMID),
                disabled: existingItems.some(e => e.pmid === i.PMID),
                ...i
            }))
        });
    }

    checkUncheckItem = (id) => {
        const { items } = this.state;

        this.setState({
            items: items.map(i => ({
                ...i,
                checked: i.PMID === id ? !i.checked && !i.disabled : i.checked && !i.disabled
            }))
        });
    }

    saveData = async () => {
        let { createResearchItem } = this.props;
        let items = this.state.items.filter(i => i.checked);
        if (items.length < 1) return;

        items = items.map(i => {
            let { PMID: pmid, SO: source, TI: title, FAU: authors, DP: dp } = i;

            let publicationDate = parseMedlineDate(dp);

            return {
                pmid,
                source,
                title,
                authors,
                dp,
                publicationDate,
                order: 0,
                published: true,
                views: 0,
                url: ''
            }
        });

        await Promise.all(items.map(i => createResearchItem(i)));
        this.props.getResearch();
        this.props.closePopup();
    }
}

const mapStateToProps = (store) => ({
    existingItems: store.research?.research
});

const mapDispatchToProps = {
    createResearchItem: actions.research.createResearchItem,
    getResearch: actions.research.loadResearch,
    closePopup: actions.popups.closePopup

}

export default connect(mapStateToProps, mapDispatchToProps)(MedlinePopup);