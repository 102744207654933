import axios from 'axios';
import config from '../config';

const URL = config.API + 'admin';
const api = axios.create({ baseURL: URL });

export default {
    login: function(data) {
        return api.post('/login', data);
    },

    verifyToken: function(role = 'admin') {
        return api.get(`/verifyToken/${role}`);
    }
};

