export const RESEARCH_ACTIONS = {
    LOAD: 'RESEARCH_LOAD',
    LOAD_ITEM: 'RESEARCH_LOAD_ITEM',
    RECEIVE: 'RESEARCH_RECEIVE',
    RECEIVE_ITEM: 'RESEARCH_RECEIVE_ITEM',
    UPDATE: 'RESEARCH_UPDATE',
    DELETE: 'RESEARCH_DELETE',
    CREATE: 'RESEARCH_CREATE',
    CHANGE_ORDER: 'RESEARCH_CHANGE_ORDER'
}

export const loadResearch = (rewrite = true) => ({
    type: RESEARCH_ACTIONS.LOAD,
    payload: rewrite
})

export const loadResearchItem = (id) => ({
    type: RESEARCH_ACTIONS.LOAD_ITEM,
    payload: id
})

export const receiveResearch = (items, rewrite = true) => ({
    type: RESEARCH_ACTIONS.RECEIVE,
    payload: {items, rewrite}
})

export const receiveResearchItem = (item) => ({
    type: RESEARCH_ACTIONS.RECEIVE_ITEM,
    payload: item
})

export const updateResearchItem = (item) => ({
    type: RESEARCH_ACTIONS.UPDATE,
    payload: item
})

export const deleteResearchItem = (item) => ({
    type: RESEARCH_ACTIONS.DELETE,
    payload: item
})

export const createResearchItem = (item) => ({
    type: RESEARCH_ACTIONS.CREATE,
    payload: item
})

export const changeResearchOrder = (ids) => ({
    type: RESEARCH_ACTIONS.CHANGE_ORDER,
    payload: ids
})