import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import User from '../../api/users';
import Auth from '../../api/login';
import AdminMenu from '../AdminMenu/AdminMenu';
import { connect } from 'react-redux';
import actions from '../../store/actions';

const adminMenuItems = [
    {text: 'Status', link: '/administration/status', default: true},
    {text: 'Traits', link: '/administration/edit-traits'},
    {text: 'Trait Categories', link: '/administration/categories'},
    {text: 'Votes', link: '/administration/votes'},
    {text: 'Charts', link: '/administration/charts'},
    {text: 'Genomic Index', link: '/administration/edit-index'},
    {text: 'Genetic Ancestry', link: '/administration/demographics'},
    {text: 'Users', link: '/administration/users'},
    {text: 'Regions', link: '/administration/regions'},
    {text: 'ESS', link: '/administration/ess'},
    {text: 'Public Health', link: '/administration/phea'},
    {text: 'Research', link: '/administration/research'},
    {text: 'Settings', link: '/administration/settings'}
];

const seniorMenuItems = [
    {text: 'Status', link: '/administration/status', default: true},
    {text: 'Votes', link: '/administration/votes'},
    {text: 'Charts', link: '/administration/charts'},
    {text: 'ESS', link: '/administration/ess'},
    {text: 'Regions', link: '/administration/regions'},
    {text: 'Public Health', link: '/administration/phea'}
]

const withAuth = (WrappedComponent = () => <div></div>, role = 'admin', user) => {
    class WithAuthClass extends Component {

        state = {
            isTokenChecked: false,
            isAuthenticated: false,
            currentUserRole: null
        };

        componentDidMount = async () => {
            const token = User.getToken();
            const { setUser } = this.props;
            if (token) {
                try {
                    const response = await Auth.verifyToken(role);
                    if (response.status === 200) {
                        setUser(response.data);
                    }
                    this.setState({ isAuthenticated: response.status === 200, isTokenChecked: true, currentUserRole: response?.data?.role });
                } catch (e) {
                    this.setState({ isAuthenticated: false, isTokenChecked: true });
                }
            } else {
                this.setState({ isTokenChecked: true, isAuthenticated: false });
            }
        };
        render() {
            const { isTokenChecked, isAuthenticated, currentUserRole } = this.state;

            return (
                isTokenChecked ? (isAuthenticated ? <>
                    {(role === 'senior' || role === 'admin') &&
                    <AdminMenu menuItems={currentUserRole === 'admin' ? adminMenuItems : seniorMenuItems} />}
                    <WrappedComponent {...this.props} />
                </> :
                    <Redirect to="/login" />) : <></>
            )
        }
    }
    const mapDistatchToProps = {
        setUser: actions.users.setCurrentUser
    }
    return connect(() => ({}), mapDistatchToProps)(WithAuthClass);
}

export default withAuth;
