import { COUNTRIES_ACTIONS } from './constants';

export const loadCountries = () => ({
    type: COUNTRIES_ACTIONS.LOAD,
});

export const receiveCountries = data => ({
    type: COUNTRIES_ACTIONS.RECEIVE,
    payload: data,
});
