import React, { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { showPopup } from '../../store/actions/popups';
import * as actions from '../../store/actions/research';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import Row from './Row';
import MedlinePopup from './Medline/MedlinePopup';
import s from './Research.module.scss';
import { classList } from '../../utils';

const ResearchList = () => {
    const loading = useSelector(s => s.loading.length);
    const research = useSelector(s => s.research);
    const dispatch = useDispatch();
    const showMedlineModal = () => {
        dispatch(showPopup(<MedlinePopup />, 'Upload Medline'));
    }
    useEffect(() => {
        if (!research.length) dispatch(actions.loadResearch())
    }, [research])

    const onDragEnd = result => {
        const list = [...research];
        const startIndex = result.source.index;
        const endIndex = result.destination.index;
        const [removed] = list.splice(startIndex, 1);

        list.splice(endIndex, 0, removed);
        dispatch(actions.receiveResearch(list));
        dispatch(actions.changeResearchOrder(list.map(item => item.id)));
    };

    return <div className={s.PageContainer}>
        <div className="admin-title-box floated">
            <h2 className="left">Research</h2>
            <Link to="/administration/research/new" className="right">
                <button className={s.Btn_Add}>
                    Add Research Item
                </button>
            </Link>
            <button onClick={showMedlineModal}
                className={s.Btn}>Upload Medline File</button>
        </div>
        <br />
        <p className="posts-number">Total Research Items: {research.length}</p>
        <div className={classList(s.Table)}>
            <div className={classList(s.Table__Row, s.Table__Row_Head)}>
                <div className={classList(s.Table__Column, s.Column)}>
                    Title
                </div>
                <div className={classList(s.Table__Column, s.Column)}>
                    Publish
                </div>
                <div className={classList(s.Table__Column, s.Column)}>
                    Actions
                </div>
            </div>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId='research'>
                    {provided => (
                        <div className="research" ref={provided.innerRef} {...provided.droppableProps}>
                            {research.map((item, index) => item &&
                                <Row
                                    key={item.id}
                                    data={item}
                                    index={index}
                                    isUpdating={loading}
                                />
                            )}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

        </div>
    </div>
}

export default ResearchList;