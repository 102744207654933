import { put, takeLatest, call } from 'redux-saga/effects';
import { CHROMOSOMES_ACTIONS } from '../actions/constants';
import * as actions from '../actions/chromosomes';
import * as loading from '../actions/loading';
import * as api from '../../api/chromosomes';

function* loadChromosomes() {
    yield put(loading.startLoading());
    try {
        let chromosomes = yield call(api.load);
        yield put(actions.receiveChromosomes(chromosomes));
    } catch(e) {
        console.error(e);
    }
    yield put(loading.endLoading());
}

export function* chromosomesSaga() {
    yield takeLatest(CHROMOSOMES_ACTIONS.LOAD, loadChromosomes);
}