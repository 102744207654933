import { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import sagas from './sagas';
import * as reducers from './reducers';
export const configureStore = () => {

    const defaultStore = {

        traits: [],

        traitNames: null,

        activeTrait: {},

        submittedHistory: [],

        categories: [],

        sex: [],

        chromosomes: [],

        regions: [],

        demographics: [],

        loading: [],

        mwEdit: 'INITIAL',

        privacy: null,

        newVotes: [],

        voteFields: [],

        genomicIndex: [],

        user: null,

        adminRegions: [],

        ess: [],

        phea: [],

        versions: [],

        contribution: { message: '', resources: 0, total: 0 },

        AUCRRRs: [],

        research: [],

        authors: [],

        countries: [],

        statistic: {
            userStatistic: [],
            indexStatusTraits: [],
            progressGraph: {}
        }
    }

    const sagaMiddleware = createSagaMiddleware();

    const store = createStore(
        combineReducers(reducers),
        defaultStore,
        composeWithDevTools(applyMiddleware(sagaMiddleware))
    );
    sagaMiddleware.run(sagas);

    return store;
}
