import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { addTraitCategory } from '../../../store/actions/categories';
import { DropZone } from '../../common';

class NewRow extends PureComponent {
    state = {
        name: '',
        description: '',
        icon: null,
        icon_nocircle: null
    }
    render() {
        const { name, description } = this.state;
        return (
            <div className={`TraitsCategories_table_row new`}>
                <div className="TraitsCategories_table_cell id">New</div>
                <div className="TraitsCategories_table_cell name">
                    <input type="text" value={name} onChange={e => this.setState({ name: e.target.value })} />
                </div>
                <div className="TraitsCategories_table_cell description">
                    <input type="text" value={description} onChange={e => this.setState({ description: e.target.value })} />
                </div>
                <div className="TraitsCategories_table_cell icon">
                    <DropZone field="source_screenshot" otherStyles={{ none: true }} onDrop={icon => this.setState({ icon })} />
                </div>
                <div className="TraitsCategories_table_cell icon">
                    <DropZone field="source_screenshot" otherStyles={{ none: true }} onDrop={icon_nocircle => this.setState({ icon_nocircle })} />
                </div>

                <div className="TraitsCategories_table_cell actions">
                    <button className="cancel-btn" disabled={!name} onClick={this.saveCategory}>Save</button>
                </div>
            </div>
        )
    }

    saveCategory = () => {
        this.props.addTraitCategory({...this.state});
        this.setState({ description: '', name: '', icon: null, icon_nocircle: null });
    }
}

const mapDispatchToProps = {
    addTraitCategory
}

export default connect((store) => ({}), mapDispatchToProps)(NewRow);