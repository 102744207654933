import React, { PureComponent, Fragment } from 'react';
import { GridCell } from '../administrationTable';
import Traits from "../../api/traits";
import * as categoriesApi from '../../api/trait_categories';
import { Button, Popup } from 'semantic-ui-react'
import './edit.traits.scss';
import TraitCategoriesDropdown from '../common/TraitCategoriesDropdown';
import Checkbox from '../common/CustomCheckbox';
import { connect } from 'react-redux';
import actions from '../../store/actions';
import { Confirm } from '../common/Popup/Confirm';
import AddTraitPopup from "../weight/AddTrait/AddTraitPopup";
import { getBrowserVisibilityProp, getIsDocumentVisible } from "../../utils/browserTabHelper";

class EditTraitsContainer extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            traits: [],
            updatedTraits: [],
            successNotification: false,
            headerValues: { fields: [], labels: [] },
            isLoading: false,
            isAddTraitOpen: false,
            filter: '',
            scrollLeft: 0,
            categories: [],
            activeCategory: '',
            withPCC: false,
            withRRR: false,
            withSSR: false
        };

        this.newTraitContainerRef = React.createRef();
    }

    componentDidMount = () => {
        this.fetchData();

        document.addEventListener('mousedown', this.handleClickOutsideNewTraitContainer);
        document.addEventListener(getBrowserVisibilityProp(), this.handleTabVisibility);
    }

    fetchData = () => {
        this.setState({ isLoading: true }, async () => {
            const categories = await categoriesApi.getCategories();
            const res = await Traits.getTraitsWithMetadata();
            if (!this.props.demographics.length) this.props.loadDemographics(true);
            this.setState({
                traits: res[0].data,
                categories,
                headerValues: {
                    fields: res[1]?.data,
                    raw: Object.keys(res[1]?.data || {}),
                    labels: Object.values(res[1]?.data || {}).map(field => field.label)
                },
                isLoading: false
            });
        })
    }

    componentWillUnmount = () => {
        document.removeEventListener('mousedown', this.handleClickOutsideNewTraitContainer);
        document.removeEventListener(getBrowserVisibilityProp(), this.handleTabVisibility);
    }

    createTrait = (trait_name, category_id, trait_text) => {
        const newTrait = { trait_name, category_id, id: 'new', trait_text };
        this.props.saveTrait(newTrait, true);
        this.setState({ isAddTraitOpen: false });
        this.fetchData();
        this.props.clearAllTraits();
    }

    closeNewTraitContainer = () => {
        this.setState({isAddTraitOpen: false});
    }

    handleClickOutsideNewTraitContainer = (event) => {
        if (this.state.isAddTraitOpen && this.newTraitContainerRef && !this.newTraitContainerRef.current.contains(event.target)) {
            this.closeNewTraitContainer();
        }
    }

    handleNewTraitButtonClick = () => {
        this.setState({isAddTraitOpen: true});
    }

    handleTabVisibility = () => {
        if (getIsDocumentVisible()) {
            this.props.loadDemographics(true);
        }
    }

    handleChange = (id, field, value, trait) => {
        let { updatedTraits, traits } = this.state;
        let changedTrait = updatedTraits.find(t => t.id === id);

        const mapFunction = t => t.id === id ? ({ ...t, [field]: value }) : t

        traits = traits.map(mapFunction)
        if (changedTrait) {
            this.setState({ traits, updatedTraits: updatedTraits.map(mapFunction) });
        } else {
            let newItem = {
                id,
                [field]: value
            }
            this.setState({ traits, updatedTraits: [...updatedTraits, newItem] })
        }
    };

    handleDelete = (id) => {
        const { showPopup } = this.props;
        let { traits } = this.state;
        let { trait_name } = traits.find(t => t.id === id);

        showPopup(<Confirm onConfirm={() => this.handleDeleteConfirmed(id)} text={`Are you sure you want to delete ${trait_name}?`} />, 'Delete trait')
    }

    handleDeleteConfirmed = async (id) => {
        let { updatedTraits, traits } = this.state;

        try {
            await Traits.deleteTrait(id);
            this.setState({ traits: traits.filter(t => t.id !== id), updatedTraits: updatedTraits.filter(t => t.id !== id) });

            this.props.clearAllTraits();
        } catch (e) {
            alert(`There is some error, Trait wasn't deleted`);
        }
    };

    handleSubmit = async () => {
        const { updatedTraits, traits } = this.state;

        if (updatedTraits.length > 0) {
            this.setState({ isSaving: true });
            try {
                let res = await Traits.updateTraits(updatedTraits);
                let traitsWithChanges = traits.map(t => {
                    let updatedTrait = updatedTraits.find(u => u.id === t.id);
                    if (updatedTrait) return { ...t, ...updatedTrait };
                    return t;
                })
                const isError = res.some(({ status }) => status !== 200);

                if (isError) {
                    console.log(res.find(({ status }) => status !== 200));
                    this.setState({ isSaving: false, successNotification: true, updatedTraits: [], traits: traitsWithChanges });
                    setTimeout(() => this.setState({ successNotification: false }), 1000);
                } else {
                    traitsWithChanges = traitsWithChanges.filter(t => !t.toDelete).map(t => {
                        const sameTraitRes = res.find(r => r.data && r.data.id === t.id);
                        const { data, toDelete, ...oldTraitData } = t;
                        return (sameTraitRes) ? { ...oldTraitData, ...sameTraitRes.data } : oldTraitData;
                    });

                    this.setState({ isSaving: false, successNotification: true, updatedTraits: [], traits: traitsWithChanges });
                    setTimeout(() => this.setState({ successNotification: false }), 1000);
                }
            } catch (e) {
                let errorResponse = e?.response;
                let errorText = errorResponse && errorResponse.status < 500 && errorResponse.data
                    ? `Error: ${errorResponse.data}`
                    : 'Something went wrong, please refresh the page'
                alert(errorText)
                this.setState({ isSaving: false, successNotification: true, updatedTraits: [] });
                setTimeout(() => this.setState({ successNotification: false }), 1000);
                document.location.reload();
            }

            this.props.clearAllTraits();
        }
    };

    onTableScroll = (e) => {
        if (this.state.scrollLeft !== e.target.scrollLeft) this.setState({ scrollLeft: e.target.scrollLeft });
    }

    render() {
        const {
            traits,
            headerValues,
            isLoading,
            isSaving,
            isAddTraitOpen,
            filter,
            scrollLeft,
            categories,
            successNotification,
            updatedTraits,
            activeCategory
        } = this.state;

        let traitsToShow = filter ? traits.filter(t => t.trait_name.toLowerCase().indexOf(filter.trim()) > -1) : traits;
        if (activeCategory && activeCategory !== '0') traitsToShow = traitsToShow.filter(t => t.category_id === activeCategory);

        if (this.state.withPCC) traitsToShow = traitsToShow.filter(t => Array.isArray(t.PCC) && t.PCC.length);

        if (this.state.withRRR) traitsToShow = traitsToShow.filter(t => Array.isArray(t.RRR) && t.RRR.length);

        if (this.state.withSSR) traitsToShow = traitsToShow.filter(t => Array.isArray(t.SSR) && t.SSR.length);

        let rowsTemplate = headerValues.labels.map(i => 'auto').join(' ');
        // let columnsTemplate = traitsToShow.map(i => 'auto').join(' ');
        const gridStyle = { gridTemplateRows: rowsTemplate }
        return (
            <Fragment>
                <div className="edit-traits">

                    <Button
                        className={`gradient edit-traits_save-btn ${successNotification ? ' success' : ''}${isSaving ? ' saving' : ''}${updatedTraits.length === 0 ? ' nothing-to-save' : ''}`}
                        onClick={this.handleSubmit}>Save Changes</Button>
                    <h1>Edit traits</h1>
                    <div className={`edit-traits_container${isLoading ? ' loading' : ''} ${isSaving ? ' saving' : ''}`}>
                        <div className="edit-traits_top-panel">
                            <div className="edit-traits_search-container">
                                <input value={this.state.filter || ''} placeholder="Search" onChange={e => this.setState({ filter: e.target.value.toLowerCase() })} />
                            </div>
                            <Button className={`gradient invert edit-traits_new-btn`} onClick={this.handleNewTraitButtonClick}>New Trait</Button>

                            <div ref={this.newTraitContainerRef} className={`edit-traits_new-trait-modal ${isAddTraitOpen ? '' : 'closed'}`}>
                                <AddTraitPopup
                                    onClose={this.closeNewTraitContainer}
                                    categories={categories}
                                    onCreateTrait={this.createTrait} />
                            </div>

                            <TraitCategoriesDropdown
                                className="categories-dropdown"
                                categories={[{ id: 0, name: 'All Categories' }, ...categories]}
                                defaultValue={0}
                                onChange={(categoryName) => this.setState({ activeCategory: categoryName })} />
                            <div className="edit-traits_fast-filters">
                                <label>Filter options</label>
                                <div><Checkbox value={this.state.withPCC} onChange={(e, value) => this.setState({ withPCC: value })} /> Only wth PCC</div>
                                <div><Checkbox value={this.state.withRRR} onChange={(e, value) => this.setState({ withRRR: value })} /> Only wth RRR</div>
                                <div><Checkbox value={this.state.withSSR} onChange={(e, value) => this.setState({ withSSR: value })} /> Only wth SSR</div>
                            </div>
                        </div>
                        <div className="edit-traits_table_container" onScroll={this.onTableScroll}>
                            {traitsToShow?.length ? <div className="edit-traits_table" style={gridStyle || {}} >
                                {Object.keys(headerValues.fields || {}).map(f => {
                                    const item = headerValues.fields[f];
                                    return <div className="edit-traits_table_th" key={f} style={{ transform: `translateX(${scrollLeft}px)` }}>{item.label}
                                        <Popup
                                            position='top right'
                                            hoverable
                                            trigger={
                                                <div className="help_icon"></div>
                                            }
                                            content={
                                                <div>
                                                    <p dangerouslySetInnerHTML={{__html: item.description || ''}}></p>
                                                </div>
                                            }
                                            inverted
                                        />
                                    </div>
                                })}
                                {traitsToShow.map(t => <Fragment key={t.id}>
                                    {headerValues.raw.map(l => <GridCell
                                        key={t.id + l}
                                        field={l}
                                        fieldMeta={headerValues.fields[l]}
                                        item={t}
                                        categories={categories}
                                        handleChange={this.handleChange}
                                        handleDelete={this.handleDelete}
                                        checkIsUnique={newValue => this.state.traits.some(trait => (trait.id !== t.id && trait[l] === newValue))}
                                    />)}
                                </Fragment>)}
                            </div> : <p className="no-data">No data</p>}
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    };
};

const mapStateToProps = (store) => ({
    demographics: store.demographics
});

const mapDispatchToProps = {
    clearAllTraits: actions.traits.clearAllTraits,
    loadDemographics: actions.demographics.loadDemographics,
    saveTrait: actions.traits.saveTrait,
    showPopup: actions.popups.showPopup
}

export default connect(mapStateToProps, mapDispatchToProps)(EditTraitsContainer)
