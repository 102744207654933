import React from 'react';

import { Grid, Form } from 'semantic-ui-react';

const FieldsSection = props => (
    <>
        <Grid.Row centered>
            <Grid.Column width={16} textAlign='center'>
                <p>Name</p>
                <Form.Input name='name' placeholder='John Doe' onChange={props.handleChange} fluid />
            </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
            <Grid.Column width={16} textAlign='center'>
                <p>Email</p>
                <Form.Input name='email' placeholder='john@mail.com' onChange={props.handleChange} fluid />
            </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
            <Grid.Column width={16} textAlign='center'>
                <p>Message</p>
                <Form.TextArea name='message' placeholder='Send us a message...' onChange={props.handleChange} fluid />
            </Grid.Column>
        </Grid.Row>
    </>
);

export default FieldsSection;