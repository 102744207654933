import React, { memo, useState } from 'react';
import s from './Ess.module.scss';
import { classList } from '../../utils';
import actions from '../../store/actions';
import { connect } from 'react-redux';

const CEOApprovalLinkPopup = memo(({ onSubmit, closePopup, model }) => {
    const [link, setLink] = useState('');
    const [validLink, setValidLink] = useState(false);

    const changeLink = (e) => {
        let link = e.target.value;
        if (link) {
            try {
                let url = new URL(link);
                setValidLink(true);
            } catch (e) {
                setValidLink(false);
            }
        } else {
            setValidLink(false);
        }
        setLink(link);
    }

    const saveModel = () => {
        if (!validLink) return;
        onSubmit({...model, link});
        closePopup();
    }

    return <div>
        <input type="text" onChange={changeLink} className={s.LinkPopup_Input} value={link} />
        <div className={classList(s.LinkPopup_Validation)}>
            {!validLink ? link.length > 0 ? 'Invalid link' : 'Link is empty' : ''}
        </div>
        <div className={s.LinkPopup_Buttons}>
                <button className={s.LinkPopup_CancelBtn} onClick={() => closePopup()}>Cancel</button>
                <button className={classList(s.LinkPopup_SaveBtn, !validLink && s.LinkPopup_SaveBtn__disabled)} onClick={saveModel}>Save</button>
            </div>
    </div>
});

const mapStateToProps = (store) => ({
    loading: store.loading.some(l => l === 'ess')
})

const mapDispatchToProps = {
    closePopup: actions.popups.closePopup,
}

export default connect(mapStateToProps, mapDispatchToProps)(CEOApprovalLinkPopup);