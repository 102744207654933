import React, { Component } from 'react';

import DemographicsContainer from '../Demographics/DemosContainer';


class EditDemographics extends Component {
    render() {
        return (
            <div className='page-container'>
                <DemographicsContainer />
            </div>
        );
    }
};

export default EditDemographics;