import { ESS_ACTIONS } from './constants';


export const loadEss = (versionId, demographicId = null, fillNA = false) => ({
    type: ESS_ACTIONS.LOAD,
    payload: versionId,
    demographicId,
    fillNA
});

export const receiveEss = (data) => ({
    type: ESS_ACTIONS.RECEIVE,
    payload: data
});

export const saveAdditionalInfo = (payload) => ({
    type: ESS_ACTIONS.SAVE_ADDITIONAL_INFO,
    payload
});

export const updateAdditionalInfo = (payload) => ({
    type: ESS_ACTIONS.UPDATE_ADDITIONAL_INFO,
    payload
})