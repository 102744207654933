import React, { Fragment, memo } from 'react';
import { Popup} from 'semantic-ui-react';
import { AvgLine } from './AvgLine';
import { Dot } from './Dot';
import { WeightChartLabels } from './Labels';
import XLabel from './XLabel';
import actions from '../../../store/actions';
import { TooltipIcon } from '../../common';

import './WeightChart.scss';
import Dropdowns from './controls/Dropdowns';
import { ScreenshotModal } from './controls/ScrenshotModal';
import { ConfirmRejectButtons } from './controls/ConfirmRejectButtons';
import { connect } from 'react-redux';
import { classList, metricsTooltips, metricsChartExplanation } from './../../../utils/index';

const fields = {
    life_span_impact_score: {
        title: 'Lifespan Impact',
        tooltip: metricsTooltips['life_span_impact_score'],
        chartExplanation: metricsChartExplanation['life_span_impact_score']
    },
    average_age_of_onset: {
        title: 'Average Age of Onset',
        tooltip: metricsTooltips['average_age_of_onset'],
        chartExplanation: metricsChartExplanation['average_age_of_onset']
    },
    life_treatment_cost_score: {
        title: 'Lifetime Cost',
        tooltip: metricsTooltips['life_treatment_cost_score'],
        chartExplanation: metricsChartExplanation['life_treatment_cost_score']
    },
    life_quality_impact_score: {
        title: 'Life Quality Impact',
        tooltip: metricsTooltips['life_quality_impact_score'],
        chartExplanation: metricsChartExplanation['life_quality_impact_score']
    },
    morbid_risk_general: {
        title: 'Lifetime Risk',
        tooltip: metricsTooltips['morbid_risk_general'],
        chartExplanation: metricsChartExplanation['morbid_risk_general']
    },
    morbid_risk: {
        title: 'Lifetime Risk with a first-degree-relative',
        tooltip: metricsTooltips['morbid_risk'],
        chartExplanation: metricsChartExplanation['morbid_risk']
    },
    life_expectancy_with_disease: {
        title: 'Life expectancy with disease',
        tooltip: metricsTooltips['life_expectancy_with_disease'],
        chartExplanation: metricsChartExplanation['life_expectancy_with_disease']
    },
    survival_rate_in_five_years: {
        title: '5 Year Survival Rate',
        tooltip: metricsTooltips['survival_rate_in_five_years'],
        chartExplanation: metricsChartExplanation['survival_rate_in_five_years']
    },
    years_of_life_lost: {
        title: 'Years of life lost per death',
        tooltip: metricsTooltips['years_of_life_lost'],
        chartExplanation: metricsChartExplanation['years_of_life_lost']
    },
}

const WeightChart = memo(({
    activeTrait,
    selectedTraits,
    expanded,
    chartData: {
        dots,
        traitsAvgValues,
        approvedTraitsAvgValues,
        maxValue,
        minValue,
        maxAvgValue,
        title,
        xLabels
    },
    traits,
    ...props
}) => {
    const user = props.user;
    if (maxValue === 0) maxValue = 100;
    const getDotColors = (dots, avg) => {
        const currentDots = dots.filter(dot => dot.avg === avg);

        const colors = {
            red: false,
            yellow: false,
            green: false,
            blue: false
        };

        const isNewDot = currentDots.some(item => item.new === true);
        if (isNewDot) colors.blue = true;

        for (let dot of currentDots.filter(dot => !dot.new)) {
            if (dot.confirmations?.some(c => !c.confirmed)) {
                colors.red = true;
                continue;
            }
            if (dot.confirmations?.some(c => c.userId === user?.id && c.confirmed)) {
                colors.green = true;
                continue;
            }

            if (!dot.confirmations?.some(c => c.user?.id === user?.id)) colors.yellow = true;
        }

        return colors;
    };
    const tooltip = fields[props?.field?.name]?.tooltip || '';

    const checkApprovedClassForAvg = (dotInd) => !xLabels[traitsAvgValues[dotInd]].traits.some(t => t.approved) ? 'notApproved' : false;

    const arrayMax = Math.max(...traitsAvgValues);
    let traitsAvgValuesForZIndex = null;

    if (arrayMax > 800) {
        const divider = arrayMax / 800;
        traitsAvgValuesForZIndex = traitsAvgValues.map(item => Math.round(item / divider));
    } else {
        traitsAvgValuesForZIndex = traitsAvgValues;
    }

    return (
        <div className={['misery_weight_chart', expanded ? 'expanded' : ''].join(' ')}>
            <ScreenshotModal {...props} />
            <div className="misery_weight_chart_head" onClick={props.toggleView}>
                <div className="misery_weight_chart_head_title">{fields[props.field.name]?.title || title} {tooltip && <TooltipIcon content={<p>{tooltip}</p>} />}</div>
            </div>
            <div className="misery_weight_chart_body">
                <Dropdowns onChange={props.onTraitSelect} selectedTraits={selectedTraits} {...props} />
                <div className="misery_weight_chart_container">
                    <AvgLine diseases={dots} maxValue={maxValue} minValue={minValue} maxAvgValue={maxAvgValue} traitsAvgValues={approvedTraitsAvgValues} />
                    <WeightChartLabels maxValue={maxValue} minValue={minValue} type={props.field.type} explanation={fields[props.field.name]?.chartExplanation} />
                    {traitsAvgValues.map((d, dotInd) => (
                        <Fragment key={"col-" + dotInd}>
                            <div className="misery_weight_chart_column" style={{ left: `${traitsAvgValues[dotInd] / maxAvgValue * 100 || 0}%` }}>
                                <div className="misery_weight_chart_data">
                                    {!isNaN(traitsAvgValues[dotInd]) && (
                                        <Popup
                                            trigger={
                                                <div
                                                    className={classList('misery_weight_chart_dot', 'avg_dot', Math.abs(d.id) === activeTrait.id && 'current', checkApprovedClassForAvg(dotInd))}
                                                    style={{ bottom: `${traitsAvgValues[dotInd] ? (traitsAvgValues[dotInd] - minValue) / (maxValue - minValue) * 100 : 0}%` }}
                                                >
                                                </div>
                                            }
                                            hoverable
                                            inverted
                                            position="top left"
                                            className="misery_weight_chart_dot_popup small_dot_popup"
                                            key={"popup-avg-" + dotInd}
                                        >
                                            <div key={"popup-content-" + dotInd} className="misery_weight_chart_dot_popup_content">
                                                <p>
                                                    Average: {Math.round((traitsAvgValues[dotInd] * 100)) / 100}
                                                </p>
                                            </div>
                                        </Popup>
                                    )}
                                    {Object.keys(dots).map((h, index) => dots[h].filter(item => +item.avg === d).length > 0 && (
                                        <Popup
                                            trigger={
                                                <Dot
                                                    key={"popup-trigger-" + index}
                                                    colors={getDotColors(dots[h], d)}
                                                    style={{
                                                        bottom: `${+h ? (+h - minValue) / (maxValue - minValue) * 100 : 0}%`,
                                                        opacity: dots[h].length > 5 ? 1 : dots[h].length / 10 + 0.5
                                                    }}
                                                />
                                            }
                                            hoverable
                                            inverted
                                            position="top left"
                                            className="misery_weight_chart_dot_popup"
                                            key={"popup-" + index}
                                        >
                                            {dots[h].filter(item => +item.avg === d).map((item, ind) => item.new ? (
                                                <div key={"popup-content-" + ind} className="misery_weight_chart_dot_popup_content">
                                                    <div>
                                                        <b>Trait Name:</b> {item?.original_name} <br />
                                                        {item?.demographic && <div><b>Genetic Ancestry:</b> {item.demographic?.name}</div>}
                                                        {item.sex && !item.sex?.is_default && <div><b>Sex:</b> {item.sex?.name}</div>}
                                                        <b>Value:</b> {+dots[h][ind].value} <br />
                                                        {item.country && <div><b>Country:</b> {item.country.name}</div>}
                                                        {item.yearOfStudy && <div><b>Year:</b> {item.yearOfStudy}</div>}
                                                        {item.publicationIdentifier && <div><b>DOI/ISBN/ISSN:</b> {item.publicationIdentifier}</div>}
                                                        {item.link ? <a target="_blank" rel="noopener noreferrer" href={item.link}><b>Source</b></a> : <span>No URL</span>}<br />
                                                        {item.comment?.methodOfCalculation && <div style={{ margin: '5px 0'}}>Method of Calculation: {item.comment?.methodOfCalculation}</div>}
                                                        {item.comment?.text && <div style={{ margin: '5px 0'}}>Comment: {item.comment?.text}</div>}
                                                        <b>Created by you</b>
                                                        {item.source ? <div className="screenshot_link" onClick={() => props.loadVoteScreenshot(item.id, item.source)}>Screenshot</div> : <div>No Screenshot</div>}
                                                        {item.comment?.calculationLink && <div className="screenshot_link" onClick={() => props.loadVoteScreenshot(item.id, item.comment.calculationLink)}>Screenshot of Calculation</div>}
                                                        {item.source_file ? <div className="screenshot_link" onClick={() => props.loadVoteSourceFile(item.source_file, item.source_file_name)}>Source File</div> : <div>No Source File</div>}
                                                    </div>
                                                    {item.id && typeof item.id === 'number' && <a className="edit-vote-link" target="_blank" rel="noopener noreferrer" href={`/administration/votes/trait/${item.traitId}/${item.id}`}>
                                                        <button title="Edit" className="edit-btn"><i></i></button>
                                                    </a>}
                                                    <button onClick={() => props.onDelete(item.id)} title="Delete" className="clear-btn cross"></button>
                                                </div>
                                            ) : (
                                                    <div key={"popup-content-" + ind} className="misery_weight_chart_dot_popup_content">
                                                        <div>
                                                            <b>Trait Name:</b> {item.original_name} <br />
                                                            {item.demographic && <div><b>Genetic Ancestry:</b> {item.demographic.name}</div>}
                                                            {item.sex && <div><b>Sex:</b> {item.sex.name}</div>}
                                                            <b>Value:</b> {+dots[h][ind].value} <br />
                                                            {item.country && <div><b>Country:</b> {item.country.name}</div>}
                                                            {item.yearOfStudy && <div><b>Year:</b> {item.yearOfStudy}</div>}
                                                            {item.publicationIdentifier && <div><b>DOI/ISBN/ISSN:</b> {item.publicationIdentifier}</div>}
                                                            {item.link ? <a target="_blank" rel="noopener noreferrer" href={item.link}><b>Source</b></a> : <span>No URL</span>}<br />
                                                            {item.author ? (
                                                                <span><b>Created by:</b> {item.author?.login === 'admin' ? 'Laurent' : item.author?.login}</span>
                                                            ) : (
                                                                    <span>No Author Data</span>
                                                                )}<br />
                                                            {item.whoApproved ? (
                                                                <span><b>Added by:</b> {item.whoApproved === 'admin' ? 'Laurent' : item.whoApproved}</span>
                                                            ) : (
                                                                    <span>No Approver Data</span>
                                                            )}<br />

                                                            {item.comment?.methodOfCalculation && <div style={{ marginTop: '5px'}}>Method of Calculation: {item.comment?.methodOfCalculation}</div>}
                                                            {item.comment?.text && <div style={{ marginTop: '5px'}}>Comment: {item.comment?.text}</div>}

                                                            {(user?.role === 'admin' || user?.role === 'senior') &&
                                                                <ConfirmRejectButtons
                                                                    vote={item}
                                                                    user={user}
                                                                    confirmRejectVote={props.confirmRejectVote}
                                                                    showPopup={props.showPopup}
                                                                    changeConfirmed={props.changeConfirmed}
                                                                />}

                                                            {(item.confirmations && item.confirmations.filter(c => c.confirmed).length > 0) ? (
                                                                <div>
                                                                    <span>Confirmed by </span>
                                                                    <span>
                                                                        {item.confirmations.filter(c => c.confirmed).map(c => (
                                                                            c.user?.login === 'admin' ? 'Laurent' : (c.user?.login || 'deleted user')
                                                                        )).join(', ')}
                                                                    </span>
                                                                </div>
                                                            ) : null}
                                                            {item.confirmations && item.confirmations.filter(c => !c.confirmed).length > 0 ? (
                                                                <>
                                                                    <span>Rejected by </span>
                                                                    <span>
                                                                        {item.confirmations.filter(c => !c.confirmed).map(c => (
                                                                            c.user?.login === 'admin' ? 'Laurent' : (c.user?.login || 'deleted user')
                                                                        )).join(', ')}
                                                                    </span>
                                                                    <br />
                                                                </>
                                                            ) : null}
                                                            {item.source ? <div className="screenshot_link" onClick={() => props.loadVoteScreenshot(item.id, item.source)}>Screenshot</div> : <div>No Screenshot</div>}
                                                            {item.comment?.calculationLink && <div className="screenshot_link" onClick={() => props.loadVoteScreenshot(item.id, item.comment.calculationLink)}>Screenshot of Calculation</div>}
                                                            {item.source_file ? <div className="screenshot_link" onClick={() => props.loadVoteSourceFile(item.source_file, item.source_file_name)}>Source File</div> : <div>No Source File</div>}
                                                        </div>

                                                        {['admin', 'senior'].includes(user?.role) && <>
                                                            <a className="edit-vote-link" target="_blank" rel="noopener noreferrer" href={`/administration/votes/trait/${item.traitId}/${item.id}`}>
                                                                <button title="Edit" className="edit-btn"><i></i></button>
                                                            </a>
                                                            <button onClick={() => props.onDelete(item.id)} title="Delete" className="clear-btn cross"></button></>
                                                        }
                                                    </div>
                                                ))}
                                        </Popup>
                                    ))}
                                </div>
                            </div>
                            {xLabels[traitsAvgValues[dotInd]]?.title && (
                                <XLabel
                                    styleObj={{
                                        left: `${traitsAvgValues[dotInd] / maxAvgValue * 100 || 0}%`,
                                        zIndex: 5 + traitsAvgValuesForZIndex[dotInd]
                                    }}
                                    text={xLabels[traitsAvgValues[dotInd]].title}
                                />
                            )}
                        </Fragment>
                    ))}
                </div>
            </div>
        </div>
    );
});

const mapStateToProps = (store) => ({
    activeTrait: store.activeTrait,
    user: store.user,
    traits: store.traits
});

const mapDispatchToProps = {
    confirmRejectVote: actions.votes.confirmRejectVote,
    onDelete: actions.votes.deleteVote,
    showPopup: actions.popups.showPopup,
    changeConfirmed: actions.votes.confirmRejectVote
};

export default connect(mapStateToProps, mapDispatchToProps)(WeightChart);
