import React from 'react';

import './loader.scss';

const Loader = () => (
    <div className="mw_dimmer">
        <div className="mw_loader_wrapper">
            <div className="circle"></div>
            <svg className="ic_loader" viewBox="0 0 204 204" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <ellipse className="spinner" ry="100" rx="100" cy="102" cx="102" strokeWidth="2"/>
                    <g id="Weigher" transform="translate(16.000000, 10.000000)" fill="#1E82F9">
                        <g id="Group" transform="translate(27.000000, 36.000000)">
                            <path d="M63,7.77955124 C63,7.09419532 62.6777,6.47010804 62.2148966,6.02129782 C62.6450833,5.40330259 62.897201,4.65467635 62.897201,3.84895887 C62.897201,1.72352873 61.1508896,0 58.999427,0 C56.8476118,0 55.102799,1.72352873 55.102799,3.84895887 C55.102799,4.65467635 55.3538588,5.40330259 55.7836048,6.02129782 C55.3208014,6.47010804 54.9808834,7.09419532 54.9808834,7.77955124 L54.9808834,8 C55.0511413,9.48289265 54.9808834,11.2329102 54.9808834,12.3302275 L55,13 C55,13.5303567 55.203029,13.9661177 55.4942866,14.3680192 C52.8476684,16.9605357 51.0253692,22.3923839 51.0253692,28.6725939 C51.0253692,34.5408942 52.6152089,39.6668343 54.9808834,42.4286229 L54.9808834,77.0451456 L53.7640191,77.0451456 C51.9561769,77.0451456 50.4767929,78.5054976 50.4767929,80.2904691 L50.4767929,95.9148435 L33.5567902,95.9148435 C32.1508377,95.9148435 31,97.0505762 31,98.4378236 L31,100 L87,100 L87,98.4378236 C87,97.0505762 85.8487215,95.9148435 84.4417993,95.9148435 L67.52259,95.9148435 L67.52259,80.2904691 C67.52259,78.5054976 66.0433823,77.0451456 64.2362454,77.0451456 L63.0188522,77.0451456 L63.0188522,42.4286229 C65.3848792,39.6668343 66.9733967,34.5408942 66.9733967,28.6725939 C66.9733967,22.3923839 65.1512737,16.9605357 62.5066831,14.3680192 C62.795825,13.9661177 62.9677234,13.5538552 62.9677234,13.0234985 L62.9677234,12.3302275 C63.0018092,11.1305837 63.0125681,9.61369156 63,7.77955124 Z" id="shutterstock_1480858220-copy" transform="translate(59.000000, 50.000000) scale(-1, 1) translate(-59.000000, -50.000000) "></path>
                            <g id="moving_weight">
                                <path id="moving_weight_part_1" d="M2.9252603,59.1163929 L18.0753506,16.9326852 L33.2262903,59.1163929 L2.9252603,59.1163929 Z M20.7445968,9.51930476 C20.7445968,7.89950992 19.2698245,7 18.2680703,7 C17.2689114,7 15.7915438,7.67627587 15.7915438,9.51930476 C15.7915438,11.3623336 17.1282151,11.7468106 17.4570176,11.906023 C13.5776803,22.304659 7.75867447,37.9026129 0,58.6998849 C2.22237174,65.7975556 9.43967319,71 17.9995675,71 C26.5603268,71 33.7802235,65.7975556 36,58.6998849 C28.3381217,37.9330549 22.591713,22.3579324 18.7607739,11.9745174 C19.340341,11.906023 20.7445968,11.1390996 20.7445968,9.51930476 Z" transform="translate(18.000000, 39.000000) scale(-1, 1) translate(-18.000000, -39.000000) "></path>
                                <path id="moving_weight_part_2" d="M84.9252603,59.1163929 L100.075351,16.9326852 L115.22629,59.1163929 L84.9252603,59.1163929 Z M102.744597,9.51930476 C102.744597,7.89950992 101.269824,7 100.26807,7 C99.2689114,7 97.7915438,7.67627587 97.7915438,9.51930476 C97.7915438,11.3623336 99.1282151,11.7468106 99.4570176,11.906023 C95.5776803,22.304659 89.7586745,37.9026129 82,58.6998849 C84.2223717,65.7975556 91.4396732,71 99.9995675,71 C108.560327,71 115.780223,65.7975556 118,58.6998849 C110.338122,37.9330549 104.591713,22.3579324 100.760774,11.9745174 C101.340341,11.906023 102.744597,11.1390996 102.744597,9.51930476 Z" transform="translate(100.000000, 39.000000) scale(-1, 1) translate(-100.000000, -39.000000) "></path>
                                <path d="M100,8 C100.828427,8 101.5,8.67157288 101.5,9.5 C101.5,10.2796961 100.905112,10.9204487 100.14446,10.9931334 L100,11 L18,11 C17.1715729,11 16.5,10.3284271 16.5,9.5 C16.5,8.72030388 17.0948881,8.07955132 17.85554,8.00686658 L18,8 L100,8 Z" id="Line-4" fillRule="nonzero"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    </div>
);

export default Loader;