import React, { memo } from 'react';
import s from '../Ess.module.scss';
import { toFixed } from '../../../utils';

const ShowNumber = memo(({ value, preText = '', afterText= '', emptyText = 'N/A', specificField = null }) => {
    const empty = <span className={s.NA}>{emptyText}</span>;
    const showNumber = (number, afterText = '', preText = '') => typeof number === 'number' ? preText + toFixed(number, 2) + afterText : empty;
    if (specificField) value = value[specificField];
    if (value === null || typeof value === 'undefined') return empty;
    if (typeof value === 'number') return showNumber(value);
    
    return <>
        <div className={s.NumberLine}><label>male: </label> {showNumber(value?.male)}</div>
        <div className={s.NumberLine}><label>female: </label> {showNumber(value?.female)}</div>
        <div className={s.NumberLine}><label>both: </label> {showNumber(value?.common)}</div>
    </>
})

export default ShowNumber;
