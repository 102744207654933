import React, { PureComponent } from 'react';

import { TraitCategoriesDropdown, DropZone, RangeInput, DistCurveCell } from '../common';

import {  TextArea, Dropdown, Checkbox } from 'semantic-ui-react'

export default class AdministrationTableCell extends PureComponent {
    render() {
        const { field, fieldMeta, item, handleChange } = this.props;

        return <div className='edit-traits_table-cell'>
            {fieldMeta.fieldType === 'TEXT' && (
                <TextArea
                    // transparent 
                    className='administration-table-cell--text'
                    name={item.id}
                    defaultValue={item[field]}
                    onBlur={e => handleChange(item.id, field, e.target.value, item)}
                />
            )}
            {fieldMeta.fieldType === 'SELECT' && (
                <Dropdown
                    className='administration-table-cell--dropdown'
                    selection
                    options={fieldMeta.options && fieldMeta.options.map(option => option)}
                    defaultValue={item[field]}
                    onChange={(e, { value }) => handleChange(item.id, field, value, item)}
                />
            )}
            {fieldMeta.fieldType === 'CATEGORIES' && (
                <TraitCategoriesDropdown
                    className='administration-table-cell--dropdown'
                    defaultValue={item[field]}
                    onChange={(e, { value }) => handleChange(item.id, field, value, item)}
                />
            )}
            {fieldMeta.fieldType === 'CHECK' && (
                <Checkbox
                    className='administration-table-cell--checkbox'
                    toggle
                    defaultChecked={item[field]}
                    onChange={(e, { checked }) => handleChange(item.id, field, checked, item)}
                />
            )}
            {fieldMeta.fieldType === 'RANGE' && (
                <RangeInput
                    className='administration-table-cell--range'
                    defaultValue={item[field]}
                    min={fieldMeta.range[0]}
                    max={fieldMeta.range[1]}
                    handleBlur={value => handleChange(item.id, field, parseInt(value, 10), item)}
                />
            )}
            {fieldMeta.fieldType === 'FILE' && (
                <DropZone
                    className='administration-table-cell--drop'
                    defaultValue={item[field]}
                    field={field}
                    onDrop={file => handleChange(item.id, field, file, item)}
                    handleNewImage={value => handleChange(item.id, 'newImages', { images: value, id: item.id }, item)}
                />
            )}
            {fieldMeta.fieldType === 'DIST_CURVE' && (
                <DistCurveCell
                    defaultValue={item[field]}
                    traitName={item.trait_name}
                    field={field}   
                    handleChange={value => handleChange(item.id, field, value, item)}
                    handleNewCurve={value => handleChange(item.id, 'newCurve', { ...value, trait: item.trait_name }, item)}
                />
            )}
        </div>
    }
}