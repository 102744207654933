import axios from 'axios';
import config from '../config';
import { sendToCloud } from './files';

const publicTraitCategoryURL = config.API + 'public/trait_categories/';
const publicTraitCategoryApi = axios.create({ baseURL: publicTraitCategoryURL });

const adminTraitCategoryURL = config.API + 'admin/trait_categories/';
const adminTraitCategoryApi = axios.create({ baseURL: adminTraitCategoryURL });


export const getCategories = () => publicTraitCategoryApi.get('/').then(r => r.data);
export const getCategory = (id) => publicTraitCategoryApi.get(`/${id}`).then(r => r.data);
export const updateCategory = async (options) => {
    let result = await adminTraitCategoryApi.put(`/${options.id}`, options).then(r => r.data);
    for (let key in result.presigned) {
        await sendToCloud(result.presigned[key], options[key]);
    }
    delete result.presigned;
    return result;
};
export const createCategory = async (options) => {
    let result = await adminTraitCategoryApi.post(`/`, options).then(r => r.data);
    for (let key in result.presigned) {
        await sendToCloud(result.presigned[key], options[key]);
    }
    delete result.presigned;
    return result;
}
export const deleteCategory = (id) => adminTraitCategoryApi.delete(`/${id}`);