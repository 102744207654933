import React, { memo } from 'react';
import SelectTrait from './SelectTrait';
import Dropdown from '../../../common/Dropdown';
import { connect } from 'react-redux';

const Dropdowns = memo((props) => (
    <div className="misery_weight_chart_filters">
        <SelectTrait {...props} />
        {props.field.sexes ? <Dropdown items={props.sex.map(s => ({ text: s.name, value: s.id, selected: s.id === props.activeSex?.id }))} onChange={props.onSexChange} /> : null}
        {props.field.demos ? <Dropdown items={props.demographics.map(s => ({ text: s.name, value: s.short_name, selected: s.id === props.activeDemo?.id }))} onChange={props.onDemoChange} /> : null}
    </div>
))

const mapStateToProps = (store) => ({
    sex: store.sex,
    demographics: store.demographics
})

export default connect(mapStateToProps)(Dropdowns);