import { WEIGHT_VALIDATION_ACTIONS } from './constants';


export const setValidation = (data = []) => ({
    type: WEIGHT_VALIDATION_ACTIONS.SET,
    payload: data

});

export const clearValidation = () => ({
    type: WEIGHT_VALIDATION_ACTIONS.CLEAR
});