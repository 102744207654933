import React from 'react';

import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import s from './Settings.module.scss';
import { classList } from '../../utils';
import { changeSettings } from '../../store/actions/settings';
import { Checkbox } from '../common';

class GenomicIndexSettings extends React.PureComponent {
    render() {
        const { settings = {}, traits = [] } = this.props;
        let { indexFilter = [] } = (settings?.data || {});
        return <div className={classList(s.GenomicIndex)}>
            <h2 className={s.Title_Small}>Genomic Index Filter</h2>
            <table className="admin-table news-table">
                <tbody>
                    <tr>
                        <th>Disease</th>
                        <th>Excluded data</th>
                    </tr>
                    {traits.filter(t => t.API_name).map(t => <tr key={t.id}>
                        <td>{t.trait_name}</td>
                        <td>
                            <label><Checkbox className={s.Checkbox} onChange={() => this.changeFiltering(t.API_name, 'male')} value={indexFilter.find(i => i.API_name === t.API_name)?.male} />&nbsp;&nbsp;Male</label>
                            <label><Checkbox className={s.Checkbox} onChange={() => this.changeFiltering(t.API_name, 'female')} value={indexFilter.find(i => i.API_name === t.API_name)?.female} />&nbsp;&nbsp;Female</label>
                        </td>
                    </tr>
                    )}
                </tbody>
            </table>
        </div>
    }

    changeFiltering = (API_name, sex) => {
        let { settings } = this.props;
        let { indexFilter = [] } = settings.data;
        let disease = indexFilter.find(f => f.API_name === API_name) || { API_name };
        disease[sex] = !disease[sex];
        indexFilter = indexFilter.filter(f => f.API_name !== API_name).concat(disease);
        settings.data.indexFilter = indexFilter;
        this.props.changeSettings(settings);
    }

}

const mapDispatchToProps = {
    changeSettings
}

const mapStateToProps = (store) => ({
    traits: store.traits,
    settings: store.settings.find(s => s.id === 'relative-risk-reduction')
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GenomicIndexSettings))