import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { changeIsPrivate } from '../../store/actions/private';
import User from '../../api/users';
import { connect } from 'react-redux';
import { Container, Grid, Button } from 'semantic-ui-react'
import '../pages/styles.css';

import { wrapPage } from '../HOC';

class Privacy extends Component {

    state = {
        success: false
    };

    handleClick = () => {
        User.deleteUser()
            .then((res) => {
                if (res.status === 200) {
                    this.setState({ success: true });
                    User.setPrivacyCookie();
                    this.props.changeIsPrivate(true);
                }
            });
    };

    render() {
        const { success } = this.state;

        return (
            <Container>
                <Grid>
                    <Grid.Row centered>
                        <Grid.Column width={16} textAlign='center'>
                            <p>We respect your right to privacy. If you change your mind about contributing to our research,
                                and would like to have all of your responses deleted, click below.</p>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row centered>
                        <Grid.Column mobile={16} tablet={7} computer={5} textAlign='center'>
                            {success ? (
                                <>
                                    <p>DELETED</p>
                                    <Button
                                        className='gradient'
                                        fluid
                                        as={Link}
                                        to='/'
                                    >
                                        GO TO HOMEPAGE
                                    </Button>
                                </>
                            ) : (
                                    <Button
                                        className='gradient'
                                        fluid
                                        onClick={this.handleClick}
                                    >
                                        DELETE PERSONAL DATA
                                </Button>
                                )}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        );
    };
};

export default connect(() => ({}), { changeIsPrivate })(wrapPage(Privacy, 'Privacy Policy'));
