import React, { useEffect, useState } from 'react';
import { ComposableMap, Geographies, Geography, Marker, ZoomableGroup } from 'react-simple-maps';
import config from '../../config';
import { classList } from '../../utils';
import { Dropdown } from '../common';
import { MarkerIcon } from '../common/Map/MarkerIcon';
import s from './PHEA.module.scss';
import { RegionsTooltipContent } from './RegionsTooltipContent';

export const PHEAMap = React.memo(({ phea = { general: [], regions: [] }, fractionOfBirth = 1 }) => {
    const [zoom, setZoom] = useState(1);
    const [expandedMarkers, setExpandedMarkers] = useState([]);
    const { general, regions } = phea;
    const [diseases, setDiseases] = useState([]);
    const activeDisease = diseases.find(d => d.selected)?.text;
    const [fields, setFields] = useState([
        { value: 'economic_impact_rrr', text: 'Economic Impact of RRR', type: ' $', common: false },
        { value: 'life_quality_impact_rrr', text: 'DALY Impact of RRR', type: ' DALY/person', common: false },
        { value: 'lifespan_impact_rrr', text: 'Lifespan Impact of RRR', type: ' years/person', common: false },
    ]);
    useEffect(() => {
        setDiseases(general.map(i => ({ text: i.disease, value: i.disease, selected: diseases.some(d => d.value === i.disease && d.selected) })));
    }, [general]);

    const onDiseaseChoose = (value) => setDiseases(diseases.map(d => ({ ...d, selected: d.value === value })));
    const onFieldChoose = (value) => setFields(fields.map(f => ({ ...f, selected: f.value === value ? !f.selected : !!f.selected })));

    const toggleMarker = (id) => {
        if (expandedMarkers.some(i => i === id)) {
            setExpandedMarkers(expandedMarkers.filter(i => i !== id));
        } else {
            setExpandedMarkers(expandedMarkers.concat(id));
        }
    }

    return (
        <div className={classList(s.MapContainer)}>
            <div className={s.MapContainer__Dropdowns}>
                <Dropdown placeholder="RRR Impact:" items={fields.map(f => ({ ...f, text: f.text.replace(' Impact of RRR', '') }))} onChange={onFieldChoose} multiple={true} />
                <Dropdown placeholder="Disease:" items={diseases} onChange={onDiseaseChoose} />
            </div>
            <div className={classList('regions-container', s.MapContainer)}>
                <ComposableMap>
                    <Geographies geography={require('../../assets/world-countries.json')}>
                        {({ geographies }) =>
                            geographies.map(geo => <Geography key={geo.rsmKey} geography={geo} />)
                        }
                    </Geographies>
                    {regions.filter(r => r.coordinates).map(r => <Marker coordinates={r.coordinates} key={r.id} onClick={(e) => activeDisease && toggleMarker(r.id)}>
                        <MarkerIcon className={classList('rsm-marker-icon')} width={10 / zoom} height={15 / zoom} x={-5 / zoom} y={-15 / zoom} />

                            }
                        </Marker>)}
                    {activeDisease && regions.filter(r => r.coordinates).map(r => <Marker coordinates={r.coordinates} key={r.id} onClick={(e) => toggleMarker(r.id)}>
                        {expandedMarkers.some(i => i === r.id) && <RegionsTooltipContent key={r.id} region={r} fields={fields} fractionOfBirth={fractionOfBirth} activeDisease={activeDisease} zoom={zoom} />}
                    </Marker>)}
                </ComposableMap>
            </div>
        </div>
    )
});