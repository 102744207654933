import React, { Component } from 'react';

import Contact from './Contact';

import './styles.css';

import { wrapPage } from '../HOC';

class ContactContainer extends Component {

    state = {
        name: null,
        email: null,
        message: null
    };

    handleSubmit = () => {  //TODO: finish

    };

    handleChange = (e, { name, value }) => this.setState({ [name]: value });

    render() {
        return (
            <Contact
                handleChange={this.handleChange}
                handleSubmit={this.handleSubmit}
            />
        );
    };
};

export default wrapPage(ContactContainer, 'Contact Us');
