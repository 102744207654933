import React, { PureComponent } from 'react';

import { Input } from "semantic-ui-react";

export default class NativeRangeInput extends PureComponent {

    state = {
        value: null
    };

    handleChange = value => {
        this.setState({ value: value }, () => this.props.handleBlur(value));
    }

    render() {
        const { value } = this.state;
        const { defaultValue, handleBlur, ...otherProps } = this.props;
        const { min, max } = this.props;
        return (
            <>
                <Input
                    type='range'
                    min={min}
                    max={max}
                    step={1}
                    value={value === null ? defaultValue || min : value}
                    onChange={e => this.handleChange(e.target.value)}
                    onBlur={() => handleBlur(value || defaultValue)}
                    {...otherProps}
                />
                <p>{value || Math.round(this.props.defaultValue) || 'Not selected'}</p>
            </>
        );
    }
}