import React, { useState, useEffect } from 'react';
import styles from './Vote.module.scss';
import { classList, toFixed } from '../../../utils';
import { connect } from 'react-redux';
import { Popup } from 'semantic-ui-react';
import Checkbox from '../../common/CustomCheckbox';
import actions from '../../../store/actions';
import VoteHistory from '../VoteHistory/VoteHistory';
import { VoteLinkPreview } from './VoteLinkPreview';
import { VoteCitationPreview } from './VoteCitationPreview';
import { VoteSourcePreview } from './VoteSourcePreview';
import { VoteSourceFilePreview } from './VoteSourceFilePreview';
import EditVotePopup from './EditVotePopup';
import ChangeVoteStatusPopup from './ChangeVoteStatusPopup';
import { ChangeVotePopupType } from './ChangeVotePopupTypes';
import { useHistory } from "react-router";
import { withRouter } from 'react-router-dom';
import { Confirm } from '../../common/Popup/Confirm';
import * as votesApi from '../../../api/votes';
import { ScreenshotModal } from '../../weight/charts/controls/ScrenshotModal';
import {VoteCommentPreview} from "./VoteCommentPreview";
import {VoteCalculationMethodPreview} from "./VoteCalculationMethodPreview";

const Vote = (props) => {
    let {
        approved,
        whoApproved,
        id,
        authorId,
        traitId,
        field,
        source,
        source_file,
        source_file_name,
        citation,
        value,
        link,
        sex,
        demographic,
        confirmations,
        showPopup,
        categories,
        chromosomes,
        changeApproved,
        changeConfirmed,
        deleteVote,
        user,
        country,
        yearOfStudy,
        publicationIdentifier,
        comment,
        confirmedByUser,
    } = props;

    const [modal, setModal] = useState(null);

    const loadScreenshot = async (id, source) => {
        setModal({ voteId: id, source, loading: true });

        if (typeof source === 'string') {
            let screenshot = await votesApi.getFile(source);

            setModal({ voteId: id, source: URL.createObjectURL(screenshot), loading: false });
        }
    };

    let selectedVoteId = +props?.match?.params?.voteId;

    const showHistory = () => {
        showPopup(<VoteHistory id={id} />, 'Vote History');
    }

    const showDeletePopup = () => {
        showPopup(
            <Confirm
                onConfirm={() => deleteVote(id)}
                text={`Are you sure you want to delete vote #${id}?`}
            />,
            "Delete Vote"
        );
    }

    const trimString = (str, maxLength = 255) => {
        return str.length > maxLength ? `${str.substring(0, maxLength)}...` : str;
    }

    const showEditVotePopup = () => {
        showPopup(<EditVotePopup id={id} />, 'Edit Vote');
    }

    const showRejectVotePopup = () => {
        showPopup(
            <ChangeVoteStatusPopup
                changesType={ChangeVotePopupType.REJECT}
                handleSubmit={reason => {
                    changeConfirmed(id, { confirm: false, reason: reason })
                }}
            />,
            'Reason for rejection'
        );
    }

    const history = useHistory();

    useEffect(() => {
        if (selectedVoteId && selectedVoteId === id) {
            history.push(`/administration/votes/trait/${traitId}`);
            showEditVotePopup();
        }
    }, []);

    // size of vote confirmation line in percent
    const confirmationBlockSize = confirmations.length > 1 ? 100 / (confirmations.length) - 1.6 : 100;

    if (field.name === 'category') value = categories.find(c => c.id == value)?.name || value;
    if (~field.name.indexOf('chromosomes')) value = chromosomes.find(c => c.id == value)?.name || value;
    let rejections = (confirmations || []).filter(item => !item.confirmed);
    confirmations = (confirmations || []).filter(item => item.confirmed);

    return (
        <div className={classList(styles.Vote, approved && styles.Vote_approved)}>
            <label>{field.displayName} {sex && `(${sex?.name})`}</label>:
            <label > {typeof value === 'number' ? toFixed(value) : value}</label>
            <div className={styles.Previews}>
                <div className={styles.Previews__row}>
                    {link && <VoteLinkPreview link={link} />}
                    {citation && <VoteCitationPreview citation={citation} />}
                    {source && <VoteSourcePreview source={source} />}
                    {source_file && <VoteSourceFilePreview sourceFile={source_file} fileName={source_file_name} />}
                </div>
                <div className={styles.Previews__row}>
                    {comment?.text && <VoteCommentPreview comment={comment.text}/>}
                    {comment?.methodOfCalculation && <VoteCalculationMethodPreview methodOfCalculation={comment.methodOfCalculation}/>}
                </div>
            </div>
            <div>
                <div className={styles.confirmation}>
                    {confirmations.map(conf => (
                        <div
                            className={styles.confirmation_confirmed}
                            style={{height: `${confirmationBlockSize}%`}}
                        />
                    ))}
                    {rejections.map(conf => (
                        <div
                            className={styles.confirmation_rejected}
                            style={{height: `${confirmationBlockSize}%`}}
                        />
                    ))}
                </div>
                {field.type !== 'List' && <div className={styles.confirmation__buttons}>
                    {(confirmedByUser !== true) && (
                        <button
                            className={styles.btn_confirm}
                            onClick={() => {
                                changeConfirmed(id, { confirm: true });
                            }}
                            disabled={authorId === user.id && user.role === 'senior'}
                        >
                            Confirm
                        </button>
                    )}
                    {(confirmedByUser !== false) && (
                        <button
                            className={styles.btn_reject}
                            onClick={showRejectVotePopup}
                            disabled={authorId === user.id && user.role === 'senior'}
                        >
                            Reject
                        </button>
                    )}
                    <Popup
                        trigger={
                            <span className={styles.confirmationsPopupTrigger} title="Actions of other users">Other users</span>
                        }
                        hoverable
                        inverted
                        position="bottom center"
                    >
                        <div className={styles.confirmationsPopupContent}>
                            <div className={styles.confirmationsPopupContent_col}>
                                {confirmations.length > 0 ? (
                                    <>
                                        <p>Confirmed by:</p>
                                        <p>
                                            {confirmations.map(item => item?.user?.login || 'Deleted user').join(', ')}
                                        </p>
                                    </>
                                ) : (
                                    <p>No confirmations</p>
                                )}
                            </div>
                            <div className={styles.confirmationsPopupContent_col}>
                                {rejections.length > 0 ? (
                                    <>
                                        <p>Rejected by:</p>
                                        <p>
                                            {rejections.map(item => item?.user?.login || 'Deleted user').join(', ')}
                                        </p>
                                    </>
                                ) : (
                                    <p>No rejections</p>
                                )}
                            </div>
                        </div>
                    </Popup>
                </div>}
            </div>

            {demographic && <div className={styles.demoLabel}>{demographic.name}</div>}
            {country?.name && <div>{`Country: ${country.name}`}</div>}
            {yearOfStudy && <div>{`Year: ${yearOfStudy}`}</div>}
            {publicationIdentifier && <div>{`DOI/ISBN/ISSN: ${publicationIdentifier}`}</div>}
            {comment?.methodOfCalculation && <div>{`Method of calculation: ${trimString(comment.methodOfCalculation)}`}</div>}
            {comment?.text && <div>{`Comment: ${trimString(comment.text)}`}</div>}
            {comment?.calculationLink && <div className='screenshot_link' style={{ marginBottom: '5px' }} onClick={() => loadScreenshot(id, comment.calculationLink )}>Screenshot of Calculation</div>}
            {modal && <ScreenshotModal modal={modal} onModalClose={() => setModal(null)}/>}

            <Checkbox
                value={approved}
                onChange={(e, value) => changeApproved(id, value)}
                disabled={authorId === user.id && user.role === 'senior'}
            />
            {whoApproved && <span className={styles.whoApproved}>{approved ? 'Added' : 'Denied'} by {whoApproved}</span>}

            <div className={styles.Vote__Actions}>
                <button className={styles.btn_delete} onClick={showDeletePopup}>Delete</button>
                <button className={styles.btn} onClick={showHistory}>History</button>
                <button className={styles.btn_edit} onClick={showEditVotePopup}>Edit</button>
                <div className={styles.voteId}>#{id}</div>
            </div>
        </div>
    );
}

const mapStateToProps = store => ({
    loading: store.loading,
    categories: store.categories,
    chromosomes: store.chromosomes,
    user: store.user
});

const mapDispatchToProps = {
    saveVote: actions.votes.saveVote,
    deleteVote: actions.votes.deleteVote,
    updateVote: actions.votes.updateVote,
    showPopup: actions.popups.showPopup,
    changeApproved: actions.votes.changeApprovedVote,
    changeConfirmed: actions.votes.confirmRejectVote
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Vote));