export const PERFORMANCE_TABS_ACTIONS = {
    CHANGE: 'PERFORMANCE_TABS_CHANGE',
    RECEIVE: 'PERFORMANCE_TABS_RECEIVE',
}

export const changeTab = (tab) => ({
    type: PERFORMANCE_TABS_ACTIONS.CHANGE,
    payload: tab
});

export const receiveTabs = (tabs) => ({
    type: PERFORMANCE_TABS_ACTIONS.RECEIVE,
    payload: tabs
})