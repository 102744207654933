import { NEWVOTES_ACTIONS } from './constants';

export const fillNewVotes = () => ({
    type: NEWVOTES_ACTIONS.FILL
});

export const filledNewVotes = (votes) => ({
    type: NEWVOTES_ACTIONS.FILLED,
    payload: votes
});

export const updateNewVote = (id, value, field = 'value') => ({
    type: NEWVOTES_ACTIONS.UPDATE,
    payload: { id, [field]: value }
});

export const contributeVotes = (captchaResponse) => ({
    type: NEWVOTES_ACTIONS.CONTRIBUTE,
    payload: { captchaResponse }
});

export const clearNewVotes = () => ({
    type: NEWVOTES_ACTIONS.CLEAR
})
