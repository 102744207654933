import React from 'react';
import { classList } from '../../../utils';

export const Dot = ({colors, ...props}) => {
    let mainColor;
    
    switch (true) {
        case colors.blue: mainColor = 'blue'; break;
        case colors.red: mainColor = 'red'; break;
        case colors.green: mainColor = 'green'; break;
        case colors.yellow: mainColor = 'yellow'; break;
        default: mainColor = 'green';
    }

    let classes = Object.keys(colors).filter(c => colors[c] && c !== mainColor);

    return <div className={classList('misery_weight_chart_dot', mainColor)} {...props}>
        <div className={classList('part', 'main_part', mainColor)}></div>
        {classes.map((c, i) => <div className={classList(c, 'part', `part_${classes.length}_${i}`)} key={c}></div>)}
    </div>
}