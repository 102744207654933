import { takeLatest, call, put } from 'redux-saga/effects';
import { COUNTRIES_ACTIONS } from '../actions/constants';
import * as actions from '../actions/countries';
import * as loadActions from '../actions/loading';
import api from '../../api/countries';

function* loadCountries() {
    yield put(loadActions.startLoading('countries'));
    try {
        let res = yield call(api.load);
        yield put(actions.receiveCountries(res.data));
    } catch (err) {
        console.log(err);
    }
    yield put(loadActions.endLoading('countries'));
}

export function* countriesSaga() {
    yield takeLatest(COUNTRIES_ACTIONS.LOAD, loadCountries);
}
