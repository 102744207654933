import React from 'react';
import styles from '../Votes.module.scss';
import { classList } from '../../../utils';

export const TraitRowHead = () => {
    return <div className={styles.Trait}>
        <div className={classList(styles.TraitRow,styles.TraitRow_Head)}>
            <div className={styles.TraitRow__Cell}>Lifespan Impact</div>
            <div className={styles.TraitRow__Cell}>Life Quality Impact</div>
            <div className={styles.TraitRow__Cell}>Lifetime Cost</div>
            <div className={styles.TraitRow__Cell}>Average Age of Onset</div>
            <div className={styles.TraitRow__Cell}>Lifetime Risk</div>
            <div className={styles.TraitRow__Cell}>Lifetime Risk with a first-degree-relative</div>
            <div className={styles.TraitRow__Cell}>Life expectancy with disease</div>
            <div className={styles.TraitRow__Cell}>Years of life lost per death</div>
            <div className={styles.TraitRow__Cell}>5 Year Survival Rate</div>
            <div className={styles.TraitRow__Cell_name}>Trait</div>

        </div>
    </div>
} 