import React from 'react';

import FieldsSection from './FieldsSection';

import { Grid, Button, Form } from 'semantic-ui-react';

const ContactForm = props => (
    <Form className='contact-form'>
        <Grid>
            <FieldsSection handleChange={props.handleChange} />
            <Grid.Row centered>
                <Grid.Column mobile={16} tablet={7} computer={8}>
                    <Button className='gradient' onClick={props.handleSubmit} fluid>SEND</Button>
                </Grid.Column>
            </Grid.Row> 
        </Grid>
    </Form>
);

export default ContactForm;
  