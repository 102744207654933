import React, { memo } from 'react';
import s from './Vote.module.scss';
import { Popup } from 'semantic-ui-react';

export const VoteCitationPreview = memo(({ citation }) => <Popup
    trigger={<div className={s.CitationPreview} title={'Citation: ' + citation}><div className={s.CitationIcon}></div></div>}
    hoverable
    inverted
    position="bottom center"
>
        <div>Citation: </div>
        <p>{citation}</p>
</Popup>);


