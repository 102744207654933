import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import s from './Settings.module.scss';
import { classList } from '../../utils';
import { changeSettings } from '../../store/actions/settings';
import { Dropdown, Checkbox } from '../common/';
import { GenomicIndexSettings } from '.';
import TabsSettings from './TabsSettings';

class PerformanceSettings extends PureComponent {
    state = { trait_name: '', demographics: '', single: null, changed: false }

    render() {
        const { trait_name, demo, single, changed } = this.state;
        const { isLoading, settings = { id: 'performance', data: {} }, demographics = [], traits = [] } = this.props;
        const singleChecked = single === null ? settings?.data?.single : single;
        return <div className={classList(s.Settings, isLoading && s.Loading)}>
            <h2 className={s.Title}>Relative Risk Reduction settings</h2>
            <h2 className={s.Title_Small}>Default data</h2>
            <div className={s.EditRow}>
                <label className={s.EditLabel}>Default Trait</label>
                <Dropdown
                    className={s.Dropdown}
                    items={traits.map(t => ({ text: t.trait_name, value: t.trait_name, selected: t.trait_name === (trait_name || settings?.data?.trait_name) }))}
                    onChange={t => this.setState({ changed: true, trait_name: t.value })} />
            </div>
            <div className={s.EditRow}>
                <label className={s.EditLabel}>Default demographics</label>
                <Dropdown
                    className={s.Dropdown}
                    items={demographics.map(t => ({ text: t.name, value: t.short_name, selected: t.short_name === (demo || settings?.data?.demo) }))}
                    onChange={t => this.setState({ changed: true, demo: t.value })} />
            </div>
            <div className={s.EditRow}>
                <label><Checkbox className={s.Checkbox} value={singleChecked} onChange={() => this.setState({ single: !singleChecked, changed: true })} />&nbsp;&nbsp;Show only default disease</label>
            </div>
            <div className={s.EditRow}>
                <button className={classList(s.Btn, !changed && s.Btn_Disabled)} disabled={!changed} onClick={this.saveSettings}>Save</button>
            </div>
            <TabsSettings />
            <GenomicIndexSettings />
        </div>
    }

    saveSettings = () => {
        let { trait_name, demo, changed, single } = this.state;
        let { settings } = this.props;
        if (!changed) return;

        let data = {
            ...settings.data,
            trait_name: trait_name || settings?.data?.trait_name,
            demo: demo || settings?.data?.demo,
            single: single === null ? settings?.data?.single : single
        }

        this.props.changeSettings({ data, id: settings.id });
        this.setState({ trait_name: '', demographics: '', single: null, changed: false });
    }
}

const mapDispatchToProps = {
    changeSettings
}

const mapStateToProps = (store) => ({
    demographics: store.demographics,
    traits: store.traits,
    settings: store.settings.find(s => s.id === 'relative-risk-reduction'),
    isLoading: store.loading.length > 0,
    loading: store.loading

})

export default connect(mapStateToProps, mapDispatchToProps)(PerformanceSettings)