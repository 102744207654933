import axios from 'axios';
import config from '../config';
import { sendToCloud } from './files';
const publicTraitsURL = config.API + 'public/trait/';
const adminTraitsURL = config.API + 'admin/trait/';
const publicTraitsApi = axios.create({ baseURL: publicTraitsURL });
const adminTraitsApi = axios.create({ baseURL: adminTraitsURL });

const publicTraitWithFormDataURL = config.API + 'public/trait/';
const publicTraitWithFormDataApi = axios.create({
    baseURL: publicTraitWithFormDataURL,
    headers: {
        'Content-Type': 'multipart/form-data'
    }
});
const publicTraitCategoryURL = config.API + 'public/trait_categories/';

export const publicFileURL = config.API + 'public/get_file/';
const adminFileURL = config.API + 'admin/';
const publicFileApi = axios.create({ baseURL: publicFileURL, responseType: 'blob' });

export default {

    submitTrait: function ({noNotify = false, ...data}) {
        return adminTraitsApi.put(noNotify ? '/' : '/request', data);
    },

    getTraits: function (options) {
        return publicTraitsApi.post('get_traits', options);
    },

    deleteTrait: function (id) {
        return adminTraitsApi.delete(`${id}`);
    },

    getTraitNames: function () {
        return adminTraitsApi.get('names');
    },

    getResultsTraits: function (options) {
        return axios.all(options.reduce((arr, { winner, loser }) =>
            arr.concat([publicTraitsApi.post('get_trait_by_id', { id: winner }), publicTraitsApi.post('get_trait_by_id', { id: loser })]),
            []));
    },

    getTraitsWithMetadata: function () {
        return axios.all([publicTraitsApi.post('get_traits', { withNotApproved: true }), publicTraitsApi.get('metadata')]);
    },

    getTrait: function (options) {
        return publicTraitsApi.post('get_trait_by_name', options);
    },

    requestRestoreTrait: (id) => adminTraitsApi.post(`${id}/restore`).then(r => r.data),

    requestApproveTrait: (id) => adminTraitsApi.post(`${id}/approve`).then(r => r.data),

    getTraitById: function (id) {
        return publicTraitsApi.get(`${id}`).then(r => r.data);
    },

    getTraitByIdAdmin: function (id) {
        return adminTraitsApi.get(`${id}`).then(r => r.data);
    },

    getFile: function (option) {
        return publicFileApi.get(`/${option}`);
    },

    getDataObject: function (folder, fileName) {
        return publicFileApi.get(`${folder}${config.ENV === 'staging' ? '_staging' : ''}/${fileName}`);
    },

    getFiles: function (options) {
        return Promise.all((options || []).map(option => option ? publicFileApi.get(`/${option}`).then(r => r).catch(e => null) : null));
    },

    updateTrait: function (options) {
        return adminTraitsApi.post('update_trait', options);
    },

    updateTraits: function (options) {
        return axios.all(options.reduce((arr, option) => {
            const buffer = [];
            const copy = { ...option };

            if (option.trait_icon) {
                const { id, trait_icon } = copy;

                buffer.push(adminTraitsApi.post('icon', { id, trait_icon }).then(r => sendToCloud(r.data, trait_icon)));
                delete option.trait_icon;
            }

            if (option.newImages) {
                copy.newImages.images.forEach(image => {
                    const { id } = copy;

                    buffer.push(adminTraitsApi.post('trait_imgs', { id, image }).then(r => sendToCloud(r.data, image)));
                });
                delete option.newImages;
            }

            if (option.deleteImages) {
                copy.deleteImages.images.forEach(image => {
                    const { id } = copy;

                    buffer.push(adminTraitsApi.post('delete_trait_image', { id, image }));
                });
                delete option.deleteImages;
            }

            if (option.newCurve) {
                const bodyFormData = new FormData();

                bodyFormData.append('file', option.newCurve.file);
                bodyFormData.append('demographic', option.newCurve.demo);
                bodyFormData.append('sex', option.newCurve.sex);
                bodyFormData.append('trait', option.newCurve.trait);
                buffer.push(
                    adminTraitsApi.post('update_trait', option)
                        .then(() =>
                            publicTraitWithFormDataApi.post('uploadDistributionCurve', bodyFormData)
                        ).catch(err => err)
                );
            }

            let objectsToUpload = [];

            for (let field of ['PCC', 'RRR', 'SSR', 'lifespan_gain']) {
                if (Array.isArray(option[field])) {
                    for (let obj of option[field]) {
                        if (obj.toUpload) {
                            objectsToUpload.push({ ...obj, family_history: +obj.family_history || 0, field });
                            delete obj.data;
                        }
                    }
                }
            }

            buffer.push(adminTraitsApi.post('update_trait', option).then(async r => {
                let trait = r.data;
                let finalResponse = r;
                if (trait.filesToUpload.length) {
                    for (let f of trait.filesToUpload) {
                        let obj = objectsToUpload.find(o => o.field === f.field && o.demographic === f.demographic && (+o.family_history || 0) === +f.family_history);
                        await sendToCloud(f.presignedUrl, new Blob([obj.data]));
                        await this.generateObject(trait.id, f.field, f.file);
                    }
                    finalResponse = await adminTraitsApi.get(`${trait.id}`);
                }
                return finalResponse;
            }));

            return arr.concat(buffer.reverse());
        }, []));
    },

    generateObject: function (traitId, field, file) {
        return adminTraitsApi.post('generate_object', { traitId, field, file });
    },

    postTraitIcon: function (option) {
        return adminTraitsApi.post('post_trait_icon', option);
    }
}
