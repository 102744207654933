import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Charts from './Charts';
import { wrapPage } from '../HOC';
import actions from '../../store/actions';

const ChartsContainer = (props) => {
    const [selectedTraits, setSelectedTraits] = useState([]);
    
    useEffect(() => {
        if (props.traits?.length < 1) props.loadTraits();
        if (props.voteFields?.length < 1) props.loadFields();
        if (props.sexes?.length < 1) props.loadSexes();
        if (props.demographics?.length < 1) props.loadDemos();
    }, [props.traits, props.voteFields, props.sexes, props.demographics]);

    useEffect(() => {
        props.clearSubmitted();
        props.clearDemo();
        props.clearSex();
        props.clearTrait();
        props.clearTraits();
        props.clearVotes();
    }, []);

    return (
        <Charts selectedTraits={selectedTraits} setSelectedTraits={setSelectedTraits} />
    );
};

const mapStateToProps = store => ({
    traits: store.traits,
    voteFields: store.voteFields,
    demographics: store.demographics,
    sexes: store.sex
})

const mapDispatchToProps = {
    loadTraits: actions.traits.loadTraits,
    clearTrait: actions.traits.clearTrait,
    loadFields: actions.voteFields.loadVoteFields,
    loadSexes: actions.sexes.loadSex,
    clearSex: actions.sexes.clearSex,
    loadDemos: actions.demographics.loadDemographics,
    clearDemo: actions.demographics.clearDemographics,
    clearSubmitted: actions.newvotes.clearNewVotes,
    clearTraits: actions.traits.clearAllTraits,
    clearVotes: actions.votes.clearVotes
}

export default wrapPage(
    connect(
        mapStateToProps, 
        mapDispatchToProps
    )(ChartsContainer), 
'Charts');
