import { USERS_ACTIONS} from './constants';

export const loadUsers = () => ({
    type: USERS_ACTIONS.LOAD
});

export const loadedUsers = (data) => ({
    type: USERS_ACTIONS.LOADED,
    payload: data
});

export const addUser = (data) => ({
    type: USERS_ACTIONS.ADD,
    payload: data
});

export const addedUser = (data) => ({
    type: USERS_ACTIONS.ADDED,
    payload: data
});

export const updateUser = (data) => ({
    type: USERS_ACTIONS.UPDATE,
    payload: data
});

export const updatedUser = (data) => ({
    type: USERS_ACTIONS.UPDATED,
    payload: data
});

export const removeUser = (data) => ({
    type: USERS_ACTIONS.DELETE,
    payload: data
});

export const removedUser = (data) => ({
    type: USERS_ACTIONS.DELETED,
    payload: data
});

export const setCurrentUser = (data) => ({
    type: USERS_ACTIONS.SET_CURRENT,
    payload: data
})