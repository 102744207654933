import React, { useState, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import { Icon, Loader, Dimmer } from 'semantic-ui-react';

import { decodeBLOB, classList } from '../../utils';
import Traits from "../../api/traits";
import * as Votes from "../../api/votes";
import './DropZone.scss';
import ic_dowload from '../../images/ic_dowload.svg';
import ic_empty_icon from '../../images/ic_empty_icon.svg';

const DropZone = ({ defaultValue, field = 'default', disabled, onDrop, handleNewImage, handleDeleteImage, className, otherStyles = null }) => {
    const [files, setFiles] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async () => {
            if (defaultValue) {
                if (field === 'trait_icon') {
                    let url = defaultValue?.fileUrl || typeof defaultValue === 'string' && defaultValue;
                    if (url) {
                        const response = await Traits.getFile(defaultValue?.fileUrl || defaultValue);
                        const blob = await decodeBLOB(response.data);

                        setFiles({ preview: URL.createObjectURL(blob) });
                    }
                }

                if (field === 'source_screenshot') {
                    setLoading(true);

                    const response = await Traits.getFile(defaultValue);
                    const blob = await decodeBLOB(response.data);

                    setLoading(false);

                    setFiles({ preview: URL.createObjectURL(blob) });
                }

                if (field === 'vote_screenshot') {
                    setLoading(true);

                    let blob = defaultValue;
                    let error = false;
                    if (typeof blob === 'string') {
                        try {
                            const file = await Votes.getFile(blob);
                            blob = await decodeBLOB(file);
                        } catch (e) {
                            error = true;
                            console.log(e);
                        }

                    }

                    setLoading(false);

                    if (!error) setFiles({ preview: URL.createObjectURL(blob) });
                }

                if (field === 'trait_images') {
                    let imageList = defaultValue.map(image => image.fileUrl || image)
                    let data = await Traits.getFiles(imageList.filter(image => typeof image === 'string'));
                    const previews = [];
                    for (let r of data) {
                        if (r && r.data) {
                            const image = r.data;
                            const img = await decodeBLOB(image);
                            previews.push(URL.createObjectURL(img));
                        } else {
                            previews.push(r || '/images/icon_404.png');
                        }
                    }
                    for (let imageFile of imageList.filter(image => typeof image === 'object')) {
                        previews.push(URL.createObjectURL(imageFile))
                    }
                    setFiles({
                        files: imageList,
                        previews
                    });
                }
            } else {
                setFiles({})
            }
        })();
    }, [defaultValue, field]);

    const handleDrop = file => {
        let { previews = [], files: filesArr } = files;

        if (!filesArr) {
            filesArr = [];
        }
        const reader = new FileReader();

        reader.onerror = () => console.log('File reading has failed');

        if (field === 'trait_images') {
            reader.onload = () => {
                const newFiles = filesArr.concat(file);

                setFiles({ previews: previews.concat(URL.createObjectURL(file)), files: newFiles });
                handleNewImage(newFiles.filter(item => typeof item !== 'string'));
            };

            reader.readAsBinaryString(file);
        } else if (field === 'source_file' || (field === 'vote_screenshot_pdf' && file.type === 'application/pdf')) {
            reader.onload = () => {
                setFiles({ file, preview: file.name });
            };

            reader.readAsBinaryString(file);
            onDrop(file);
        } else {
            reader.onload = () => {
                setFiles({ file, preview: URL.createObjectURL(file) });
            };

            reader.readAsBinaryString(file);
            onDrop(file);
        }
    };

    const handleImageDelete = (ind, e) => {
        const { files: filesArr = [], previews } = files;
        const newFiles = [...(filesArr || [])];
        const newPreviews = [...previews];

        let deletedImage = newFiles.pop();
        newPreviews.splice(ind, 1);

        e.stopPropagation();
        setFiles({ files: newFiles, previews: newPreviews });
        onDrop(newFiles)
        handleNewImage(newFiles.filter(image => typeof image !== 'string'))
        typeof deletedImage === 'string' && handleDeleteImage(deletedImage);
    };

    const getAccept = () => {
        switch (field) {
            case 'trait_icon':
            case 'trait_images':
            case 'source_screenshot':
                return ['image/jpeg', 'image/png', 'image/gif', 'image/svg+xml'];

            case 'vote_screenshot':
                return ['image/jpeg', 'image/png', 'image/gif'];

            case 'vote_screenshot_pdf':
                return ['image/png', 'image/jpeg', 'application/pdf'];

            case 'source_file':
                return 'application/pdf';

            default:
                return '';
        }
    };

    return (
        <Dropzone accept={getAccept()} onDrop={acceptedFiles => acceptedFiles.map(file => handleDrop(file))} multiple={false} disabled={disabled}>
            {({ getRootProps, getInputProps }) => (
                <section className={classList('drop-container', className)}>
                    <div {...getRootProps({ className: `dropzone ${field === 'distribution_curve_array_JSON' && 'dropzone-small'} ${disabled && 'disabled'}` })}>
                        <input {...getInputProps()} />
                    </div>
                    {(files && files.preview && (field === 'trait_icon' || field === 'default')) && (
                        <div className='thumb'>
                            <img
                                src={files.preview}
                                className='thumb-image'
                                alt="preview"
                            />
                        </div>
                    )}

                    {field === "source_screenshot" && (
                        loading ? (
                            <div className='thumb_loading'>
                                <Dimmer active inverted>
                                    <Loader inverted />
                                </Dimmer>
                            </div>
                        ) : files.preview ? (
                            <div className='thumb' style={{ backgroundImage: `url(${files.preview})` }}>
                            </div>
                        ) : (
                            <div className={classList('DropZone', otherStyles?.none && 'not_displayed' )}>
                                <div className={classList('icon')}>
                                    <img src={ic_empty_icon} alt='dowload' />
                                </div>
                            </div>
                        )
                    )}

                    {field === "source_file" && (
                        files.preview ? (
                            <p className="thumb pdf">{files.preview}</p>
                        ) : defaultValue ? (
                            <p className="thumb">{defaultValue}</p>
                        ) : null
                    )}

                    {field === "vote_screenshot" && (
                        loading ? (
                            <div className='thumb_loading'>
                                <Dimmer active inverted>
                                    <Loader inverted />
                                </Dimmer>
                            </div>
                        ) : files.preview ? (
                            <div className='thumb' style={{ backgroundImage: `url(${files.preview})` }}>
                            </div>
                        ) : null
                    )}

                    {field === "vote_screenshot_pdf" && (
                        loading ? (
                            <div className='thumb_loading'>
                                <Dimmer active inverted>
                                    <Loader inverted />
                                </Dimmer>
                            </div>
                        ) : files.preview ? files.file.type === 'application/pdf' ? <p className="thumb pdf">{files.preview}</p> : (
                            <div className='thumb' style={{ backgroundImage: `url(${files.preview})` }}></div>
                        ) : (
                            <div className={classList('DropZone')}>
                                <div>
                                    <img src={ic_dowload} alt='dowload' />
                                    <p>Drag and drop or <span>browse</span> file to upload</p>
                                </div>
                                <p>Png, jpeg, pdf. Max size 10 mb.</p>
                            </div>
                        )
                    )}

                    {files && files.previews && field === 'trait_images' && (
                        <div className='thumbsContainer'>
                            {files.previews.map((preview, ind) => (
                                <div className='thumb' key={ind}>
                                    <Icon
                                        className='delete-image-button'
                                        name='close'
                                        onClick={(e) => handleImageDelete(ind, e, files)}
                                    />
                                    <img
                                        src={preview}
                                        className='thumb-image'
                                        alt={preview}
                                    />
                                </div>
                            ))}
                        </div>
                    )}
                    {defaultValue && field === 'distribution_curve_array_JSON' && (
                        <p className='preview'>{defaultValue}</p>
                    )}
                </section>
            )}
        </Dropzone>
    );
};

export default DropZone;