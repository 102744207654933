import { put, takeLatest, call } from 'redux-saga/effects';
import { ADMIN_REGIONS_ACTIONS as ACTIONS } from '../actions/constants';
import actions from '../actions';
import * as api from '../../api/admin.regions';

const {adminRegions, loading} = actions;
function* loadRegions() {
    yield put(loading.startLoading('regions'));
    try {
        let regions = yield call(api.load);
        yield put(adminRegions.loadedRegions(regions));
        yield put(actions.phea.clearPhea());
    } catch (e) {
        console.error(e);
    }
    yield put(loading.endLoading('regions'));
}

function* saveRegion(action) {
    let model = action.payload;
    yield put(loading.startLoading('regions'));
    try {
        let region = yield call(model.id ? api.update : api.add, model);
        yield put(model.id ? adminRegions.updateRegion(region) : adminRegions.addRegion(region));
        yield put(actions.popups.closePopup());
        yield put(actions.phea.clearPhea());
    } catch(e) {

    }
    yield put(loading.endLoading('regions'));
}

function* deleteRegion(action) {
    let id = action.payload;
    yield put(loading.startLoading('regions'));
    try {
        yield call(api.remove, id);
        yield put(adminRegions.removedRegion(id));
        yield put(actions.phea.clearPhea());
    } catch(e) {

    }
    yield put(loading.endLoading('regions'));
}

export function* adminRegionsSaga() {
    yield takeLatest(ACTIONS.LOAD, loadRegions);
    yield takeLatest(ACTIONS.SAVE, saveRegion);
    yield takeLatest(ACTIONS.REMOVE, deleteRegion);
}