import {ADMIN_REGIONS_ACTIONS as ACTIONS} from '../actions/constants';
export const adminRegions = (state = [], action) => {
    switch (action.type) {
        case ACTIONS.LOADED: return [...action.payload];
        case ACTIONS.UPDATE: return state.map(r => r.id === action.payload.id ? ({...r, ...action.payload}) : r);
        case ACTIONS.ADD: return [...state, action.payload];
        case ACTIONS.REMOVED: return state.filter(r => r.id !== action.payload);
        default: return state;
    }
}
