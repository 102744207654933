import React from 'react';
import { Modal, Loader } from 'semantic-ui-react';

export const ScreenshotModal = (props) => (
    <Modal
        open={!!props.modal}
        closeOnDocumentClick
        onClose={props.onModalClose}
        className="screenshot_modal"
    >
        {props.modal && props.modal.loading ? (
            <div className="screenshot_modal_loading">
                <Loader size="huge" />
            </div>
        ) : (
                <div className="screenshot_modal_image" style={{ backgroundImage: `url(${props.modal && props.modal.source})` }}>
                </div>
            )}
    </Modal>
)
