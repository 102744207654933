import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Frequency from '../Frequency';
import { Form, Dropdown, Button, Icon } from 'semantic-ui-react';
import TooltipIcon from '../../common/TooltipIcon';
import FormRowWithLink from './FormRow/FormRowWithLink';
import ReCaptcha from '../../common/ReCaptcha';
import NumberInput from '../../common/NumberInput';
import FormTitle from './FormTitle';
import { metricsTooltips, classList } from '../../../utils';
import { DropZone } from '../../common';
import s from './WeightForm.module.scss';
import actions from '../../../store/actions';

const WeightForm = props => {
    let [verified, changeVerifying] = useState(false);
    let [resetCaptcha, setReset] = useState(null);
    useEffect(() => {
        let block = document.querySelector('#editing');
        window.scrollTo(0, block.getBoundingClientRect().top + window.scrollY - 20);
        loadCountries();
    }, []);

    const {
        trait = {},
        validation = [],
        newVotes,
        contributeVotes,
        captchaResponse,
        loadCountries,
    } = props;

    const onSubmit = () => {
        if (verified) {
            contributeVotes(captchaResponse);
            if (resetCaptcha) resetCaptcha();
        }
    };

    let impactVotes = newVotes.filter(v => ~v.field.name.indexOf('life_span_impact'));
    let onsetVotes = newVotes.filter(v => ~v.field.name.indexOf('average_age_of_onset'));
    let qualityVotes = newVotes.filter(v => ~v.field.name.indexOf('life_quality'));
    let treatmentVotes = newVotes.filter(v => ~v.field.name.indexOf('life_treatment'));
    let morbidVotes = newVotes.filter(v => ~v.field.name.indexOf('morbid'));
    let yearsLifeLostPerDeath = newVotes.filter(v => ~v.field.name.indexOf('years_of_life_lost'));
    let survivalRateInFiveYears = newVotes.filter(v => ~v.field.name.indexOf('survival_rate_in_five_years'));
    let lifeExpectancyVotes = newVotes.filter(
        v => ~v.field.name.indexOf('life_expectancy_with_disease')
    );

    return (
        <div id="editing" className="editing">
            <hr className="mw_section_divider" />
            {trait.trait_name ? (
                <>
                    <FormTitle />
                    <div className="mw_section_title">
                        Enter values for {trait.trait_name}:
                    </div>
                    {treatmentVotes.map(v => (
                        <FormRowWithLink
                            vote={v}
                            key={v.sexId}
                            tooltip={metricsTooltips['life_treatment_cost_score']}
                        >
                            <NumberInput
                                min={0}
                                validationFieldName="Lifetime Cost"
                                placeholder="Enter amount"
                            />
                            <input type="text" placeholder="http(s)://" />
                            <textarea
                                type="text"
                                placeholder="Paste Citation Here"
                            ></textarea>
                        </FormRowWithLink>
                    ))}

                    {impactVotes.map(v => (
                        <FormRowWithLink
                            vote={v}
                            key={v.sexId}
                            tooltip={metricsTooltips['life_span_impact_score']}
                        >
                            <NumberInput
                                min={0}
                                max={80}
                                float
                                placeholder="Enter amount"
                            />
                            <input type="text" placeholder="http(s)://" />
                            <textarea
                                type="text"
                                placeholder="Paste Citation Here"
                            ></textarea>
                        </FormRowWithLink>
                    ))}

                    {qualityVotes.map(v => (
                        <FormRowWithLink
                            vote={v}
                            key={v.sexId}
                            tooltip={metricsTooltips['life_quality_impact_score']}
                        >
                            <NumberInput
                                min={0}
                                max={100}
                                float
                                placeholder="Enter percent"
                            />
                            <input type="text" placeholder="http(s)://" />
                            <textarea
                                type="text"
                                placeholder="Paste Citation Here"
                            ></textarea>
                        </FormRowWithLink>
                    ))}

                    {onsetVotes.map(o => (
                        <FormRowWithLink
                            key={o.sexId}
                            vote={o}
                            tooltip={metricsTooltips['average_age_of_onset']}
                        >
                            <NumberInput
                                min={0}
                                max={100}
                                float
                                placeholder="Enter amount"
                            />
                            <input type="text" placeholder="http(s)://" />
                            <textarea
                                type="text"
                                placeholder="Paste Citation Here"
                            ></textarea>
                        </FormRowWithLink>
                    ))}

                    {lifeExpectancyVotes.map(v => (
                        <FormRowWithLink
                            vote={v}
                            key={v.sexId}
                            tooltip={metricsTooltips['life_expectancy_with_disease']}
                        >
                            <NumberInput
                                min={0}
                                max={100}
                                float
                                placeholder="Enter amount"
                            />
                            <input type="text" placeholder="http(s)://" />
                            <textarea
                                type="text"
                                placeholder="Paste Citation Here"
                            ></textarea>
                        </FormRowWithLink>
                    ))}

                    {yearsLifeLostPerDeath.map(v => (
                        <FormRowWithLink
                            vote={v}
                            key={v.field.id}
                            tooltip={metricsTooltips['years_of_life_lost']}
                        >
                            <NumberInput
                                min={0}
                                max={100}
                                float
                                placeholder="Enter amount"
                            />
                            <input type="text" placeholder="http(s)://" />
                            <textarea
                                type="text"
                                placeholder="Paste Citation Here"
                            ></textarea>
                        </FormRowWithLink>
                    ))}

                    {survivalRateInFiveYears.map(v => (
                        <FormRowWithLink
                            vote={v}
                            key={v.field.id}
                            placeholder="percent"
                            tooltip={metricsTooltips['survival_rate_in_five_years']}
                        >
                            <NumberInput
                                min={0}
                                max={100}
                                float
                                placeholder="Enter percent"
                            />
                            <input type="text" placeholder="http(s)://" />
                            <textarea
                                type="text"
                                placeholder="Paste Citation Here"
                            ></textarea>
                        </FormRowWithLink>
                    ))}

                    <Frequency votes={morbidVotes} />

                    <div className="form_row">
                        <div className="form_col label"></div>
                        <div className="form_col field">
                            <ReCaptcha
                                changeVerifying={(value, reset) => {
                                    changeVerifying(value);
                                    if (!reset) setReset(reset);
                                }}
                                saveResponse={props.saveResponse}
                            />
                        </div>
                    </div>
                    <div className="form_row block">
                        {validation.map(e => (
                            <div key={e} className="form_col full error">
                                {e}
                            </div>
                        ))}
                    </div>
                    <div className="form_row">
                        <div className="form_col full">
                            <div className="centered_buttons">
                                <Button
                                    to="/weight"
                                    className="gradient invert mono"
                                    onClick={() => {
                                        window.location.href = '/weight';
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className={`gradient misery_weight-save${
                                        !verified ? ' disabled-button' : ''
                                    }`}
                                    onClick={onSubmit}
                                >
                                    <Icon name="balance scale" />
                                    Contribute
                                </Button>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
        </div>
    );
};

const mapStateToProps = store => ({
    categories: store.categories,
    chromosomes: store.chromosomes,
    trait: store.activeTrait,
    newVotes: store.newVotes,
    validation: store.weightValidation,
});

const mapDispatchToProps = {
    contributeVotes: actions.newvotes.contributeVotes,
    loadCountries: actions.countries.loadCountries,
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(WeightForm));
