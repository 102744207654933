import React from 'react';
import { connect } from 'react-redux';
import { classList } from '../../../utils';
import s from '../Regions.module.scss';
import { RegionRow } from '.';

const RegionsTable = React.memo((props) => {
    const {data, loading} = props;
    return (
        <div className={classList(s.RegionsTable, loading && s.RegionsTable_loading)}>
            <div className={classList(s.RegionsTable__Row, s.RegionsTable__Row_Head)}>
                <div className={classList(s.RegionsTable__Column, s.RegionsTable__Column_id)}>id</div>
                <div className={classList(s.RegionsTable__Column, s.RegionsTable__Column_name)}>Name</div>
                <div className={classList(s.RegionsTable__Column, s.RegionsTable__Column_shortName)}>Short Name</div>
                <div className={classList(s.RegionsTable__Column, s.RegionsTable__Column_demos)}>Genetic Ancestry Breakdown</div>
                <div className={classList(s.RegionsTable__Column, s.RegionsTable__Column_population)}>Population</div>
                <div className={classList(s.RegionsTable__Column, s.RegionsTable__Column_births)}>Births / year</div>
                <div className={classList(s.RegionsTable__Column, s.RegionsTable__Column_actions)}> </div>
            </div>
            {data.map(r => <RegionRow key={r.id} model={r} />)}
        </div>
    )
})

const mapStateToProps = (store) => ({
    data: store.adminRegions,
    loading: store.loading
})

export default connect(mapStateToProps)(RegionsTable);
