import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import { Container, Grid, Responsive, Menu } from 'semantic-ui-react';

import ic_logo_footer from '../../resources/images/ic_logo_footer.svg';
import ic_blogger from '../../resources/images/ic_blogger.svg';
import ic_facebook from '../../resources/images/ic_facebook.svg';
import ic_twitter from '../../resources/images/ic_twitter.svg';
import Netflix_logo from '../../resources/images/netflix_logo.svg';

export default class Footer extends PureComponent{
    render = () => <Container fluid className='footer'>
        <Container>
            <Responsive maxWidth={539}>
                <Grid centered={true} verticalAlign='top'>
                    <Grid.Column width={6} centered={true}>
                        <Grid centered={true}>
                            <Grid.Row>
                                <Grid.Column textAlign='center' width={16}>
                                    <Menu.Item className="disabled" href='/about'>
                                        <h3>ABOUT</h3>
                                    </Menu.Item>
                                </Grid.Column>
                                <Grid.Column textAlign='center' width={16}>
                                    <Menu.Item href='/privacy'>
                                        <h3>PRIVACY</h3>
                                    </Menu.Item>
                                </Grid.Column>
                                <Grid.Column textAlign='center' width={16}>
                                    <Menu.Item className="disabled" href='/contact'>
                                        <h3>CONTACT</h3>
                                    </Menu.Item>
                                </Grid.Column>
                                <Grid.Column textAlign='center' width={16}>
                                    <Menu.Item className="disabled" href='/faq'>
                                        <h3>FAQ</h3>
                                    </Menu.Item>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width={10} centered={true}>
                        <Grid centered={true}>
                            <Grid.Row>
                                <Grid.Column width={5}>
                                    <Menu.Item href='http://infoproc.blogspot.com/'>
                                        <img src={ic_blogger} alt={ic_blogger} />
                                    </Menu.Item>
                                </Grid.Column>
                                <Grid.Column width={5}>
                                    <Menu.Item href='https://www.facebook.com/Genomic-Prediction-206515586784814/'>
                                        <img src={ic_facebook} alt={ic_facebook} />
                                    </Menu.Item>
                                </Grid.Column>
                                <Grid.Column width={5}>
                                    <Menu.Item href='https://twitter.com/GenomPrediction/'>
                                        <img src={ic_twitter} alt={ic_twitter} />
                                    </Menu.Item>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row centered={true}>
                                <Grid.Column width={10}>
                                    <Menu.Item href='https://www.netflix.com/watch/80243752?trackId=200257858'>
                                        <img src={Netflix_logo} alt={Netflix_logo} />
                                    </Menu.Item>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid>
            </Responsive>
            <Responsive minWidth={540}>
                <Grid centered={true} verticalAlign='middle'>
                    <Grid.Row verticalAlign='middle'>
                        <Grid.Column tablet={6} computer={5}>
                            <Grid>
                                <Grid.Row textAlign='right'>
                                    <Grid.Column width={16}>
                                        <Link  className="disabled" to='/about'>
                                            <h3>ABOUT PROJECT</h3>
                                        </Link>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row className="footer-left-row" textAlign='right'>
                                    <Grid.Column tablet={7} computer={5} floated='right'>
                                        {/* <a href='https://www.netflix.com/watch/80243752?trackId=200257858'> */}
                                        <span>
                                            <img className='netflix-logo' src={Netflix_logo} alt={Netflix_logo} />
                                        </span>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                        <Grid.Column width={2} textAlign='center'>
                            <img className='footer-logo' src={ic_logo_footer} alt={ic_logo_footer} />
                        </Grid.Column>
                        <Grid.Column tablet={6} computer={5}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column width={4}>
                                        <Link className="disabled" to='/faq'>
                                            <h3>FAQ</h3>
                                        </Link>
                                    </Grid.Column>
                                    <Grid.Column width={7}>
                                        <Link className="disabled" to='/contact'>
                                            <h3>CONTACT US</h3>
                                        </Link>
                                    </Grid.Column>
                                    <Grid.Column width={5}>
                                        <Link to='/privacy'>
                                            <h3>PRIVACY</h3>
                                        </Link>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row className="footer-right-row">
                                    <Grid.Column tablet={4} computer={3}>
                                        <a className='disabled' href='http://infoproc.blogspot.com/'>
                                            <img className='blogger-logo' src={ic_blogger} alt={ic_blogger} />
                                        </a>
                                    </Grid.Column>
                                    <Grid.Column tablet={4} computer={3}>
                                        <a className='disabled' href='https://www.facebook.com/Genomic-Prediction-206515586784814/'>
                                            <img className='facebook-logo' src={ic_facebook} alt={ic_facebook} />
                                        </a>
                                    </Grid.Column>
                                    <Grid.Column tablet={4} computer={3}>
                                        <a className='disabled' href='https://twitter.com/GenomPrediction/'>
                                            <img className='twitter-logo' src={ic_twitter} alt={ic_twitter} />
                                        </a>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Responsive>
        </Container>
    </Container>
}
