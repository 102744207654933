import { PHEA_ACTIONS } from './constants';


export const loadPhea = (version) => ({
    type: PHEA_ACTIONS.LOAD,
    payload: version
});

export const receivePhea = (data) => ({
    type: PHEA_ACTIONS.RECEIVE,
    payload: data
});

export const clearPhea = () => ({
    type: PHEA_ACTIONS.CLEAR
})