import '../choose/styles.css'

import React, { PureComponent } from 'react';
import Rating from "react-rating";
import numeral from 'numeral';
import { categoryImages } from '../../utils';
import { Card, Icon, Grid, Divider, Progress, Popup, Responsive } from "semantic-ui-react";

import { getHearts } from '../../utils';

const ic_bracket = '/images/ic_bracket.svg';
const ic_man = '/images/ic_man.svg';
const ic_woman = '/images/ic_woman.svg';




export default class Embryo extends PureComponent {

    render() {
        const { condition, onClick, showsResult = false, winner = false } = this.props;
        
        return <Card className={`game-card ${showsResult && 'unclickable'}`} link fluid raised>
            {winner && <Icon className='selected-embryo' name='check circle' />}
            <Card.Content onClick={onClick} textAlign='center'>
                <Grid>
                    <Grid.Row centered>
                        <div className='embryo-outer'>
                            <div className='embryo-inner'>
                            </div>
                            <Popup
                                position='top right'
                                hoverable
                                trigger={
                                    <div className='disease-group-icon'>
                                        <img src={categoryImages[condition.category]} alt={categoryImages[condition.category]} />
                                    </div>
                                }
                                content={
                                    <div>
                                        <p>
                                            {condition.category}
                                        </p>
                                    </div>
                                }
                                inverted
                            />
                        </div>
                    </Grid.Row>
                    <Grid.Row centered verticalAlign='middle'>
                        <div className='question-icon-container'>
                            {(condition.trait_text || condition.life_quality_impact_score_text) ? (
                                <Popup
                                    position='top right'
                                    hoverable
                                    trigger={
                                        <h3>{condition.trait_name}</h3>
                                    }
                                    content={
                                        <div>
                                            <p>
                                                {condition.trait_text || condition.life_quality_impact_score_text}
                                            </p>
                                        </div>
                                    }
                                    inverted
                                />
                            ) : (
                                    <h3>{condition.trait_name}</h3>
                                )}
                            <Popup
                                position='top right'
                                hoverable
                                trigger={
                                    <Icon className='question-icon' name='question circle outline' />
                                }
                                content={
                                    <div>
                                        <a target="_blank" rel="noopener noreferrer" href={condition.trait_link || condition.extra_info} onClick={e => e.stopPropagation()}>
                                            Read full source
                                </a>
                                    </div>
                                }
                                inverted
                            />
                        </div>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={7} verticalAlign='middle' textAlign='left'>
                            <Popup
                                position='top left'
                                hoverable
                                trigger={
                                    <h4>Life Quality</h4>
                                }
                                content={
                                    <div>
                                        <p>
                                            How strongly this disorder negatively affects life quality,
                                            on a scale from 1-100, where 1 denotes a small inconvenience,
                                            and a 100 is a debilitating disability.
                                </p>
                                    </div>
                                }
                                inverted
                            />
                        </Grid.Column>
                        <Grid.Column floated='right' mobile={9} tablet={7} computer={7} verticalAlign='middle' textAlign='left'>
                            <Popup
                                position='top left'
                                hoverable
                                trigger={
                                    <div>
                                        <Rating
                                            emptySymbol={
                                                <Icon className='disabled-leaf-icon' name='leaf' size='large' />
                                            }
                                            fullSymbol={<Icon name='leaf' size='large' color='green' />}
                                            initialRating={getHearts(condition.life_quality_impact_score)}
                                            readonly
                                        />
                                    </div>
                                }
                                content={
                                    <div>
                                        <p>
                                            Life Quality Impact
                                </p>
                                        <p>
                                            {condition.life_quality_impact_tooltip}
                                        </p>
                                    </div>
                                }
                                inverted
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={7} verticalAlign='middle' textAlign='left'>
                            <Popup
                                position='top left'
                                trigger={
                                    <h4>Lifetime Cost</h4>
                                }
                                content={
                                    <div>
                                        <p>
                                            In American dollars, estimate how much money you think that this disorder would cost in treatment, over an entire lifespan.
                                </p>
                                    </div>
                                }
                                inverted
                            />
                        </Grid.Column>
                        <Grid.Column floated='right' width={7} verticalAlign='middle' textAlign='left'>
                            <Popup
                                position='top left'
                                trigger={
                                    <p className='treatment-cost'>{numeral(condition.life_treatment_cost_score).format('($0a)')}</p>
                                }
                                content={
                                    <div>
                                        <p>
                                            Lifetime Cost
                                </p>
                                        <p>
                                            {condition.life_treatment_cost_tooltip}
                                        </p>
                                    </div>
                                }
                                inverted
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row verticalAlign='middle'>
                        <Grid.Column width={7} verticalAlign='middle' textAlign='left'>
                            <Popup
                                position='top left'
                                trigger={
                                    <h4>Median Lifespan</h4>
                                }
                                content={
                                    <div>
                                        <p>
                                            Number of years one affected by this disorder might
                                            normally live. The normal lifespan for a person without the disease is 80 years.
                                </p>
                                    </div>
                                }
                                inverted
                            />
                        </Grid.Column>
                        <Responsive minWidth={960}>
                            <Grid.Column computer={1} verticalAlign='middle' floated='left'>
                                <img className="bracket" src={ic_bracket} alt={ic_bracket} />
                            </Grid.Column>
                        </Responsive>
                        <Grid.Column className='median-lifespan' floated='right' mobile={9} computer={7} verticalAlign='middle' textAlign='left'>
                            <Grid verticalAlign='middle'>
                                <Grid.Row>
                                    <Grid.Column width={13} floated='right'>
                                        <Grid padded={false}>
                                            <Grid.Row>
                                                <Grid.Column textAlign='center'>
                                                    <img className="sex-icon" src={ic_man} alt={ic_man} />
                                                    <p><b>{condition.life_span_impact_score_male}</b> years</p>
                                                    <Popup
                                                        position='top right'
                                                        trigger={
                                                            <Progress
                                                                size='tiny'
                                                                color='blue'
                                                                value={condition.life_span_impact_score_male}
                                                                total={80}
                                                            />
                                                        }
                                                        content={<p>{condition.life_span_impact_tooltip}</p>}
                                                        inverted
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Grid.Column>
                                    <Grid.Column width={13} floated='right'>
                                        <Grid>
                                            <Grid.Row>
                                                <Grid.Column textAlign='center'>
                                                    <img className="sex-icon" src={ic_woman} alt={ic_woman} />
                                                    <p><b>{condition.life_span_impact_score_female}</b> years</p>
                                                    <Popup
                                                        position='top right'
                                                        trigger={
                                                            <Progress
                                                                size='tiny'
                                                                color='blue'
                                                                value={condition.life_span_impact_score_female}
                                                                total={80}
                                                            />
                                                        }
                                                        content={<p>{condition.life_span_impact_tooltip}</p>}
                                                        inverted
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Divider vertical hidden />
            </Card.Content>
        </Card>
    }
}