import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styles from './Votes.module.scss';
import { classList } from '../../utils';
import { Dropdown } from '../common';
import actions from '../../store/actions';
const overflowString = (string, max) => string.length <= max ? string : string.slice(0, max) + '...';
const VoteFilters = (props) => {
    let { sexId, fieldId, demoId, setSex, setDemo, setField, sexes, demographics, fields,
        loadDemos, loadSexes, loadFields } = props;
    useEffect(() => { if (demographics.length < 1) loadDemos(); }, [demographics]);
    useEffect(() => { if (fields.length < 1) loadFields(); }, [fields]);
    useEffect(() => { if (sexes.length < 1) loadSexes(); }, [sexes]);
    let newDemos = [{ name: 'All', id: 0 }, ...demographics].map(d => ({ value: d.id, text: d.name, selected: d.id === demoId }));
    let newSexes = [{ name: 'All', id: 0 }, ...sexes].map(d => ({ value: d.id, text: d.name, selected: d.id === sexId }));
    let newFields = [{ displayName: 'All', id: 0 }, ...fields].map(d => ({ value: d.id, text: overflowString(d.displayName, 40), selected: d.id === fieldId }));

    return <div className={classList(styles.VoteFilters)}>
        {newDemos?.length > 0 && <div className={styles.DropdownBlock}> Genetic Ancestry <Dropdown className={styles.FiltersDropdown} items={newDemos} onChange={setDemo} /></div>}
        {newFields?.length > 0 && <div className={styles.DropdownBlock}> Field <Dropdown className={styles.FiltersDropdown} items={newFields} onChange={setField} /></div>}
        {newSexes?.length > 0 && <div className={styles.DropdownBlock}> Sex <Dropdown className={styles.FiltersDropdown} items={newSexes} onChange={setSex} /></div>}
    </div>
}

const mapStateToProps = (store) => ({
    sexes: store.sex,
    demographics: store.demographics,
    fields: store.voteFields
});

const mapDispatchToProps = {
    loadDemos: actions.demographics.loadDemographics,
    loadSexes: actions.sexes.loadSex,
    loadFields: actions.voteFields.loadVoteFields
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VoteFilters));
