import React from 'react';
import { Popup, Icon } from 'semantic-ui-react'
import { classList } from '../../utils';

const TooltipIcon = ({ content, inverted = true, hoverable = true, position = 'top left', className = '' }) => <Popup
    position={position}
    hoverable={hoverable}
    trigger={
        <Icon className={classList('question-icon', className)} name='question circle outline' />
    }
    content={content}
    inverted={inverted}
/>

export default TooltipIcon;