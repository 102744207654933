import React from 'react';
import Dropzone from 'react-dropzone';
import { classList } from '../../utils';
import s from './Research.module.scss';

const acceptedTypes = [
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
];
 
const DocumentDropZone = ({ isError, field: { value }, placeholder, setFieldValue, setFieldTouched, setFieldError }) => {

    const handleDrop = file => {
        const reader = new FileReader();

        reader.onerror = () => {
            console.log('File reading has failed');
        };

        reader.onabort = () => {
            console.log('File reading has failed');
        };

        reader.onload = () => {
            setFieldValue('document', { file, preview: reader.result });
            setFieldValue('filename', file.name);
        };

        try{
            reader.readAsDataURL(file);
        } catch (e) {
            setFieldError('document', 'File must have .pdf, .doc or .docx extension');
            console.log(e.message);
        }

        setFieldTouched('document', true, false);
    };

    return (
        <Dropzone accept={acceptedTypes} onDrop={acceptedFiles => handleDrop(acceptedFiles[0])}>
            {({ getRootProps, getInputProps }) => (
                <div className={classList(s.DropContainer, isError && s.DropError)}>
                    <div { ...getRootProps({ className: "research_dropzone" }) }>
                        <input { ...getInputProps() } />
                        {!value && (<p>{placeholder}</p> || <p>Drag'n'drop a PDF, or click to select it</p>)}
                    </div>
                    {value && (
                        <div className='thumb'>
                            <p className="centered">{value && value.file && (value.file.name || 'Click to replace uploaded document')}</p>
                        </div>
                    )}
                </div>
            )}
        </Dropzone>
    );
};

export default DocumentDropZone;