import { STATISTIC_ACTIONS } from "./constants";

export const loadUsersContributions = () => ({
    type: STATISTIC_ACTIONS.LOAD_CONTRIBUTIONS,
});

export const receiveUsersContributions = (data) => ({
    type: STATISTIC_ACTIONS.RECEIVE_CONTRIBUTIONS,
    payload: data,
});

export const loadUserContributions = (userId) => ({
    type: STATISTIC_ACTIONS.LOAD_USER_CONTRIBUTIONS,
    payload: userId
});

export const receiveUserContributions = (data, userId) => ({
    type: STATISTIC_ACTIONS.RECEIVE_USER_CONTRIBUTIONS,
    payload: {data, userId}
});

export const loadIndexStatusActivityTraitsData = () => ({
    type: STATISTIC_ACTIONS.LOAD_INDEX_ACTIVITY_TRAITS_DATA
});

export const receiveIndexStatusActivityTraitsData = (data) => ({
    type: STATISTIC_ACTIONS.RECEIVE_INDEX_ACTIVITY_TRAITS_DATA,
    payload: data
});

export const loadProgressGraphData = (metricFieldId, typeOfVotes) => ({
    type: STATISTIC_ACTIONS.LOAD_PROGRESS_GRAPH_DATA,
    payload: {metricFieldId, typeOfVotes}
});

export const receiveProgressGraphData = (data) => ({
    type: STATISTIC_ACTIONS.RECEIVE_PROGRESS_GRAPH_DATA,
    payload: data
});
