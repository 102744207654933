import React, { memo } from 'react';
import s from './Vote.module.scss';
import { Popup } from 'semantic-ui-react';
import { DropZone } from '../../common';

export const VoteSourcePreview = memo(({ source }) => {
    return (
        <Popup trigger={<div className={s.SourcePreview}><div className={s.SourceIcon}></div></div>}
            hoverable
            inverted
            position="bottom center"
        >
            <DropZone field="vote_screenshot" className={s.SourceImage} defaultValue={source} disabled={true} />
        </Popup>)
});


