import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { TraitSearchDropdown } from '../traitSearch';
import AddTraitPopup from './AddTrait/AddTraitPopup';
import { Dropdown, Modal } from 'semantic-ui-react'
import * as demoActions from '../../store/actions/demographics';
import * as sexActions from '../../store/actions/sex';
import * as newVotesActions from '../../store/actions/newvotes.actions';
import * as traitActions from '../../store/actions/traits';
import * as mwEditActions from '../../store/actions/mw.edit';

class WeightInitialBlock extends PureComponent {
    state = { isAdding: false }

    resetEditing = () => {
        this.props.clearTrait();
        this.props.updateSex({ id: null });
        this.props.chooseDemographics({ value: null });
        this.props.changeEditMode('INITIAL');
        window.scrollTo(0, 0);
        this.setState({ newTrait: null })
    }

    createTrait = (trait_name, category_id, trait_text) => {
        const newTrait = { trait_name, category_id, id: 'new', trait_text };
        this.props.createTrait(newTrait);
        this.setState({ isAdding: false });
    }

    render() {
        const { isAdding } = this.state;
        let { demographics, sex, activeTrait = false, categories, mwEdit, traits } = this.props;
        if (activeTrait && activeTrait.id === 'new') {
            traits = [...traits, activeTrait]
        }

        const activeSex = sex.some(s => s.selected) ? sex.find(s => s.selected).id : null;
        const activeRegion = demographics.some(r => r.selected) ? demographics.find(r => r.selected).short_name : null;
        return <>
            <p className="intro-text">It is important that we have an accurate understanding of the prevalence, severity, and cost of each disease. Each disease has its own unique weight. If you have access to research or resources to help us to describe a disease, please contribute your knowledge below.
            </p>
            <div>
                <div className="mw_section_title">Chosen options:</div>
                <div className="form_row">
                    <div className="form_col label">
                        <div className="text">Trait</div>
                    </div>
                    <div className="form_col field">
                        <TraitSearchDropdown
                            className="gray"
                            disabled={mwEdit !== 'INITIAL'}
                            trait={activeTrait}
                            customTraits={traits.filter(t => t.approved || t.id === 'new')}
                            handleChange={(trait) => this.props.selectTrait(trait)}
                            placeholder="Search by Trait" />
                    </div>
                    <div className="form_col link_col">
                        {mwEdit === 'INITIAL' ? (
                            <>
                                <span className="or">or</span>
                                <div className="add_btn" onClick={() => this.setState({ isAdding: true })}>Add New Trait</div>
                            </>
                        ) : (
                                <div className="add_btn" onClick={this.resetEditing}>Reset</div>
                            )}
                    </div>
                </div>
                <Modal open={isAdding} className="add_trait_popup_container" onClose={this.closeNewTraitModal}>
                    <Modal.Content>
                        <AddTraitPopup
                            onClose={this.closeNewTraitModal}
                            categories={categories}
                            onCreateTrait={this.createTrait} />
                    </Modal.Content>
                </Modal>
                <div className="form_row">
                    <div className="form_col label">
                        <div className="text">Genetic Ancestry</div>
                    </div>
                    <div className="form_col field">
                        <Dropdown
                            className="demographic_dropdown gray"
                            placeholder="Genetic Ancestry"
                            options={demographics.map(d => ({ text: d.name, value: d.short_name }))}
                            disabled={mwEdit !== 'INITIAL'}
                            value={activeRegion}
                            onChange={(e, s) => this.props.chooseDemographics(s)}
                        />
                    </div>
                </div>
                <div className="form_row">
                    <div className="form_col label">
                        <div className="text">Sex</div>
                    </div>
                    <div className="form_col field">
                        <Dropdown
                            className="demographic_dropdown gray"
                            placeholder="Sex"
                            options={sex.map(s => ({ text: s.name, value: s.id }))}
                            disabled={mwEdit !== 'INITIAL'}
                            value={activeSex}
                            onChange={(e, s) => this.props.updateSex(s)}
                        />
                    </div>
                </div>
                <div className="form_row">
                    <div className="form_col label">
                    </div>
                    <div className="form_col field">
                    </div>
                    <div className="form_col submit_col">
                        {mwEdit === 'INITIAL' &&
                            <button className="ui button gradient invert misery_weight-save"
                                disabled={!activeTrait.id || !activeSex || !activeRegion} onClick={this.startEditing}>Continue</button>}
                    </div>
                </div>
            </div>
        </>
    }

    startEditing = () => {
        this.props.changeEditMode('EDIT');
        this.props.fillVotes();
    }

    closeNewTraitModal = () => {
        this.setState({ isAdding: false })
    }
}

const mapStateToProps = (store) => ({
    demographics: store.demographics,
    categories: store.categories,
    chromosomes: store.chromosomes,
    sex: store.sex,
    activeTrait: store.activeTrait,
    mwEdit: store.mwEdit,
    traits: store.traits
});

const mapDispatchToProps = {
    chooseDemographics: demoActions.chooseDemographics,
    updateSex: sexActions.updateSex,
    selectTrait: traitActions.selectTrait,
    createTrait: traitActions.createTrait,
    clearTrait: traitActions.changeTrait,
    changeEditMode: mwEditActions.mwEditChange,
    fillVotes: newVotesActions.fillNewVotes
}

export default connect(mapStateToProps, mapDispatchToProps)(WeightInitialBlock);
