import React from "react";
import styles from '../IndexStatus.module.scss';

export const IndexStatusHeader = ({metricsMap, regions}) => (
  <div className={styles.header}>
      <div className={styles.title} />
      {Object.values(metricsMap).map(metric => (
          <div className={styles.metric_block} key={metric}>
              <div className={styles.metric}>{metric}</div>
              <div className={styles.regions}>
              {regions.map(regionISO => (
                  <div key={`${metric} ${regionISO}`}>{regionISO}</div>
              ))}
              </div>
          </div>
      ))}
  </div>
);
