import React from 'react';

import MainCard from './MainCard';
import CollaborationCards from './CollaborationCards';

import { Grid, Card, Responsive } from 'semantic-ui-react';

const Home = () => (
    <>
        <Responsive maxWidth={959}>
            <div className='mobile-content-section'>
                <MainCard />
                <CollaborationCards />
            </div>
        </Responsive>
        <Responsive minWidth={960}>
            <div className="video-wrapper">
                <video autoPlay loop muted>
                    <source src="video.mp4" type="video/mp4"/>
                </video>
            </div>
            <Grid>
                <Card raised className='default-page-card'>
                    <MainCard />
                </Card>
            </Grid>
            <CollaborationCards />
        </Responsive>
    </>
);

export default Home;
