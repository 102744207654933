import React from "react";
import styles from "../IndexStatus.module.scss";
import {classList} from "../../../../../utils";

export const IndexStatusActivityBlock = ({activityCount = 0, isConfirms = false, isApproved = true}) => {
    const getColorClassName = (activityCount) => {
        if (!isApproved) return styles.gray;
        if (activityCount === 1) return styles.orange;
        if (activityCount === 2) return styles.yellow;
        if (activityCount === 3) return styles.blue;
        if (activityCount > 3) return styles.green;
        return styles.red;
    }

    return (
        <div
            className={classList(
                styles.activity_block,
                !isConfirms && getColorClassName(activityCount),
                isConfirms && ((isApproved && styles.green) || styles.gray)
            )}
        >
            {activityCount}
        </div>
    )
}
