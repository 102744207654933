import React from 'react'
import regression from 'regression';

export const AvgLine = ({ maxValue, minValue, traitsAvgValues, maxAvgValue }) => {
    let equation = null;
    let startAvgY = 0;
    let endAvgY = 0;
    const points = (traitsAvgValues || []).map(item => [+item / maxAvgValue * 100 || 0, (item - minValue) / (maxValue - minValue) * 100]);

    if (points.length > 1) {
        equation = regression.linear(points).equation;
    }

    if (equation) {
        const { predict } = regression.linear(points);

        startAvgY = predict(0)[1];
        endAvgY = predict(100)[1];
    }

    return equation ? (
        <svg className="avg" viewBox="0 0 100 100" preserveAspectRatio="none">
            <path className="avg_line" d={`M0 ${100 - startAvgY} L100 ${100 - endAvgY}`}></path>
        </svg>
    ) : null;
};