import React from 'react';

import { Modal, Grid } from "semantic-ui-react";

const Instructions = props => (
    <Modal trigger={props.button} closeIcon>
        <div className='instructions'>
            <Grid>
                <Grid.Row textAlign='center' centered>
                    <h2>Instructions</h2>
                </Grid.Row>
                <Grid.Row centered>
                    <Grid.Column width={14}>
                        <p>You will be presented with 10 decisions. In each decision, you must choose which of two embryos to implant.
                        <strong> Each embryo has a high probability of having an incurable disease.</strong> You can not make anymore embryos, so you must choose one of the two shown.
                        At the end of the game, you will be presented with a summary of your choice preferences and how you compare to others.
                        <strong> There are no right or wrong answers.</strong></p>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    </Modal>
);

export default Instructions;
