import React, { PureComponent } from 'react'
import FileInput from './FileInput';
import { connect } from 'react-redux';
import ajv from 'ajv';
import * as jsonSchemas from '../../jsonSchemas';
const familyHistoryItems = [
    { id: 1, value: 0, text: 'No known' },
    { id: 2, value: 1, text: '1st order relatives' },
    { id: 3, value: 2, text: '2nd order relatives' }
]

class JsonDropRelative extends PureComponent {
    state = { demographic: undefined, family_history: undefined }
    handleDelete = () => {
        const { handleChange, file } = this.props;
        const { demographic = this.props.demographic, family_history = this.props.family_history } = this.state;
        handleChange({ file, toDelete: true, demographic, family_history });
    };

    handleDrop = files => {
        const { demographics, field } = this.props;
        if (!files.length) return;
        for (let i = 0; i < files.length; i++) {
            let file = files[i];

            let reader = new FileReader();
            reader.onload = () => {
                let text = reader.result;
                try {
                    let data = JSON.parse(text);
                    let { demographic, family_history } = data;

                    let validation = new ajv().compile(jsonSchemas[field]);
                    const valid = validation(data);
                    if (!valid) {
                        alert('Validation error\n\n' + validation.errors.map(e => `${(e.dataPath || jsonSchemas[field].title).replace('/', '')} ${e.message}`).join('\n'));
                        return;
                    }

                    const correctDemo = demographics.find(d => d.short_name.toLowerCase() === demographic.toLowerCase() || d.name.toLowerCase() === demographic.toLowerCase());
                    if (!correctDemo) {
                        alert(`${jsonSchemas[field].title} has unknown demographic ${demographic}, this file will be skipped, please add a valid demographic or change demographic for this file`);
                        return;
                    }
                    demographic = correctDemo.short_name;

                    this.props.handleChange({ demographic, family_history, file: file.name, data: text, toUpload: true, toDelete: false });
                    this.setState({ demographic: undefined, family_history: undefined });
                } catch (e) {
                    console.error(e);
                    alert('This file is not valid!');
                }
            }
            reader.readAsText(file);
        }
    };

    render() {
        let { demographic = this.props.demographic, family_history = this.props.family_history } = this.state;
        let { demographics } = this.props;
        let correctDemo = demographics.find(d => d.short_name === demographic);

        if (correctDemo) demographic = correctDemo.name;

        family_history = +family_history || 0;
        family_history = familyHistoryItems.find(h => (+h.value || 0) === family_history);

        const inputLabel = demographic ? demographic + (family_history ? `(${family_history.text})` : '') : 'Upload .json file';
        return (
            <div className="json-drop-relative">

                {demographic ?
                    <button onClick={this.handleDelete} className="clear-btn cross"></button> : null}
                <FileInput onChange={this.handleDrop} multiple={true} text={inputLabel} />

            </div>
        );
    }
};

const mapStateToProps = (store) => ({
    demographics: store.demographics
})

export default connect(mapStateToProps)(JsonDropRelative);