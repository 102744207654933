import React, { Component } from 'react';
import { connect } from 'react-redux';
import { saveGenomicIndex, removeGenomicIndex } from '../../../store/actions/genomic.index';
import { showPopup } from '../../../store/actions/popups';
import Dropdown from '../../common/Dropdown';
import GSBSGenerator from './GSBSGenerator';
import GSBSUploadWarning from './GSBSUploadWarning';
import { File } from './File';
import * as csv from 'csv-string';
import actions from "../../../store/actions";

const family_historyItems = [
    { id: 1, value: 0, text: 'No known' },
    { id: 2, value: 1, text: '1st order relatives' },
    { id: 3, value: 2, text: '2nd order relatives' }
]
class GenomicIndexTableRow extends Component {
    state = {
        gsbs: null,
        gain: null,
        diff: null,
        demographic: null,
        embryos: null,
        family_history: null,
        invalid_disease: [],
    }

    componentDidMount() {
        this.props.loadTraits();
    }

    gsbsFileInputRef = React.createRef();
    intervalRef = null;

    render() {
        let { gsbs, gain, diff, embryos, family_history, demographic } = this.state;
        const { demographics, id } = this.props;
        embryos = embryos || this.props.embryos;
        demographic = demographic || this.props.demographic;
        family_history = family_history === null ? this.props.family_history : family_history;
        let finalGsbs = gsbs || this.props.gsbs;
        let finalGain = gain || this.props.gain;
        let gsbsUploaded;
        let gainUploaded;
        if (!finalGsbs?.toDelete && this.props.gsbsUploaded) gsbsUploaded = new Date(this.props.gsbsUploaded);
        if (!finalGain?.toDelete && this.props.gainUploaded) gainUploaded = new Date(this.props.gainUploaded);

        let enableToSave = this.state.gsbs || this.state.gain || this.state.embryos || this.state.demographic || this.state.family_history !== null;
        if (id === 'new') enableToSave = (this.state.gsbs || this.state.gain) && this.state.embryos !== null && this.state.demographic && this.state.family_history !== null;

        return (
            demographics.length && <div className={`genomic-index_table_row new`}>
                <div className="genomic-index_table_cell id">{id}</div>
                <div className="genomic-index_table_cell embryos"><input type="number" min="0" max="10" step="1" value={embryos || ''} onChange={e => this.setState({ embryos: e.target.value })} /></div>
                <div className="genomic-index_table_cell demographic">
                    <Dropdown
                        items={demographics.map(i => ({ ...i, text: i.name, value: i.short_name, selected: i.short_name === demographic }))}
                        onChange={(value) => this.setState({ demographic: value })} />
                </div>
                <div className="genomic-index_table_cell family_history">
                    <Dropdown
                        items={family_historyItems.map(i => ({ ...i, selected: +i.value === +family_history }))}
                        onChange={(value) => this.setState({ family_history: value })} />
                </div>
                <div className="genomic-index_table_cell center gsbs">
                    {finalGsbs && !finalGsbs.toDelete && <a className="download_blob_link"
                        href={URL.createObjectURL(new Blob([finalGsbs.data], { type: 'text/csv' }))}
                        download="gsbs_result.csv">
                        Download gsbs file
                            </a>}
                    {diff && <a className="download_blob_link"
                        href={URL.createObjectURL(new Blob([diff.data], { type: 'text/csv' }))}
                        download="gsbs_differentials.csv">
                        Download differentials file
                        </a>}
                    <File file={finalGsbs} uploaded={gsbsUploaded} label="Upload gsbs.csv file" format=".csv" onChange={this.saveFile('gsbs')} ref={this.gsbsFileInputRef} />
                    {embryos && <>
                        or
                        <button type="button" onClick={this.showGSBSPopup}>Generate GSBS</button></>}

                </div>
                <div className="genomic-index_table_cell center gain">
                    {finalGain && !finalGain.toDelete && <a className="download_blob_link"
                        href={URL.createObjectURL(new Blob([typeof finalGain.data === 'string' ? finalGain.data : JSON.stringify(finalGain.data)], { type: 'text/json' }))}
                        download={finalGain.name}>
                        Download gain file
                            </a>}
                    <File file={finalGain} uploaded={gainUploaded} label="Upload gain file" format=".json" onChange={this.saveFile('gain', 'json')} />
                </div>
                <div className="genomic-index_table_cell actions">
                    <button className="cancel-btn"
                        disabled={!enableToSave}
                        onClick={this.saveIndex}>Save</button>
                    <br />
                    {id !== 'new' && <button className="delete_index" onClick={this.deleteIndex}>Delete</button>}
                </div>
            </div>
        )
    }

    showGSBSPopup = () => {
        let embryos = this.state.embryos || this.props.embryos;
        this.props.showPopup(<GSBSGenerator embryos={embryos} onGenerated={this.onGenerated} />)
    }

    showGSBSWarningPopup = () => {
        this.props.showPopup(
            <GSBSUploadWarning
                invalidTraits={this.state.invalid_disease}
                primaryAction={() => {this.gsbsFileInputRef.current.click()}}
            />,
            'Error uploading the GSBS file'
        );
    }

    onGenerated = ({ gsbs, diff }) => {
        this.setState({ gsbs, diff });
    }

    saveFile = (name, formatValidation = false) => (file) => {

        if (formatValidation && !file?.toDelete) {
            switch (formatValidation) {
                case 'json':
                    try {
                        JSON.parse(file.data);
                    } catch (e) {
                        alert(`File ${file?.name} has invalid ${formatValidation} format!`);
                        return;
                    }
                    break;
                default: break;
            }
        }

        if (name === 'gsbs' && !file?.toDelete) {
            this.setState({ invalid_disease: [] });

            try {
                let csvText = file.data + '';
                const parsedData = csv.parse(csvText);
                let json = [];
                if (parsedData.length > 1) {
                    let names = parsedData[0];
                    let neededNames = ['QALY_impact', 'bad_sibling', 'good_sibling', 'disease', 'RRR', 'lifespan_differential', 'pop_frequency', 'total_cases'];
                    for (let name of neededNames) {
                        if (!names.some(n => n.toLowerCase() === name.toLowerCase())) throw new Error('There is no all fields');
                    }
                    for (let i = 1; i < parsedData.length; i++) {
                        let obj = {};
                        parsedData[i].forEach((value, index) => {
                            obj[names[index]] = value
                        });
                        json.push(obj);
                    }
                    if (!this.isValidGSBS(json)) {
                        this.showGSBSWarningPopup();
                        return;
                    }

                    this.setState({ [name]: { name: file.name, data: csvText, json: JSON.stringify(json) } });
                    return;
                } else {
                    alert(`File ${file?.name} is empty!`);
                    return;
                }
            } catch (e) {
                console.error(e);
                alert(`File ${file?.name} has invalid format!`);
                return;
            }
        }
        this.setState({ [name]: { ...file } });

    }

    isValidGSBS = (jsonGSBS) => {
        const approvedTraits = this.props.traits.filter(({ approved }) => approved);
        jsonGSBS.forEach(({ disease }) => {
            const hasInApprovedTraits = approvedTraits.some(({ API_name }) => API_name === disease);
            if (!hasInApprovedTraits) {
                this.setState({ invalid_disease: [...this.state.invalid_disease, disease ]});
            }
        });

        return this.state.invalid_disease.length === 0;
    }

    deleteIndex = () => {
        this.props.removeGenomicIndex(this.props.id);
    }

    saveIndex = () => {
        const { id = 'new', ...old } = this.props;
        const model = { id }

        if (this.state.embryos !== null) model.embryos = this.state.embryos;
        if (this.state.demographic !== null) model.demographic = this.state.demographic;
        if (this.state.family_history !== null) model.family_history = this.state.family_history;

        if (this.state.gsbs) {
            model.gsbs = { ...this.state.gsbs };
            model.gsbsUploaded = model.gsbs?.toDelete ? null : new Date();
        }
        if (this.state.gain) {
            model.gain = { ...this.state.gain };
            model.gainUploaded = model.gain?.toDelete ? null : new Date();
        }

        const noGSBS = (!old.gsbs || model.gsbs?.toDelete) && !model.gsbs?.data;
        const noGAIN = (!old.gain || model.gain?.toDelete) && !model.gain?.data;

        if (noGAIN && noGSBS) {
            if (model.gsbs?.toDelete || model.gain?.toDelete) {
                if (id !== 'new') this.deleteIndex();
                return;
            }
        }
        this.props.saveGenomicIndex(model);
        this.setState({ gsbs: null, gain: null, demographic: null, family_history: null, embryos: null });
    }
}

const mapDispatchToProps = {
    saveGenomicIndex,
    removeGenomicIndex,
    showPopup,
    loadTraits: actions.traits.loadTraits
}

export default connect((store) => ({ demographics: store.demographics, traits: store.traits }), mapDispatchToProps)(GenomicIndexTableRow);
