export const RRR = {
    "title": "RRR Object",
    "type": "object",
    "properties": {
      "stats_array": {
        "type": "array",
        "items": {
            "type": "object",
            "properties": {
                "sibling_step": {
                    "type": "integer",
                    "description": "Sibling step",
                    "minimum": 1,
                    "maximum": 10
                },
                "sibling_step_n": {
                    "type": "integer",
                    "description": "Siblings count",
                    "minimum": 0
                },
                "frequency_random_sibling": {
                    "type": "number",
                    "minimum": 0,
                    "maximum": 1
                },
                "frequency_low_prs_sibling": {
                    "type": "number",
                    "minimum": 0,
                    "maximum": 1
                }
            },
            "required": ["sibling_step", "sibling_step_n", "frequency_random_sibling", "frequency_low_prs_sibling"] 
        }
      },
      "demographic": {
          "type": "string"
      },
      "family_history": {
        "type": "integer",
        "minimum": 0,
        "maximum": 2
      }
    },
    "required": ["stats_array", "demographic", "family_history"]
  }