import { takeLatest, call, put } from 'redux-saga/effects';
import { USERS_ACTIONS } from '../actions/constants';
import * as actions from '../actions/users';
import * as loadActions from '../actions/loading';
import api from '../../api/users';

function* loadUsers() {
    yield put(loadActions.startLoading('users'));
    try {
        let users = yield call(api.getUsers);
        yield put(actions.loadedUsers(users));
    } catch (err) {
        console.log(err);
    }
    yield put(loadActions.endLoading('users'));

}

function* deleteUser(action) {
    yield put(loadActions.startLoading('users'));

    try {
        let res = yield call(api.deleteUser, action.payload);
        if (res.status !== 200) throw new Error(res.data);
        yield put(actions.removedUser(action.payload));
    } catch (err) {
        console.log(err);
    }
    yield put(loadActions.endLoading('users'));

}

function* addUser(action) {
    yield put(loadActions.startLoading('users'));

    try {
        let user = yield call(api.createUser, action.payload);
        yield put(actions.addedUser(user));
    } catch (err) {
        console.log(err);
    }
    yield put(loadActions.endLoading('users'));

}

function* updateUser(action) {
    yield put(loadActions.startLoading('users'));

    try {
        let user = yield call(api.updateUser, action.payload);
        yield put(actions.updatedUser(user));
    } catch (err) {
        console.log(err);
    }
    yield put(loadActions.endLoading('users'));

}

export function* usersSaga() {
    yield takeLatest(USERS_ACTIONS.LOAD, loadUsers);
    yield takeLatest(USERS_ACTIONS.DELETE, deleteUser);
    yield takeLatest(USERS_ACTIONS.ADD, addUser);
    yield takeLatest(USERS_ACTIONS.UPDATE, updateUser);
}