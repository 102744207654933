import React from 'react';
import { connect } from 'react-redux';
import { metricsTooltips } from '../../utils';
import NumberInput from '../common/NumberInput';
import FormRowWithLink from './Form/FormRow/FormRowWithLink';

const fields = [
    { field: 'morbid_risk_general', name: 'Lifetime Risk', tooltip: metricsTooltips['morbid_risk_general'] },
    { field: 'morbid_risk', name: 'Lifetime Risk with a first-degree-relative', tooltip: metricsTooltips['morbid_risk'] }
]

const FrequencySection = ({ title, votes }) => {
    let items = [...votes];
    items.sort((a, b) => (a.name < b.name) ? 1 : -1);
    return (
        <div className="bordered_section">
            <div className="bordered_section-title">{title}</div>
            {items.map(i => <FormRowWithLink
                vote={i}
                key={i.field.name}
                placeholder="percent"
                tooltip={fields.find(f => f.field === i.field.name).tooltip}
            >
                <NumberInput
                    min={0}
                    max={100}
                    placeholder='Enter percent'
                    validationFieldName={fields.find(f => f.field === i.field.name).title}
                />
                <input type="text" placeholder='http(s)://' />
                <textarea type="text" placeholder="Paste Citation Here"></textarea>
            </FormRowWithLink>
            )}
        </div>
    );
};

const mapStateToProps = store => ({
    sex: store.sex
});

const mapDispatchToProps = {
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FrequencySection);