import { NEWVOTES_ACTIONS } from '../actions/constants';

export const newVotes = (state = [], action) => {
    switch (action.type) {
        case NEWVOTES_ACTIONS.FILLED: return action.payload;
        case NEWVOTES_ACTIONS.UPDATE:
            return state.map(v => v.id === action.payload.id ? { ...v, ...action.payload } : v);
        case NEWVOTES_ACTIONS.CLEAR: return [];
        default: return state;
    }
}
