import React, { useState } from 'react';
import s from './Vote.module.scss';
import { classList, maxNumOfMB } from '../../../utils';
import { connect } from 'react-redux';
import { DropZone } from '../../common';
import { ChangeVotePopupType } from './ChangeVotePopupTypes';
import actions from '../../../store/actions';

const ChangeVoteStatusPopup = props => {
    const { closePopup, changesType, handleSubmit } = props;
    const [validation, setValidation] = useState('');
    const [reason, setReason] = useState({
        text: '',
        file: '',
    });

    const handleReject = () => {
        if (reason.text.trim().length < 3) {
            return setValidation(`Textarea length should be more than 3 characters`);
        }

        if (reason.file.size > maxNumOfMB(10)) {
            return setValidation(`The size of the attached file must not exceed 10 MB.`);
        }

        handleSubmit(reason);
        closePopup();
    };

    return (
        <div className={classList(s.AddReasonVotePopup)}>
            <div className={s.PopupRow}>
                <label>Reason for {changesType === ChangeVotePopupType.EDIT ? 'edit' : 'rejection'}*</label>
                <div>
                    <textarea
                        value={reason.text}
                        onChange={e =>
                            setReason({
                                ...reason,
                                text: e.target.value,
                            })
                        }
                        placeholder={`Explain why this vote was ${changesType === ChangeVotePopupType.EDIT ? 'edited' : 'rejected'}...`}
                    />
                </div>
            </div>
            <div className={s.PopupDropzoneRow}>
                <label>Attachments*</label>
                <div className={s.DropzoneWrapper}>
                    <DropZone
                        field="vote_screenshot_pdf"
                        onDrop={file => setReason({ ...reason, file })}
                    />
                </div>
            </div>
            <div className={s.Validation}>{validation}</div>
            <div className={s.PopupVoteButtons}>
                <button
                    type="button"
                    className={changesType === ChangeVotePopupType.EDIT ? s.btn_edit : s.btn_rejected}
                    onClick={handleReject}
                >
                    {changesType === ChangeVotePopupType.EDIT ? 'Edit' : 'Reject'}
                </button>
                <button
                    type="button"
                    className={s.btn_cancel}
                    onClick={() => props.closePopup()}
                >
                    Cancel
                </button>
            </div>
        </div>
    );
};
const mapStateToProps = store => ({
    user: store.user,
});

const mapDispatchToProps = {
    closePopup: actions.popups.closePopup,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeVoteStatusPopup);
