import React from 'react';
import { WeightChart } from '../weight/charts';
import SelectTrait from '../weight/charts/controls/SelectTrait';
import { connect } from 'react-redux';

const Charts = (props) => {
    const charts = props.fields.filter(f => f.type !== 'List');

    return (
        <div className={`misery_weight submitted ${props.loading.length > 0 ? ' loading' : ''}`}>
            <SelectTrait placeholder="Select diseases for all charts" selectedTraits={props.selectedTraits} setSelectedTraits={props.setSelectedTraits} />
            <div className="misery_weight_submitted">
                {charts.map((data, ind) => {
                    return <WeightChart key={ind} field={data} ind={ind} commonTraits={props.selectedTraits} />
                })}
            </div>
        </div>
    );
};

const mapStateToProps = (store) => ({
    loading: store.loading,
    fields: store.voteFields
});

export default connect(mapStateToProps)(Charts);