import React from 'react';

export const GenomicIndexTableRowHead = (props) => (
    <div className={`genomic-index_table_row`}>
        <div className="genomic-index_table_cell id">id</div>
        <div className="genomic-index_table_cell embryos">Embryos</div>
        <div className="genomic-index_table_cell demographic">Genetic Ancestry</div>
        <div className="genomic-index_table_cell family_history">Family History</div>
        <div className="genomic-index_table_cell gsbs">GSBS</div>
        <div className="genomic-index_table_cell gain">GAIN</div>
        <div className="genomic-index_table_cell actions">Actions</div>
    </div>
)
