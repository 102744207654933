import React, { PureComponent } from 'react';

import AdministrationTableRow from './AdministrationTableRow';

import { Table } from 'semantic-ui-react'

export default class AdministrationTableBody extends PureComponent {
    render() {
        const { data, headerValues, handleChange } = this.props;

        return <Table.Body>
            {data.map(item => (
                <AdministrationTableRow
                    key={'row' + item.id}
                    item={item}
                    headerValues={headerValues}
                    handleChange={handleChange}
                />
            ))}
        </Table.Body>
    }
}