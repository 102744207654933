import React, { PureComponent } from 'react';

import { AdminHomeContainer } from '../adminHome';

import { Container, Card, Responsive } from 'semantic-ui-react'

class AdminHome extends PureComponent {
    render() {
        return (
            <Container>
                <div className='page-container'>
                    <h1>Administration</h1>
                    <Responsive maxWidth={959}>
                        <AdminHomeContainer />
                    </Responsive>
                    <Responsive minWidth={960}>
                        <Card raised className='card-container'>
                            <AdminHomeContainer />
                        </Card>
                    </Responsive>
                </div>
            </Container>
        );
    };
};

export default AdminHome;