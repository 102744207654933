import React, { useEffect, useRef, useState } from 'react';
import { classList } from '../../../utils';
import s from '../Ess.module.scss';
import table from '../../common/Table.module.scss';
import { ShowNumber, AUCPlaceholder } from '.';
import { Popup } from 'semantic-ui-react';
import actions from '../../../store/actions';
import { connect } from 'react-redux';
import { CEOApproval, CEOApprovalLinkPopup } from '../';

const EssRow = React.memo(({ versionId, saveAdditionalInfo, AUCRRRs, ...props }) => {
    const { model } = props;
    const [, setAucPlaceholder] = useState('There is no data for this AUC value');
    let inputRef = useRef();
    useEffect(() => {
        if (inputRef.current) {
            let newValue = typeof model.AUC === 'number' ? model.AUC : '';
            inputRef.current.value = newValue;
        }

        if (AUCRRRs.length) {
            let rrr = AUCRRRs.find(a => a.auc === Math.round(model.AUC) / 100);
            if (!rrr) {
                setAucPlaceholder('There is no data for this AUC value');
            } else {
                setAucPlaceholder('!!!!');
            }
        }

    }, [model.AUC, model.morbid_risk_general.common, model.morbid_risk.common, AUCRRRs])
    const saveAUC = (e) => {
        let AUC = e.target.value.trim();
        if (AUC !== '' && (typeof +AUC !== 'number' || isNaN(+AUC))) return;
        if (AUC !== '') {
            if (+inputRef.current.value === model.AUC) return;
            inputRef.current.value = model.AUC;
            if (+AUC < 0.5 || +AUC > 1) {
                props.showPopup('AUC value should be between 0.5 and 1', 'Incorrect AUC value');
                return;
            }
            props.showPopup(<CEOApprovalLinkPopup model={{ link: props.auc_link }} onSubmit={({ link }) => saveAdditionalInfo({
                AUC,
                auc_link: link,
                traitId: model.id,
                link: null,
                approved: null,
                auc_approved: null,
                auc_approval_link: null,
                versionId
            })} />, 'Please enter the link');
        } else {
            saveAdditionalInfo({ AUC, traitId: model.id, versionId, auc_link: null, auc_approved: null, auc_approval_link: null, link: null, approved: null });
        }
    }

    const saveAUCConfirmation = (approvalData) => {
        let data = {
            auc_approval_link: approvalData.link,
            auc_approved: approvalData.approved,
            versionId: approvalData.versionId,
            traitId: approvalData.traitId
        }

        saveAdditionalInfo(data);
    }

    return (
        <div className={table.Table__Row}>
            <div className={classList(table.Table__Column, s.Column_disease)}>
                {model.disease}
                <Popup
                    position='top right'
                    hoverable
                    trigger={
                        <div className={s.HelpIcon}></div>
                    }
                    content={
                        <div>
                            <p>{model.trait_text || ''}</p>
                        </div>
                    }
                    inverted
                />
            </div>
            <div className={classList(table.Table__Column, s.Column_number)}><ShowNumber value={model.life_span_impact_score} /></div>
            <div className={classList(table.Table__Column, s.Column_number)}><ShowNumber value={model.average_age_of_onset} /></div>
            <div className={classList(table.Table__Column, s.Column_number)}><ShowNumber value={model.life_quality_impact_score} /></div>
            <div className={classList(table.Table__Column, s.Column_number, s.Column_number_cost)}><ShowNumber value={model.life_treatment_cost_score} /></div>
            <div className={classList(table.Table__Column, s.Column_number)}><ShowNumber value={model.morbid_risk_general} /></div>
            <div className={classList(table.Table__Column, s.Column_number)}><ShowNumber value={model.morbid_risk} /></div>
            <div className={classList(table.Table__Column, s.Column_number)}><ShowNumber value={model.life_expectancy_with_disease} /></div>
            <div className={classList(table.Table__Column, s.Column_number)}><ShowNumber value={model.years_of_life_lost} /></div>
            <div className={classList(table.Table__Column, s.Column_number)}><ShowNumber value={model.survival_rate_in_five_years} /></div>
            <div className={classList(table.Table__Column, s.Column_number)}><ShowNumber value={model.general_population_burden} /></div>
            <div className={classList(table.Table__Column, s.Column_number, s.Column_number_AUC)}>
                {model.AUC !== null && (
                    <>
                        <AUCPlaceholder {...model} />
                        <CEOApproval className="auc" data={{ link: model.auc_approval_link, approved: model.auc_approved, versionId, traitId: model.id }} onSave={saveAUCConfirmation} />
                    </>
                    )}
                <input type="number"
                    className={s.AUC_input}
                    ref={inputRef}
                    onBlur={saveAUC} />
                {model?.auc_link && <a href={model.auc_link} className={classList('CEO_ApprovalLink')} rel="noopener noreferrer" target="_blank">{model.auc_link}</a>}
            </div>
            <div className={classList(table.Table__Column, s.Column_number, s.Column_numberCEO)}>
                <CEOApproval data={{ link: model.link, approved: model.approved, versionId, traitId: model.id }} onSave={saveAdditionalInfo} />
            </div>
        </div >
    )
})


const mapStateToProps = (store) => ({
    versionId: store.versions.find(v => v.selected)?.id,
    AUCRRRs: store.AUCRRRs
});

const mapDispatchToProps = {
    saveAdditionalInfo: actions.ess.saveAdditionalInfo,
    showPopup: actions.popups.showPopup
}
export default connect(mapStateToProps, mapDispatchToProps)(EssRow);