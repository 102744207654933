import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Button, Container, Icon } from 'semantic-ui-react'

import Instructions from './Instructions';

import './styles.css';

const DefaultPageCard = () => (
    <Container>
        <Grid>
            <Grid.Row>
                <Grid.Column textAlign='center'>
                    <h1>Acceptable Risks</h1>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row centered>
                <Grid.Column mobile={16} tablet={14} computer={16}>
                    <p>Embryo selection is rapidly becoming more complex. <strong>How should we choose?</strong></p>
                    <p>If you must choose between two embryos, each with a risk of disease, which risk do you choose above the other?</p>
                    <p>As an outside observer, it is your task to judge which risks you think are more acceptable. Then, you will see how your choices compare to those of others. You may also design your own dilemmas... and watch how others choose.</p>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row textAlign='center' centered>
                <Grid.Column width={16}>
                    <Grid>
                        <Grid.Row verticalAlign='middle' centered>
                            <Grid.Column mobile={16} tablet={10} computer={5} textAlign='center'>
                                <Button className='gradient disabled-button' fluid as={Link} to='/choose'>
                                    <Icon name='balance scale' />
                                    START
                                </Button>
                            </Grid.Column>
                            <Grid.Column mobile={16} computer={10} floated='right'>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column mobile={16} computer={8} textAlign='center' floated='right'>
                                            <Instructions button={
                                                <Button className='secondary-button' fluid>
                                                    <Icon name='book' />
                                                    READ RULES
                                                </Button>}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </Container>
);

export default DefaultPageCard;
