import React, { PureComponent } from 'react';
import { Route } from 'react-router-dom';
import App from '../app';

import { withAuth } from '../HOC';

export default class PrivateRoute extends PureComponent {

    render() {
        let { component: Component, role = 'admin' } = this.props;
        let props = {...this.props};
        if (Component) props = { ...props, component: withAuth(Component, role) };
        return (
            <App>
                <Route {...props} />
            </App>
        );
    }
};


