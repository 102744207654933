import React, { PureComponent } from 'react';
import styles from './Users.module.scss';
import { classList } from '../../utils';
import { EditUser } from '.';
import { removeUser } from '../../store/actions/users';
import actions from '../../store/actions';
import { connect } from 'react-redux';
import { Confirm } from '../common/Popup/Confirm';
class User extends PureComponent {
    state = { editing: false }
    render() {
        const { login, email, inactive, role, sort, totalVotes, confirmedVotes, rejectedVotes, notReviewedVotes } = this.props;
        return (<><div className={classList(styles.User, role === 'admin' && styles.User_Admin, inactive && styles.User_Inactive)}>
            <div className={classList(styles.User__Col, sort === 'login' && styles.User__Col_Sorted, styles.User__Col_User)}>{login}</div>
            <div className={classList(styles.User__Col, sort === 'email' && styles.User__Col_Sorted)} title={email || ''}>{email || 'N/A'}</div>
            <div className={classList(styles.User__Col, sort === 'role' && styles.User__Col_Sorted)}>{role || 'N/A'}</div>
            <div className={classList(styles.User__Col, sort === 'totalVotes' && styles.User__Col_Sorted)} >{totalVotes || 0}</div>
            <div className={classList(styles.User__Col, sort === 'confirmedVotes' && styles.User__Col_Sorted)} >{confirmedVotes || 0}</div>
            <div className={classList(styles.User__Col, sort === 'rejectedVotes' && styles.User__Col_Sorted)} >{rejectedVotes || 0}</div>
            <div className={classList(styles.User__Col, sort === 'notReviewedVotes' && styles.User__Col_Sorted)}>{notReviewedVotes || 0}</div>
            <div className={classList(styles.User__Col, sort === 'inactive' && styles.User__Col_Sorted, styles.User__Col_Status)}>{<div className={inactive ? styles.inactive : styles.active}>
                {inactive ? 'inactive' : 'active'}</div>}
            </div>
            <div className={classList(styles.User__Col, styles.User__Col_Actions)}>
                <button className={styles.Btn_Delete} onClick={this.deleteUser}>Delete</button>
                <button className={styles.EditBtn} onClick={this.editUser}>Edit</button>
            </div>
        </div>
            {this.state.editing && <EditUser onClose={this.onClose} {...this.props} />}
        </>)
    }

    onClose = () => this.setState({ editing: false });

    editUser = () => this.setState({ editing: true });

    deleteUser = () => {
        let { login, showPopup } = this.props;
        showPopup(<Confirm text={`Are you sure you want to delete ${login}?`} onConfirm={this.deleteUserConfirmed} />)
    }

    deleteUserConfirmed = () => {
        let { id } = this.props;
        this.props.removeUser(id);
    }
}

const mapDispatchToProps = {
    removeUser,
    showPopup: actions.popups.showPopup
}

export default connect(() => ({}), mapDispatchToProps)(User);