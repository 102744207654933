import React, { useState } from 'react';
import { connect } from 'react-redux';
import { classList, metricsTooltips } from '../../../utils';
import s from '../Ess.module.scss';
import actions from '../../../store/actions'
import table from '../../common/Table.module.scss';
import { EssRow } from '.';
import { Dropdown, TooltipIcon } from '../../common';
import './dropdown.scss';

const EssTable = React.memo((props) => {
    const { data = [], loading } = props;
    const sexes = [{ text: 'Both', value: 'common' }, { text: 'Male', value: 'male' }, { text: 'Female', value: 'female' }]
    const [sorting, changeSorting] = useState({ field: '', desc: false });

    const [fields, updateFields] = useState([
        { field: 'disease', name: 'Disease' },
        { field: 'life_span_impact_score', name: 'Lifespan Impact', sex: 'common', multivalue: true, tooltip: metricsTooltips['life_span_impact_score']},
        { field: 'average_age_of_onset', name: 'Average Age of Onset', sex: 'common', multivalue: true, tooltip: metricsTooltips['average_age_of_onset'] },
        { field: 'life_quality_impact_score', name: 'Life Quality Impact', sex: 'common', multivalue: true, tooltip: metricsTooltips['life_quality_impact_score'] },
        { field: 'life_treatment_cost_score', name: 'Lifetime Cost', sex: 'common', multivalue: true, tooltip: metricsTooltips['life_treatment_cost_score'] },
        { field: 'morbid_risk_general', name: 'Lifetime Risk', sex: 'common', multivalue: true, tooltip: metricsTooltips['morbid_risk_general'] },
        { field: 'morbid_risk', name: 'Lifetime Risk with a first-degree-relative', sex: 'common', multivalue: true, tooltip: metricsTooltips['morbid_risk'] },
        { field: 'life_expectancy_with_disease', name: 'Life expectancy with disease', sex: 'common', multivalue: true, tooltip: metricsTooltips['life_expectancy_with_disease'] },
        { field: 'years_of_life_lost', name: 'Years of life lost per death', sex: 'common', multivalue: true, tooltip: metricsTooltips['years_of_life_lost'] },
        { field: 'survival_rate_in_five_years', name: '5 Year Survival Rate', sex: 'common', multivalue: true, tooltip: metricsTooltips['survival_rate_in_five_years'] },
        { field: 'general_population_burden', name: 'General Population Burden (sometimes known as “Ground Truth”)', sex: 'common', multivalue: true, tooltip: 'Lifespan Impact * Lifetime Risk' },
        { field: 'AUC', name: 'AUC', multivalue: false, tooltip: 'AUC' },
        { field: 'approved', name: 'CEO Legal Use Approval', multivalue: false, tooltip: 'CEO Legal use Approval' }
    ]);

    const sort = (field) => () => {
        if (sorting.field === field) return changeSorting({ field, desc: !sorting.desc });
        changeSorting({ field, desc: false});
    };

    let sortedData = [...data];
    if (sorting.field) {
        let field = fields.find(f => f.field === sorting.field);
        sortedData.sort((a, b) => {
            let aValue = field.sex ? a[sorting.field][field.sex] : a[sorting.field];
            let bValue = field.sex ? b[sorting.field][field.sex] : b[sorting.field];

            if (typeof aValue === 'string' || typeof bValue === 'string') {
                aValue = aValue.toLowerCase();
                bValue = bValue.toLowerCase();
            }

            let result = 0;
            if (aValue === null || typeof aValue === 'undefined') aValue = -Infinity
            if (bValue === null || typeof bValue === 'undefined') bValue = -Infinity
            if (aValue < bValue) result = -1;
            if (aValue > bValue) result = 1;
            if (sorting.desc) result = result * -1;
            return result;
        })
    }

    const checkSorting = (field) => {
        let result = [];
        if (sorting.field === field) {
            result = result.concat(table.Table__Column_Sorting);
            if (sorting.desc) result = result.concat(table.Table__Column_Sorting_Desc);
        }
        return result;
    }

    return (
        <div className={classList(table.Table, loading && table.Table_loading)}>
            <div className={classList(table.Table__Row, table.Table__Row_Head, s.Head)}>
                {fields.map(f => (
                    <div className={
                        classList(
                            table.Table__Column,
                            table.Table__Column_Sortable,
                            ...checkSorting(f.field),
                            f.field === 'disease'
                                ? s.Column_disease
                                : s.Column_number,
                            s.Column_head,
                            f.field === 'AUC' && s.Column_number_AUC,
                            f.field === 'life_treatment_cost_score' && s.Column_number_cost
                        )}
                        key={f.field}
                        onClick={sort(f.field)}>
                        {f.name}
                        {f.multivalue && sorting.field === f.field && <Dropdown className="ess_field_sex-dropdown" items={sexes.map(i => ({ ...i, selected: i.value === f.sex }))} 
                        onChange={(sex) => updateFields(fields.map(ff => ({...ff, sex: ff.field === f.field ? sex : ff.sex})))} />}
                        {f.tooltip && <TooltipIcon content={<p>{f.tooltip}</p>} className="ess_tooltip" />}
                    </div>
                ))}
            </div>
            {sortedData.map(r => <EssRow key={r.disease + props.version} model={r} sorting={sorting} />)}
        </div>
    )
})

const mapStateToProps = (store) => ({
    data: store.ess,
    loading: store.loading,
    version: store.versions.find(v => v.selected)
})
const mapDispatchToProps = {
    saveVersion: actions.versions.saveVersion
}

export default connect(mapStateToProps, mapDispatchToProps)(EssTable);