import { TRAIT_ACTIONS } from './constants';

export const loadTraits = (params = { withNotApproved: false, num_traits: -1 }) => ({
    type: TRAIT_ACTIONS.LOAD,
    payload: params
});

export const saveTrait = (trait, noNotify = false) => ({
    type: TRAIT_ACTIONS.SAVE,
    payload: {trait, noNotify}
});

export const receiveTraits = (data) => ({
    type: TRAIT_ACTIONS.RECEIVE,
    payload: data
});

export const createTrait = (trait) => ({
    type: TRAIT_ACTIONS.CREATE_ONE,
    payload: trait
})


export const selectTrait = (trait) => ({
    type: TRAIT_ACTIONS.SELECT_ONE,
    payload: trait
})

export const changeTrait = (trait) => ({
    type: TRAIT_ACTIONS.CHANGE_ONE,
    payload: trait
})

export const updateTrait = (trait) => ({
    type: TRAIT_ACTIONS.UPDATE_ONE,
    payload: trait
})

export const updateNewTrait = (trait) => ({
    type: TRAIT_ACTIONS.UPDATE_NEW,
    payload: trait
})

export const refreshTrait = (trait) => ({
    type: TRAIT_ACTIONS.UPDATE,
    payload: trait
})

export const clearTrait = () => ({
    type: TRAIT_ACTIONS.CLEAR
})

export const clearAllTraits = () => ({
    type: TRAIT_ACTIONS.CLEAR_ALL
})

export const loadVoteScreenshot = (id, traitId, fieldName, fileKey) => ({
    type: TRAIT_ACTIONS.LOAD_VOTE_SCREENSHOT,
    payload: { id, traitId, fieldName, fileKey }
})

export const addVoteScreenshot = (id, traitId, fieldName, file) => ({
    type: TRAIT_ACTIONS.ADD_VOTE_SCREENSHOT,
    payload: { id, traitId, fieldName, file }
})

export const loadVoteHistory = (id, traitId) => ({
    type: TRAIT_ACTIONS.LOAD_VOTE_HISTORY,
    payload: { id, traitId }
})

export const receiveVoteHistory = ({id, traitId, history}) => ({
    type: TRAIT_ACTIONS.RECEIVE_VOTE_HISTORY,
    payload: { id, traitId, history }
})

export const loadTraitNames = () => ({
    type: TRAIT_ACTIONS.LOAD_TRAIT_NAMES
})

export const receiveTraitNames = (names) => ({
    type: TRAIT_ACTIONS.RECEIVE_TRAIT_NAMES,
    payload: {names}
})

export const requestRestoreTrait = (id) => ({
    type: TRAIT_ACTIONS.RESTORE_REQUEST,
    payload: id
});

export const requestApproveTrait = (id) => ({
    type: TRAIT_ACTIONS.APPROVE_REQUEST,
    payload: id
});