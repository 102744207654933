import React, { Component } from 'react';

import GenomicIndexContainer from '../GenomicIndex/GenomicIndexContainer';


class EditIndex extends Component {
    render() {
        return (
            <div className='page-container'>
                <GenomicIndexContainer />
            </div>
        );
    }
};

export default EditIndex;
