import React from 'react';
import {Line} from 'react-chartjs-2';
import styles from './ProgressGraph.module.scss';

const ProgressGraphChart = ({data, selectedDemographics, demographicsColorMap}) => {
    const getState = () => {
        if (!Object.keys(data).length) return {};

        const getDaysDifference = (date1, date2) => {
            const diffTime = Math.abs(date2 - date1);
            return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        }
        const datasets = [];
        const allDates = selectedDemographics.reduce(
            (acc, demoId) => [
                ...acc,
                ...((data[demoId] && data[demoId].map(date => new Date(date))) || [])],
            []);
        const maxDate = new Date(Math.max.apply(null, allDates));
        const minDate = new Date(Math.min.apply(null, allDates));
        let maxLength = 2;

        selectedDemographics.forEach(demoId => {
            const demographic = demographicsColorMap.find(demo => demo.id === demoId);
            maxLength = (data[demoId] && data[demoId].length > maxLength ? data[demoId].length : maxLength) || maxLength;
            datasets.push({
                label: demographic.name,
                fill: false,
                lineTension: 0,
                backgroundColor: demographic.color,
                borderColor: demographic.color,
                borderWidth: 5,
                steppedLine: true,
                pointHitRadius: 10,
                pointRadius: 0,
                data: (data[demoId] && data[demoId].map((demoData, index) => ({
                    x: demoData,
                    y: index + 1
                }))) || []
            })
        });

        const addSpacingDataset = () => {
            const difference = Math.round(getDaysDifference(maxDate, minDate) * 1.25);
            if (isNaN(difference)) return;
            const date = new Date(minDate)
            date.setDate(date.getDate() + difference);
            datasets.push({
                label: "padding",
                backgroundColor: 'rgba(0,0,0,0)',
                borderColor: 'rgba(0,0,0,0)',
                data: [
                    {
                        x: date,
                        y: Math.round(maxLength * 1.15)
                    }
                ]

            });
        }
        addSpacingDataset();

        return ({datasets});
    }
    const state = getState();

    return (
        <div className={styles.chart}>
            <Line
                data={state}
                options={{
                    legend: {
                        display: false,
                        position: 'right',
                    },
                    scales: {
                        yAxes: [{
                            ticks: {
                                min: 1,
                                callback: (value) => Number.isInteger(value) && value,
                                stepSize: 1
                            },
                            gridLines: {
                                drawOnChartArea: false
                            },
                        }],
                        xAxes: [{
                            type: 'time',
                            maxWidth: 0,
                            gridLines: {
                                drawOnChartArea: false,
                                borderWidth: 1
                            },
                            time: {
                                parser: 'YYYY-MM-DD',
                                unit: 'month',
                                displayFormats: {
                                    month: 'MMM / YY'
                                },
                            },

                        }]
                    }
                }}
            />
            <div className={styles.color_help}>
                {demographicsColorMap.map(demographic => (
                    <div className={styles.demographic_color}>
                        <div
                            className={styles.color_line}
                            style={{
                                backgroundColor: demographic.color,
                            }} />
                        <label>{demographic.name}</label>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default ProgressGraphChart;
