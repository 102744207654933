import React, {useEffect} from "react";
import StatisticContainer from "../../StatisticContainer";
import { IndexStatusTable } from "./IndexStatusTable";
import actions from "../../../../store/actions";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Loader from "../../../choose/Loader";

const IndexStatus = ({loading, traitActivityData, loadIndexStatusActivityTraitsData, voteFields, loadFields}) => {
    useEffect(() => {
        !voteFields.length && loadFields();
        loadIndexStatusActivityTraitsData();
    }, []);

    const getMetricsAndRegions = () => {
        const getVoteFieldMap = (targetMetrics) => {
            return targetMetrics.reduce((acc, cur) => ({...acc, [cur]: voteFields.find(field => field.name === cur).displayName}), ({}));
        }

        for (const traitActivity of traitActivityData) {
            for (const metric in traitActivity.metrics) {
                return [
                    getVoteFieldMap(Object.keys(traitActivity.metrics)),
                    Object.keys(traitActivity.metrics[metric])
                ];
            }
        }
    }

    const [metricsMap, regions] = voteFields.length && traitActivityData.length ? getMetricsAndRegions() : [null, null]

    return (
        <StatisticContainer activeTab="index_status">
            <IndexStatusTable traitActivityData={traitActivityData} metricsMap={metricsMap} regions={regions}/>
            {loading.length > 0 && <Loader />}
        </StatisticContainer>
    )
}

const mapStateToProps = (store) => ({
    voteFields: store.voteFields,
    loading: store.loading,
    traitActivityData: store.statistic.indexStatusTraits,
})

const mapDispatchToProps = {
    loadIndexStatusActivityTraitsData: actions.statistic.loadIndexStatusActivityTraitsData,
    loadFields: actions.voteFields.loadVoteFields
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(IndexStatus));
