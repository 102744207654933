export const SETTINGS_ACTIONS = {
    LOAD: 'SETTINGS_LOAD',
    RECEIVE: 'SETTINGS_RECEIVE',
    CHANGE: 'SETTINGS_CHANGE',
    CHANGED: 'SETTINGS_CHANGED'
}
export const loadSettings = (id) => ({
    type: SETTINGS_ACTIONS.LOAD,
    payload: id
})

export const receiveSettings = (setting) => ({
    type: SETTINGS_ACTIONS.RECEIVE,
    payload: setting
})

export const changeSettings = (setting) => ({
    type: SETTINGS_ACTIONS.CHANGE,
    payload: setting
})

export const changedSettings = (setting) => ({
    type: SETTINGS_ACTIONS.CHANGED,
    payload: setting
})