import React from 'react';
import styles from '../UserContributions.module.scss';
import {Popup} from "semantic-ui-react";
import {classList} from "../../../../../utils";

export const Header = () => (
    <div className={classList(styles.table, styles.table_header)}>
        <div className={classList(styles.row, styles.login_row)}>
            <div>Login</div>
        </div>
        <div className={classList(styles.row, styles.submitted_votes_row)}>
            <div>Submitted Votes</div>
            <Popup
                position="top right"
                hoverable
                trigger={
                    <div className={styles.help_icon}></div>
                }
                content={
                    <div>
                        <p>Votes submitted by this user</p>
                    </div>
                }
                inverted
            />
        </div>
        <div className={classList(styles.row, styles.user_confirmed_votes_row)}>
            <div>User Votes Confirmed</div>
            <Popup
                position="top right"
                hoverable
                trigger={
                    <div className={styles.help_icon}></div>
                }
                content={
                    <div>
                        <p>Votes submitted by this user and fully confirmed by others</p>
                    </div>
                }
                inverted
            />
        </div>
        <div className={classList(styles.row, styles.user_rejected_votes_row)}>
            <div>User Votes Rejected</div>
            <Popup
                position="top right"
                hoverable
                trigger={
                    <div className={styles.help_icon}></div>
                }
                content={
                    <div>
                        <p>Votes submitted by this user that have been rejected by another user</p>
                    </div>
                }
                inverted
            />
        </div>
        <div className={classList(styles.row, styles.confirmations_row)}>
            <div>Confirmations</div>
            <Popup
                position="top right"
                hoverable
                trigger={
                    <div className={styles.help_icon}></div>
                }
                content={
                    <div>
                        <p>Confirmations of other users votes made by this user</p>
                    </div>
                }
                inverted
            />
        </div>
        <div className={classList(styles.row, styles.rejections_row)}>
            <div>Rejections</div>
            <Popup
                position="top right"
                hoverable
                trigger={
                    <div className={styles.help_icon}></div>
                }
                content={
                    <div>
                        <p>Rejections of other users votes made by this user</p>
                    </div>
                }
                inverted
            />
        </div>
        <div className={classList(styles.row, styles.voted_edited_row)}>
            <div>Votes Edited</div>
            <Popup
                position="top right"
                hoverable
                trigger={
                    <div className={styles.help_icon}></div>
                }
                content={
                    <div>
                        <p>Other users votes that have been edited by this user</p>
                    </div>
                }
                inverted
            />
        </div>
    </div>
)
