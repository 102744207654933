import React from 'react';

import FieldsSection from './FieldsSection';

import { Grid, Button, Form } from 'semantic-ui-react';
import s from './Login.module.scss';
import { classList } from '../../../utils';
const LoginForm = props => (
    <Form onSubmit={props.handleSubmit} className={classList('', props.loading && s.loading)}>
        <Grid>
            <FieldsSection
                username={props.username}
                password={props.password}
                handleChange={props.handleChange}
                handleSubmit={props.handleSubmit}
            />
            
            <Grid.Row centered>
                <Grid.Column mobile={16} tablet={7} computer={6}>
                    <Button className='gradient' fluid>SUBMIT</Button>
                </Grid.Column>
            </Grid.Row>
            {props.error && <Grid.Row centered>
                <Grid.Column mobile={16} tablet={7} computer={6}>
                    <div className={s.validationError}>{props.error}</div>
                </Grid.Column>
            </Grid.Row>}
        </Grid>
    </Form>
);

export default LoginForm;
