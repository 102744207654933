import { REGIONS_ACTIONS } from './constants';


export const updateRegions = (data) => ({
    type: REGIONS_ACTIONS.UPDATE,
    payload: data
});

export const clearRegions = () => ({
    type: REGIONS_ACTIONS.UPDATE,
    payload: { value: null }
});