import { DMS } from 'aws-sdk';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { classList } from '../../../utils';
import { Dropdown } from '../../common';
import s from '../Regions.module.scss';
const Demographics = React.memo((props) => {
    const { model, onChange, demographics, setValue, warnings } = props;
    if (!model.demographics) model.demographics = [];
    const deleteDemo = (id) => {
        onChange({ ...model, demographics: model.demographics.filter(d => d.id !== id) });
    }
    const demosSummaryPercent = (model.demographics || []).map(d => +d?.regions_demo?.percent).reduce((sum, percent) => percent + sum, 0);
    const availableDemos = demographics.filter(d => !model.demographics.some(md => md.id === d.id)).map(d => ({
        value: d.id,
        text: d.name
    }));
    const onDemoValueChange = (value, demo) => {

        if (value + demosSummaryPercent - demo.regions_demo.percent > 100) {
            alert(`The percent mustn't be greater than ${100 - demosSummaryPercent}`);
            return;
        }
        onChange({
            ...model,
            demographics: model.demographics.map(d => ({
                ...d,
                regions_demo: {
                    ...d.regions_demo,
                    percent: d.id === demo.id ? value : d.regions_demo.percent
                }
            }))
        });
    }

    const addDemo = (id) => {
        let demo = demographics.find(d => d.id === id);
        onChange({
            ...model,
            demographics: model.demographics.concat({
                ...demo,
                regions_demo: {
                    demoId: demo.id,
                    regionId: model.id,
                    percent: 0
                }
            })
        });
    }

    return (
        <div className={s.EditRegion__Demographics}>
             <h4>Genomic Ancestry ({demosSummaryPercent}% total)</h4>
             <div className={s.RegionsTable__Row}>
                    <div className={classList(s.RegionsTable__Column, s.RegionsTable__Column_label)}>Genomic Ancestry source</div>
                    <div className={classList(s.RegionsTable__Column, s.RegionsTable__Column_sourceBig)}>
                        <input type="text" placeholder="Genomic Ancestry source" value={model.demos_source} onChange={e => setValue(e.target.value, 'demos_source')} />
                        {warnings.demos_source && <div className={s.Warning}>{warnings.demos_source}</div>}
                    </div>
                </div>

            <div className={classList(s.EditRegion__Demographics__List)}>
                {(model.demographics || []).map(d => <div key={d.id} className={s.EditRegion__Demographics__Item}>
                    <div><label>{d.name}</label></div>
                    <div><input min="0" max="100" type="number" value={d?.regions_demo?.percent} placeholder="Percent" onChange={e => onDemoValueChange(+e.target.value, d)} /></div>
                    <div><button className={s.EditRegion__Demographics__DeleteBtn} onClick={() => deleteDemo(d.id)}>Delete</button></div>
                </div>)}

            </div>
            {demosSummaryPercent < 100 && <div className={s.EditRegion__Demographics__Item}>
                <label>Add Genomic Ancestry: </label><Dropdown className={s.Dropdown} items={availableDemos} onChange={(id) => addDemo(id)} />
            </div>}
        </div>
    )
})

const mapStateToProps = (store) => ({
    data: store.adminRegions,
    demographics: store.demographics
})

export default connect(mapStateToProps)(Demographics);
