import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';

import ControlHeader from './ControlHeader';
import ResearchForm from './ResearchForm';
import validationSchema from './validation';
import { parseMedlineDate } from '../../utils';
import { loadResearchItem, updateResearchItem } from '../../store/actions/research';
import { loadAllAuthors } from '../../store/actions/authors';
import { saveSettings } from '../../api/settings';
import s from './Research.module.scss';

const EditResearchItem = ({ history, match, research, submitted, allAuthors }) => {
    const [initialValues, setInitialValues] = useState({});
    const dispatch = useDispatch();
    useEffect(() => {
        let researchItem = research.find(i => i.id === +match.params.id);
        if (!researchItem) dispatch(loadResearchItem(match.params.id));
    }, [match.params.id]);
    useEffect(() => {
        if (!allAuthors.length) dispatch(loadAllAuthors());
    }, []);

    useEffect(() => {
        let authors = [];
        let researchItem = research.find(i => i.id === +match.params.id);
        if (!researchItem) return;
        if (initialValues && initialValues.id === researchItem.id) return;
        if (researchItem.authors?.length) authors = researchItem.authors.map(a => a.name);
        setInitialValues({ ...researchItem, authors, publicationDate: researchItem.publicationDate ? new Date(researchItem.publicationDate) : null });
    }, [research]);

    const handleSubmit = async values => {
        if (values.pmid === '') values.pmid = null;
        if (values.dp) {
            values.publicationDate = parseMedlineDate(values.dp);
            if (isNaN(values.publicationDate.getTime())) {
                alert('Invalid publication date!');
                return;
            }
        }
        dispatch(updateResearchItem({ ...values, id: match.params.id }));
    };

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            isInitialValid={false}
            onSubmit={handleSubmit}
        >
            {props => (
                <div className={s.EditPage}>
                    <ControlHeader title="Edit Research Item" isNew={false} sectionType="research" showCreateButton={true} handleSubmit={() => props.submitForm(props.values)} />
                    <div className="admin-table-wrapper">
                        <ResearchForm {...props} allAuthors={allAuthors} />
                    </div>
                </div>
            )}
        </Formik>
    );
};

const mapStateToProps = state => ({
    research: state.research,
    allAuthors: state.authors
});

export default compose(
    withRouter,
    connect(mapStateToProps)
)(EditResearchItem);