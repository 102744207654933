import React, { PureComponent } from 'react';
import { Route } from 'react-router-dom';
import App from '../app';

export default class CustomRoute extends PureComponent {

    render() {
        let props = {...this.props};
        return (
            <App>
                <Route {...props} />
            </App>
        );
    }
};


