import React from 'react';
import { connect } from 'react-redux';
import actions from '../../../store/actions';
import { classList } from '../../../utils';
import s from '../Regions.module.scss';
import { Confirm } from '../../common/Popup/Confirm';
import EditRegion from './EditRegion';

const RegionRow = React.memo((props) => {
    const { model, showPopup, deleteRegion } = props;

    const onDeleteRegion = () => showPopup(<Confirm onConfirm={() => deleteRegion(model.id)} text={`Are you sure you want to delete ${model.name} region?`} />);
    const onEditRegion = () => showPopup(<EditRegion model={model} />, `Edit ${model.name} Region`)
    return (
        <div className={classList(s.RegionsTable__Row, !model.coordinates && s.RegionsTable__Row_withoutCoordinates)} title={model.coordinates ? '' : `Please specify coordinates for this region`}>
            <div className={classList(s.RegionsTable__Column, s.RegionsTable__Column_id)}>{model.id}</div>
            <div className={classList(s.RegionsTable__Column, s.RegionsTable__Column_name)}>{model.name}</div>
            <div className={classList(s.RegionsTable__Column, s.RegionsTable__Column_shortName)}>{model.short_name}</div>
            <div className={classList(s.RegionsTable__Column, s.RegionsTable__Column_demos)}>
                {model.demographics && <a href={model.demos_source || ''} target="_blank" rel="noopener noreferrer" title="Click to Open Source">
                    {model.demographics.filter(d => d.regions_demo?.percent).map(d => <div key={d.id}>{d.name} - {d.regions_demo.percent}%</div>)}
                </a>}
            </div>
    <div className={classList(s.RegionsTable__Column, s.RegionsTable__Column_population)}>{model.population !== null && <a href={model.population_source || ''} target="_blank" rel="noopener noreferrer" title="Click to Open Source">{model.population}</a>}</div>
            <div className={classList(s.RegionsTable__Column, s.RegionsTable__Column_births)}>{model.births  !== null && <a href={model.population_source || ''} target="_blank" rel="noopener noreferrer" title="Click to Open Source">{model.births}</a>}</div>
            <div className={classList(s.RegionsTable__Column, s.RegionsTable__Column_actions)}>
                <button className={s.EditRegionBtn} onClick={onEditRegion}>Edit</button>
                <button className={s.RemoveRegionBtn} onClick={onDeleteRegion}>Delete</button>
            </div>
        </div>
    )
})

const mapStateToProps = (store) => ({
    data: store.adminRegions,
    loading: store.loading
})

const mapDispatchToProps = {
    showPopup: actions.popups.showPopup,
    deleteRegion: actions.adminRegions.removeRegion
}

export default connect(mapStateToProps, mapDispatchToProps)(RegionRow);