import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RegionsTable } from './RegionsTable';
import s from './Regions.module.scss';
import actions from '../../store/actions';
import { classList, getCountryData } from '../../utils';
import EditRegion from './RegionsTable/EditRegion';
import { ComposableMap, Geographies, Geography, Marker } from 'react-simple-maps';
import './map.scss';
import config from '../../config';
import { MarkerIcon } from '../common/Map/MarkerIcon';

const Regions = React.memo((props) => {
    useEffect(() => {
        props.loadRegions();
        props.loadDemographics();
    }, []);
    const [zoom, setZoom] = useState(1);

    const countryClick = (geo) => () => {
        let data = getCountryData(geo);
        let existingRegion = props.regions.filter(r => r.coordinates).find(r => r.coordinates[0] === data.coordinates[0] && r.coordinates[1] === data.coordinates[1]);
        if (!existingRegion) {
            props.showPopup(<EditRegion preCreationData={data} />, 'Add new region');
        } else {
            props.showPopup(<EditRegion model={existingRegion} />, `Edit ${existingRegion.name} Region`);
        }
    } 
    
    return (<div className={classList(s.RegionsPage)}>
        <h1>Regions</h1>
        <div className="regions-container-wrapper">
        <div className="regions-container">
            <ComposableMap>
                    <Geographies geography={require("../../assets/world-countries.json")}>
                        {({ geographies }) =>
                            geographies.map(geo => <Geography key={geo.rsmKey} geography={geo} onClick={countryClick(geo)} />)
                        }
                    </Geographies>
                    {props.regions.filter(r => r.coordinates).map(r => <Marker coordinates={r.coordinates} key={r.id} onClick={() => props.showPopup(<EditRegion model={r} />, `Edit ${r.name} Region`)}>
                        <MarkerIcon  className={classList('rsm-marker-icon')} width={10 / zoom} height={15 / zoom} x={-5 / zoom} y={-15 / zoom} />
                    </Marker>)}
            </ComposableMap>
        </div>
        </div>
        <button className={s.AddRegionBtn} onClick={() => props.showPopup(<EditRegion />, 'Add new region')}>Add Region</button>
        <RegionsTable />
    </div>
    )
});
const mapStateToProps = (store) => ({
    regions: store.adminRegions
})

const mapDispatchToProps = {
    loadRegions: actions.adminRegions.loadRegions,
    loadDemographics: actions.demographics.loadDemographics,
    showPopup: actions.popups.showPopup
}
export default connect(mapStateToProps, mapDispatchToProps)(Regions);