import React, { memo, useEffect, useState, Fragment } from 'react';
import s from '../Ess.module.scss';
import { Popup } from 'semantic-ui-react';
import { toFixed as clearToFixed } from '../../../utils';
import { connect } from 'react-redux';

const AUCPlaceholder = ({ AUC, morbid_risk, morbid_risk_general, RRRs, disease, sexed }) => {
    let [enoughData, setEnoughData] = useState(false);
    let [rrr, setRrr] = useState(null);
    let [risk, setRisk] = useState(null);
    let [riskGeneral, setRiskGeneral] = useState(null);
    const toFixed = (x, pow = 2) => {
        let result = clearToFixed(x, pow);
        if (result === '0.00' && x !== 0) {
            return x > 0 ? 0.01 : -0.01;
        }
        return result;
    }

    useEffect(() => {
        let newRisk, newRiskGeneral;
        switch (sexed) {
            case 'f':
                newRisk = morbid_risk.female;
                newRiskGeneral = morbid_risk_general.female;
                break;
            case 'm':
                newRisk = morbid_risk.male;
                newRiskGeneral = morbid_risk_general.male;
                break;
            default:
                newRisk = morbid_risk.common;
                newRiskGeneral = morbid_risk_general.common;
                break;
        }

        if (risk !== newRisk) setRisk(newRisk);
        if (riskGeneral !== newRiskGeneral) setRiskGeneral(newRiskGeneral);

    }, [morbid_risk, morbid_risk_general, sexed, risk, riskGeneral]);
    useEffect(() => {
        if (typeof AUC !== 'number' || AUC < 0.5 || AUC > 1) {
            if (enoughData) setEnoughData(false);
            return;
        }


        if (typeof risk !== 'number' || typeof riskGeneral !== 'number') {
            if (enoughData) setEnoughData(false);
            return;
        }

        let rrr = RRRs.find(r => r.auc === Math.round(AUC * 100) / 100);

        if (!rrr) {
            if (enoughData) setEnoughData(false);
            return;
        }

        if (!enoughData) setEnoughData(true);
        setRrr(rrr.rrr);
    }, [AUC, risk, riskGeneral, RRRs])

    let general, family;

    if (enoughData) {
        general = toFixed(riskGeneral, 2);
        family = toFixed(risk, 2);
    }

    return (
        <div className={s.Utility_Block}>
            Utility <Popup
                position='top right'
                hoverable
                trigger={
                    <div className={s.HelpIcon}></div>
                }
                content={
                    <div>
                        {enoughData ? <p style={{ fontSize: 12, whiteSpace: 'pre', overflowY: 'auto', maxHeight: '70vh' }}>
                            {disease} genomic selection performance, using a predictor with an AUC of {AUC}
                            {rrr.map((rrr, i) => {
                                let reducedGeneralRisk = (1 - rrr[1]) * riskGeneral;
                                let reducedFamilyRisk = (1 - rrr[1]) * risk;
                                return i < 1 ? <Fragment key={`${i}${rrr.join('_')}`}>
                                    <br /><br />
                                    For a family from the <b>general population</b>, where lifetime risk of {disease} is {general}%, 
                                    choice of the <b>1 of {(i + 1) * 2}</b> embryos with the lowest {disease} PRS
                                    <br />(in stead of selecting an embryo by chance) will reduce risk from {general}% to {toFixed(reducedGeneralRisk, 2)}%, on average.
                                    This is a relative risk reduction <b>(RRR) of {toFixed(rrr[1] * 100, 2)}%</b>, and an absolute risk reduction <b>(ARR) of {toFixed(general - reducedGeneralRisk, 2)}%</b>.
                                    <br /><br />

                                    For a family from the <b>“family history” population</b>, where lifetime risk of {disease} is {family}%,
                                    choice of the <b>1 of {(i + 1) * 2}</b> embryos with the lowest {disease} PRS
                                    <br />(in stead of selecting an embryo by chance) will reduce risk from {family}% to {toFixed(reducedFamilyRisk, 2)}%, on average.
                                    This is a relative risk reduction <b>(RRR) of {toFixed(rrr[1] * 100, 2)}%</b>, and an absolute risk reduction <b>(ARR) of {toFixed(family - reducedFamilyRisk, 2)}</b>.
                                    <br /><br />The <b>“family history” population is defined</b> by a first degree relative diagnosed with the disorder: a sibling or a parent.
                                </Fragment> : <Fragment key={`${i}${rrr.join('_')}`}>
                                    <br /><br />
                                    <b>General population: </b> Choosing the lowest PRS <b>1 of {(i + 1) * 2}</b> embryos will reduce absolute lifetime risk from {general}% to {toFixed(reducedGeneralRisk, 2)}%.
                                    <b> RRR: {toFixed(rrr[1] * 100, 2)}%</b> ({toFixed(rrr[2] * 100, 2)}%-{toFixed(rrr[0] * 100, 2)}%). <b>ARR: {toFixed(general - reducedGeneralRisk, 2)}%</b>
                                    <br />
                                    <b>“Family History” population: </b> Choosing the lowest PRS <b>1 of {(i + 1) * 2}</b> embryos will reduce absolute lifetime risk from {family}% to {toFixed(reducedFamilyRisk, 2)}%.
                                    <b> RRR: {toFixed(rrr[1] * 100, 2)}%</b> ({toFixed(rrr[2] * 100, 2)}%-{toFixed(rrr[0] * 100, 2)}%). <b>ARR: {toFixed(family - reducedFamilyRisk, 2)}%</b>
                                </Fragment>
                            })}

                        </p> : <p>There is not enough data for showing placeholder</p>}
                    </div>
                }
                inverted
            />
        </div>
    )
}

export default connect((store) => ({ RRRs: store.AUCRRRs }))(memo(AUCPlaceholder));