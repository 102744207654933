import { LOADING_ACTIONS } from '../actions/constants';

export const loading = (state = [], action) => {
    switch (action.type) {
        case LOADING_ACTIONS.START: return (state || []).concat(action.payload);
        case LOADING_ACTIONS.END: return (state || []).filter(i => i !== action.payload);
        case LOADING_ACTIONS.START_VOTE_LOADING: return (state || []).concat(action.payload);
        case LOADING_ACTIONS.END_VOTE_LOADING: return (state || []).filter(i => i !== action.payload);
        default: return state;
    }
}  