import React, { memo, useState } from 'react';
import './Dropdown.scss';
import { classList } from '../../utils';

const Dropdown = memo(({ items = [], onChange, className = '', autoComplete = false, multiple = false, placeholder = '' }) => {
    const toggleView = (e, to = null) => {
        setExpanded(to === null ? !expanded : to);
        if (to) setFilter('');
    }

    let [expanded, setExpanded] = useState(false);
    let [filter, setFilter] = useState('');

    if (filter) items = items.filter(i => ~i.text.toLowerCase().indexOf(filter.trim().toLowerCase()));
    let activeItem = items.find(i => i.selected);
    let ddText = activeItem ? activeItem.text : '';
    if (multiple) {
        activeItem = items.filter(i => i.selected);
        ddText = activeItem.map(i => i.text).join(', ');
    }
    if (filter) ddText = filter;

    return <div className={classList('dropdown', expanded && 'expanded', className)} onClick={e => e.stopPropagation()}>
        <div className={classList('dropdown_title', autoComplete && 'dropdown_title__autoComplete')} onClick={toggleView}>
            {autoComplete ? (
                <input className="dropdown_input" value={ddText} onChange={e => setFilter(e.target.value)} />)
                : <>
                    {placeholder && <span className="dropdown_placeholder">{placeholder}</span>}
                    {ddText}
                </>}
        </div>
        <div className="dropdown_list">
            {items.map(i => <div className={classList('dropdown_list_item', i.selected && 'dropdown_list_item__selected')} key={i.value} onClick={e => {
                e.stopPropagation();
                onChange(i.value);
                if (!multiple) setExpanded(false);
                setFilter('');
            }}>
                <div className="dropdown_list_item_title">{i.text}</div>
            </div>)}
        </div>
        <div className="dropdown_fake_background" onClick={e => toggleView(e, false)}></div>
    </div>
});

export default Dropdown;