import React, {useEffect, useReducer, useState} from 'react';
import StatisticContainer from "../../StatisticContainer";
import ProgressGraphSelectors from "./ProgressGraphSelectors";
import actions from "../../../../store/actions";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Loader from "../../../choose/Loader";
import ProgressGraphChart from "./ProgressGraphChart";

const ProgressGraph = ({loading, progressGraphData, loadProgressGraphData}) => {
    const demographics = [
        {name: "Do Not Know/All", color: "#6D6D6D", id: 8},
        {name: "European", color: "#FF9B9B", id: 1},
        {name: "South Asian", color: "#58C900", id: 4},
        {name: "East Asian", color: "#E9D100", id: 3},
        {name: "African", color: "#6B8CFF", id: 2},
        {name: "American Native", color: "#D765FF", id: 6},
    ];

    const typeOfVotes = [
        {text: "Confirmed Votes", value: "confirmed"},
        {text: "Rejected Votes", value: "rejected"},
        {text: "Submitted Votes", value: "submitted"},
    ];

    const targetVoteFields = [
        "life_span_impact_score",
        "average_age_of_onset",
        "morbid_risk_general",
        "life_expectancy_with_disease",
        "life_quality_impact_score"
    ];

    const [selectedState, updateSelectedProperty] = useReducer(
        (selectedState, val) => ({...selectedState, ...val}),
        {
            metricId: 1,
            lookingFor: "confirmed",
            selectedDemographics: [8]
        },
        initialValue => initialValue
    );

    useEffect(() => {
        loadProgressGraphData(selectedState.metricId, selectedState.lookingFor);

    }, [selectedState.metricId, selectedState.lookingFor]);

    return (
        <StatisticContainer activeTab="progress_graph">
            <ProgressGraphSelectors
                demographics={demographics}
                selectedState={selectedState}
                updateSelectedProperty={updateSelectedProperty}
                lookingForOptions={typeOfVotes}
                targetMetrics={targetVoteFields}
            />
            <ProgressGraphChart
                data={progressGraphData}
                selectedDemographics={selectedState.selectedDemographics}
                demographicsColorMap={demographics}
            />
            {loading.length > 0 && <Loader />}
        </StatisticContainer>
    )
}


const mapStateToProps = (store) => ({
    loading: store.loading,
    progressGraphData: store.statistic.progressGraph
})

const mapDispatchToProps = {
    loadProgressGraphData: actions.statistic.loadProgressGraphData
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProgressGraph));
