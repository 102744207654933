import { put, takeLatest, call } from 'redux-saga/effects';
import { SEX_ACTIONS } from '../actions/constants';
import * as sexActions from '../actions/sex';
import * as loading from '../actions/loading';
import * as api from '../../api/sex';

function* loadSex() {
    yield put(loading.startLoading());
    try {
        let sex = yield call(api.load);
        yield put(sexActions.receiveSex(sex));
    } catch(e) {
        console.error(e);
    }
    yield put(loading.endLoading());
}

export function* sexSaga() {
    yield takeLatest(SEX_ACTIONS.LOAD, loadSex);
}