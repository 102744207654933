import React, { Component } from 'react';

import UsersContainer from '../Users/UsersContainer';


class Users extends Component {
    render() {
        return (
            <div className='page-container'>
                <UsersContainer />
            </div>
        );
    }
};

export default Users;