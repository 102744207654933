import React, { memo } from "react";
import s from './Popup.module.scss';

export const Confirm = memo(({text, closePopup, onConfirm}) => {
    const confirm = () => {
        onConfirm();
        closePopup();
    }

    return (<div className={s.Confirm}>
        <div className={s.Confirm__Text}>{text}</div>
        <div className={s.Confirm__Buttons}>
            <button type="button" className={s.Confirm__Buttons_Yes} onClick={confirm}>Yes</button>
            <button type="button" className={s.Confirm__Buttons_No} onClick={closePopup}>No</button>
        </div>
    </div>
)});