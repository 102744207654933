import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, NewRow } from './Table';
import './TraitsCategories.scss';
import { loadCategories } from '../../store/actions/categories';
class TraitsCategories extends Component {
    state = { status: '' }
    headData = {
        id: 'id',
        name: 'Name',
        description: 'Description',
        icon: 'Category Icon',
        icon_nocircle: 'Category Icon without circle',
        actions: 'Actions',
    }
    render() {
        let { data = [], traits = [] } = this.props;
        data.sort((a, b) => b.id - a.id);
        return <div className={`TraitsCategories_page${this.props.loading.length > 0 ? ' loading' : ''}`} data-status={this.state.status}>
            <h1>Trait Categories</h1>
            <div className="edit-traits_container">
                <div className="TraitsCategories_table">
                    <Row {...this.headData} className="head" />

                    {data.map(i => <Row
                        key={i.id}
                        {...i}
                        actions={{
                            edit: true,
                            delete: true
                        }} />)}

                    <NewRow changeLoadingStatus={this.changeLoadingStatus} />
                </div>
            </div>
        </div>
    }
    componentDidMount() {
        this.props.loadCategories();
    }

    changeLoadingStatus = (loading, status = '') => this.setState({ loading, status });
}
const mapStateToProps = (store) => ({
    data: store.categories,
    traits: store.traits,
    loading: store.loading
})

const mapDispatchToProps = {
    loadCategories
}
export default connect(mapStateToProps, mapDispatchToProps)(TraitsCategories);