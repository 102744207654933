import axios from 'axios';
import config from '../config';
import moment from 'moment';

const publicUserURL = config.API + 'public/user/';
const adminUserURL = config.API + 'admin/user/';
const publicUserApi = axios.create({ baseURL: publicUserURL });
const adminUserApi = axios.create({ baseURL: adminUserURL });

const publicSessionURL = config.API + 'public/session/';
const publicSessionApi = axios.create({ baseURL: publicSessionURL });

export default {

    getSessionId: function () {
        return localStorage['sessionID'];
    },

    getOffset: function () {
        return localStorage['offset'];
    },

    getInterestingOffset: function () {
        return localStorage['interestingOffset'];
    },

    setSessionData: function (session) {
        localStorage.setItem('sessionID', session.id);
        localStorage.setItem('offset', 0);
    },

    setOffset: function (offset) {
        localStorage.setItem('offset', offset, { path: "/", expires: moment().add(100, "days").toDate() });
    },

    setInterestingOffset: function (offset) {
        localStorage.setItem('interestingOffset', offset, { path: "/", expires: moment().add(100, "days").toDate() });
    },

    getToken: function () {
        return localStorage['token'];
    },

    setToken: function (token) {
        localStorage.setItem('token', token, { path: "/", expires: moment().add(100, "days").toDate() });
    },

    getPrivacyCookie: function () {
        return localStorage['private'];
    },

    setPrivacyCookie: function () {
        localStorage.setItem('private', true);
    },

    createUser: function (data) {
        return adminUserApi.put('/', data).then(res => res.data);
    },

    updateUser: function (data) {
        return adminUserApi.put(`/${data.id}`, data).then(res => res.data);
    },

    getUsers: function() {
        return adminUserApi.get('/').then(res => res.data);
    },

    deleteUser: function(id) {
        return adminUserApi.delete(`/${id}`);
    },

    getUser: function (ip) {
        return publicUserApi.post('get_user', { IP_address: ip })
    },

    getUserBySession: function () {
        const sessionId = localStorage['sessionID'];

        return adminUserApi.get(`/${sessionId}`)
    },

    submitSurvey: function (data) {
        const userId = localStorage['user']?.id;
        return adminUserApi.post('submit_survey', { ...data, userId });
    },

    checkSession: function () {
        const sessionId = localStorage['sessionID'];
        return publicSessionApi.post('check', { sessionId });
    }
}

