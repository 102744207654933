import axios from 'axios';
import config from '../config';

const ADMIN_URL = config.API + 'admin/regions';
const adminApi = axios.create({ baseURL: ADMIN_URL });

export const load = () => adminApi.get('').then(r => r.data);
export const update = (data) => adminApi.post(`/${data.id}`, data).then(r => r.data);
export const add = (data) => adminApi.put('', data).then(r => r.data);
export const remove = (id) => adminApi.delete(`/${id}`);

