import { VOTES_ACTIONS, NEWVOTES_ACTIONS } from '../actions/constants';

export const votes = (state = [], action) => {
    switch (action.type) {
        case VOTES_ACTIONS.RECEIVE:
            if (action.payload.length < 1) return state;
            const {traitId} = action.payload[0];
            if (action.append) return state.filter(v => {
                let sameNewVote = action.payload.find(n => n.id === v.id);
                if (v.new && sameNewVote) sameNewVote.new = true;
                return !sameNewVote; 
            }).concat(action.payload);
            let filteredVotes = state.filter(v => v.traitId !== traitId || v.new);
            let votes = [...filteredVotes, ...action.payload.filter(v => !filteredVotes.some(f => f.id === v.id))];
            return votes;
        case VOTES_ACTIONS.UPDATE: return state.map(v => vote(v, action));
        case VOTES_ACTIONS.CLEAR: return [];
        case VOTES_ACTIONS.DELETED: return state.filter(v => v.id !== action.payload);
        case NEWVOTES_ACTIONS.CLEAR: return state.filter(v => !v.new);
        default: return state; 
    }
}

const vote = (state = {}, action) => {
    switch (action.type) {
        case VOTES_ACTIONS.UPDATE:
            if (state.id === action.payload.oldId || state.id === action.payload.id) {
                return {
                    ...state,
                    ...action.payload
                }
            }
            return state;

        default: return state;
    }
}

