import React, {useReducer, useState} from "react";
import styles from './IndexStatus.module.scss';
import {IndexStatusHeader} from "./table/IndexStatusHeader";
import TraitRow from "./table/TraitRow";
import {Popup} from "semantic-ui-react";

const [minCount, maxCount] = [0, 15];

export const IndexStatusTable = ({traitActivityData, metricsMap, regions}) => {
        const [diseasesOnly, setDiseasesOnly] = useState(true);
        const [confirmationsToCount, setConfirmationsToCount] = useReducer(
            (value, newValue) => {
                if (isNaN(+newValue)) return minCount;
                if (minCount > +newValue) return minCount;
                if (+newValue > maxCount) return maxCount;
                return +newValue;
            },
            1,
            initialValue => initialValue
        );

        const toggleDiseasesOnly = (e) => {
                setDiseasesOnly(e.target?.checked);
        };

        const getTraitsActivityData = () => {
            return diseasesOnly
                ? traitActivityData.filter(trait => trait.is_part_of_future_index === true)
                : traitActivityData;
        }

        return (
            <>
            <div className={styles.toolbarContainer}>
                    <div className={styles.toolbarBlock}>
                            <div className={styles.confirmationCount}>
                                <input
                                    type="number"
                                    id="confirmationCount"
                                    max={maxCount}
                                    min={minCount}
                                    value={confirmationsToCount.toString()}
                                    onChange={(e) => setConfirmationsToCount(e.target.value)}
                                />
                                <label htmlFor="confirmationCount">Confirmations</label>
                                <Popup
                                    position="top left"
                                    hoverable
                                    trigger={
                                        <div className={styles.help_icon}></div>
                                    }
                                    content={
                                        <div>
                                            <p>Change the number of confirmations needed for a vote to count towards the total.</p>
                                        </div>
                                    }
                                    inverted
                                />
                            </div>
                            <input
                                type="checkbox"
                                id="diseasesOnly"
                                checked={diseasesOnly}
                                onChange={toggleDiseasesOnly}
                            />
                            <label htmlFor="diseasesOnly">Health Score Diseases Only</label>
                    </div>
            </div>
            <div className={styles.table}>
                    <IndexStatusHeader
                        metricsMap={metricsMap || {}}
                        regions={regions || []}
                    />
                    <div>
                            {traitActivityData.length ? getTraitsActivityData().map(
                                trait => (
                                    <TraitRow
                                        key={trait.name}
                                        data={trait}
                                        confirmationsToCount={confirmationsToCount}
                                    />
                                )
                            ) : ''}
                    </div>
                    <div className={styles.scroll_chin}/>
            </div>
            </>
        );
};
