import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Popup, Icon } from 'semantic-ui-react';
import {
    updateDemographics,
    removeDemographics,
} from '../../../store/actions/demographics';
import actions from '../../../store/actions';
import { Confirm } from '../../common/Popup/Confirm';
import Checkbox from '../../common/CustomCheckbox';

const defaultState = {
    name: '',
    short_name: '',
    editing: false,
};
class DemographicsRowComponent extends PureComponent {
    state = defaultState;
    render() {
        const {
            actions = { delete: false, edit: false },
            id,
            name,
            short_name,
            is_public,
            isHeader,
        } = this.props;
        const { name: stateName, short_name: stateShortName, editing } = this.state;

        return (
            <div className="demographic_table_row">
                <div className="demographic_table_cell id">{id}</div>
                <div className="demographic_table_cell name">
                    {editing ? (
                        <input
                            type="text"
                            value={stateName}
                            onChange={e => this.setState({ name: e.target.value })}
                        />
                    ) : (
                        name
                    )}
                </div>
                <div className="demographic_table_cell short_name">
                    {editing ? (
                        <input
                            type="text"
                            value={stateShortName}
                            onChange={e => this.setState({ short_name: e.target.value })}
                        />
                    ) : (
                        short_name
                    )}
                </div>
                <div
                    className={`demographic_table_cell ${
                        isHeader ? 'actions-label' : 'actions'
                    }`}
                >
                    {isHeader && actions}
                    {actions.edit && editing && stateName && stateShortName && (
                        <button className="demographic_btn_save" onClick={this.saveData}>
                            Save
                        </button>
                    )}
                    {actions.edit && editing && (
                        <button
                            className="demographic_btn_cancel"
                            onClick={this.clearState}
                        >
                            Cancel
                        </button>
                    )}
                    {actions.edit && !editing && (
                        <Popup
                            position="top center"
                            content="Make publicly visible on patient-facing pages."
                            trigger={
                                <Icon.Group>
                                    <Checkbox
                                        className="demographic_checkbox"
                                        value={is_public}
                                        onChange={() => this.onTogglePublic(!is_public)}
                                    />
                                </Icon.Group>
                            }
                        />
                    )}
                    {actions.edit && !editing && (
                        <button className="demographic_btn_edit" onClick={this.editState}>
                            Edit
                        </button>
                    )}
                    {actions.delete && !editing && (
                        <button
                            className="demographic_btn_delete"
                            onClick={this.removeDemographic}
                        >
                            Delete
                        </button>
                    )}
                </div>
            </div>
        );
    }

    removeDemographic = () => {
        const { id, name, votes, removeDemographics, showPopup } = this.props;

        if (votes > 0) {
            showPopup(
                `The Demographic cannot be deleted because there ${
                    votes === 1 ? 'is' : 'are'
                } ${votes} ${votes === 1 ? 'vote' : 'votes'} related to it.`,
                'Delete Demographic'
            );
            return;
        }

        showPopup(
            <Confirm
                text={`Are you sure you want to delete ${name} demographic?`}
                onConfirm={() => removeDemographics(id)}
            />,
            'Delete Demographic'
        );
    };

    clearState = () => this.setState(defaultState);

    editState = () => {
        this.setState({
            name: this.props.name,
            short_name: this.props.short_name,
            editing: true,
        });
    };

    onTogglePublic = value => {
        this.props.updateDemographics({
            id: this.props.id,
            name: this.props.name,
            short_name: this.props.short_name,
            is_public: value,
        });
    };

    saveData = () => {
        const { editing, ...newData } = this.state;

        this.props.updateDemographics({
            id: this.props.id,
            ...newData,
        });

        this.setState(defaultState);
    };
}

const mapDispatchToProps = {
    removeDemographics,
    updateDemographics,
    showPopup: actions.popups.showPopup,
};

export const DemographicsRow = connect(
    () => ({}),
    mapDispatchToProps
)(DemographicsRowComponent);
