import { POPUP_ACTIONS } from './constants';


export const showPopup = (content, title = '') => ({
    type: POPUP_ACTIONS.SHOW,
    payload: { id: Symbol(), content, title }
});

export const closePopup = (id = null) => ({
    type: POPUP_ACTIONS.HIDE,
    payload: { id }
});

