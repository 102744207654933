import { put, takeLatest, takeEvery, call, select } from 'redux-saga/effects';
import { TRAIT_ACTIONS } from '../actions/constants';
import Traits, {publicFileURL} from "../../api/traits";
import * as categoriesApi from "../../api/trait_categories";
import * as traitActions from '../actions/traits';
import actions from '../actions';
import * as categoriesActions from '../actions/categories';
import * as loading from '../actions/loading';

function* fetchTraits(action) {
    yield put(loading.startLoading());
    let response = yield call(Traits.getTraits, action.payload);
    let traits = response.data;

    for (let i = 0; i < traits.length; i++) {
        if (traits[i].icon) {
            traits[i].icon = publicFileURL + traits[i].icon;
        }
    }

    yield put(traitActions.receiveTraits(traits))

    let categories = yield call(categoriesApi.getCategories);

    yield put(categoriesActions.receiveCategories(categories));
    yield put(loading.endLoading());
}

function* loadTraitNames(action) {
    yield put(loading.startLoading('trait_names'));
    try {
        let response = yield call(Traits.getTraitNames);
        let names = response.data;
        yield put(traitActions.receiveTraitNames(names));
    } catch (e) {
        console.log(e);
    }

    yield put(loading.endLoading('trait_names'));
}

function* requestRestoreTrait({ payload: id }) {
    yield put(loading.startLoading('trait_restore'));
    try {
        yield call(Traits.requestRestoreTrait, id);
        yield put(actions.popups.showPopup(`The request has been successfully sent to admin.`, `Trait restore request`));
        yield put(actions.traits.loadTraits());
    } catch (e) {
        yield put(actions.log.sendLog(e));
    }
    yield put(loading.endLoading('trait_restore'));
}

function* requestApproveTrait({ payload: id }) {
    yield put(loading.startLoading('trait_restore'));
    try {
        yield call(Traits.requestApproveTrait, id);
        yield put(actions.popups.showPopup(`The request has been successfully sent to admin.`, `Trait approve request`));
        yield put(actions.traits.loadTraits());

    } catch (e) {
        yield put(actions.log.sendLog(e));
    }
    yield put(loading.endLoading('trait_restore'));
}

function* saveTrait(action) {
    yield put(loading.startLoading());
    const {trait, noNotify} = action.payload;
    const addTraitRes = yield call(Traits.submitTrait, {...trait, noNotify});
    const { data } = addTraitRes;
    yield put(traitActions.updateNewTrait(data));
    yield put(loading.endLoading());
}

function* createTrait(action) {
    const trait = {
        id: 'new',
        chromosomes_affected: 'DK',
        
        ...action.payload
    }
    yield put(traitActions.changeTrait(trait));
    yield call(selectTrait, action);
}


function* selectTrait(action) {
    yield put(traitActions.changeTrait({
        ...action.payload,
        chromosomes_affected: 'DK',
        comment: ''
    }));
}


export function* traitsSaga() {
    yield takeLatest(TRAIT_ACTIONS.LOAD, fetchTraits);
    yield takeLatest(TRAIT_ACTIONS.SAVE, saveTrait);
    yield takeLatest(TRAIT_ACTIONS.CREATE_ONE, createTrait);
    yield takeLatest(TRAIT_ACTIONS.SELECT_ONE, selectTrait);
    yield takeLatest(TRAIT_ACTIONS.LOAD_TRAIT_NAMES, loadTraitNames);
    yield takeLatest(TRAIT_ACTIONS.RESTORE_REQUEST, requestRestoreTrait)
    yield takeLatest(TRAIT_ACTIONS.APPROVE_REQUEST, requestApproveTrait)
}