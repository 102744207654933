import React, { useState, useEffect } from 'react';
import s from './Vote.module.scss';
import { classList, FIELD_NAMES } from '../../../utils';
import { connect } from 'react-redux';
import { DropZone, Dropdown } from '../../common';
import actions from '../../../store/actions';
import ChangeVoteStatusPopup from './ChangeVoteStatusPopup';
import { ChangeVotePopupType } from './ChangeVotePopupTypes';

const EditVotePopup = (props) => {
    const { id, votes, categories, chromosomes, sex: sexes, demographics, showPopup, addReasonForVoteUpdate } = props;
    let vote = votes.find(v => v.id === id);
    const [validation, setValidation] = useState('');
    const [model, setModel] = useState({});

    useEffect(() => {
        setModel(vote);
    }, []);

    const saveVote = () => {
        if (model.field.type !== 'List' && (!model.source || !model.source_file || !model.link || !model.citation)) {
            setValidation('Please fill in all empty fields.');
            return;
        }

        if (model.field.type !== 'List') {
            if (model.value > 80 && model.field.name === FIELD_NAMES.life_span_impact_score) {
                setValidation(`Value should be less than 80.`);
                return;
            }
            if (model.value > 100 && (model.field.name !== FIELD_NAMES.life_span_impact_score && model.field.name !== FIELD_NAMES.life_treatment_cost_score)) {
                setValidation(`Value should be less than 100.`);
                return;
            }

            if (model.value < 0 || isNaN(model.value) || model.value === '') {
                setValidation(`Value should be a non-negative number.`);
                return;
            }
        }

        model.value = +model.value;

        if (model.field.type !== 'List') {
            try {
                let link = new URL(model.link);
                model.link = link.href;
            } catch (e) {
                setValidation(`Link is not valid`);
                return;
            }
        }
        showPopup(
            <ChangeVoteStatusPopup
                changesType={ChangeVotePopupType.EDIT}
                handleSubmit={
                    reason => {
                        addReasonForVoteUpdate(reason, id);
                        props.updateVoteAndSave(model);
                        props.closePopup();
                    }
                }
            />,
            'Edit vote'
        );
    }

    let voteType = 'number';

    if (~vote.field.name.toLowerCase().indexOf('category')) {
        voteType = 'category';
        var values = categories.map(c => ({ ...c, selected: c.value === model.value }));
        var onChange = (value) => setModel({ ...model, value });
    }

    if (~vote.field.name.toLowerCase().indexOf('chromosome')) {
        voteType = 'chromosome';
        var values = chromosomes.map(c => ({ ...c, selected: c.value === model.value }));
        var onChange = (value) => setModel({ ...model, value });
    }

    return (
        <div className={classList(s.EditVotePopup, vote?.field?.type === 'List' && s.EditVotePopup_Small)}>
            <div className={s.EditRow}>
                {vote.field.displayName} (#{vote.id})
            </div>
            {vote?.sex &&
                <div className={s.EditRow}>
                    <label className={s.sexLabel} />
                    <Dropdown className={s.ValueDropdown}
                        items={sexes.map(sex => ({
                              text: sex.name,
                              value: sex.id,
                              selected: model?.sex?.id === sex.id
                          })
                        )}
                        onChange={(sexId) => setModel({...model, sex: sexes.find(sex => sex.id === sexId), sexId},)}
                    />
                </div>
            }
            {vote?.demographic &&
                <div className={s.EditRow}>
                    <label className={s.demoLabel}/>
                    <Dropdown
                        className={s.ValueDropdown}
                        items={demographics.map(demographic => ({
                                text: demographic.name,
                                value: demographic.id,
                                selected: model?.demographic?.id === demographic.id
                            })
                        )}
                        onChange={(demoId) => setModel({
                            ...model,
                            demographic: demographics.find(demographic => demographic.id === demoId),
                            demoId
                        })
                        }
                    />
                </div>
            }
            <div className={s.EditRow}>
                <label>Value</label>
                <div>{voteType === 'number' ? <input type="number" step="0.01" min="0"
                    value={isNaN(model.value) ? '' : model.value} onChange={(e) => setModel({ ...model, value: e.target.value })} /> :
                    <Dropdown className={s.ValueDropdown} items={values} onChange={onChange} />
                }</div>
            </div>
            <div className={s.EditRow}>
                <label>Link</label>
                <div>
                    <input type="text" value={model.link || ''} onChange={(e) => setModel({ ...model, link: e.target.value })} />
                </div>
            </div>
            <div className={s.EditRow}>
                <label>Citation</label>
                <div>
                    <textarea value={model.citation || ''} onChange={(e) => setModel({ ...model, citation: e.target.value })} />
                </div>
            </div>
            <div className={s.DropzoneRow}>
                <label>Source Screenshot</label>
                <div className={s.DropzoneWrapper}>
                    <DropZone field="vote_screenshot" defaultValue={vote.source} onDrop={source => setModel({ ...model, source })} />
                </div>
            </div>
            <div className={s.DropzoneRow}>
                <label>Source File (PDF)</label>
                <div className={s.DropzoneWrapper}>
                    <DropZone field="source_file" defaultValue={vote.source_file ? vote.source_file_name : null} onDrop={source_file => setModel({ ...model, source_file, source_file_name: source_file.name })} />
                </div>
            </div>
            <div className={s.Validation}>{validation}</div>
            <div className={s.EditVoteButtons}>
                <button type="button" className={s.btn_save} onClick={saveVote}>Save</button>
                <button type="button" className={s.btn_cancel} onClick={() => props.closePopup()}>Cancel</button>
            </div>
        </div>
    );
}
const mapStateToProps = store => ({
    loading: store.loading,
    votes: store.votes,
    categories: store.categories.map(s => ({ value: s.id, text: s.name })),
    chromosomes: store.chromosomes.map(s => ({ value: s.id, text: s.name })),
    user: store.user,
    sex: store.sex,
    demographics: store.demographics
});

const mapDispatchToProps = {
    deleteVote: actions.votes.deletedVote,
    updateVoteAndSave: actions.votes.updateVoteAndSave,
    loadScreenshot: actions.votes.loadVoteScreenshot,
    showPopup: actions.popups.showPopup,
    closePopup: actions.popups.closePopup,
    addReasonForVoteUpdate: actions.votes.addReasonForVoteUpdate
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditVotePopup);