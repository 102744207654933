import React, {useEffect, useReducer} from 'react';
import styles from '../UserContributions.module.scss';
import {classList} from "../../../../../utils";
import actions from "../../../../../store/actions";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

const UserWeeklyRow = ({
    title,
    submittedVotes,
    userVotesConfirmed,
    userVotesRejected,
    confirmedVotes,
    rejectedVotes,
    editedVotes
}) => (
    <div className={classList(styles.table, styles.user_weekly_rows)}>
        <div className={classList(styles.row, styles.week_row)}>
            {title || 'Week of ???'}
        </div>
        <div className={classList(styles.row, styles.submitted_votes_row)}>{submittedVotes || 0}</div>
        <div className={classList(styles.row, styles.user_confirmed_votes_row)}>{userVotesConfirmed || 0}</div>
        <div className={classList(styles.row, styles.user_rejected_votes_row)}>{userVotesRejected || 0}</div>
        <div className={classList(styles.row, styles.confirmations_row)}>{confirmedVotes || 0}</div>
        <div className={classList(styles.row, styles.rejections_row)}>{rejectedVotes || 0}</div>
        <div className={classList(styles.row, styles.voted_edited_row)}>{editedVotes || 0}</div>
    </div>
)

const UserYearRow = ({year}) => (
    <div className={classList(styles.table, styles.year_row)}>
        {year}
    </div>
)

const UserRow = ({
    userId,
    username,
    submittedVotes,
    userVotesConfirmed,
    userVotesRejected,
    confirmedVotes,
    rejectedVotes,
    editedVotes,
    historyActions,
    loadUserContributions
}) => {
    const [isToggled, toggle] = useReducer(
        isToggled => !isToggled,
        false,
        initialValue => initialValue
    );

    useEffect(() => {
        if (!isToggled) return;
        !historyActions && loadUserContributions(userId);
    }, [isToggled])

    return (
        <>
            <div className={classList(styles.table, styles.user_rows)} onClick={toggle}>
                <div className={classList(styles.row, styles.login_row)}>
                    <div className={classList(styles.arrow, isToggled && styles.arrow_down )} />
                    {username}
                </div>
                <div className={classList(styles.row, styles.submitted_votes_row)}>{submittedVotes || 0}</div>
                <div className={classList(styles.row, styles.user_confirmed_votes_row)}>{userVotesConfirmed || 0}</div>
                <div className={classList(styles.row, styles.user_rejected_votes_row)}>{userVotesRejected || 0}</div>
                <div className={classList(styles.row, styles.confirmations_row)}>{confirmedVotes || 0}</div>
                <div className={classList(styles.row, styles.rejections_row)}>{rejectedVotes || 0}</div>
                <div className={classList(styles.row, styles.voted_edited_row)}>{editedVotes || 0}</div>
            </div>
            {isToggled && historyActions && Object.keys(historyActions).reverse().map(year => (
                <div key={`${year}-${userId}`}>
                    <UserYearRow year={year} />
                    {historyActions[year].map(week => (
                        <UserWeeklyRow
                            key={`${week.title}-${userId}-${year}`}
                            title={week.title}
                            submittedVotes={week.added}
                            userVotesConfirmed={week.userVotesConfirmed}
                            userVotesRejected={week.userVotesRejected}
                            confirmedVotes={week.confirmed}
                            rejectedVotes={week.rejected}
                            editedVotes={week.edited}
                        />
                    ))}
                </div>
            ))
            }
        </>
    )
}

const mapStateToProps = (store) => ({
    usersContributionStatistic: store.statistic,
})

const mapDispatchToProps = {
    loadUserContributions: actions.statistic.loadUserContributions,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserRow));
