import React, { PureComponent } from 'react';
import styles from './Users.module.scss';
import { classList } from '../../utils';
import { Dropdown } from '../common';
import Checkbox from '../common/CustomCheckbox';
import { connect } from 'react-redux';
import { addUser, updateUser } from '../../store/actions/users'
const DEFAULT_ROLES = [
    { text: 'Without role', value: null },
    { text: 'user', value: 'user', selected: true },
    { text: 'admin', value: 'admin' },
    { text: 'Senior User', value: 'senior' },

]
class EditUser extends PureComponent {
    state = { id: '', login: '', email: '', inactive: false, role: '', password: '', viewPass: false }
    render() {
        const { id, login, email, inactive, role, password, viewPass } = this.state;
        const requieredFieldsEmpty = !(login || email);
        let roles = DEFAULT_ROLES.map(r => ({ ...r, selected: r.value === role }));
        let notUniqueLogin = this.checkNotUniqueField('login');
        let notUniqueEmail = this.checkNotUniqueField('email');
        return <div className={classList(styles.EditUser__Background, 'fake')} onClick={e => { if (e.target.classList.contains('fake')) this.props.onClose() }}>
            <div className={styles.EditUser}>
                <div className={styles.EditUser__Head}>{id ? 'Edit User' : 'Add user'}</div>
                <div className={styles.EditUser__Content}>
                    <div className={styles.EditUser__Row}>
                        <label className={styles.EditUser__Label}>Login</label>
                        <label className={styles.EditUser__Field}>
                            <input type="text" className={classList(notUniqueLogin && styles.InvalidInput)} value={login} onChange={e => this.setState({ login: e.target.value })} />
                            {notUniqueLogin && <div className={styles.validation}>The user with the same login already exists!</div>}
                        </label>
                    </div>
                    <div className={styles.EditUser__Row}>
                        <label className={styles.EditUser__Label}>Password</label>
                        <label className={styles.EditUser__Field}>
                            <input type={viewPass ? 'text' : 'password'} className={styles.password} value={password} onChange={e => this.setState({ password: e.target.value })} />
                            <div className={styles.PaswordToggler} onClick={() => this.setState({ viewPass: !viewPass })}></div>
                        </label>
                    </div>
                    <div className={styles.EditUser__Row}>
                        <label className={styles.EditUser__Label}>E-mail</label>
                        <label className={styles.EditUser__Field}>
                            <input
                                type="email" className={classList(notUniqueLogin && styles.InvalidInput)}
                                value={email}
                                onChange={e => this.setState({ email: e.target.value })} />
                            {notUniqueEmail && <div className={styles.validation}>The user with the same E-mail already exists!</div>}

                        </label>
                    </div>
                    <div className={styles.EditUser__Row}>
                        <label className={styles.EditUser__Label}>Role</label>
                        <label className={styles.EditUser__Field}>
                            <Dropdown className={styles.Dropdown} items={roles} onChange={(role) => this.setState({ role })} />
                        </label>
                    </div>
                    <div className={styles.EditUser__Row}>
                        <label className={styles.EditUser__Label}>Inactive</label>
                        <label className={styles.EditUser__Field}>
                            <Checkbox className={styles.EditUser__Checkbox} value={inactive} onChange={() => this.setState({ inactive: !inactive })} />
                        </label>
                    </div>

                    <div className={classList(styles.EditUser__Row)}>
                        <label className={styles.EditUser__Label}></label>
                        <label className={styles.EditUser__Field} style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <button onClick={this.props.onClose} className={styles.Btn_Cancel}>Close</button>
                            <button onClick={this.save} className={styles.Btn_Save} disabled={notUniqueLogin || notUniqueEmail || requieredFieldsEmpty}>Save</button>
                        </label>

                    </div>
                </div>
            </div>
        </div>
    }

    componentDidMount() {
        const { id = '', login = '', email = '', inactive = false, role = '' } = this.props;
        this.setState({ id, login, email, inactive, role });
    }

    save = () => {
        const { id, login, email, inactive, role, password = '' } = this.state;
        const model = { login, email, inactive, role, password }

        if (id) {
            model.id = id;
            this.props.updateUser(model);
        } else {
            this.props.addUser(model);
        }
        this.props.onClose();
    }

    checkNotUniqueField = (field) => {
        let { users } = this.props;
        let { id } = this.state;
        let value = this.state[field] || '';
        value = value.toLowerCase().trim()
        return value && users.some(u => u.id !== id && u[field] && (u[field] || '').toLowerCase().trim() === value);
    }
}

const mapStateToProps = ({ users }) => ({ users })

const mapDispatchToProps = {
    addUser,
    updateUser
}

export default connect(mapStateToProps, mapDispatchToProps)(EditUser);