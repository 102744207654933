import React, { PureComponent } from 'react';

import { Table } from 'semantic-ui-react'

export default class AdministrationTableHeader extends PureComponent {
    render() {
        const { data, category } = this.props;
        return <Table.Header>
            <Table.Row>
                {data.map(item => (
                    <Table.HeaderCell key={`${category}${item}`}>{item}</Table.HeaderCell>
                ))}
            </Table.Row>
        </Table.Header>
    }
}