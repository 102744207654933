import React, { PureComponent } from 'react';
import { Link } from "react-router-dom";
import styles from './AdminMenu.module.scss';

export default class AdminMenu extends PureComponent {
    render() {
        const { menuItems } = this.props;
        let activeLink = menuItems.find(l => ~window.location.pathname.indexOf(l.link));
        if (!activeLink) activeLink = menuItems.find(l => l.default);

        return (
            <nav className={styles.AdminMenu}>
                {menuItems.map(l => <Link 
                    key={l.link}
                    className={l.link === activeLink.link ? styles.AdminMenu__Link_active : styles.AdminMenu__Link} 
                to={l.link}>{l.text}</Link>)}
            </nav>
        );
    };
};