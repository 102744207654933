import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import { LoginForm } from '../forms';
import User from '../../api/users';
import Auth from '../../api/login';

import { PageWrapper } from '../common';

import { Container } from 'semantic-ui-react'
import { setAuthTokenToHeader } from '../../utils';

class LoginContainer extends Component {

    state = {
        username: '',
        password: '',
        isLoginChecked: false,
        isLoggedIn: null,
        error: '',
        loading: false
    };

    componentDidMount = async () => {
        const token = User.getToken();
        if (token) {
            try {
                const response = await Auth.verifyToken();
                this.setState({ isLoginChecked: true, isLoggedIn: response.status === 200 })
                // if (response.status !== 200) localStorage.removeItem('token');
            } catch (e) {
                console.log(e.message);
                // localStorage.removeItem('token');
                this.setState({ isLoginChecked: true, isLoggedIn: false });
            }

        } else {
            this.setState({ isLoginChecked: true, isLoggedIn: false });
        }
    };

    handleSubmit = () => {
        const {
            username,
            password
        } = this.state;
        const { history } = this.props;
        this.setState({ error: '', loading: true });

        Auth
            .login({ username, password })
            .then((res) => {
                let { data } = res;
                if (data.token) {
                    User.setToken(data.token);
                    setAuthTokenToHeader(data.token);
                    history.push(data.role === 'admin' ? '/administration' : '/');

                } else {
                    this.setState({ error: data, loading: false });
                }
            }).catch((err) => {
                console.log(err);
                this.setState({ error: err?.response?.data || 'Server Error', loading: false })
            });
    };

    handleChange = (e, { name, value }) => this.setState({ [name]: value });

    render() {
        const {
            username,
            password,
            isLoginChecked,
            isLoggedIn,
            error,
            loading
        } = this.state;

        return (
            <Container>
                {(isLoginChecked && isLoggedIn) && (
                    <Redirect to='/administration' />
                )}
                {(!isLoginChecked || !isLoggedIn) && (
                    <PageWrapper title={'Login'}>
                        <Container>
                            <LoginForm
                                error={error}
                                loading={loading}
                                username={username}
                                password={password}
                                handleChange={this.handleChange}
                                handleSubmit={this.handleSubmit}
                            />
                        </Container>
                    </PageWrapper>
                )}
            </Container>
        );
    };
};

export default LoginContainer;
