import React, { PureComponent } from 'react';

import User from "../../api/users";
import { connect } from 'react-redux';
import { wrapPage } from '../HOC';
import Weight from './Weight';
import { loadTraits, updateNewTrait, updateTrait } from '../../store/actions/traits';
import { startLoading, endLoading } from '../../store/actions/loading';
import { loadDemographics } from '../../store/actions/demographics';
import { loadVoteFields } from '../../store/actions/vote.fields.actions';
import { loadChromosomes } from '../../store/actions/chromosomes';
import { loadSex, updateSex } from '../../store/actions/sex';

import ic_weight from '../../resources/images/weight.svg';

class WeightContainer extends PureComponent {

    state = {
        sessionId: parseInt(User.getSessionId()),
        trait: {},
        validation: [],
    };

    componentDidMount() {
        if (!this.props.traits.length) this.props.loadTraits();
        if (!this.props.demographics.length) this.props.loadDemographics();
        if (!this.props.fields.length) this.props.loadVoteFields();
        if (!this.props.sex.length) this.props.loadSex();
        if (!this.props.chromosomes.length) this.props.loadChromosomes();
    }
    componentDidUpdate(newProps) {
        if (this.state.trait.id !== +this.props.match.params.id && this.props.traits.length > 0 && this.props.match.params.id && this.props.match.params.id !== 'add') {
            this.props.updateSex({ value: null });
            this.setState({ trait: this.props.traits.find(t => t.id === +this.props.match.params.id) });
        }
    }

  
    saveResponse = (captchaResponse) => {
        this.setState({ captchaResponse })
    }

    createTrait = (name, category) => {
        this.props.history.push(`/weight/add`)
        this.props.updateSex({ value: null });
        this.setState({ trait: { trait_name: name, category } })
    }

    render() {
        const {
            validation
        } = this.state;
        return (
            <Weight
                validation={validation}
                createTrait={this.createTrait}
                history={this.props.history}
                captchaResponse={this.state.captchaResponse}
                saveResponse={this.saveResponse}
            />
        );
    };
}

const mapStateToProps = (store) => ({
    loading: store.loading,
    sex: store.sex,
    traits: store.traits,
    submittedHistory: store.submittedHistory,
    chromosomes: store.chromosomes,
    voteFields: store.voteFields,
    frequencies: store.frequencies,
    demographics: store.demographics,
    activeTrait: store.activeTrait,
    fields: store.voteFields
})

const mapDispatchToProps = {
    loadTraits,
    updateNewTrait,
    updateTrait,
    updateSex,
    startLoading,
    endLoading,
    loadDemographics,
    loadVoteFields,
    loadChromosomes,
    loadSex
}

export default wrapPage(connect(mapStateToProps, mapDispatchToProps)(WeightContainer), 'Weight', null, ic_weight);
