import axios from 'axios';
import config from '../config';
import { sendToCloud } from './files';
const adminVotesURL = config.API + 'admin/votes/';
const adminVotesApi = axios.create({ baseURL: adminVotesURL });

const fileURL = config.API + 'public/get_file/';
const fileApi = axios.create({ baseURL: fileURL, responseType: 'blob' });

export const getVotesForTrait = ({ traitId, all = false }) =>
    adminVotesApi.get(`trait/${traitId}${all ? '/all' : ''}`).then(r => r.data);
export const getVotesForField = (traitId, fieldId) =>
    adminVotesApi.get(`${traitId}/${fieldId}`);

export const loadScreenshot = async (id, file) => {
    const bodyFormData = new FormData();
    bodyFormData.append('source', file);
    const source = await adminVotesApi
        .post(`${id}/source`, bodyFormData)
        .then(r => r.data);
    return source;
};

export const loadSourceFile = async (id, file) => {
    const bodyFormData = new FormData();
    bodyFormData.append('source_file', file);
    const source_file = await adminVotesApi
        .post(`${id}/source-file`, bodyFormData)
        .then(r => r.data);
    return source_file;
};

export const updateVote = async vote => {
    let model = { ...vote };
    if (model.source?.path) {
        model.source = model.source.path;
        model.uploadSource = true;
    }

    if (model.source_file?.path) {
        model.source_file = model.source_file.path;
        model.uploadSourceFile = true;
    }

    let savedData = await adminVotesApi.post(`${vote.id}`, model).then(r => r.data);

    if (vote.source && typeof vote.source === 'object') {
        await sendToCloud(savedData.presignedSource, vote.source);
    }

    if (vote.source_file && typeof vote.source_file === 'object') {
        await sendToCloud(savedData.presignedSourcePDF, vote.source_file);
    }

    if (vote?.comment?.screenshotOfCalculation && typeof vote.comment.screenshotOfCalculation === 'object') {
        await sendToCloud(savedData.presignedScreenOfCalc, vote.comment.screenshotOfCalculation);
    }

    return savedData;
};

export const getVoteFields = () => adminVotesApi.get(`fields`);

export const getEditableVote = id => adminVotesApi.get(id).then(r => r?.data);

export const getVoteHistory = id =>
    adminVotesApi.get(`${String(id)}/history`).then(r => r.data);

export const deleteVote = id => adminVotesApi.delete(`${id}`);

export const submitVotes = async (votes, captchaResponse) => {
    let savedVotes = await adminVotesApi
        .put('', { votes, captchaResponse })
        .then(r => r.data);

    return savedVotes;
};

export const changeConfirmed = async (id, confirmed) => {
    const { linkToFile, vote } = await adminVotesApi
        .post(`${id}/confirmed`, { confirmed })
        .then(r => r.data);

    if (confirmed?.reason?.file && linkToFile) {
        await sendToCloud(linkToFile, confirmed.reason.file);
    }
    
    await adminVotesApi.post(`${id}/confirmed/email`, vote);
    
    return vote;
};

export const changeApproved = (id, approved) =>
    adminVotesApi.post(`${id}/approved`, { approved }).then(r => r.data);

export const notifyEditReason = (voteId, reason) =>
    adminVotesApi.post(`${voteId}/notify_reason`, { reason }).then(r => r.data);

export const getFile = file =>
    fileApi.get(`${file}`, { responseType: 'blob' }).then(r => r.data);

export const checkPublicationIdentifier = data =>
    adminVotesApi.post('/check', data).then(r => r.data);

export const getVotesByIds = (ids) =>
    adminVotesApi.post('/get_vote_by_ids', {ids}).then(r => r.data);
