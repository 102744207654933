import React, { useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import ControlHeader from './ControlHeader';

import actions from '../../store/actions';
import ResearchForm from './ResearchForm';
import validationSchema from './validation';
import { parseMedlineDate } from '../../utils';
import s from './Research.module.scss';

const NewResearchItem = ({ history, submitted, createResearchItem, resetSubmitted, loadAuthors, allAuthors }) => {

    useEffect(() => {
        if (submitted) {
            resetSubmitted();
            history.push('/administration/research');
        }
    }, [history, submitted, resetSubmitted]);

    useEffect(() => {
        loadAuthors();
    }, []);

    const handleSubmit = async values => {
        if (values.pmid === '') values.pmid = null;
        values.order = 0;
        if (values.dp) {
            values.publicationDate = parseMedlineDate(values.dp);
            if (isNaN(values.publicationDate.getTime())) {
                alert('Invalid publication date!');
                return;
            }
        }
        createResearchItem(values);
    };

    return (
        <Formik 
            initialValues={{ 
                title: '',
                url: '',
                publicationDate: null,
                document: null,
                format: null,
                authors: [''],
                published: true,
                views: 0
            }}
            validationSchema={validationSchema}
            isInitialValid={false}
            onSubmit={handleSubmit}
        >
            {props => (
                <div className={s.EditPage}>
                    <ControlHeader title="Create Research Item" isNew={true} sectionType="research" showCreateButton={true} handleSubmit={() => props.submitForm(props.values)} />
                    <div className="admin-table-wrapper">
                        <ResearchForm { ...props } allAuthors={allAuthors} />
                    </div>
                </div>
            )}
        </Formik>
    );
};

const mapStateToProps = state => ({
    submitted: state.research.submitted,
    allAuthors: state.authors
});

const mapDispatchToProps = {
    createResearchItem: actions.research.createResearchItem,
    loadAuthors: actions.authors.loadAllAuthors
};

export default compose(
    withRouter,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(NewResearchItem);