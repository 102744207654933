import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { changeIsPrivate } from '../../store/actions/private';
import { connect } from 'react-redux';

class ScrollToTop extends Component {
    componentDidMount() {
        // this.props.changeIsPrivate(localStorage['private']);
    }
    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname && 
            this.props.location.pathname.indexOf('/ess') < 0 && prevProps.location.pathname.indexOf('/ess') < 0) {
            window.scrollTo(0, 0);
        }
    }
  
    render() {
        return this.props.children;
    }
}
  
export default connect(({ privacy }) => ({ privacy }), { changeIsPrivate })(withRouter(ScrollToTop));