import axios from 'axios';
import config from '../config';

const publicURL = config.API + 'public/demographics';
const adminURL = config.API + 'admin/demographics';
const publicApi = axios.create({ baseURL: publicURL });
const adminApi = axios.create({ baseURL: adminURL });

export default {
    load: () => publicApi.get(''),
    loadAdmin: () => adminApi.get(''),
    add: (data) => adminApi.put('', data),
    update: (data) => adminApi.post('', data),
    delete: (id) => adminApi.delete(`/${id}`)
}
