import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { EssTable } from './ESSTable';
import s from './Ess.module.scss';
import actions from '../../store/actions';
import { classList } from '../../utils';
import { Dropdown } from '../common';
import Loader from '../choose/Loader';
import { CEOApproval } from './';
import { Popup } from 'semantic-ui-react';
import { ReactComponent as WarningIcon } from '../../resources/images/ic_warning.svg';

const Ess = React.memo((props) => {
    const activeVersionIndex = props.versions.findIndex(v => v.selected);
    const activeVersion = props.versions[activeVersionIndex];
    const [demographicId, setDemographicId] = useState(null);
    const [demographicDefaultId, setDemographicDefaultId] = useState(null);
    const [isFillNA, setIsFillNA] = useState(false);

    useEffect(() => {
        props.loadVersions('stats', props?.match?.params?.version);
        props.loadDemographics();
    }, []);

    useEffect(() => {
        let defaultDemographic = props.demographics.find(item => item.is_default);
        defaultDemographic && setDemographicDefaultId(defaultDemographic.id);
    }, [props.demographics])

    useEffect(() => {
        if (demographicId !== demographicDefaultId && demographicId !== null) {
            props.loadEss(activeVersion.id, demographicId, isFillNA);
        }
    }, [isFillNA])


    let versions = props.versions.map(v => {
        const version = { ...v };
        if (version.updatedAt) { 
            let date = new Date(version.updatedAt);
            version.updatedAt = `(${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()})`;
        } else {
            version.updatedAt = '';
        }
        return version;
    });
    
    let versionAddress = `/administration/ess/${activeVersion?.major}-${activeVersion?.changed}-${activeVersion?.updated}`

    if (!props?.match?.params?.version && activeVersion) {
        props.history.push(versionAddress);
    }


    useEffect(() => {
        if (typeof activeVersion?.major === 'number' && typeof activeVersion?.changed === 'number' && typeof activeVersion?.updated === 'number' && versionAddress !== props.match.url) {
            props.history.push(versionAddress);
        }
    }, [versionAddress])
    

    let selectedVersion = versions.find(v => v.selected);

    const onVersionChoose = (value) => {
        let version = versions.find(v => v.id === value);
        if (version) {
            props.history.push(`/administration/ess/${version.major}-${version.changed}-${version.updated}`);
        }

        props.chooseVersion(value);
        props.loadEss(value);

    }

    const onDemographicChange = (value) => {
        let newDemographic = props.demographics.find(item => item.short_name === value);
        if (demographicId !== newDemographic.id) {
            setDemographicId(newDemographic.id);
            newDemographic.is_default
                ? props.loadEss(activeVersion.id)
                : props.loadEss(activeVersion.id, newDemographic.id, isFillNA);
        }
    }

    return (<div className={classList(s.StatsPage, props.loading?.length && s.StatsPage_loading)}>
        <h1>Embryo Selection Statistics
            {selectedVersion &&
                <a
                    target="_blank"
                    rel="noopener norefferrer"
                    href={`/api/public/ess/${selectedVersion.major}-${selectedVersion.changed}-${selectedVersion.updated}`}
                    className={s.ApiLink}
                >
                    API
                </a>
            }
        </h1>
        <div className={s.Controls}>
            <div className={s.Controls__row}>
                <Dropdown autoComplete={true} className={s.Dropdown} items={versions.map(v => ({
                    text: `${v.major}.${v.changed}.${v.updated} ${v.updatedAt}`,
                    value: v.id,
                    selected: v.selected
                }))}
                    onChange={onVersionChoose}
                />
                {activeVersionIndex > 0 &&
                    <Popup
                        position='top right'
                        hoverable
                        trigger={
                            <WarningIcon className={s.OldVersionIcon} />
                        }
                        content={
                            <div>
                                <p>You are viewing an outdated version. New version available.</p>
                            </div>
                        }
                    />
                }

                <div className={classList(s.MainButtons,'ESSMainButtons')}>
                    <button className={s.Btn} onClick={() => props.increaseVersion()}>Increase primary version</button>
                    <CEOApproval data={props.version} onSave={props.saveVersion} className={s.CEOApproval} />
                </div>
            </div>

            <div className={s.Controls__row}>
                <Dropdown
                    autoComplete={true}
                    className={s.Dropdown}
                    items={
                        props.demographics.map((item) => (
                                {
                                    ...item,
                                    text: item.name,
                                    value: item.short_name,
                                    selected: demographicId ? item.id === demographicId : item.is_default
                                }
                            )
                        )
                    }
                    onChange={onDemographicChange}
                />
                {demographicId && demographicDefaultId !== demographicId && (
                    <div className={s.fillNA}>
                        <input
                            type="checkbox"
                            checked={isFillNA}
                            onChange={() => setIsFillNA(!isFillNA)}
                        />
                        <label>Fill NA with values from Do Not Know Genetic Ancestry</label>
                    </div>
                )}
            </div>
        </div>

        <EssTable />
        {props.loading.length > 0 && <Loader className={s.Loader} />}
    </div>
    )
});
const mapStateToProps = (store) => ({
    versions: store.versions,
    loading: store.loading,
    version: store.versions.find(v => v.selected),
    demographics: store.demographics,
})

const mapDispatchToProps = {
    loadVersions: actions.versions.loadVersions,
    chooseVersion: actions.versions.chooseVersion,
    loadEss: actions.ess.loadEss,
    increaseVersion: actions.versions.increaseVersion,
    saveVersion: actions.versions.saveVersion,
    loadDemographics: actions.demographics.loadDemographics,
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Ess));