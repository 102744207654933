import React from 'react';
import styles from '../Votes.module.scss';
import { classList, toFixed } from '../../../utils';
import { Link } from 'react-router-dom';
export const TraitRow = ({ onClick, ...trait }) => {
    return <div className={styles.TraitRow} onClick={onClick}>

        <div className={styles.TraitRow__Cell}>
            <label className={classList(styles.label_male)}>{toFixed(trait.life_span_impact_score_male)}</label>
            <label className={classList(styles.label_female)}>{toFixed(trait.life_span_impact_score_female)}</label>
        </div>
        <div className={styles.TraitRow__Cell}>
            <label className={styles.label_male}>{toFixed(trait.life_quality_impact_score_male)}</label>
            <label className={styles.label_female}>{toFixed(trait.life_quality_impact_score_female)}</label>
        </div>
        <div className={styles.TraitRow__Cell}>
            <label className={styles.label_male}>{toFixed(trait.life_treatment_cost_score_male)}</label>
            <label className={styles.label_female}>{toFixed(trait.life_treatment_cost_score_female)}</label>
        </div>
        <div className={styles.TraitRow__Cell}>
            <label className={classList(styles.label_male)}>{toFixed(trait.average_age_of_onset_male)}</label>
            <label className={classList(styles.label_female)}>{toFixed(trait.average_age_of_onset_female)}</label>
        </div>
        <div className={styles.TraitRow__Cell}>
            <label className={classList(styles.label_male)}>{toFixed(trait.morbid_risk_general_male)}</label>
            <label className={classList(styles.label_female)}>{toFixed(trait.morbid_risk_general_female)}</label>
        </div>
        <div className={styles.TraitRow__Cell}>
            <label className={classList(styles.label_male)}>{toFixed(trait.morbid_risk_male)}</label>
            <label className={classList(styles.label_female)}>{toFixed(trait.morbid_risk_female)}</label>
        </div>
        <div className={styles.TraitRow__Cell}>
            <label className={classList(styles.label_male)}>{toFixed(trait.life_expectancy_with_disease_male)}</label>
            <label className={classList(styles.label_female)}>{toFixed(trait.life_expectancy_with_disease_female)}</label>
        </div>
        <div className={styles.TraitRow__Cell}>
            <label className={classList(styles.label_male)}>{toFixed(trait.years_of_life_lost_male)}</label>
            <label className={classList(styles.label_female)}>{toFixed(trait.years_of_life_lost_female)}</label>
        </div>
        <div className={styles.TraitRow__Cell}>
            <label className={classList(styles.label_male)}>{toFixed(trait.survival_rate_in_five_years_male)}</label>
            <label className={classList(styles.label_female)}>{toFixed(trait.survival_rate_in_five_years_female)}</label>
        </div>
        <div className={styles.TraitRow__Cell_name}>
            <Link className={styles.link} to={`/administration/votes/trait/${trait.id}`} title="Click on the link if you want review votes only for this trait">
                {trait.trait_name}
            </Link>
        </div>
    </div>
} 