import React, { PureComponent } from 'react';
export default class ReCaptcha extends PureComponent {
    verifyCallback = (data) => {
        const response = window.grecaptcha.getResponse(this.captcha)
        this.props.saveResponse(response)
        if (!response) window.grecaptcha.reset(this.captcha);
        this.props.changeVerifying(response && response.length, this.resetCaptcha);
    }

    resetCaptcha = () => {
        this.props.changeVerifying(false, this.resetCaptcha);
    }

    render() {
        return <>
            <div id="recaptcha_container"></div>
        </>
    }
    componentDidMount() {
        if (window.loadedCap) {
            this.captcha = window.grecaptcha.render('recaptcha_container', {
                'sitekey': '6LeprL0UAAAAAAR535pbWp1tc7QmRy3gv1RnxVrE',
                'callback': this.verifyCallback,
                'expired-callback': this.resetCaptcha
            });
        }
    }
}
