import React, { PureComponent } from 'react';
export default class FileInput extends PureComponent {
    ref = React.createRef()
    state={}
    render() {
        const { onChange, disabled, text, multiple = false } = this.props;
        return <>
            <button type="button" disabled={disabled} className="upload_btn" onClick={() => this.ref.current.click()}>{text}</button>
            <input type="file" multiple={multiple} onChange={e => { onChange([...e.target.files]); this.ref.current.files = this.state.initialFileList }} ref={this.ref} style={{ display: 'none' }} />
        </>
    }

    componentDidMount() {
        this.setState({initialFileList: this.ref.current.files});
    }
}