export const AUTHORS_ACTIONS = {
    LOAD_ALL_AUTHORS: 'LOAD_AUTHORS',
    RECEIVE_ALL_AUTHORS: 'RECEIVE_AUTHORS'
}

export const loadAllAuthors = () => ({
    type: AUTHORS_ACTIONS.LOAD_ALL_AUTHORS
})

export const receiveAllAuthors = (authors) => ({
    type: AUTHORS_ACTIONS.RECEIVE_ALL_AUTHORS,
    payload: authors
})