import { CATEGORIES_ACTIONS } from '../actions/constants';

export const categories = (state = [], action) => {
    switch (action.type) {
        case CATEGORIES_ACTIONS.RECEIVE: return action.payload;
        case CATEGORIES_ACTIONS.ADDED: return state.concat(action.payload);
        case CATEGORIES_ACTIONS.UPDATED: return state.map(c => c.id === action.payload.id ? action.payload : c);
        case CATEGORIES_ACTIONS.DELETED: return state.filter(c => c.id !== action.payload);
        default: return state;
    }
}
