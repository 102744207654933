import React, { PureComponent } from 'react';
import { Votes } from '.';
import { connect } from 'react-redux';
import actions from '../../store/actions';
class VotesContainer extends PureComponent {
    render() {
        return <Votes />;
    }

    componentDidMount() {
        this.props.loadTraits({withNotApproved: true});
        this.props.loadFields();
        this.props.loadChromosomes();
    }
};

const mapStateToProps = (store) => ({
    traits: store.traits,
    voteFields: store.voteFields
});

const mapDispatchToProps = {
    loadTraits: actions.traits.loadTraits,
    loadFields: actions.voteFields.loadVoteFields,
    loadChromosomes: actions.chromosomes.loadChromosomes
}

export default connect(mapStateToProps, mapDispatchToProps)(VotesContainer)