import React, { memo, Fragment } from 'react';
import styles from '../Votes.module.scss';
import { Vote } from '../Vote';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

const VotesList = (props) => {
    let { votes, sexId, demoId, fieldId, trait_name, user } = props;

    if (sexId > 0) votes = votes.filter(v => v.sexId === sexId);
    if (fieldId > 0) votes = votes.filter(v => v.fieldId === fieldId);
    if (demoId > 0) votes = votes.filter(v => v.demoId === demoId);

    let authors = Array.from(new Set(votes.map(v => v.authorId))).map(id => {
        let aVotes = votes.filter(v => v.authorId === id);
        aVotes = aVotes.sort((a, b) => b.id - a.id);
        return { id, votes: aVotes }
    }).filter(i => i.votes.length > 0);

    const getConfirmedByUser = (confirmations) => {
        if (!confirmations.length) return null;
        let userConfirmation = confirmations.find(item => item.userId === user.id);
        if (userConfirmation) return userConfirmation?.confirmed;
        return null;
    }

    return <div className={styles.TraitHistory}>
        {votes?.length === 0 && props.votes?.length > 0 && <div className={styles.NothingFound}>Nothing Found</div>}
        {authors.map(a => <Fragment key={a.id}>
            <div className={styles.authorTitle}>{a.votes[0]?.author?.login || 'unknown user'}</div>
            {a.votes.map(v =>
                <Vote
                    {...v}
                    confirmedByUser={getConfirmedByUser(v.confirmations)}
                    trait_name={trait_name}
                    key={v.id}
                >
                </Vote>)}
        </Fragment>)}
    </div>
}

const mapStateToProps = store => ({
    user: store.user
});

export default withRouter(connect(
    mapStateToProps
)(VotesList));
