import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';

import { DropZone, TooltipIcon } from '../../../common';
import NumberInput from '../../../common/NumberInput';
import './FormRowWithLink.scss';
import actions from '../../../../store/actions';
import {classList, internalMetricsTooltips} from '../../../../utils/index';
import s from "../WeightForm.module.scss";

const FormRowWithLink = props => {
    const { vote, updateVote, countries } = props;

    const [linkEditing, setLinkEditing] = useState(false);
    const currentYear = new Date().getFullYear();

    const deleteLink = () => {
        setLinkEditing(false);
        updateVote(vote.id, null, 'link');
    };
    let title = vote.title;

    return (
        <div className={linkEditing ? 'bordered_section' : ''}>
            {linkEditing ? (
                <div className="bordered_section-title">
                    {title} {!vote?.sex?.is_default && vote?.sex?.name ? ' ' + vote.sex.name : ''}
                </div>
            ) : null}
            <div className="form_row">
                <div className="form_col label">
                    <div className="question-icon-container">
                        <div className="text">
                            {title} {!vote?.sex?.is_default && vote?.sex?.name ? ' ' + vote.sex.name : ''}
                        </div>
                        <TooltipIcon content={<p>{props.tooltip}</p>} />
                    </div>
                </div>
                <div
                    className={`form_col field${
                        props.placeholder ? ' placeholder ' + props.placeholder : ''
                    }`}
                >
                    {React.cloneElement(props.children[0], {
                        value: vote.value === null ? '' : vote.value,
                        onChange: e => updateVote(vote.id, e.target.value),
                    })}
                </div>
                <div className="form_col link_col">
                    {!linkEditing === true && (
                        <div className="add_btn" onClick={() => setLinkEditing(true)}>
                            Add Source Link
                        </div>
                    )}
                </div>
            </div>
            {linkEditing && (
                <>
                    <div className="form_row">
                        <div className="form_col label">
                            <div className="text">Source Link</div>
                        </div>
                        <div className="link_editor form_col field">
                            {React.cloneElement(props.children[1], {
                                onBlur: e => updateVote(vote.id, e.target.value, 'link'),
                            })}
                            <div className="delete_link_btn" onClick={deleteLink}></div>
                        </div>
                    </div>

                    <div className="form_row">
                        <div className="form_col label">
                            <div className="text">Source Screenshot</div>
                        </div>
                        <div className="form_col dropzone_col field">
                            <DropZone
                                field="source_screenshot"
                                onDrop={file => updateVote(vote.id, file, 'source')}
                            />
                        </div>
                    </div>
                    <div className="form_row">
                        <div className="form_col label">
                            <div className="text">Source File (PDF)</div>
                        </div>
                        <div className="form_col dropzone_col field">
                            <DropZone
                                field="source_file"
                                onDrop={file => {
                                    updateVote(vote.id, file, 'source_file');
                                    updateVote(vote.id, file.name, 'source_file_name');
                                }}
                            />
                        </div>
                    </div>
                    <div className="form_row">
                        <div className="form_col label">
                            <div className="text">Citation</div>
                        </div>
                        <div className="form_col citation_col field">
                            {React.cloneElement(props.children[2], {
                                onBlur: e =>
                                    updateVote(vote.id, e.target.value, 'citation'),
                            })}
                        </div>
                    </div>

                    <div className="form_row">
                        <div className="form_col label">
                            <div className="question-icon-container">
                                <div className="text">Country of Study</div>
                                <TooltipIcon
                                    content={internalMetricsTooltips.countryOfStudy}
                                />
                            </div>
                        </div>
                        <div className="form_col field">
                            <Dropdown
                                search
                                placeholder='Select A Country...'
                                value={vote.country?.countryCode}
                                options={countries.map(c => ({
                                    value: c.countryCode,
                                    text: c.name,
                                }))}
                                onChange={(f, country) =>
                                    updateVote(
                                        vote.id,
                                        countries.find(
                                            c => c.countryCode === country.value
                                        ),
                                        'country'
                                    )
                                }
                            />
                        </div>
                    </div>

                    <div className="form_row">
                        <div className="form_col label">
                            <div className="question-icon-container">
                                <div className="text">Year of Study</div>
                                <TooltipIcon
                                    content={internalMetricsTooltips.yearOfStudy}
                                />
                            </div>
                        </div>
                        <div className="form_col field">
                            <NumberInput
                                min={currentYear - 100}
                                max={currentYear}
                                float={false}
                                placeholder="Year of Study"
                                onChange={e =>
                                    updateVote(vote.id, e.target.value, 'yearOfStudy')
                                }
                            />
                        </div>
                    </div>

                    <div className="form_row">
                        <div className="form_col label">
                            <div className="question-icon-container">
                                <div className="text">DOI/ISBN/ISSN</div>
                                <TooltipIcon
                                    content={internalMetricsTooltips.doiIsbnIssn}
                                />
                            </div>
                        </div>
                        <div className="form_col field">
                            <input
                                type="text"
                                placeholder="DOI/ISBN/ISSN"
                                onChange={e =>
                                    updateVote(vote.id, e.target.value, 'publicationIdentifier')
                                }
                            />
                        </div>
                    </div>

                    <div className="form_row">
                        <div className="form_col label">
                            <div className="question-icon-container">
                                <div className="text">Method of Calculation</div>
                                <TooltipIcon
                                    content={
                                        <p>
                                            If the number you have entered was not
                                            directly taken from the paper, please explain
                                            here how you arrived at the value.
                                        </p>
                                    }
                                />
                            </div>
                        </div>
                        <div className="form_col field">
                            <input
                                value={vote?.comment?.methodOfCalculation || ''}
                                onChange={e =>
                                    updateVote(vote.id, {
                                        ...vote.comment,
                                        methodOfCalculation: e.target.value
                                    }, 'comment')
                                }
                            />
                        </div>
                    </div>


                    <div className="form_row">
                        <div className="form_col label">
                            <div className="question-icon-container">
                                <div className="text">Screenshot of Calculation</div>
                                <TooltipIcon
                                    content={
                                        <p>
                                            If the number you have entered was not
                                            directly taken from the paper, please attach a
                                            screenshot of your method of calculation if
                                            that will help a reviewer understand your
                                            method of arriving at the value.
                                        </p>
                                    }
                                />
                            </div>
                        </div>
                        <div className={classList(s.DropzoneWrapper, 'form_col field')}>
                            <DropZone
                                field="source_screenshot"
                                onDrop={file =>
                                    updateVote(vote.id, {
                                        ...vote.comment,
                                        screenshotOfCalculation: file
                                    }, 'comment')
                                }
                            />
                        </div>
                    </div>

                    <div className="form_row">
                        <div className="form_col label">
                            <div className="question-icon-container">
                                <div className="text">Comment</div>
                            </div>
                        </div>
                        <div className="form_col field">
                            <textarea
                                value={vote?.comment?.comment || ''}
                                onChange={e =>
                                    updateVote(vote.id, {
                                        ...vote.comment,
                                        comment: e.target.value
                                    }, 'comment')
                                }
                            />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

const mapStateToProps = store => ({
    countries: store.countries,
});

const mapDispatchToProps = {
    updateVote: actions.newvotes.updateNewVote,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormRowWithLink);
