import React from 'react';
import { useHistory } from 'react-router-dom';
import s from './Research.module.scss';
const ControlHeader = ({ title, isNew, sectionType, showCreateButton = true, handleSubmit }) => {
    const history = useHistory();

    const handleGoBack = () => {
        history.goBack();
    }

    return (
        <div className={s.Head}>
                <button onClick={handleGoBack} className={s.Btn_Cancel}>
                    Cancel
                </button>
                {showCreateButton && (
                    <button className={s.Btn_Save} onClick={handleSubmit}>
                        {isNew ? 'Create' : 'Save'}
                    </button>
                )}
        </div>
    );
}

export default ControlHeader;