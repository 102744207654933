import { put, takeLatest, call } from 'redux-saga/effects';
import { LOG_ACTIONS } from '../actions/constants';
import * as actions from '../actions/log';
import * as api from '../../api/log';

function* sendLog(action) {
    try {
        yield call(api.send, action.payload);
    } catch(e) {
        console.error('Error sending log', e);
    }
}

export function* logSaga() {
    yield takeLatest(LOG_ACTIONS.SEND, sendLog);
}