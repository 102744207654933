import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import actions from '../../../store/actions';
import { classList, getCountryData } from '../../../utils';
import s from '../Regions.module.scss';
import { Demographics } from '.';
import { ComposableMap, Geographies, Geography, Marker } from 'react-simple-maps';
import { MarkerIcon } from '../../common/Map/MarkerIcon';

const EditRegion = React.memo((props) => {
    const { model: initalModel, closePopup, saveRegion, loading, preCreationData, regions } = props;
    const [zoom] = useState(1);
    const [clickedCity, setClickedCity] = useState("");

    let [model, setModel] = useState({
        id: null,
        name: '',
        short_name: '',
        population: null,
        population_source: '',
        births: null,
        births_source: '',
        demoraphics: [],
        demos_source: '',
        coordinates: null,
    });

    let [warnings, setWarnings] = useState({});

    useEffect(() => {
        if (initalModel) setModel(initalModel);

    }, [])

    useEffect(() => {
        if (preCreationData) setModel({ ...model, ...preCreationData });
    }, [preCreationData])

    const setValue = (value, field) => {
        if (field === 'name' || field === 'short_name') {
            let regionWithSameValue = regions.find(r => r[field].toLowerCase().trim() === value.toLowerCase().trim() && r.id !== model.id);
            if (regionWithSameValue) {
                setWarnings({ ...warnings, [field]: `The ${regionWithSameValue.name} region has the same value` })
            } else if (warnings[field]) {
                delete warnings[field];
                setWarnings(warnings);
            }
        }
        if (field === 'population' || field === 'births') {
            if (!model[field + '_source']) setWarnings({ ...warnings, [field + '_source']: 'Source is empty' });
        }

        if (~field.indexOf('_source') && value.length) {
            delete warnings[field];
            setWarnings(warnings);
        }

        setModel({ ...model, [field]: value });

    }

    const selectRegion = (geo) => {
        const data = getCountryData(geo);
        let existingRegion = regions && regions.filter(r => r.coordinates).find(r => r.coordinates[0] === data.coordinates[0] && r.coordinates[1] === data.coordinates[1]);
        if (existingRegion && existingRegion?.id !== model.id) {
            alert(`Can't place new region over existing region ${existingRegion.name}`);
        } else {
            setClickedCity(geo.properties.name);
            setModel((!model.id) ? { ...model, ...getCountryData(geo) } : { ...model, coordinates: getCountryData(geo).coordinates });
        }
    }

    const saveModel = () => {
        let isValid = true;
        let newWarnings = {};

        const checkLink = (field) => {
            try {
                let url = new URL(model[field]);
                model[field] = url.href;
                delete newWarnings[field];
            } catch (e) {
                newWarnings[field] = 'Invalid link';
                isValid = false;
            }
        }
        if (!model.name) {
            newWarnings.name = 'Please fill in Name field';
            isValid = false;
        }
        if (!model.short_name) {
            newWarnings.short_name = 'Please fill in Short Name field';
            isValid = false;
        }
        if (model.population && !model.population_source) {
            newWarnings.population_source = 'Please fill in population source';
            isValid = false;
        }

        if (model.births && !model.births_source) {
            newWarnings.births_source = 'Please fill in births/year source';
            isValid = false;
        }
        if (model?.demographics?.length && !model.demos_source) {
            newWarnings.demos_source = 'Please fill in Genomic Ancestry source';
            isValid = false;
        }
        let source_fields = [];

        if ((model.births || model.births === 0) && model.births_source) source_fields.push('births_source');
        if ((model.population || model.population === 0) && model.population_source) source_fields.push('population_source');
        if (model?.demographics?.length && model.demos_source) source_fields.push('demos_source');
        for (let field of source_fields) checkLink(field);

        if (model.births === '') {
            model.births = null;
        }
        if (model.population === '') {
            model.population = null;
        }

        if (model.id) {
            let summaryDemosPercent = (model.demographics || []).reduce((sum, demo) => sum + demo?.regions_demo.percent, 0);
            if (summaryDemosPercent !== 100) {
                newWarnings.demos_source = 'The Genomic Ancestry sum should be a 100%';
                isValid = false;
            } else if ((model.demographics || []).some(d => !d?.regions_demo?.percent)) {
                newWarnings.demos_source = 'Each Genomic Ancestry should have a positive percent value';
                isValid = false;
            }
        }

        if (!isValid) {
            setWarnings(newWarnings);
        } else saveRegion(model);
    }

    return (
        <div className={s.EditRegion}>
            <div className="regions-container">
                <ComposableMap>
                        <Geographies disableOptimization={true} geography={require('../../../assets/world-countries.json')}>
                            {({ geographies, projection }) =>
                                geographies.map(
                                    geo => {
                                        const isClicked = clickedCity === geo.properties.name;
                                        return (
                                            <Geography
                                                key={geo.rsmKey}
                                                geography={geo}
                                                projection={projection}
                                                className={isClicked && "rsm-geography__selected"}
                                                onClick={() => selectRegion(geo)}
                                            />
                                        )
                                    })
                            }
                        </Geographies>
                        {model.coordinates &&
                            <Marker coordinates={model.coordinates} >
                                <MarkerIcon  className={classList('rsm-marker-icon')} width={15 / zoom} height={22.5 / zoom} x={-7.5 / zoom} y={-22.5 / zoom} />
                            </Marker>
                        }
                </ComposableMap>
            </div>
            <div className={s.RegionsTable__Row}>
                <div className={classList(s.RegionsTable__Column, s.RegionsTable__Column_label)}>Name</div>
                <div className={classList(s.RegionsTable__Column, s.RegionsTable__Column_value)}>
                    <input type="text" value={model.name} onChange={e => setValue(e.target.value, 'name')} />
                    {warnings.name && <div className={s.Warning}>{warnings.name}</div>}
                </div>
                <div className={classList(s.RegionsTable__Column, s.RegionsTable__Column_source)}></div>
            </div>
            <div className={s.RegionsTable__Row}>
                <div className={classList(s.RegionsTable__Column, s.RegionsTable__Column_label)}>Short Name</div>
                <div className={classList(s.RegionsTable__Column, s.RegionsTable__Column_value)}>
                    <input type="text" value={model.short_name} onChange={e => setValue(e.target.value, 'short_name')} />
                    {warnings.short_name && <div className={s.Warning}>{warnings.short_name}</div>}
                </div>
                <div className={classList(s.RegionsTable__Column, s.RegionsTable__Column_source)}></div>

            </div>
            <div className={s.RegionsTable__Row}>
                <div className={classList(s.RegionsTable__Column, s.RegionsTable__Column_label)}>Population</div>
                <div className={classList(s.RegionsTable__Column, s.RegionsTable__Column_value)}>
                    <input type="number" min="0" value={model.population} onChange={e => setValue(e.target.value, 'population')} /></div>
                <div className={classList(s.RegionsTable__Column, s.RegionsTable__Column_source)}>
                    <input type="text" placeholder="Source" value={model.population_source} onChange={e => setValue(e.target.value, 'population_source')} />
                    {warnings.population_source && <div className={s.Warning}>{warnings.population_source}</div>}
                </div>
            </div>

            <div className={s.RegionsTable__Row}>
                <div className={classList(s.RegionsTable__Column, s.RegionsTable__Column_label)}>Births/year</div>
                <div className={classList(s.RegionsTable__Column, s.RegionsTable__Column_value)}>
                    <input type="number" min="0" value={model.births} onChange={e => setValue(e.target.value, 'births')} /></div>
                <div className={classList(s.RegionsTable__Column, s.RegionsTable__Column_source)}>
                    <input type="text" placeholder="Source" value={model.births_source} onChange={e => setValue(e.target.value, 'births_source')} />
                    {warnings.births_source && <div className={s.Warning}>{warnings.births_source}</div>}
                </div>
            </div>

            {model.id && <>
                <Demographics model={model} setValue={setValue} warnings={warnings} onChange={setModel} />
            </>}

            <div className={s.EditRegion__Buttons}>
                <button className={s.EditRegion__CancelBtn} onClick={() => closePopup()}>Cancel</button>
                <button
                    className={s.EditRegion__SaveBtn}
                    onClick={saveModel}
                    disabled={Object.keys(warnings).length !== 0}
                >
                    Save
                </button>
            </div>
            {loading && <div className={s.loading}></div>}
        </div>
    )
})

const mapStateToProps = (store) => ({
    regions: store.adminRegions,
    loading: store.loading.some(l => l === 'regions')
})

const mapDispatchToProps = {
    closePopup: actions.popups.closePopup,
    saveRegion: actions.adminRegions.saveRegion
}

export default connect(mapStateToProps, mapDispatchToProps)(EditRegion);
