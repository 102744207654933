import React, { PureComponent } from 'react';

import './RangeInput.scss';

export default class RangeInput extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            value: 50,
            moving: false
        };
        this.rangeRef = React.createRef();
    }



    handleChange = value => {
        this.props.handleBlur(value);
    }

    render() {
        const { min, max, disabled, step, value, className } = this.props;
        let left = +value < min ? min : +value;
        left = left > max ? max : left;

        let rest = left % step;
        left -= rest;
        if (rest >= step / 2) left += step;

        left = (left - min) / (max - min) * 100;
        return (
            <>
                <div
                    className={`range_input${disabled ? ' disabled' : ''}${className ? ' ' + className : ''}`}
                    onMouseDown={this.onMouseDown}
                    onTouchStart={this.onTouchStart}
                    ref={this.rangeRef}
                >
                    <div className="range_input-runner" style={{ left: `${left}%` }}>
                        <div className="value">{Math.round(value || this.props.defaultValue) || 'Not selected'}</div>
                    </div>
                    <div className="range_input-second_part" style={{ left: `${left}%` }}></div>
                    <div className="range_input-min">{min}</div>
                    <div className="range_input-max">{max}</div>
                </div>
            </>
        );
    }

    componentDidMount() {
        window.addEventListener('mouseup', this.onMouseUp);
        window.addEventListener('touchend', this.onTouchEnd);
        window.addEventListener('mousemove', this.onMouseMove);
        window.addEventListener('touchmove', this.onTouchMove);
        this.setState({ value: this.props.value });
    }
    componentWillUnmount() {
        window.removeEventListener('mouseup', this.onMouseUp);
        window.removeEventListener('touchend', this.onTouchEnd);
        window.removeEventListener('mousemove', this.onMouseMove);
        window.removeEventListener('touchmove', this.onTouchMove);
    }

    onMouseDown = (e) => {
        if (this.props.disabled) return;
        const { min, max, step } = this.props;
        let elemRect = e.currentTarget.getBoundingClientRect()
        const relativeLeft = e.pageX - elemRect.left;

        let newValue = (relativeLeft / elemRect.width) * (max - min) + min;
        let rest = newValue % step;
        newValue -= rest;
        if (rest >= step / 2) newValue += step;

        if (relativeLeft < 0) newValue = this.props.min;
        if (relativeLeft > elemRect.width) newValue = this.props.max;
        this.props.onChange({target: {value: newValue}});
        this.setState({ moving: true })
    }
    onMouseMove = (e) => {
        if (!this.state.moving) return;
        // e.stopPropagation();
        const { min, max, step } = this.props;
        let elemRect = this.rangeRef.current.getBoundingClientRect()
        const relativeLeft = e.pageX - elemRect.left;

        let newValue = (relativeLeft / elemRect.width) * (max - min) + min;
        let rest = newValue % step;
        newValue -= rest;
        if (rest >= step / 2) newValue += step;

        if (relativeLeft < 0) newValue = this.props.min;
        if (relativeLeft > elemRect.width) newValue = this.props.max;

        this.props.onChange({target: {value: newValue}});
    }

    onMouseUp = (e) => {
        this.setState({ moving: false });
    }
    onTouchStart = (e) => {
        e.stopPropagation();
        if (this.props.disabled) return;
        this.setState({ moving: true })
    }
    onTouchMove = (e) => {
        if (!this.state.moving) return;
        // e.stopPropagation();
        const { min, max, step } = this.props;
        let elemRect = this.rangeRef.current.getBoundingClientRect()
        const relativeLeft = e.touches[0].clientX - elemRect.left;

        let newValue = (relativeLeft / elemRect.width) * (max - min) + min;
        let rest = newValue % step;
        newValue -= rest;
        if (rest >= step / 2) newValue += step;

        if (relativeLeft < 0) newValue = this.props.min;
        if (relativeLeft > elemRect.width) newValue = this.props.max;

        if (this.props.value !== newValue) this.props.onChange({target: {value: newValue}});
    }
    onTouchEnd = (e) => {
        this.setState({ moving: false });
    }
}