import { takeLatest, call } from 'redux-saga/effects';
import { PRIVATE_ACTIONS } from '../actions/constants';
import User from '../../api/users';

function* changePrivacy(action) {
    let value = String(action.payload).trim();
    localStorage.setItem('private', value);
    // if (value === 'false') {
    //     try {
    //         let res = yield call(User.checkSession);
    //         User.setSessionData(res.data);
    //     } catch (err) {
    //         //localStorage.clear();
    //         //window.location.reload();
    //     }
    // }
}

export function* privacySaga() {
    yield takeLatest(PRIVATE_ACTIONS.CHANGE, changePrivacy);
}