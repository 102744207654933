import { all } from 'redux-saga/effects';
import { traitsSaga } from './traits.saga';
import { sexSaga } from './sex.saga';
import { privacySaga } from './privacy.saga';
import { genomicIndexSaga } from './genomic.index.saga'
import { demographicsSaga } from './demographics.saga'
import { usersSaga } from './users.saga'
import { votesSaga } from './votes.saga'
import { chromosomesSaga } from './chromosomes.saga'
import { logSaga } from './log.saga'
import { adminRegionsSaga } from './admin.regions.saga'
import { essSaga } from './ess.saga'
import { versionsSaga } from './versions.saga'
import { pheaSaga } from './phea.saga'
import { categoriesSaga } from './categories.saga'
import { settingsSaga } from './settings.saga'
import { researchSaga } from './research.saga'
import { countriesSaga } from './countries.saga'
import { statisticSaga } from "./statistic.saga";

export default function* sagas() {

    yield all(
        [
            traitsSaga(),
            sexSaga(),
            privacySaga(),
            genomicIndexSaga(),
            demographicsSaga(),
            usersSaga(),
            votesSaga(),
            chromosomesSaga(),
            logSaga(),
            adminRegionsSaga(),
            essSaga(),
            versionsSaga(),
            pheaSaga(),
            categoriesSaga(),
            settingsSaga(),
            researchSaga(),
            countriesSaga(),
            statisticSaga()
        ]
    )
}
