import React, { PureComponent } from 'react';
import { Users } from '.';
import { connect } from 'react-redux';

class UsersContainer extends PureComponent {
    render() {
        return <Users />;
    }

    componentDidMount() {
    }
};

const mapStateToProps = (store) => ({
    users: store.users
});

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersContainer)