import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Menu, Grid, Sidebar, Icon, Button } from 'semantic-ui-react';

import './styles.css';

export default class HeaderMobile extends Component {

    state = {
        visible: false
    };

    handleClick = () => this.setState({ visible: !this.state.visible });

    render() {
        const { visible } = this.state;

        return (
            <Sidebar.Pushable className={!visible && 'hidden'}>
                <Sidebar
                    className='nav-menu-mobile'
                    direction='top'
                    as={Menu}
                    animation='overlay'
                    vertical
                    visible={visible}
                    width='wide'
                >
                    <Menu.Item href='/' fitted='vertically'>
                        <h3>HOME</h3>
                    </Menu.Item>
                    {/* <Menu.Item href='/choose' fitted='vertically'>
                        <h3>CHOOSE</h3>
                    </Menu.Item>
                    <Menu.Item href='/design' fitted='vertically'>
                        <h3>DESIGN</h3>
                    </Menu.Item> */}
                    {/* <Menu.Item href='/browse' fitted='vertically'>
                        <h3>BROWSE</h3>
                    </Menu.Item> */}
                    <Menu.Item href='/weight' fitted='vertically'>
                        <h3>WEIGHT</h3>
                    </Menu.Item>
                    <Menu.Item fitted='vertically'>
                        <Button className='secondary-button menu-button' onClick={this.handleClick}>
                            <Icon name='sidebar' size='large' />
                        </Button>
                    </Menu.Item>
                </Sidebar>
                <Sidebar.Pusher>
                    <Grid>
                        <Grid.Row verticalAlign='middle'>
                            <div className="menu_toggler">
                                <Button className='secondary-button menu-button' onClick={this.handleClick}>
                                    <Icon name='sidebar' size='big' />
                                </Button>
                            </div>
                            <Grid.Column width={16} textAlign='center'>
                                <Link to='/'>
                                    <h3 className='title'>Embryo Selection</h3>
                                </Link>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Sidebar.Pusher>
            </Sidebar.Pushable>
        );
    };
};
