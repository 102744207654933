import React, { PureComponent, Fragment } from 'react';
import { TraitCategoriesDropdown, DropZone, NativeRangeInput, DistCurveCell } from '../common';
import DownloadObjectLink from '../common/DownloadObjectLink';
import { TextArea, Dropdown, Input } from 'semantic-ui-react'
import Checkbox from '../common/CustomCheckbox';
import JsonDrop from '../common/JsonDrop';
import JsonDropRelative from '../common/JsonDropRelative';
export default class GridCell extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { editing: false, validationError: '' }
        this.toggleEditMode = this.toggleEditMode.bind(this);
        this.offEditMode = this.offEditMode.bind(this);
    }

    toggleEditMode() {
        const { fieldMeta } = this.props;

        if (fieldMeta.fieldType === 'TEXT' || fieldMeta.fieldType === 'SELECT' || fieldMeta.fieldType === 'CATEGORIES') {
            this.setState({ editing: true })
        }
    }

    onEditMode = () => {
        this.setState({ editing: true })
    }

    onJsonRelativeChange = (data) => {
        const { field, item } = this.props;
        let jsonData = [...(item[field] || [])];

        if (jsonData.some(i => i.demographic.toLowerCase().trim() === data.demographic.toLowerCase().trim() && (+i.family_history || 0) === (+data.family_history || 0))) {
            jsonData = jsonData.filter(i => !(i.demographic.toLowerCase().trim() === data.demographic.toLowerCase().trim() && (+i.family_history || 0) === (+data.family_history || 0))).concat(data);
        } else {
            jsonData = [...jsonData, data];
        }

        this.props.handleChange(item.id, field, jsonData, item);
    }

    offEditMode(e) {
        e.stopPropagation();
        this.setState({ editing: false, validationError: '' });
    }

    deleteTrait = (e) => {
        e.stopPropagation();
        this.props.handleDelete(this.props.item.id);
    }

    handleTextChange = (newValue) => {
        if (!this.state.validationError) {
            this.setState({ editing: false, validationError: '' });
            this.props.handleChange(this.props.item.id, this.props.field, newValue, this.props.item);
        }
    }

    validateText = (newValue) => {
        if (this.props.fieldMeta.maxLength && this.props.fieldMeta.maxLength < newValue.length) {
            this.setState({ validationError: `The text should be less than ${this.props.fieldMeta.maxLength} characters` });
            return;
        }

        if (this.props.fieldMeta.unique && this.props.checkIsUnique(newValue)) {
            this.setState({ validationError: `Value must be unique!` });
            return;
        }

        if (this.state?.validationError) {
            this.setState({ validationError: '' });
        }
    }

    render() {
        const { field, fieldMeta, item, handleChange, categories } = this.props;
        const { editing, validationError } = this.state;

        return <div className={`edit-traits_table_cell${editing ? ' editing' : ''}${field === 'trait_name' ? ' name-cell' : ''}`} onClick={this.toggleEditMode}>
            {fieldMeta.fieldType === 'TEXT' && (
                <div>
                    {editing ? <TextArea
                            className='edit-traits_table_cell--text'
                            name={item.id}
                            defaultValue={item[field]}
                            onInput={e => this.validateText(e.target?.value)}
                            onBlur={e => this.handleTextChange(e.target.value)}
                        /> :
                        <div className={field === 'trait_name' ? 'edit-traits_disease-title' : ''}>
                            {item[field] && item[field].length > 150 ? item[field].slice(0, 150) + '...' : item[field]}
                            {field === 'trait_name' &&
                                <div className="edit-traits_delete-btn" onClick={this.deleteTrait}></div>}
                        </div>
                    }
                    {validationError && <div className="validation_error_text">{validationError}</div>}
                </div>
            )}
            {fieldMeta.fieldType === 'NUMBER' && (
                editing && fieldMeta.editing !== false ? <Input
                    className='edit-traits_table_cell--number'
                    name={item.id}
                    type="number"
                    defaultValue={item[field]}
                    onBlur={e => { this.setState({ editing: false }); handleChange(item.id, field, e.target.value === '' ? null : e.target.value, item) }}
                /> : <div>{item[field] && Math.round(item[field] * 100) / 100 || null}</div>)}
            {fieldMeta.fieldType === 'SELECT' && (
                <Dropdown
                    className='edit-traits_table_cell--dropdown'
                    selection
                    options={fieldMeta.options && fieldMeta.options.map(option => option)}
                    defaultValue={item[field]}
                    onClick={e => this.setState({ editing: !this.state.editing })}
                    onChange={(e, { value }) => handleChange(item.id, field, value, item)}
                />
            )}
            {fieldMeta.fieldType === 'CATEGORIES' && (
                <TraitCategoriesDropdown
                    className='edit-traits_table_cell--dropdown'
                    defaultValue={item[field]}
                    categories={categories}
                    onClick={e => this.setState({ editing: !this.state.editing })}
                    onChange={async (value) => handleChange(item.id, field, value, item)}
                />
            )}
            {fieldMeta.fieldType === 'CHECK' && (
                <Checkbox
                    className='edit-traits_table_cell--checkbox'
                    value={item[field]}
                    onChange={(e, checked) => handleChange(item.id, field, checked, item)}
                />
            )}
            {fieldMeta.fieldType === 'RANGE' && (
                <NativeRangeInput
                    className='edit-traits_table_cell--range'
                    defaultValue={item[field]}
                    min={fieldMeta.range[0]}
                    max={fieldMeta.range[1]}
                    handleBlur={value => handleChange(item.id, field, parseInt(value, 10), item)}
                />
            )}
            {fieldMeta.fieldType === 'FILE' && (
                <DropZone
                    className='edit-traits_table_cell--drop'
                    defaultValue={item[field]}
                    field={field}
                    onDrop={file => handleChange(item.id, field, file, item)}
                    handleNewImage={value => handleChange(item.id, 'newImages', { images: value, id: item.id }, item)}
                    handleDeleteImage={value => {
                        handleChange(item.id, 'deleteImages', {
                            images: [...(
                                (item?.deleteImages?.images && item.deleteImages.images) || []),
                                value
                            ],
                            id: item.id
                        }, item)
                    }}
                />
            )}
            {fieldMeta.fieldType === 'JSON_FILE' && (
                <JsonDrop
                    defaultValue={item[field]}
                    traitName={item.trait_name}
                    field={field}
                    handleChange={value => handleChange(item.id, field, value, item)}
                />
            )}
            {fieldMeta.fieldType === 'JSON_FILE_RELATIVE' && <>
                {(Array.isArray(item[field]) ? item[field] : []).filter(i => !i.toDelete).map((i, index) => (
                    <div key={String(i.file) + index} className="json-drop-relative__container">
                        <JsonDropRelative
                            demographic={i.demographic}
                            family_history={i.family_history}
                            file={i.file}
                            field={field}
                            handleChange={this.onJsonRelativeChange}
                        />
                        <DownloadObjectLink field={field} file={i.file} />
                    </div>))}
                <JsonDropRelative
                    demographic={null}
                    family_history={null}
                    field={field}
                    file={null}
                    handleChange={this.onJsonRelativeChange}
                />

            </>}
            {fieldMeta.fieldType === 'DIST_CURVE' && (
                <DistCurveCell
                    defaultValue={item[field]}
                    traitName={item.trait_name}
                    field={field}
                    handleChange={value => handleChange(item.id, field, value, item)}
                    handleNewCurve={value => handleChange(item.id, 'newCurve', { ...value, trait: item.trait_name }, item)}
                />
            )}
            <div className={`opaciter${editing ? ' editing' : ''}`} tabIndex="0" onClick={this.offEditMode}></div>
        </div>
    }
}