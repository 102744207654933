import axios from 'axios';
import config from '../config';

const api = axios.create({
    baseURL: `${config.GSBS_API}`,
});

export const generateGSBSFile = ({ embryos, scoreData, sexesData }) => {
        return api.post(`/${embryos}`, { scoreData, sexesData });
}
