import React, { PureComponent } from 'react';

import { Dropdown } from "semantic-ui-react";

export default class TraitCategoriesDropdown extends PureComponent {

    prepareOptions = () => { 
        const { categories } = this.props;

        return categories && categories.length ? categories.map(category => ({
            value: category.id,
            text: category.name === 'DK' ? 'Don\'t know' : category.name
        })) : null;
    };

    render() {
        const { defaultValue, onChange, ...props } = this.props;
        return(
            <Dropdown 
                { ...props }
                selection 
                defaultValue={defaultValue}
                options={this.prepareOptions()} 
                onChange={(e, { value }) => onChange(value)}
            />
        );
    };
};