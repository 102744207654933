import { CHROMOSOMES_ACTIONS } from './constants';

export const loadChromosomes = () => ({
    type: CHROMOSOMES_ACTIONS.LOAD
});

export const receiveChromosomes = (data) => ({
    type: CHROMOSOMES_ACTIONS.RECEIVE,
    payload: data
});

export const updateChromosomes = (data) => ({
    type: CHROMOSOMES_ACTIONS.UPDATE,
    payload: data
});