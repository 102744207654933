import React from 'react';

import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import s from './Settings.module.scss';
import { loadSettings } from '../../store/actions/settings';
import { classList } from '../../utils';
import { PerformanceSettings } from '.';

class Settings extends React.PureComponent {
    render() {
        const { settings = [], isLoading, match: { params } } = this.props;
        if (params?.id === 'relative-risk-reduction') return <PerformanceSettings />

        return <div className={classList(s.Settings, isLoading && s.Loading)}>
            <h2 className={s.Title}>Settings</h2>
            <div className={s.Table}>
                    <div className={classList( s.Table__Row,  s.Table__Row_Head)}>
                        <div className={s.Table__Column}>Type</div>
                        <div className={s.Table__Column}>Actions</div>
                    </div>

                    {settings.map(i => <div className={s.Table__Row} key={i.id}>
                        <div className={s.Table__Column}>
                            <Link to={`/administration/settings/${i.id}`}>{i.title}</Link>
                        </div>
                        <div className={s.Table__Column}>
                            <Link className={s.Btn} to={`/administration/settings/${i.id}`}>
                                Edit
                            </Link>

                        </div>
                    </div>)}
            </div>
        </div>
    }

    componentDidMount() {
        if (!this.props.settings.length) this.props.loadSettings();
    }
}

const mapDispatchToProps = {
    loadSettings
}

const mapStateToProps = (store) => ({
    settings: store.settings,
    isLoading: store.loading.some(l => l === 'settings')
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Settings))