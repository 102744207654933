import React, {Component} from 'react';

import {Container, Grid, Header, Card} from 'semantic-ui-react'

export default class About extends Component {
    render() {
        return (
            <div>
                <div style={{backgroundColor: '#B670C1', borderBottom: '1px solid black'}}>
                    <div style={{padding: '20px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                        <h2 style={{color: '#fff', fontFamily: 'Montserrat', fontWeight: 700, fontSize: '24px'}}>ABOUT</h2>
                    </div>
                </div>
                <Container text style={{marginTop: '2em'}}>
                <Grid centered>
                    <Grid.Row>
                        <Grid.Column>
                            <p>Embryo Selection is a non-commercial, non-profit, independent research entity, exploring the ethics of reproductive technology and genetics. Based in Denmark, Embryo Selection aims to be international in scope, gathering data and performing research globally. Research output from Embryo Selection is available to all entities working in the space of reproductive technology and genetics. None of Embryo Selection's researchers or ethicists are compensated, or have other conflict of interest.</p>
                            <p>A broad goal of Embryo Selection is to develop data-driven research into the ethics of reproduction, in a format usable to IVF couples and IVF clinicians doing Preimplantation Genetic Testing (PGT). This includes, but is not limited to, education and outreach generally, and research on ethics related to genomic science more broadly. Embryo Selection gathers data in the form of responses to ethical questions and judgements from a pool of surveyed individuals; many of these from outside of the IVF field. This data is used to build a crowd-sourced, empirical ethics framework for assisting difficult decisions in human reproduction.</p>
                            <p>The rapidly developing science of embryo screening and modification needs this research data. The scenarios faced in advanced IVF have grave ethical consequence. Crowd-sourcing ethical intuitions is both necessary and overdue.</p>
                            <p>Embryo Selection was inspired by the Google Cars affiliated ethics project <a href="https://www.forbes.com/sites/oliversmith/2018/03/21/the-results-of-the-biggest-global-study-on-driverless-car-ethics-are-in/#159496f84a9f">MIT Moral Machine</a>. The <a href="http://moralmachine.mit.edu/">output data of MMM</a> is being used to inform life-or-death decisions by cars being driven by AI. These decisions, about preservation and loss of life in the context of automobile transport, are comparable in moral weight to the decisions made in embryo selection.</p>
                            </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Header as='h2' textAlign='center'>
                            <Header.Content>Ethicists</Header.Content>
                        </Header>
                    </Grid.Row>
                    <Grid.Row>
                        <Card.Group centered>
                            <Card
                                href='https://publichealth.ku.dk/staff/?pure=en/persons/174046'
                                header='Prof. Lene Koch'
                                meta='Copenhagen University'
                            />

                            <Card
                                href='https://sites.google.com/view/jonathananomaly/home'
                                header='Jonathan Anomaly'
                                meta='University of San Diego'
                            />

                            <Card
                                href='http://faculty.smu.edu/jkazez/pap/savulescu-kahane.pdf'
                                header='Julian Savulescu'
                                meta='Southern Methodist University'
                            />

                            <Card
                                href='http://faculty.smu.edu/jkazez/pap/savulescu-kahane.pdf'
                                header='Guy Kahane'
                                meta='Southern Methodist University'
                            />

                            <Card
                                href='http://nicholasagar.com/'
                                header='Nicholas Agar'
                                meta='Victoria University of Wellington'
                            />

                            <Card
                                href='http://www.eubios.info/'
                                header='Daryl Macer'
                                meta='Eubios Ethics Institute'
                            />

                            <Card
                                href='https://sls.gmu.edu/ilya-somin/'
                                header='Ilya Somin'
                                meta='George Mason University'
                            />

                            <Card
                                href='https://www.linkedin.com/in/tyler-cowen-166718/'
                                header='Tyler Cowen'
                                meta='George Mason University'
                            />
                        </Card.Group>
                    </Grid.Row>

                    <Grid.Row>
                        <Header as='h2' textAlign='center'>
                            <Header.Content>Technical Staff</Header.Content>
                        </Header>
                    </Grid.Row>
                    <Grid.Row style={{marginBottom: "2em"}}>
                        <Card.Group centered>
                            <Card
                                href='https://www.facebook.com/DjangroNeman'
                                header='Jan Gronemann'
                                meta='Administrator, Treasurer, and Legal.'
                            />

                            <Card
                                href='https://github.com/daytonmills'
                                header='Dayton Mills'
                                meta='Frontend Developer and UX/UI'
                            />

                            <Card
                                href='https://joseph-moussa.herokuapp.com/'
                                header='Joe Moussa'
                                meta='Backend Developer'
                            />

                            <Card
                                href='http://adamlgreen.com/'
                                header='Adam Green'
                                meta='Game and Item design'
                            />
                        </Card.Group>
                    </Grid.Row>
                </Grid>
            </Container>
            </div>
        );
    }
}