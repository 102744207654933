import React, { Component } from 'react';
import $ from 'jquery';

import { default as NukaCarousel } from 'nuka-carousel';

import { Pagination, Embed } from 'semantic-ui-react'

export default class Carousel extends Component {

    state = {
        isSliding: false,
        active: 0,
        windowWidth: $(window).width()
    };

    componentDidMount = () => {
        this.setState({ windowWidth: $(window).width() })
        window.onresize = () => {
            setTimeout(() => {
                this.setState({ windowWidth: $(window).width() });
            }, 300);
        };
    };

    render() {
        const { isSliding, active, windowWidth } = this.state;
        const { desktop } = this.props;

        return (
            <NukaCarousel 
                beforeSlide={() => this.setState({ isSliding: true })}
                afterSlide={() => this.setState({ isSliding: false })}
                width={desktop ? '960px' : `${((windowWidth || $(window).width()) - 30)}px`}
                slideWidth={desktop ? '960px' : `${((windowWidth || $(window).width()) - 30)}px`}
                speed={300}
                autoplay={false}
                renderCenterLeftControls={() => null}
                renderCenterRightControls={() => null}
                renderBottomCenterControls={({ goToSlide }) => (
                    <div className='media-pagination'>
                        <Pagination
                            pointing
                            secondary
                            disabled={isSliding}
                            defaultActivePage={1}
                            firstItem={null}
                            lastItem={null}
                            prevItem={{
                                'aria-label': 'Prev item',
                                content: '⟨'
                            }}
                            nextItem={{
                                'aria-label': 'Next item',
                                content: '⟩'
                            }}
                            onPageChange={(e, data) => {
                                goToSlide(data.activePage - 1);
                                this.setState({ active: data.activePage - 1 });
                            }}
                            totalPages={5}
                        />     
                    </div>
                )}
            >
                <Embed autoplay={false} active={active === 0} id='t83j-uhISM0' source='youtube' />
                <Embed autoplay={false} active={active === 1} id='9VVqYmf5s18' source='youtube' />
                <Embed autoplay={false} active={active === 2} id='bThajoCIJng' source='youtube' />
                <Embed autoplay={false} active={active === 3} id='XCO8ET66xE4' source='youtube' />
                <Embed autoplay={false} active={active === 4} id='EPmQyfG1xYE' source='youtube' />
            </NukaCarousel>
        );
    }
}
