import React from 'react';
import { classList } from '../../../utils';
import s from '../PHEA.module.scss';
import table from '../../common/Table.module.scss';
import { ShowNumber } from '../../Ess/ESSTable';

const TotalRow = React.memo((props) => {
    const { model } = props;
    return (
        <div className={classList(table.Table__Row, s.TotalRow)}>
            <div className={classList(table.Table__Column, s.Column_disease)}>{model.disease}</div>
            <div className={classList(table.Table__Column, s.Column_number)}><ShowNumber specificField="common" value={model.economic_impact_rrr} emptyText='-' afterText='/person' preText='$' /></div>
            <div className={classList(table.Table__Column, s.Column_number)}><ShowNumber value={model.lifespan_impact_rrr} emptyText='-' afterText=' years/person' /></div>
            <div className={classList(table.Table__Column, s.Column_number)}><ShowNumber specificField="common" value={model.life_quality_impact_rrr} emptyText='-' afterText=' DALY/person' /></div>
        </div>
    )
})


export default TotalRow;