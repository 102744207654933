import React, { useState, memo, useEffect } from 'react';
import { File } from './File';
import { generateGSBSFile } from '../../../api/gsbs';
import { closePopup } from '../../../store/actions/popups';
import { connect } from 'react-redux';
import { classList } from '../../../utils';

const GSBSGenerator = memo(({ embryos, onGenerated, closePopup }) => {
    let [timer, setTimer] = useState(null);
    let [sexFile, setSexFile] = useState(null);
    let [scoreFile, setScoreFile] = useState(null);
    let [loading, setLoading] = useState(false);

    useEffect(() => {
        return () => clearInterval(timer);
    }, [closePopup, timer])

    const checkGSBSGeneration = async () => {
        try {
            const response = await generateGSBSFile({ embryos });
            const { data } = response;


            if (data.status === 'done' && data.data) {
                clearInterval(timer);
                setLoading(false);
                onGenerated({ gsbs: { data: data.data, name: `gsbs.${embryos}.csv` }, diff: { data: data.diff, name: 'differentials.csv' } });
                closePopup();
            } else {
                setLoading(true);
            }
        } catch (e) {
            clearInterval(timer);
            setLoading(false);
            alert('Something went wrong');
        }

    }

    const generateGSBS = async () => {
        try {
            setLoading(true);
            await generateGSBSFile({ embryos, sexesData: sexFile.data, scoreData: scoreFile.data });
            setTimer(setInterval(checkGSBSGeneration, 1000));
            setLoading(true);
        } catch (e) {
            console.log(e);
            alert('Something went wrong');
            setLoading(false);
        }
    }

    return (
        <div className={classList('gsbs-generator', loading && 'loading')}>
            <div className="gsbs_panel">
                <label>Sibscore file</label><File file={scoreFile} format=".csv" onChange={(file) => setScoreFile(file)} />
                <label>Sibsexes file</label><File file={sexFile} format=".fam" onChange={(file) => setSexFile(file)} />
            </div>
            {sexFile && scoreFile && <button type="button" onClick={generateGSBS}>Generate GSBS</button>}
        </div>
    )
})

export default connect(() => ({}), { closePopup })(GSBSGenerator)