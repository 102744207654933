import { LOADING_ACTIONS } from './constants';


export const startLoading = (component = 'all') => ({
    type: LOADING_ACTIONS.START,
    payload: component

});

export const endLoading = (component = 'all') => ({
    type: LOADING_ACTIONS.END,
    payload: component
});

export const startVoteLoading = (id) => ({
    type: LOADING_ACTIONS.START_VOTE_LOADING,
    payload: id

});

export const endVoteLoading = (id) => ({
    type: LOADING_ACTIONS.END_VOTE_LOADING,
    payload: id
});

export const startFrequencyLoading = (id) => ({
    type: LOADING_ACTIONS.START_FREQUENCY_LOADING,
    payload: id

});

export const endFrequencyLoading = (id) => ({
    type: LOADING_ACTIONS.END_FREQUENCY_LOADING,
    payload: id
});