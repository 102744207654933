import * as Yup from 'yup';

export default Yup.object().shape({
    title: Yup.string()
        .min(7, 'Too Short')
        .max(250 ,'Too Long')
        .required('Required'),
    url: Yup.string()
        .min(2, 'Too Short')
        .url()
        .when('document', function(field) {
            if (field == null) return this.required('Required');
        }),
    document: Yup.mixed()
        .when('url', function(field) { 
            if (field == null) return this.required('Required');
        }),
    authors: Yup.array().of(
        Yup.string()
            .min(2, 'Too Short')
            .max(100, 'Too Long')
            .required('Required')
    ).min(1, 'Required')
}, ['document', 'url']);