import * as performanceActions from '../actions/performanceTabs';
const { PERFORMANCE_TABS_ACTIONS: ACTIONS } = performanceActions;

const tabs = [
    {id: 'overview', name: 'Overview', active: true},
    {id: 'scientific', name: 'Scientific Details', active: false}
]

export const performanceTabs = (state = tabs, action) => {
    switch (action.type) {
        case ACTIONS.CHANGE: return state.map(t => ({...t, active: t.id === action.payload}));
        case ACTIONS.RECEIVE: return action.payload;
        default: return state;
    }
}